import React from 'react'
import './section4.css'
import location from '../../images/location.svg'
import view360degre from '../../images/360.svg'
import { useNavigate } from 'react-router-dom'

function Section4() {
    return (
        <div className='sec4bg'>
            <div className='container'>
                <div className='py-5 px-5'>
                    <div className='row g-3'>
                        <div className='col-md-5'>
                            <div className='imgwrap1'>
                            <div className='centered-project'>
                                        CLICK TO VIEW THE PROJECT
                                    </div>
                            </div>
                        </div>
                        <div className='col-md-5 offset-md-2'>
                            <div className='in4'>
                                  <div className='imgwrap2'>
                                  <div className='centered-project2'>
                                        <div>
                                        <img src={view360degre} />
                                        </div>
                                        
                                    </div>
                                    <div className='centered-project3'>
                                            Click To View Apartment in 360
                                        </div>
                                  </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Section4
