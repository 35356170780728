import React from 'react'
import location from '../../images/location.svg'
import './view360.css'
import view360degre from '../../images/360.svg'

function View360fullpage() {
    return (
        <div>
            <div>
                <div>
                    <div>
                        <img src={require('../../images/5ph.jpg')} style={{ width: '100%' }} />
                    </div>

                    <div className='sec4bg'>
                        <div className='container'>
                            <h2 className='pt-5'>View All Projects</h2>
                            <div className='pb-5 px-5'>
                                <div className='row g-3'>
                                    <div className='col-md-5 mt-md-5 mt-5 mt-md-0'>
                                        <div className='in4'>
                                            <div className='imgwrap2'>
                                                <div className='centered-project2'>
                                                    <div>
                                                        <img src={view360degre} />
                                                    </div>

                                                </div>
                                                <div className='centered-project3'>
                                                    Click To View Apartment in 360
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <h1>Objekti 1 Tipi A</h1>
                                            <div>
                                                <table class="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th>Surface	</th>
                                                            <th>Room</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>123.9 ㎡</td>
                                                            <td>4</td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-5 mt-md-5 offset-md-2'>
                                        <div className='in4'>
                                            <div className='imgwrap2'>
                                                <div className='centered-project2'>
                                                    <div>
                                                        <img src={view360degre} />
                                                    </div>

                                                </div>
                                                <div className='centered-project3'>
                                                    Click To View Apartment in 360
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <h1>Objekti 1 Tipi A</h1>
                                            <div>
                                                <table class="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th>Surface	</th>
                                                            <th>Room</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>123.9 ㎡</td>
                                                            <td>4</td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-5 '>
                                        <div className='in4'>
                                            <div className='imgwrap2'>
                                                <div className='centered-project2'>
                                                    <div>
                                                        <img src={view360degre} />
                                                    </div>

                                                </div>
                                                <div className='centered-project3'>
                                                    Click To View Apartment in 360
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <h1>Objekti 1 Tipi A</h1>
                                            <div>
                                                <table class="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th>Surface	</th>
                                                            <th>Room</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>123.9 ㎡</td>
                                                            <td>4</td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-5 offset-md-2'>
                                        <div className='in4'>
                                            <div className='imgwrap2'>
                                                <div className='centered-project2'>
                                                    <div>
                                                        <img src={view360degre} />
                                                    </div>

                                                </div>
                                                <div className='centered-project3'>
                                                    Click To View Apartment in 360
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <h1>Objekti 1 Tipi A</h1>
                                            <div>
                                                <table class="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th>Surface	</th>
                                                            <th>Room</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>123.9 ㎡</td>
                                                            <td>4</td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-5 my-md-5'>
                                        <div className='in4'>
                                            <div className='imgwrap2'>
                                                <div className='centered-project2'>
                                                    <div>
                                                        <img src={view360degre} />
                                                    </div>

                                                </div>
                                                <div className='centered-project3'>
                                                    Click To View Apartment in 360
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <h1>Objekti 1 Tipi A</h1>
                                            <div>
                                                <table class="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th>Surface	</th>
                                                            <th>Room</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>123.9 ㎡</td>
                                                            <td>4</td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-5 my-md-5 offset-md-2'>
                                        <div className='in4'>
                                            <div className='imgwrap2'>
                                                <div className='centered-project2'>
                                                    <div>
                                                        <img src={view360degre} />
                                                    </div>

                                                </div>
                                                <div className='centered-project3'>
                                                    Click To View Apartment in 360
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <h1>Objekti 1 Tipi A</h1>
                                            <div>
                                                <table class="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th>Surface	</th>
                                                            <th>Room</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>123.9 ㎡</td>
                                                            <td>4</td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default View360fullpage
