import React from 'react'
import Navbar from '../components/Navbar/Navbar'
import Footer from '../components/Footer/Footer'
import Projectpage from '../components/Projectpagefull/Projectpage'
function Project() {
    return (
        <div>
            <div className='navi px-md-5'>
                <Navbar />
            </div>

            <Projectpage />
            <div>
                <Footer />
            </div>

        </div>
    )
}

export default Project