import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import Obj1 from '../../images/Projekti - 3D-01.svg'
import Obj2 from '../../images/Projekti - 3D-02.svg'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import $ from 'jquery'
import im2for2to11 from '../../images/ven/k1b2.jpg'


import "./styles.css";



function Projectpage() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [isHovered, setIsHovered] = useState(false);



  // k1
  const [isHovered11, setIsHovered11] = useState(false);
  const [isHovered12, setIsHovered12] = useState(false);
  const [isHovered13, setIsHovered13] = useState(false);
  const [isHovered14, setIsHovered14] = useState(false);
  const [isHovered15, setIsHovered15] = useState(false);
  const [isHovered16, setIsHovered16] = useState(false);
  // k1end

  // k2
  const [isHovered21, setIsHovered21] = useState(false);
  const [isHovered22, setIsHovered22] = useState(false);
  const [isHovered23, setIsHovered23] = useState(false);
  const [isHovered24, setIsHovered24] = useState(false);
  const [isHovered25, setIsHovered25] = useState(false);
  const [isHovered26, setIsHovered26] = useState(false);
  // k2end

  // k3
  const [isHovered31, setIsHovered31] = useState(false);
  const [isHovered32, setIsHovered32] = useState(false);
  const [isHovered33, setIsHovered33] = useState(false);
  const [isHovered34, setIsHovered34] = useState(false);
  const [isHovered35, setIsHovered35] = useState(false);
  const [isHovered36, setIsHovered36] = useState(false);
  // k3end

  // k4
  const [isHovered41, setIsHovered41] = useState(false);
  const [isHovered42, setIsHovered42] = useState(false);
  const [isHovered43, setIsHovered43] = useState(false);
  const [isHovered44, setIsHovered44] = useState(false);
  const [isHovered45, setIsHovered45] = useState(false);
  const [isHovered46, setIsHovered46] = useState(false);
  // k4end

  // k5
  const [isHovered51, setIsHovered51] = useState(false);
  const [isHovered52, setIsHovered52] = useState(false);
  const [isHovered53, setIsHovered53] = useState(false);
  const [isHovered54, setIsHovered54] = useState(false);
  const [isHovered55, setIsHovered55] = useState(false);
  const [isHovered56, setIsHovered56] = useState(false);
  // k5end


  // k6
  const [isHovered61, setIsHovered61] = useState(false);
  const [isHovered62, setIsHovered62] = useState(false);
  const [isHovered63, setIsHovered63] = useState(false);
  const [isHovered64, setIsHovered64] = useState(false);
  const [isHovered65, setIsHovered65] = useState(false);
  const [isHovered66, setIsHovered66] = useState(false);
  // k6end

  // k7
  const [isHovered71, setIsHovered71] = useState(false);
  const [isHovered72, setIsHovered72] = useState(false);
  const [isHovered73, setIsHovered73] = useState(false);
  const [isHovered74, setIsHovered74] = useState(false);
  const [isHovered75, setIsHovered75] = useState(false);
  const [isHovered76, setIsHovered76] = useState(false);
  // k7end

  // k8
  const [isHovered81, setIsHovered81] = useState(false);
  const [isHovered82, setIsHovered82] = useState(false);
  const [isHovered83, setIsHovered83] = useState(false);
  const [isHovered84, setIsHovered84] = useState(false);
  const [isHovered85, setIsHovered85] = useState(false);
  const [isHovered86, setIsHovered86] = useState(false);
  // k8end

  // k9
  const [isHovered91, setIsHovered91] = useState(false);
  const [isHovered92, setIsHovered92] = useState(false);
  const [isHovered93, setIsHovered93] = useState(false);
  const [isHovered94, setIsHovered94] = useState(false);
  const [isHovered95, setIsHovered95] = useState(false);
  const [isHovered96, setIsHovered96] = useState(false);
  // k8end


  // k10
  const [isHovered101, setIsHovered101] = useState(false);
  const [isHovered102, setIsHovered102] = useState(false);
  const [isHovered103, setIsHovered103] = useState(false);
  const [isHovered104, setIsHovered104] = useState(false);
  const [isHovered105, setIsHovered105] = useState(false);
  const [isHovered106, setIsHovered106] = useState(false);
  // k10end


  // k11
  const [isHovered111, setIsHovered111] = useState(false);
  const [isHovered112, setIsHovered112] = useState(false);
  const [isHovered113, setIsHovered113] = useState(false);
  const [isHovered114, setIsHovered114] = useState(false);
  const [isHovered115, setIsHovered115] = useState(false);
  const [isHovered116, setIsHovered116] = useState(false);
  // k11end


  // k12
  const [isHovered121, setIsHovered121] = useState(false);
  const [isHovered122, setIsHovered122] = useState(false);
  const [isHovered123, setIsHovered123] = useState(false);
  const [isHovered124, setIsHovered124] = useState(false);
  const [isHovered125, setIsHovered125] = useState(false);
  const [isHovered126, setIsHovered126] = useState(false);
  const [isHovered127, setIsHovered127] = useState(false);
  const [isHovered128, setIsHovered128] = useState(false);
  // k12end

  const [showModal, setShowModal] = useState(false);


  // modalformat
  const [showModalfk12b1, setShowModalfk12b1] = useState(false);
  const [showModalfk12b2, setShowModalfk12b2] = useState(false);
  const [showModalfk12b3, setShowModalfk12b3] = useState(false);
  const [showModalfk12b4, setShowModalfk12b4] = useState(false);
  const [showModalfk12b5, setShowModalfk12b5] = useState(false);
  const [showModalfk12b6, setShowModalfk12b6] = useState(false);
  const [showModalfk12b7, setShowModalfk12b7] = useState(false);
  const [showModalfk12b8, setShowModalfk12b8] = useState(false);
  const [showModalfk123456789b6, setShowModalfk123456789b6] = useState(false);
  const [showModalfk134b1, setShowModalfk134b1] = useState(false);

  const [showModalfk11b1, setShowModalfk11b1] = useState(false);
  const [showModalfk11b2, setShowModalfk11b2] = useState(false);
  const [showModalfk11b3, setShowModalfk11b3] = useState(false);
  const [showModalfk11b4, setShowModalfk11b4] = useState(false);
  const [showModalfk1234578910b5, setShowModalfk1234578910b5] = useState(false);
  const [showModalfk12345678910b4, setShowModalfk12345678910b4] = useState(false);
  const [showModalfk12345678b3, setShowModalfk12345678b3] = useState(false);
  const [showModalfk25b1, setShowModalfk25b1] = useState(false);
  const [showModalfk6b2, setShowModalfk6b2] = useState(false);
  const [showModalfk6b1, setShowModalfk6b1] = useState(false);
  const [showModalfk7891011b1, setShowModalfk7891011b1] = useState(false);
  const [showModalfk910b3, setShowModalfk910b3] = useState(false);
  const [showModalfk10b6, setShowModalfk10b6] = useState(false);
  const [showModalfk11b6, setShowModalfk11b6] = useState(false);
  const [showModalfk11b5, setShowModalfk11b5] = useState(false);



  const modalComponents = [
    { showModal: showModalfk12b1, image: require('../../images/ven/k12b1.jpg'), setShowModal: setShowModalfk12b1 },
    { showModal: showModalfk12b2, image: require('../../images/ven/k12b2.jpg'), setShowModal: setShowModalfk12b2 },
    { showModal: showModalfk12b3, image: require('../../images/ven/k12b45.jpg'), setShowModal: setShowModalfk12b3 },
    { showModal: showModalfk12b4, image: require('../../images/ven/k12b45.jpg'), setShowModal: setShowModalfk12b4 },
    { showModal: showModalfk12b5, image: require('../../images/ven/k12b45.jpg'), setShowModal: setShowModalfk12b5 },
    { showModal: showModalfk12b6, image: require('../../images/ven/k12b67.jpg'), setShowModal: setShowModalfk12b6 },
    { showModal: showModalfk12b7, image: require('../../images/ven/k12b67.jpg'), setShowModal: setShowModalfk12b7 },
    { showModal: showModalfk12b8, image: require('../../images/ven/k1b2.jpg'), setShowModal: setShowModalfk12b8 },
    // k11
    { showModal: showModalfk11b1, image: require('../../images/ven/k12b8.jpg'), setShowModal: setShowModalfk11b1 },
    { showModal: showModalfk11b2, image: require('../../images/ven/k12b8.jpg'), setShowModal: setShowModalfk11b2 },
    { showModal: showModalfk11b3, image: require('../../images/ven/k12b8.jpg'), setShowModal: setShowModalfk11b3 },
    { showModal: showModalfk11b4, image: require('../../images/ven/k11b4.png'), setShowModal: setShowModalfk11b4 },
    { showModal: showModalfk11b5, image: require('../../images/ven/k12b8.jpg'), setShowModal: setShowModalfk11b5 },
    { showModal: showModalfk123456789b6, image: require('../../images/ven/k1-2-3-4-5-6-7-8-9b6.png'), setShowModal: setShowModalfk123456789b6 },
    { showModal: showModalfk134b1, image: require('../../images/ven/k1-3-4b1.png'), setShowModal: setShowModalfk134b1 },
    { showModal: showModalfk1234578910b5, image: require('../../images/ven/k1-2-3-4-5-7-8-9-10b5.png'), setShowModal: setShowModalfk1234578910b5 },
    { showModal: showModalfk12345678910b4, image: require('../../images/ven/k1-2-3-4-5-6-7-8-9-10b4.png'), setShowModal: setShowModalfk12345678910b4 },
    { showModal: showModalfk12345678b3, image: require('../../images/ven/k1-2-3-4-5-6-7-8b3.png'), setShowModal: setShowModalfk12345678b3 },
    { showModal: showModalfk25b1, image: require('../../images/ven/k25b1.png'), setShowModal: setShowModalfk25b1 },
    { showModal: showModalfk6b2, image: require('../../images/ven/k6b2.png'), setShowModal: setShowModalfk6b2 },
    { showModal: showModalfk6b1, image: require('../../images/ven/k6b1.png'), setShowModal: setShowModalfk6b1 },
    { showModal: showModalfk7891011b1, image: require('../../images/ven/k7-8-9-10-11b1.png'), setShowModal: setShowModalfk7891011b1 },
    { showModal: showModalfk910b3, image: require('../../images/ven/k9-10b3.png'), setShowModal: setShowModalfk910b3 },
    { showModal: showModalfk10b6, image: require('../../images/ven/k10b6.png'), setShowModal: setShowModalfk10b6 },
    { showModal: showModalfk11b6, image: require('../../images/ven/k11b6.png'), setShowModal: setShowModalfk11b6 },
    { showModal: showModalfk11b5, image: require('../../images/ven/k11b5.png'), setShowModal: setShowModalfk11b5 },


  ];
  // 

  // allmodals 
  const modals = [showModalfk11b4,showModalfk11b5,showModalfk11b6,showModalfk10b6,showModalfk910b3,showModalfk7891011b1,showModalfk6b1,showModalfk6b2,showModalfk12b1, showModalfk12b2, showModalfk12b3, showModalfk12b4, showModalfk12b5, showModalfk12b6, showModalfk12b7, showModalfk12b8,showModalfk123456789b6, showModalfk134b1,showModalfk1234578910b5,showModalfk12345678910b4,showModalfk12345678b3,showModalfk25b1];

  return (
    <>
      <div>
        <div>
          <div>
            <img src={require('../../images/7ph.jpg')} style={{ width: '100%' }} />
          </div>
          <div className='bgsec2'>

            <div className='container'>
              <div className='py-md-5'>
                <div className='py-4 px-5'>
                  <h1 className='text-start px-md-5' style={{ color: 'white' }}>Location in Kosovo.<br />
                    </h1>
                </div>
                <div className='px-md-5 pb-4 text-start'>
                  <p className='px-5 mb-4' style={{ color: 'white' }}>Xharra Towers offers residential complexes which are compositions of Swiss elements ranging from quality, safety, design, spatial organization to building materials. Working with expert, well-informed and visionary teams will result in magnificent settlements that improve the quality of life.</p>
                  <p className='px-5' style={{ color: 'white' }}>The quality of our work and customer service is uncompromising. With mastery in construction, extremely good use of space and green areas that give life to the environment, our complexes are elite. A combination of creativity and efficiency, our project surpasses even your biggest dreams!</p>
                </div>
              </div>
            </div>
          </div>

          <div className='py-5' style={{ backgroundColor: 'white' }}>
            <div className='row g-0 py-5 align-items-center'>
              {!modals.some(modal => modal) && (
                <div className='col-md-5'>
                  <Swiper navigation={true} modules={[Navigation]} className="mySwiper">

                    <SwiperSlide>
                      <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2048 2048">
                        <g> 
                          <g>
                            <g>
                              <polygon class="cls-2" points="1479.39 1392.35 1252.27 1437.27 1024.64 1392.26 1024.56 1392.27 1024.57 1391.99 1024.34 1391.94 1251.97 1346.93 1479.62 1391.94 1479.39 1391.99 1479.39 1392.35" />
                              <path class="cls-1" d="M1024.34,1394.1l-4.61,.69v-1.84s-5.11-.99-5.11-.99l237.35-46.94,230.06,45.49,6.98,1.48-4.8,.96v.37s-231.93,45.87-231.93,45.87l-227.93-45.07Zm227.63-45.27l-218.63,43.24,218.93,43.3,218.64-43.24-218.94-43.29Z" />
                            </g>
                            <g>
                              <polygon class="cls-2" points="1252.27 1437.27 1479.39 1392.35 1479.39 1482.12 1479.62 1482.17 1252.5 1527.09 1252.5 1437.32 1252.27 1437.27" />
                              <path class="cls-1" d="M1247.67,1438.29l-5.13-.99,241.67-47.8v91.66s5.16,.99,5.16,.99l-241.7,47.8v-91.66Zm226.89-43.07l-217.24,42.97v86.03s217.25-42.96,217.25-42.96v-86.04Z" />
                            </g>
                            <g>
                              <polygon class="cls-2" points="1024.56 1392.27 1024.64 1392.26 1252.27 1437.27 1252.05 1437.32 1252.05 1527.17 1251.97 1527.19 1024.34 1482.16 1024.56 1482.12 1024.56 1392.27" />
                              <path class="cls-1" d="M1021.93,1483.59l-6.96-1.46,4.77-.96v-88.9s.08-.99,.08-.99l4.88-.92,229.98,45.48,7.1,1.5-4.92,.95v89.8s-4.8,1.03-4.8,1.03l-230.13-45.52Zm7.45-88.49v86.15s217.84,43.09,217.84,43.09v-86.16s-217.84-43.08-217.84-43.08Z" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="1252.05 1521.74 1252.5 1521.5 1252.5 1522.56 1252.27 1522.69 1252.05 1522.56 1252.05 1521.74" />
                              <polygon class="cls-1" points="1252.05 1528.06 1257.32 1525.44 1257.32 1513.25 1247.67 1518.68 1247.22 1524.63 1249.75 1526.81 1252.05 1528.06" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="1252.27 1437.27 1252.5 1437.32 1252.5 1527.09 1252.05 1527.17 1252.05 1437.32 1252.27 1437.27" />
                              <polygon class="cls-1" points="1247.22 1529.99 1257.32 1528.05 1257.32 1436.35 1252.5 1435.42 1247.45 1436.31 1247.22 1437.32 1247.22 1529.99" />
                            </g>9
                          </g>
                          <g>
                            <g>
                              <polygon class="cls-2" points="1251.39 1437.35 1024.27 1482.27 796.64 1437.26 796.56 1437.27 796.57 1436.99 796.34 1436.94 1023.97 1391.93 1251.62 1436.94 1251.39 1436.99 1251.39 1437.35" />
                              <path class="cls-1" d="M796.34,1439.1l-4.61,.69v-1.84s-5.11-.99-5.11-.99l237.35-46.94,230.06,45.49,6.98,1.48-4.8,.96v.37s-231.93,45.87-231.93,45.87l-227.93-45.07Zm227.63-45.27l-218.63,43.24,218.93,43.3,218.64-43.24-218.94-43.29Z" />
                            </g>
                            <g>
                              <polygon class="cls-2" points="1024.27 1482.27 1251.39 1437.35 1251.39 1527.12 1251.62 1527.17 1024.5 1572.09 1024.5 1482.32 1024.27 1482.27" />
                              <path class="cls-1" d="M1019.67,1483.29l-5.13-.99,241.67-47.8v91.66s5.16,.99,5.16,.99l-241.7,47.8v-91.66Zm226.89-43.07l-217.24,42.97v86.03s217.25-42.96,217.25-42.96v-86.04Z" />
                            </g>
                            <g>
                              <polygon class="cls-2" points="796.56 1437.27 796.64 1437.26 1024.27 1482.27 1024.05 1482.32 1024.05 1572.17 1023.97 1572.19 796.34 1527.16 796.56 1527.12 796.56 1437.27" />
                              <path class="cls-1" d="M793.93,1528.59l-6.96-1.46,4.77-.96v-88.9s.08-.99,.08-.99l4.88-.92,229.98,45.48,7.1,1.5-4.92,.95v89.8s-4.8,1.03-4.8,1.03l-230.13-45.52Zm7.45-88.49v86.15s217.84,43.09,217.84,43.09v-86.16s-217.84-43.08-217.84-43.08Z" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="1024.05 1566.74 1024.5 1566.5 1024.5 1567.56 1024.27 1567.69 1024.05 1567.56 1024.05 1566.74" />
                              <polygon class="cls-1" points="1024.05 1573.06 1029.32 1570.44 1029.32 1558.25 1019.67 1563.68 1019.22 1569.63 1021.75 1571.81 1024.05 1573.06" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="1024.27 1482.27 1024.5 1482.32 1024.5 1572.09 1024.05 1572.17 1024.05 1482.32 1024.27 1482.27" />
                              <polygon class="cls-1" points="1019.22 1574.99 1029.32 1573.05 1029.32 1481.35 1024.5 1480.42 1019.45 1481.31 1019.22 1482.32 1019.22 1574.99" />
                            </g>
                          </g>
                          <g>
                            <g>
                              <polygon class="cls-2" points="1021.39 1483.35 794.27 1528.27 566.64 1483.26 566.56 1483.27 566.57 1482.99 566.34 1482.94 793.97 1437.93 1021.62 1482.94 1021.39 1482.99 1021.39 1483.35" />
                              <path class="cls-1" d="M566.34,1485.1l-4.61,.69v-1.84s-5.11-.99-5.11-.99l237.35-46.94,230.06,45.49,6.98,1.48-4.8,.96v.37s-231.93,45.87-231.93,45.87l-227.93-45.07Zm227.63-45.27l-218.63,43.24,218.93,43.3,218.64-43.24-218.94-43.29Z" />
                            </g>
                            <g>
                              <polygon class="cls-2" points="794.27 1528.27 1021.39 1483.35 1021.39 1573.12 1021.62 1573.17 794.5 1618.09 794.5 1528.32 794.27 1528.27" />
                              <path class="cls-1" d="M789.67,1529.29l-5.13-.99,241.67-47.8v91.66s5.16,.99,5.16,.99l-241.7,47.8v-91.66Zm226.89-43.07l-217.24,42.97v86.03s217.25-42.96,217.25-42.96v-86.04Z" />
                            </g>
                            <g>
                              <polygon class="cls-2" points="566.56 1483.27 566.64 1483.26 794.27 1528.27 794.05 1528.32 794.05 1618.17 793.97 1618.19 566.34 1573.16 566.56 1573.12 566.56 1483.27" />
                              <path class="cls-1" d="M563.93,1574.59l-6.96-1.46,4.77-.96v-88.9s.08-.99,.08-.99l4.88-.92,229.98,45.48,7.1,1.5-4.92,.95v89.8s-4.8,1.03-4.8,1.03l-230.13-45.52Zm7.45-88.49v86.15s217.84,43.09,217.84,43.09v-86.16s-217.84-43.08-217.84-43.08Z" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="794.05 1612.74 794.5 1612.5 794.5 1613.56 794.27 1613.69 794.05 1613.56 794.05 1612.74" />
                              <polygon class="cls-1" points="794.05 1619.06 799.32 1616.44 799.32 1604.25 789.67 1609.68 789.22 1615.63 791.75 1617.81 794.05 1619.06" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="794.27 1528.27 794.5 1528.32 794.5 1618.09 794.05 1618.17 794.05 1528.32 794.27 1528.27" />
                              <polygon class="cls-1" points="789.22 1620.99 799.32 1619.05 799.32 1527.35 794.5 1526.42 789.45 1527.31 789.22 1528.32 789.22 1620.99" />
                            </g>
                          </g>
                          <g>
                            <g>
                              <polygon class="cls-2" points="793.39 1528.35 566.27 1573.27 338.64 1528.26 338.56 1528.27 338.57 1527.99 338.34 1527.94 565.97 1482.93 793.62 1527.94 793.39 1527.99 793.39 1528.35" />
                              <path class="cls-1" d="M338.34,1530.1l-4.61,.69v-1.84s-5.11-.99-5.11-.99l237.35-46.94,230.06,45.49,6.98,1.48-4.8,.96v.37s-231.93,45.87-231.93,45.87l-227.93-45.07Zm227.63-45.27l-218.63,43.24,218.93,43.3,218.64-43.24-218.94-43.29Z" />
                            </g>
                            <g>
                              <polygon class="cls-2" points="566.27 1573.27 793.39 1528.35 793.39 1618.12 793.62 1618.17 566.5 1663.09 566.5 1573.32 566.27 1573.27" />
                              <path class="cls-1" d="M561.67,1574.29l-5.13-.99,241.67-47.8v91.66s5.16,.99,5.16,.99l-241.7,47.8v-91.66Zm226.89-43.07l-217.24,42.97v86.03s217.25-42.96,217.25-42.96v-86.04Z" />
                            </g>
                            <g className="k1b1base">
                              <polygon className={`k1b1 ${isHovered11 ? 'act' : ''}`}
                                onMouseEnter={() => setIsHovered11(true)}
                                onMouseLeave={() => setIsHovered11(false)} points="338.56 1528.27 338.64 1528.26 566.27 1573.27 566.05 1573.32 566.05 1663.17 565.97 1663.19 338.34 1618.16 338.56 1618.12 338.56 1528.27" />
                              <path class="cls-1 k1b1path" d="M335.93,1619.59l-6.96-1.46,4.77-.96v-88.9s.08-.99,.08-.99l4.88-.92,229.98,45.48,7.1,1.5-4.92,.95v89.8s-4.8,1.03-4.8,1.03l-230.13-45.52Zm7.45-88.49v86.15s217.84,43.09,217.84,43.09v-86.16s-217.84-43.08-217.84-43.08Z" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="566.05 1657.74 566.5 1657.5 566.5 1658.56 566.27 1658.69 566.05 1658.56 566.05 1657.74" />
                              <polygon class="cls-1" points="566.05 1664.06 571.32 1661.44 571.32 1649.25 561.67 1654.68 561.22 1660.63 563.75 1662.81 566.05 1664.06" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="566.27 1573.27 566.5 1573.32 566.5 1663.09 566.05 1663.17 566.05 1573.32 566.27 1573.27" />
                              <polygon class="cls-1" points="561.22 1665.99 571.32 1664.05 571.32 1572.35 566.5 1571.42 561.45 1572.31 561.22 1573.32 561.22 1665.99" />
                            </g>
                          </g>
                          <g>
                            <g>
                              <polygon class="cls-2" points="1709.39 1438.35 1482.27 1483.27 1254.64 1438.26 1254.56 1438.27 1254.57 1437.99 1254.34 1437.94 1481.97 1392.93 1709.62 1437.94 1709.39 1437.99 1709.39 1438.35" />
                              <path class="cls-1" d="M1254.34,1440.1l-4.61,.69v-1.84s-5.11-.99-5.11-.99l237.35-46.94,230.06,45.49,6.98,1.48-4.8,.96v.37s-231.93,45.87-231.93,45.87l-227.93-45.07Zm227.63-45.27l-218.63,43.24,218.93,43.3,218.64-43.24-218.94-43.29Z" />
                            </g>
                            <g className="k1b5base">
                              <polygon className={`k1b5 ${isHovered15 ? 'act' : ''}`}
                                onMouseEnter={() => setIsHovered15(true)}
                                onMouseLeave={() => setIsHovered15(false)} points="1482.27 1483.27 1709.39 1438.35 1709.39 1528.12 1709.62 1528.17 1482.5 1573.09 1482.5 1483.32 1482.27 1483.27" />
                              <path class="cls-1 k1b5path" d="M1477.67,1484.29l-5.13-.99,241.67-47.8v91.66s5.16,.99,5.16,.99l-241.7,47.8v-91.66Zm226.89-43.07l-217.24,42.97v86.03s217.25-42.96,217.25-42.96v-86.04Z" />
                            </g>
                            <g>
                              <polygon class="cls-2" points="1254.56 1438.27 1254.64 1438.26 1482.27 1483.27 1482.05 1483.32 1482.05 1573.17 1481.97 1573.19 1254.34 1528.16 1254.56 1528.12 1254.56 1438.27" />
                              <path class="cls-1" d="M1251.93,1529.59l-6.96-1.46,4.77-.96v-88.9s.08-.99,.08-.99l4.88-.92,229.98,45.48,7.1,1.5-4.92,.95v89.8s-4.8,1.03-4.8,1.03l-230.13-45.52Zm7.45-88.49v86.15s217.84,43.09,217.84,43.09v-86.16s-217.84-43.08-217.84-43.08Z" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="1482.05 1567.74 1482.5 1567.5 1482.5 1568.56 1482.27 1568.69 1482.05 1568.56 1482.05 1567.74" />
                              <polygon class="cls-1" points="1482.05 1574.06 1487.32 1571.44 1487.32 1559.25 1477.67 1564.68 1477.22 1570.63 1479.75 1572.81 1482.05 1574.06" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="1482.27 1483.27 1482.5 1483.32 1482.5 1573.09 1482.05 1573.17 1482.05 1483.32 1482.27 1483.27" />
                              <polygon class="cls-1" points="1477.22 1575.99 1487.32 1574.05 1487.32 1482.35 1482.5 1481.42 1477.45 1482.31 1477.22 1483.32 1477.22 1575.99" />
                            </g>
                          </g>
                          <g>
                            <g>
                              <polygon class="cls-2" points="1481.39 1483.35 1254.27 1528.27 1026.64 1483.26 1026.56 1483.27 1026.57 1482.99 1026.34 1482.94 1253.97 1437.93 1481.62 1482.94 1481.39 1482.99 1481.39 1483.35" />
                              <path class="cls-1" d="M1026.34,1485.1l-4.61,.69v-1.84s-5.11-.99-5.11-.99l237.35-46.94,230.06,45.49,6.98,1.48-4.8,.96v.37s-231.93,45.87-231.93,45.87l-227.93-45.07Zm227.63-45.27l-218.63,43.24,218.93,43.3,218.64-43.24-218.94-43.29Z" />
                            </g>
                            <g className="k1b4base">
                              <polygon className={`k1b4 ${isHovered14 ? 'act' : ''}`}
                                onMouseEnter={() => setIsHovered14(true)}
                                onMouseLeave={() => setIsHovered14(false)} points="1254.27 1528.27 1481.39 1483.35 1481.39 1573.12 1481.62 1573.17 1254.5 1618.09 1254.5 1528.32 1254.27 1528.27" />
                              <path class="cls-1 k1b4path" d="M1249.67,1529.29l-5.13-.99,241.67-47.8v91.66s5.16,.99,5.16,.99l-241.7,47.8v-91.66Zm226.89-43.07l-217.24,42.97v86.03s217.25-42.96,217.25-42.96v-86.04Z" />
                            </g>
                            <g>
                              <polygon class="cls-2" points="1026.56 1483.27 1026.64 1483.26 1254.27 1528.27 1254.05 1528.32 1254.05 1618.17 1253.97 1618.19 1026.34 1573.16 1026.56 1573.12 1026.56 1483.27" />
                              <path class="cls-1" d="M1023.93,1574.59l-6.96-1.46,4.77-.96v-88.9s.08-.99,.08-.99l4.88-.92,229.98,45.48,7.1,1.5-4.92,.95v89.8s-4.8,1.03-4.8,1.03l-230.13-45.52Zm7.45-88.49v86.15s217.84,43.09,217.84,43.09v-86.16s-217.84-43.08-217.84-43.08Z" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="1254.05 1612.74 1254.5 1612.5 1254.5 1613.56 1254.27 1613.69 1254.05 1613.56 1254.05 1612.74" />
                              <polygon class="cls-1" points="1254.05 1619.06 1259.32 1616.44 1259.32 1604.25 1249.67 1609.68 1249.22 1615.63 1251.75 1617.81 1254.05 1619.06" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="1254.27 1528.27 1254.5 1528.32 1254.5 1618.09 1254.05 1618.17 1254.05 1528.32 1254.27 1528.27" />
                              <polygon class="cls-1" points="1249.22 1620.99 1259.32 1619.05 1259.32 1527.35 1254.5 1526.42 1249.45 1527.31 1249.22 1528.32 1249.22 1620.99" />
                            </g>
                          </g>
                          <g>
                            <g>
                              <polygon class="cls-2" points="1251.39 1529.35 1024.27 1574.27 796.64 1529.26 796.56 1529.27 796.57 1528.99 796.34 1528.94 1023.97 1483.93 1251.62 1528.94 1251.39 1528.99 1251.39 1529.35" />
                              <path class="cls-1" d="M796.34,1531.1l-4.61,.69v-1.84s-5.11-.99-5.11-.99l237.35-46.94,230.06,45.49,6.98,1.48-4.8,.96v.37s-231.93,45.87-231.93,45.87l-227.93-45.07Zm227.63-45.27l-218.63,43.24,218.93,43.3,218.64-43.24-218.94-43.29Z" />
                            </g>
                            <g className="k1b3base">
                              <polygon className={`k1b3 ${isHovered13 ? 'act' : ''}`}
                                onMouseEnter={() => setIsHovered13(true)}
                                onMouseLeave={() => setIsHovered13(false)} points="1024.27 1574.27 1251.39 1529.35 1251.39 1619.12 1251.62 1619.17 1024.5 1664.09 1024.5 1574.32 1024.27 1574.27" />
                              <path class="cls-1 k1b3path" d="M1019.67,1575.29l-5.13-.99,241.67-47.8v91.66s5.16,.99,5.16,.99l-241.7,47.8v-91.66Zm226.89-43.07l-217.24,42.97v86.03s217.25-42.96,217.25-42.96v-86.04Z" />
                            </g>
                            <g>
                              <polygon class="cls-2" points="796.56 1529.27 796.64 1529.26 1024.27 1574.27 1024.05 1574.32 1024.05 1664.17 1023.97 1664.19 796.34 1619.16 796.56 1619.12 796.56 1529.27" />
                              <path class="cls-1" d="M793.93,1620.59l-6.96-1.46,4.77-.96v-88.9s.08-.99,.08-.99l4.88-.92,229.98,45.48,7.1,1.5-4.92,.95v89.8s-4.8,1.03-4.8,1.03l-230.13-45.52Zm7.45-88.49v86.15s217.84,43.09,217.84,43.09v-86.16s-217.84-43.08-217.84-43.08Z" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="1024.05 1658.74 1024.5 1658.5 1024.5 1659.56 1024.27 1659.69 1024.05 1659.56 1024.05 1658.74" />
                              <polygon class="cls-1" points="1024.05 1665.06 1029.32 1662.44 1029.32 1650.25 1019.67 1655.68 1019.22 1661.63 1021.75 1663.81 1024.05 1665.06" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="1024.27 1574.27 1024.5 1574.32 1024.5 1664.09 1024.05 1664.17 1024.05 1574.32 1024.27 1574.27" />
                              <polygon class="cls-1" points="1019.22 1666.99 1029.32 1665.05 1029.32 1573.35 1024.5 1572.42 1019.45 1573.31 1019.22 1574.32 1019.22 1666.99" />
                            </g>
                          </g>
                          <g>
                            <g>
                              <polygon class="cls-2" points="1023.39 1574.35 796.27 1619.27 568.64 1574.26 568.56 1574.27 568.57 1573.99 568.34 1573.94 795.97 1528.93 1023.62 1573.94 1023.39 1573.99 1023.39 1574.35" />
                              <path class="cls-1" d="M568.34,1576.1l-4.61,.69v-1.84s-5.11-.99-5.11-.99l237.35-46.94,230.06,45.49,6.98,1.48-4.8,.96v.37s-231.93,45.87-231.93,45.87l-227.93-45.07Zm227.63-45.27l-218.63,43.24,218.93,43.3,218.64-43.24-218.94-43.29Z" />
                            </g>
                            <g className="k1b2base">
                              <polygon className={`k1b2 ${isHovered12 ? 'act' : ''}`}
                                onMouseEnter={() => setIsHovered12(true)}
                                onMouseLeave={() => setIsHovered12(false)} points="796.27 1619.27 1023.39 1574.35 1023.39 1664.12 1023.62 1664.17 796.5 1709.09 796.5 1619.32 796.27 1619.27" />
                              <path class="cls-1 k1b2base" d="M791.67,1620.29l-5.13-.99,241.67-47.8v91.66s5.16,.99,5.16,.99l-241.7,47.8v-91.66Zm226.89-43.07l-217.24,42.97v86.03s217.25-42.96,217.25-42.96v-86.04Z" />
                            </g>
                            <g className="k1b2base">
                              <polygon className={`k1b2 ${isHovered12 ? 'act' : ''}`}
                                onMouseEnter={() => setIsHovered12(true)}
                                onMouseLeave={() => setIsHovered12(false)} points="568.56 1574.27 568.64 1574.26 796.27 1619.27 796.05 1619.32 796.05 1709.17 795.97 1709.19 568.34 1664.16 568.56 1664.12 568.56 1574.27" />
                              <path class="cls-1 k1b2path" d="M565.93,1665.59l-6.96-1.46,4.77-.96v-88.9s.08-.99,.08-.99l4.88-.92,229.98,45.48,7.1,1.5-4.92,.95v89.8s-4.8,1.03-4.8,1.03l-230.13-45.52Zm7.45-88.49v86.15s217.84,43.09,217.84,43.09v-86.16s-217.84-43.08-217.84-43.08Z" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="796.05 1703.74 796.5 1703.5 796.5 1704.56 796.27 1704.69 796.05 1704.56 796.05 1703.74" />
                              <polygon class="cls-1" points="796.05 1710.06 801.32 1707.44 801.32 1695.25 791.67 1700.68 791.22 1706.63 793.75 1708.81 796.05 1710.06" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="796.27 1619.27 796.5 1619.32 796.5 1709.09 796.05 1709.17 796.05 1619.32 796.27 1619.27" />
                              <polygon class="cls-1" points="791.22 1711.99 801.32 1710.05 801.32 1618.35 796.5 1617.42 791.45 1618.31 791.22 1619.32 791.22 1711.99" />
                            </g>
                          </g>
                        </g>
                        <g>
                          <g>
                            <g>
                              <polygon class="cls-2" points="1479.39 1298.35 1252.27 1343.27 1024.64 1298.26 1024.56 1298.27 1024.57 1297.99 1024.34 1297.94 1251.97 1252.93 1479.62 1297.94 1479.39 1297.99 1479.39 1298.35" />
                              <path class="cls-1" d="M1024.34,1300.1l-4.61,.69v-1.84s-5.11-.99-5.11-.99l237.35-46.94,230.06,45.49,6.98,1.48-4.8,.96v.37s-231.93,45.87-231.93,45.87l-227.93-45.07Zm227.63-45.27l-218.63,43.24,218.93,43.3,218.64-43.24-218.94-43.29Z" />
                            </g>
                            <g>
                              <polygon class="cls-2" points="1252.27 1343.27 1479.39 1298.35 1479.39 1388.12 1479.62 1388.17 1252.5 1433.09 1252.5 1343.32 1252.27 1343.27" />
                              <path class="cls-1" d="M1247.67,1344.29l-5.13-.99,241.67-47.8v91.66s5.16,.99,5.16,.99l-241.7,47.8v-91.66Zm226.89-43.07l-217.24,42.97v86.03s217.25-42.96,217.25-42.96v-86.04Z" />
                            </g>
                            <g>
                              <polygon class="cls-2" points="1024.56 1298.27 1024.64 1298.26 1252.27 1343.27 1252.05 1343.32 1252.05 1433.17 1251.97 1433.19 1024.34 1388.16 1024.56 1388.12 1024.56 1298.27" />
                              <path class="cls-1" d="M1021.93,1389.59l-6.96-1.46,4.77-.96v-88.9s.08-.99,.08-.99l4.88-.92,229.98,45.48,7.1,1.5-4.92,.95v89.8s-4.8,1.03-4.8,1.03l-230.13-45.52Zm7.45-88.49v86.15s217.84,43.09,217.84,43.09v-86.16s-217.84-43.08-217.84-43.08Z" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="1252.05 1427.74 1252.5 1427.5 1252.5 1428.56 1252.27 1428.69 1252.05 1428.56 1252.05 1427.74" />
                              <polygon class="cls-1" points="1252.05 1434.06 1257.32 1431.44 1257.32 1419.25 1247.67 1424.68 1247.22 1430.63 1249.75 1432.81 1252.05 1434.06" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="1252.27 1343.27 1252.5 1343.32 1252.5 1433.09 1252.05 1433.17 1252.05 1343.32 1252.27 1343.27" />
                              <polygon class="cls-1" points="1247.22 1435.99 1257.32 1434.05 1257.32 1342.35 1252.5 1341.42 1247.45 1342.31 1247.22 1343.32 1247.22 1435.99" />
                            </g>
                          </g>
                          <g>
                            <g>
                              <polygon class="cls-2" points="1251.39 1343.35 1024.27 1388.27 796.64 1343.26 796.56 1343.27 796.57 1342.99 796.34 1342.94 1023.97 1297.93 1251.62 1342.94 1251.39 1342.99 1251.39 1343.35" />
                              <path class="cls-1" d="M796.34,1345.1l-4.61,.69v-1.84s-5.11-.99-5.11-.99l237.35-46.94,230.06,45.49,6.98,1.48-4.8,.96v.37s-231.93,45.87-231.93,45.87l-227.93-45.07Zm227.63-45.27l-218.63,43.24,218.93,43.3,218.64-43.24-218.94-43.29Z" />
                            </g>
                            <g>
                              <polygon class="cls-2" points="1024.27 1388.27 1251.39 1343.35 1251.39 1433.12 1251.62 1433.17 1024.5 1478.09 1024.5 1388.32 1024.27 1388.27" />
                              <path class="cls-1" d="M1019.67,1389.29l-5.13-.99,241.67-47.8v91.66s5.16,.99,5.16,.99l-241.7,47.8v-91.66Zm226.89-43.07l-217.24,42.97v86.03s217.25-42.96,217.25-42.96v-86.04Z" />
                            </g>
                            <g>
                              <polygon class="cls-2" points="796.56 1343.27 796.64 1343.26 1024.27 1388.27 1024.05 1388.32 1024.05 1478.17 1023.97 1478.19 796.34 1433.16 796.56 1433.12 796.56 1343.27" />
                              <path class="cls-1" d="M793.93,1434.59l-6.96-1.46,4.77-.96v-88.9s.08-.99,.08-.99l4.88-.92,229.98,45.48,7.1,1.5-4.92,.95v89.8s-4.8,1.03-4.8,1.03l-230.13-45.52Zm7.45-88.49v86.15s217.84,43.09,217.84,43.09v-86.16s-217.84-43.08-217.84-43.08Z" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="1024.05 1472.74 1024.5 1472.5 1024.5 1473.56 1024.27 1473.69 1024.05 1473.56 1024.05 1472.74" />
                              <polygon class="cls-1" points="1024.05 1479.06 1029.32 1476.44 1029.32 1464.25 1019.67 1469.68 1019.22 1475.63 1021.75 1477.81 1024.05 1479.06" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="1024.27 1388.27 1024.5 1388.32 1024.5 1478.09 1024.05 1478.17 1024.05 1388.32 1024.27 1388.27" />
                              <polygon class="cls-1" points="1019.22 1480.99 1029.32 1479.05 1029.32 1387.35 1024.5 1386.42 1019.45 1387.31 1019.22 1388.32 1019.22 1480.99" />
                            </g>
                          </g>
                          <g>
                            <g>
                              <polygon class="cls-2" points="1021.39 1389.35 794.27 1434.27 566.64 1389.26 566.56 1389.27 566.57 1388.99 566.34 1388.94 793.97 1343.93 1021.62 1388.94 1021.39 1388.99 1021.39 1389.35" />
                              <path class="cls-1" d="M566.34,1391.1l-4.61,.69v-1.84s-5.11-.99-5.11-.99l237.35-46.94,230.06,45.49,6.98,1.48-4.8,.96v.37s-231.93,45.87-231.93,45.87l-227.93-45.07Zm227.63-45.27l-218.63,43.24,218.93,43.3,218.64-43.24-218.94-43.29Z" />
                            </g>
                            <g>
                              <polygon class="cls-2" points="794.27 1434.27 1021.39 1389.35 1021.39 1479.12 1021.62 1479.17 794.5 1524.09 794.5 1434.32 794.27 1434.27" />
                              <path class="cls-1" d="M789.67,1435.29l-5.13-.99,241.67-47.8v91.66s5.16,.99,5.16,.99l-241.7,47.8v-91.66Zm226.89-43.07l-217.24,42.97v86.03s217.25-42.96,217.25-42.96v-86.04Z" />
                            </g>
                            <g>
                              <polygon class="cls-2" points="566.56 1389.27 566.64 1389.26 794.27 1434.27 794.05 1434.32 794.05 1524.17 793.97 1524.19 566.34 1479.16 566.56 1479.12 566.56 1389.27" />
                              <path class="cls-1" d="M563.93,1480.59l-6.96-1.46,4.77-.96v-88.9s.08-.99,.08-.99l4.88-.92,229.98,45.48,7.1,1.5-4.92,.95v89.8s-4.8,1.03-4.8,1.03l-230.13-45.52Zm7.45-88.49v86.15s217.84,43.09,217.84,43.09v-86.16s-217.84-43.08-217.84-43.08Z" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="794.05 1518.74 794.5 1518.5 794.5 1519.56 794.27 1519.69 794.05 1519.56 794.05 1518.74" />
                              <polygon class="cls-1" points="794.05 1525.06 799.32 1522.44 799.32 1510.25 789.67 1515.68 789.22 1521.63 791.75 1523.81 794.05 1525.06" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="794.27 1434.27 794.5 1434.32 794.5 1524.09 794.05 1524.17 794.05 1434.32 794.27 1434.27" />
                              <polygon class="cls-1" points="789.22 1526.99 799.32 1525.05 799.32 1433.35 794.5 1432.42 789.45 1433.31 789.22 1434.32 789.22 1526.99" />
                            </g>
                          </g>
                          <g>
                            <g>
                              <polygon class="cls-2" points="793.39 1434.35 566.27 1479.27 338.64 1434.26 338.56 1434.27 338.57 1433.99 338.34 1433.94 565.97 1388.93 793.62 1433.94 793.39 1433.99 793.39 1434.35" />
                              <path class="cls-1" d="M338.34,1436.1l-4.61,.69v-1.84s-5.11-.99-5.11-.99l237.35-46.94,230.06,45.49,6.98,1.48-4.8,.96v.37s-231.93,45.87-231.93,45.87l-227.93-45.07Zm227.63-45.27l-218.63,43.24,218.93,43.3,218.64-43.24-218.94-43.29Z" />
                            </g>
                            <g>
                              <polygon class="cls-2" points="566.27 1479.27 793.39 1434.35 793.39 1524.12 793.62 1524.17 566.5 1569.09 566.5 1479.32 566.27 1479.27" />
                              <path class="cls-1" d="M561.67,1480.29l-5.13-.99,241.67-47.8v91.66s5.16,.99,5.16,.99l-241.7,47.8v-91.66Zm226.89-43.07l-217.24,42.97v86.03s217.25-42.96,217.25-42.96v-86.04Z" />
                            </g>
                            <g className="k2b1base">
                              <polygon className={`k2b1 ${isHovered21 ? 'act' : ''}`}
                                onMouseEnter={() => setIsHovered21(true)}
                                onMouseLeave={() => setIsHovered21(false)} points="338.56 1434.27 338.64 1434.26 566.27 1479.27 566.05 1479.32 566.05 1569.17 565.97 1569.19 338.34 1524.16 338.56 1524.12 338.56 1434.27" />
                              <path class="cls-1 k2b1path" d="M335.93,1525.59l-6.96-1.46,4.77-.96v-88.9s.08-.99,.08-.99l4.88-.92,229.98,45.48,7.1,1.5-4.92,.95v89.8s-4.8,1.03-4.8,1.03l-230.13-45.52Zm7.45-88.49v86.15s217.84,43.09,217.84,43.09v-86.16s-217.84-43.08-217.84-43.08Z" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="566.05 1563.74 566.5 1563.5 566.5 1564.56 566.27 1564.69 566.05 1564.56 566.05 1563.74" />
                              <polygon class="cls-1" points="566.05 1570.06 571.32 1567.44 571.32 1555.25 561.67 1560.68 561.22 1566.63 563.75 1568.81 566.05 1570.06" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="566.27 1479.27 566.5 1479.32 566.5 1569.09 566.05 1569.17 566.05 1479.32 566.27 1479.27" />
                              <polygon class="cls-1" points="561.22 1571.99 571.32 1570.05 571.32 1478.35 566.5 1477.42 561.45 1478.31 561.22 1479.32 561.22 1571.99" />
                            </g>
                          </g>
                          <g>
                            <g>
                              <polygon class="cls-2" points="1709.39 1344.35 1482.27 1389.27 1254.64 1344.26 1254.56 1344.27 1254.57 1343.99 1254.34 1343.94 1481.97 1298.93 1709.62 1343.94 1709.39 1343.99 1709.39 1344.35" />
                              <path class="cls-1" d="M1254.34,1346.1l-4.61,.69v-1.84s-5.11-.99-5.11-.99l237.35-46.94,230.06,45.49,6.98,1.48-4.8,.96v.37s-231.93,45.87-231.93,45.87l-227.93-45.07Zm227.63-45.27l-218.63,43.24,218.93,43.3,218.64-43.24-218.94-43.29Z" />
                            </g>
                            <g className="k2b5base">
                              <polygon className={`k2b5 ${isHovered25 ? 'act' : ''}`}
                                onMouseEnter={() => setIsHovered25(true)}
                                onMouseLeave={() => setIsHovered25(false)} points="1482.27 1389.27 1709.39 1344.35 1709.39 1434.12 1709.62 1434.17 1482.5 1479.09 1482.5 1389.32 1482.27 1389.27" />
                              <path class="cls-1 k2b5path" d="M1477.67,1390.29l-5.13-.99,241.67-47.8v91.66s5.16,.99,5.16,.99l-241.7,47.8v-91.66Zm226.89-43.07l-217.24,42.97v86.03s217.25-42.96,217.25-42.96v-86.04Z" />
                            </g>
                            <g>
                              <polygon class="cls-2" points="1254.56 1344.27 1254.64 1344.26 1482.27 1389.27 1482.05 1389.32 1482.05 1479.17 1481.97 1479.19 1254.34 1434.16 1254.56 1434.12 1254.56 1344.27" />
                              <path class="cls-1" d="M1251.93,1435.59l-6.96-1.46,4.77-.96v-88.9s.08-.99,.08-.99l4.88-.92,229.98,45.48,7.1,1.5-4.92,.95v89.8s-4.8,1.03-4.8,1.03l-230.13-45.52Zm7.45-88.49v86.15s217.84,43.09,217.84,43.09v-86.16s-217.84-43.08-217.84-43.08Z" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="1482.05 1473.74 1482.5 1473.5 1482.5 1474.56 1482.27 1474.69 1482.05 1474.56 1482.05 1473.74" />
                              <polygon class="cls-1" points="1482.05 1480.06 1487.32 1477.44 1487.32 1465.25 1477.67 1470.68 1477.22 1476.63 1479.75 1478.81 1482.05 1480.06" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="1482.27 1389.27 1482.5 1389.32 1482.5 1479.09 1482.05 1479.17 1482.05 1389.32 1482.27 1389.27" />
                              <polygon class="cls-1" points="1477.22 1481.99 1487.32 1480.05 1487.32 1388.35 1482.5 1387.42 1477.45 1388.31 1477.22 1389.32 1477.22 1481.99" />
                            </g>
                          </g>
                          <g>
                            <g>
                              <polygon class="cls-2" points="1481.39 1389.35 1254.27 1434.27 1026.64 1389.26 1026.56 1389.27 1026.57 1388.99 1026.34 1388.94 1253.97 1343.93 1481.62 1388.94 1481.39 1388.99 1481.39 1389.35" />
                              <path class="cls-1" d="M1026.34,1391.1l-4.61,.69v-1.84s-5.11-.99-5.11-.99l237.35-46.94,230.06,45.49,6.98,1.48-4.8,.96v.37s-231.93,45.87-231.93,45.87l-227.93-45.07Zm227.63-45.27l-218.63,43.24,218.93,43.3,218.64-43.24-218.94-43.29Z" />
                            </g>
                            <g className="k2b4base">
                              <polygon className={`k2b4 ${isHovered24 ? 'act' : ''}`}
                                onMouseEnter={() => setIsHovered24(true)} 
                                onMouseLeave={() => setIsHovered24(false)} points="1254.27 1434.27 1481.39 1389.35 1481.39 1479.12 1481.62 1479.17 1254.5 1524.09 1254.5 1434.32 1254.27 1434.27" />
                              <path class="cls-1 k2b4path" d="M1249.6 7,1435.29l-5.13-.99,241.67-47.8v91.66s5.16,.99,5.16,.99l-241.7,47.8v-91.66Zm226.89-43.07l-217.24,42.97v86.03s217.25-42.96,217.25-42.96v-86.04Z" />
                            </g>
                            <g>
                              <polygon class="cls-2" points="1026.56 1389.27 1026.64 1389.26 1254.27 1434.27 1254.05 1434.32 1254.05 1524.17 1253.97 1524.19 1026.34 1479.16 1026.56 1479.12 1026.56 1389.27" />
                              <path class="cls-1" d="M1023.93,1480.59l-6.96-1.46,4.77-.96v-88.9s.08-.99,.08-.99l4.88-.92,229.98,45.48,7.1,1.5-4.92,.95v89.8s-4.8,1.03-4.8,1.03l-230.13-45.52Zm7.45-88.49v86.15s217.84,43.09,217.84,43.09v-86.16s-217.84-43.08-217.84-43.08Z" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="1254.05 1518.74 1254.5 1518.5 1254.5 1519.56 1254.27 1519.69 1254.05 1519.56 1254.05 1518.74" />
                              <polygon class="cls-1" points="1254.05 1525.06 1259.32 1522.44 1259.32 1510.25 1249.67 1515.68 1249.22 1521.63 1251.75 1523.81 1254.05 1525.06" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="1254.27 1434.27 1254.5 1434.32 1254.5 1524.09 1254.05 1524.17 1254.05 1434.32 1254.27 1434.27" />
                              <polygon class="cls-1" points="1249.22 1526.99 1259.32 1525.05 1259.32 1433.35 1254.5 1432.42 1249.45 1433.31 1249.22 1434.32 1249.22 1526.99" />
                            </g>
                          </g>
                          <g>
                            <g>
                              <polygon class="cls-2" points="1251.39 1435.35 1024.27 1480.27 796.64 1435.26 796.56 1435.27 796.57 1434.99 796.34 1434.94 1023.97 1389.93 1251.62 1434.94 1251.39 1434.99 1251.39 1435.35" />
                              <path class="cls-1" d="M796.34,1437.1l-4.61,.69v-1.84s-5.11-.99-5.11-.99l237.35-46.94,230.06,45.49,6.98,1.48-4.8,.96v.37s-231.93,45.87-231.93,45.87l-227.93-45.07Zm227.63-45.27l-218.63,43.24,218.93,43.3,218.64-43.24-218.94-43.29Z" />
                            </g>
                            <g className="k2b3base">
                              <polygon className={`k2b3 ${isHovered23 ? 'act' : ''}`}
                                onMouseEnter={() => setIsHovered23(true)}
                                onMouseLeave={() => setIsHovered23(false)} points="1024.27 1480.27 1251.39 1435.35 1251.39 1525.12 1251.62 1525.17 1024.5 1570.09 1024.5 1480.32 1024.27 1480.27" />
                              <path class="cls-1 k2b3path" d="M1019.67,1481.29l-5.13-.99,241.67-47.8v91.66s5.16,.99,5.16,.99l-241.7,47.8v-91.66Zm226.89-43.07l-217.24,42.97v86.03s217.25-42.96,217.25-42.96v-86.04Z" />
                            </g>
                            <g>
                              <polygon class="cls-2" points="796.56 1435.27 796.64 1435.26 1024.27 1480.27 1024.05 1480.32 1024.05 1570.17 1023.97 1570.19 796.34 1525.16 796.56 1525.12 796.56 1435.27" />
                              <path class="cls-1" d="M793.93,1526.59l-6.96-1.46,4.77-.96v-88.9s.08-.99,.08-.99l4.88-.92,229.98,45.48,7.1,1.5-4.92,.95v89.8s-4.8,1.03-4.8,1.03l-230.13-45.52Zm7.45-88.49v86.15s217.84,43.09,217.84,43.09v-86.16s-217.84-43.08-217.84-43.08Z" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="1024.05 1564.74 1024.5 1564.5 1024.5 1565.56 1024.27 1565.69 1024.05 1565.56 1024.05 1564.74" />
                              <polygon class="cls-1" points="1024.05 1571.06 1029.32 1568.44 1029.32 1556.25 1019.67 1561.68 1019.22 1567.63 1021.75 1569.81 1024.05 1571.06" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="1024.27 1480.27 1024.5 1480.32 1024.5 1570.09 1024.05 1570.17 1024.05 1480.32 1024.27 1480.27" />
                              <polygon class="cls-1" points="1019.22 1572.99 1029.32 1571.05 1029.32 1479.35 1024.5 1478.42 1019.45 1479.31 1019.22 1480.32 1019.22 1572.99" />
                            </g>
                          </g>
                          <g>
                            <g>
                              <polygon class="cls-2" points="1023.39 1480.35 796.27 1525.27 568.64 1480.26 568.56 1480.27 568.57 1479.99 568.34 1479.94 795.97 1434.93 1023.62 1479.94 1023.39 1479.99 1023.39 1480.35" />
                              <path class="cls-1" d="M568.34,1482.1l-4.61,.69v-1.84s-5.11-.99-5.11-.99l237.35-46.94,230.06,45.49,6.98,1.48-4.8,.96v.37s-231.93,45.87-231.93,45.87l-227.93-45.07Zm227.63-45.27l-218.63,43.24,218.93,43.3,218.64-43.24-218.94-43.29Z" />
                            </g>
                            <g className="k2b2base">
                              <polygon className={`k2b2 ${isHovered22 ? 'act' : ''}`}
                                onMouseEnter={() => setIsHovered22(true)}
                                onMouseLeave={() => setIsHovered22(false)} points="796.27 1525.27 1023.39 1480.35 1023.39 1570.12 1023.62 1570.17 796.5 1615.09 796.5 1525.32 796.27 1525.27" />
                              <path class="cls-1 k2b2path" d="M791.67,1526.29l-5.13-.99,241.67-47.8v91.66s5.16,.99,5.16,.99l-241.7,47.8v-91.66Zm226.89-43.07l-217.24,42.97v86.03s217.25-42.96,217.25-42.96v-86.04Z" />
                            </g>
                            <g className="k2b2base">
                              <polygon className={`k2b2 ${isHovered22 ? 'act' : ''}`}
                                onMouseEnter={() => setIsHovered22(true)}
                                onMouseLeave={() => setIsHovered22(false)} points="568.56 1480.27 568.64 1480.26 796.27 1525.27 796.05 1525.32 796.05 1615.17 795.97 1615.19 568.34 1570.16 568.56 1570.12 568.56 1480.27" />
                              <path class="cls-1 k2b2path" d="M565.93,1571.59l-6.96-1.46,4.77-.96v-88.9s.08-.99,.08-.99l4.88-.92,229.98,45.48,7.1,1.5-4.92,.95v89.8s-4.8,1.03-4.8,1.03l-230.13-45.52Zm7.45-88.49v86.15s217.84,43.09,217.84,43.09v-86.16s-217.84-43.08-217.84-43.08Z" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="796.05 1609.74 796.5 1609.5 796.5 1610.56 796.27 1610.69 796.05 1610.56 796.05 1609.74" />
                              <polygon class="cls-1" points="796.05 1616.06 801.32 1613.44 801.32 1601.25 791.67 1606.68 791.22 1612.63 793.75 1614.81 796.05 1616.06" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="796.27 1525.27 796.5 1525.32 796.5 1615.09 796.05 1615.17 796.05 1525.32 796.27 1525.27" />
                              <polygon class="cls-1" points="791.22 1617.99 801.32 1616.05 801.32 1524.35 796.5 1523.42 791.45 1524.31 791.22 1525.32 791.22 1617.99" />
                            </g>
                          </g>
                        </g>
                        <g>
                          <g>
                            <g>
                              <polygon class="cls-2" points="1479.39 1204.35 1252.27 1249.27 1024.64 1204.26 1024.56 1204.27 1024.57 1203.99 1024.34 1203.94 1251.97 1158.93 1479.62 1203.94 1479.39 1203.99 1479.39 1204.35" />
                              <path class="cls-1" d="M1024.34,1206.1l-4.61,.69v-1.84s-5.11-.99-5.11-.99l237.35-46.94,230.06,45.49,6.98,1.48-4.8,.96v.37s-231.93,45.87-231.93,45.87l-227.93-45.07Zm227.63-45.27l-218.63,43.24,218.93,43.3,218.64-43.24-218.94-43.29Z" />
                            </g>
                            <g>
                              <polygon class="cls-2" points="1252.27 1249.27 1479.39 1204.35 1479.39 1294.12 1479.62 1294.17 1252.5 1339.09 1252.5 1249.32 1252.27 1249.27" />
                              <path class="cls-1" d="M1247.67,1250.29l-5.13-.99,241.67-47.8v91.66s5.16,.99,5.16,.99l-241.7,47.8v-91.66Zm226.89-43.07l-217.24,42.97v86.03s217.25-42.96,217.25-42.96v-86.04Z" />
                            </g>
                            <g>
                              <polygon class="cls-2" points="1024.56 1204.27 1024.64 1204.26 1252.27 1249.27 1252.05 1249.32 1252.05 1339.17 1251.97 1339.19 1024.34 1294.16 1024.56 1294.12 1024.56 1204.27" />
                              <path class="cls-1" d="M1021.93,1295.59l-6.96-1.46,4.77-.96v-88.9s.08-.99,.08-.99l4.88-.92,229.98,45.48,7.1,1.5-4.92,.95v89.8s-4.8,1.03-4.8,1.03l-230.13-45.52Zm7.45-88.49v86.15s217.84,43.09,217.84,43.09v-86.16s-217.84-43.08-217.84-43.08Z" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="1252.05 1333.74 1252.5 1333.5 1252.5 1334.56 1252.27 1334.69 1252.05 1334.56 1252.05 1333.74" />
                              <polygon class="cls-1" points="1252.05 1340.06 1257.32 1337.44 1257.32 1325.25 1247.67 1330.68 1247.22 1336.63 1249.75 1338.81 1252.05 1340.06" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="1252.27 1249.27 1252.5 1249.32 1252.5 1339.09 1252.05 1339.17 1252.05 1249.32 1252.27 1249.27" />
                              <polygon class="cls-1" points="1247.22 1341.99 1257.32 1340.05 1257.32 1248.35 1252.5 1247.42 1247.45 1248.31 1247.22 1249.32 1247.22 1341.99" />
                            </g>
                          </g>
                          <g>
                            <g>
                              <polygon class="cls-2" points="1251.39 1249.35 1024.27 1294.27 796.64 1249.26 796.56 1249.27 796.57 1248.99 796.34 1248.94 1023.97 1203.93 1251.62 1248.94 1251.39 1248.99 1251.39 1249.35" />
                              <path class="cls-1" d="M796.34,1251.1l-4.61,.69v-1.84s-5.11-.99-5.11-.99l237.35-46.94,230.06,45.49,6.98,1.48-4.8,.96v.37s-231.93,45.87-231.93,45.87l-227.93-45.07Zm227.63-45.27l-218.63,43.24,218.93,43.3,218.64-43.24-218.94-43.29Z" />
                            </g>
                            <g>
                              <polygon class="cls-2" points="1024.27 1294.27 1251.39 1249.35 1251.39 1339.12 1251.62 1339.17 1024.5 1384.09 1024.5 1294.32 1024.27 1294.27" />
                              <path class="cls-1" d="M1019.67,1295.29l-5.13-.99,241.67-47.8v91.66s5.16,.99,5.16,.99l-241.7,47.8v-91.66Zm226.89-43.07l-217.24,42.97v86.03s217.25-42.96,217.25-42.96v-86.04Z" />
                            </g>
                            <g>
                              <polygon class="cls-2" points="796.56 1249.27 796.64 1249.26 1024.27 1294.27 1024.05 1294.32 1024.05 1384.17 1023.97 1384.19 796.34 1339.16 796.56 1339.12 796.56 1249.27" />
                              <path class="cls-1" d="M793.93,1340.59l-6.96-1.46,4.77-.96v-88.9s.08-.99,.08-.99l4.88-.92,229.98,45.48,7.1,1.5-4.92,.95v89.8s-4.8,1.03-4.8,1.03l-230.13-45.52Zm7.45-88.49v86.15s217.84,43.09,217.84,43.09v-86.16s-217.84-43.08-217.84-43.08Z" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="1024.05 1378.74 1024.5 1378.5 1024.5 1379.56 1024.27 1379.69 1024.05 1379.56 1024.05 1378.74" />
                              <polygon class="cls-1" points="1024.05 1385.06 1029.32 1382.44 1029.32 1370.25 1019.67 1375.68 1019.22 1381.63 1021.75 1383.81 1024.05 1385.06" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="1024.27 1294.27 1024.5 1294.32 1024.5 1384.09 1024.05 1384.17 1024.05 1294.32 1024.27 1294.27" />
                              <polygon class="cls-1" points="1019.22 1386.99 1029.32 1385.05 1029.32 1293.35 1024.5 1292.42 1019.45 1293.31 1019.22 1294.32 1019.22 1386.99" />
                            </g>
                          </g>
                          <g>
                            <g>
                              <polygon class="cls-2" points="1021.39 1295.35 794.27 1340.27 566.64 1295.26 566.56 1295.27 566.57 1294.99 566.34 1294.94 793.97 1249.93 1021.62 1294.94 1021.39 1294.99 1021.39 1295.35" />
                              <path class="cls-1" d="M566.34,1297.1l-4.61,.69v-1.84s-5.11-.99-5.11-.99l237.35-46.94,230.06,45.49,6.98,1.48-4.8,.96v.37s-231.93,45.87-231.93,45.87l-227.93-45.07Zm227.63-45.27l-218.63,43.24,218.93,43.3,218.64-43.24-218.94-43.29Z" />
                            </g>
                            <g>
                              <polygon class="cls-2" points="794.27 1340.27 1021.39 1295.35 1021.39 1385.12 1021.62 1385.17 794.5 1430.09 794.5 1340.32 794.27 1340.27" />
                              <path class="cls-1" d="M789.67,1341.29l-5.13-.99,241.67-47.8v91.66s5.16,.99,5.16,.99l-241.7,47.8v-91.66Zm226.89-43.07l-217.24,42.97v86.03s217.25-42.96,217.25-42.96v-86.04Z" />
                            </g>
                            <g>
                              <polygon class="cls-2" points="566.56 1295.27 566.64 1295.26 794.27 1340.27 794.05 1340.32 794.05 1430.17 793.97 1430.19 566.34 1385.16 566.56 1385.12 566.56 1295.27" />
                              <path class="cls-1" d="M563.93,1386.59l-6.96-1.46,4.77-.96v-88.9s.08-.99,.08-.99l4.88-.92,229.98,45.48,7.1,1.5-4.92,.95v89.8s-4.8,1.03-4.8,1.03l-230.13-45.52Zm7.45-88.49v86.15s217.84,43.09,217.84,43.09v-86.16s-217.84-43.08-217.84-43.08Z" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="794.05 1424.74 794.5 1424.5 794.5 1425.56 794.27 1425.69 794.05 1425.56 794.05 1424.74" />
                              <polygon class="cls-1" points="794.05 1431.06 799.32 1428.44 799.32 1416.25 789.67 1421.68 789.22 1427.63 791.75 1429.81 794.05 1431.06" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="794.27 1340.27 794.5 1340.32 794.5 1430.09 794.05 1430.17 794.05 1340.32 794.27 1340.27" />
                              <polygon class="cls-1" points="789.22 1432.99 799.32 1431.05 799.32 1339.35 794.5 1338.42 789.45 1339.31 789.22 1340.32 789.22 1432.99" />
                            </g>
                          </g>
                          <g>
                            <g>
                              <polygon class="cls-2" points="793.39 1340.35 566.27 1385.27 338.64 1340.26 338.56 1340.27 338.57 1339.99 338.34 1339.94 565.97 1294.93 793.62 1339.94 793.39 1339.99 793.39 1340.35" />
                              <path class="cls-1" d="M338.34,1342.1l-4.61,.69v-1.84s-5.11-.99-5.11-.99l237.35-46.94,230.06,45.49,6.98,1.48-4.8,.96v.37s-231.93,45.87-231.93,45.87l-227.93-45.07Zm227.63-45.27l-218.63,43.24,218.93,43.3,218.64-43.24-218.94-43.29Z" />
                            </g>
                            <g>
                              <polygon class="cls-2" points="566.27 1385.27 793.39 1340.35 793.39 1430.12 793.62 1430.17 566.5 1475.09 566.5 1385.32 566.27 1385.27" />
                              <path class="cls-1" d="M561.67,1386.29l-5.13-.99,241.67-47.8v91.66s5.16,.99,5.16,.99l-241.7,47.8v-91.66Zm226.89-43.07l-217.24,42.97v86.03s217.25-42.96,217.25-42.96v-86.04Z" />
                            </g>
                            <g className="k3b1base">
                              <polygon className={`k3b1 ${isHovered31 ? 'act' : ''}`}
                                onMouseEnter={() => setIsHovered31(true)}
                                onMouseLeave={() => setIsHovered31(false)} points="338.56 1340.27 338.64 1340.26 566.27 1385.27 566.05 1385.32 566.05 1475.17 565.97 1475.19 338.34 1430.16 338.56 1430.12 338.56 1340.27" />
                              <path class="cls-1 k3b1path" d="M335.93,1431.59l-6.96-1.46,4.77-.96v-88.9s.08-.99,.08-.99l4.88-.92,229.98,45.48,7.1,1.5-4.92,.95v89.8s-4.8,1.03-4.8,1.03l-230.13-45.52Zm7.45-88.49v86.15s217.84,43.09,217.84,43.09v-86.16s-217.84-43.08-217.84-43.08Z" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="566.05 1469.74 566.5 1469.5 566.5 1470.56 566.27 1470.69 566.05 1470.56 566.05 1469.74" />
                              <polygon class="cls-1" points="566.05 1476.06 571.32 1473.44 571.32 1461.25 561.67 1466.68 561.22 1472.63 563.75 1474.81 566.05 1476.06" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="566.27 1385.27 566.5 1385.32 566.5 1475.09 566.05 1475.17 566.05 1385.32 566.27 1385.27" />
                              <polygon class="cls-1" points="561.22 1477.99 571.32 1476.05 571.32 1384.35 566.5 1383.42 561.45 1384.31 561.22 1385.32 561.22 1477.99" />
                            </g>
                          </g>
                          <g>
                            <g>
                              <polygon class="cls-2" points="1709.39 1250.35 1482.27 1295.27 1254.64 1250.26 1254.56 1250.27 1254.57 1249.99 1254.34 1249.94 1481.97 1204.93 1709.62 1249.94 1709.39 1249.99 1709.39 1250.35" />
                              <path class="cls-1" d="M1254.34,1252.1l-4.61,.69v-1.84s-5.11-.99-5.11-.99l237.35-46.94,230.06,45.49,6.98,1.48-4.8,.96v.37s-231.93,45.87-231.93,45.87l-227.93-45.07Zm227.63-45.27l-218.63,43.24,218.93,43.3,218.64-43.24-218.94-43.29Z" />
                            </g>
                            <g className="k3b5base">
                              <polygon className={`k3b5 ${isHovered35 ? 'act' : ''}`}
                                onMouseEnter={() => setIsHovered35(true)}
                                onMouseLeave={() => setIsHovered35(false)} points="1482.27 1295.27 1709.39 1250.35 1709.39 1340.12 1709.62 1340.17 1482.5 1385.09 1482.5 1295.32 1482.27 1295.27" />
                              <path class="cls-1 k3b5path" d="M1477.67,1296.29l-5.13-.99,241.67-47.8v91.66s5.16,.99,5.16,.99l-241.7,47.8v-91.66Zm226.89-43.07l-217.24,42.97v86.03s217.25-42.96,217.25-42.96v-86.04Z" />
                            </g>
                            <g>
                              <polygon class="cls-2" points="1254.56 1250.27 1254.64 1250.26 1482.27 1295.27 1482.05 1295.32 1482.05 1385.17 1481.97 1385.19 1254.34 1340.16 1254.56 1340.12 1254.56 1250.27" />
                              <path class="cls-1" d="M1251.93,1341.59l-6.96-1.46,4.77-.96v-88.9s.08-.99,.08-.99l4.88-.92,229.98,45.48,7.1,1.5-4.92,.95v89.8s-4.8,1.03-4.8,1.03l-230.13-45.52Zm7.45-88.49v86.15s217.84,43.09,217.84,43.09v-86.16s-217.84-43.08-217.84-43.08Z" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="1482.05 1379.74 1482.5 1379.5 1482.5 1380.56 1482.27 1380.69 1482.05 1380.56 1482.05 1379.74" />
                              <polygon class="cls-1" points="1482.05 1386.06 1487.32 1383.44 1487.32 1371.25 1477.67 1376.68 1477.22 1382.63 1479.75 1384.81 1482.05 1386.06" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="1482.27 1295.27 1482.5 1295.32 1482.5 1385.09 1482.05 1385.17 1482.05 1295.32 1482.27 1295.27" />
                              <polygon class="cls-1" points="1477.22 1387.99 1487.32 1386.05 1487.32 1294.35 1482.5 1293.42 1477.45 1294.31 1477.22 1295.32 1477.22 1387.99" />
                            </g>
                          </g>
                          <g>
                            <g>
                              <polygon class="cls-2" points="1481.39 1295.35 1254.27 1340.27 1026.64 1295.26 1026.56 1295.27 1026.57 1294.99 1026.34 1294.94 1253.97 1249.93 1481.62 1294.94 1481.39 1294.99 1481.39 1295.35" />
                              <path class="cls-1" d="M1026.34,1297.1l-4.61,.69v-1.84s-5.11-.99-5.11-.99l237.35-46.94,230.06,45.49,6.98,1.48-4.8,.96v.37s-231.93,45.87-231.93,45.87l-227.93-45.07Zm227.63-45.27l-218.63,43.24,218.93,43.3,218.64-43.24-218.94-43.29Z" />
                            </g>
                            <g className="k3b4base">
                              <polygon className={`k3b4 ${isHovered34 ? 'act' : ''}`}
                                onMouseEnter={() => setIsHovered34(true)}
                                onMouseLeave={() => setIsHovered34(false)} points="1254.27 1340.27 1481.39 1295.35 1481.39 1385.12 1481.62 1385.17 1254.5 1430.09 1254.5 1340.32 1254.27 1340.27" />
                              <path class="cls-1 k3b4path" d="M1249.67,1341.29l-5.13-.99,241.67-47.8v91.66s5.16,.99,5.16,.99l-241.7,47.8v-91.66Zm226.89-43.07l-217.24,42.97v86.03s217.25-42.96,217.25-42.96v-86.04Z" />
                            </g>
                            <g>
                              <polygon class="cls-2" points="1026.56 1295.27 1026.64 1295.26 1254.27 1340.27 1254.05 1340.32 1254.05 1430.17 1253.97 1430.19 1026.34 1385.16 1026.56 1385.12 1026.56 1295.27" />
                              <path class="cls-1" d="M1023.93,1386.59l-6.96-1.46,4.77-.96v-88.9s.08-.99,.08-.99l4.88-.92,229.98,45.48,7.1,1.5-4.92,.95v89.8s-4.8,1.03-4.8,1.03l-230.13-45.52Zm7.45-88.49v86.15s217.84,43.09,217.84,43.09v-86.16s-217.84-43.08-217.84-43.08Z" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="1254.05 1424.74 1254.5 1424.5 1254.5 1425.56 1254.27 1425.69 1254.05 1425.56 1254.05 1424.74" />
                              <polygon class="cls-1" points="1254.05 1431.06 1259.32 1428.44 1259.32 1416.25 1249.67 1421.68 1249.22 1427.63 1251.75 1429.81 1254.05 1431.06" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="1254.27 1340.27 1254.5 1340.32 1254.5 1430.09 1254.05 1430.17 1254.05 1340.32 1254.27 1340.27" />
                              <polygon class="cls-1" points="1249.22 1432.99 1259.32 1431.05 1259.32 1339.35 1254.5 1338.42 1249.45 1339.31 1249.22 1340.32 1249.22 1432.99" />
                            </g>
                          </g>
                          <g>
                            <g>
                              <polygon class="cls-2" points="1251.39 1341.35 1024.27 1386.27 796.64 1341.26 796.56 1341.27 796.57 1340.99 796.34 1340.94 1023.97 1295.93 1251.62 1340.94 1251.39 1340.99 1251.39 1341.35" />
                              <path class="cls-1" d="M796.34,1343.1l-4.61,.69v-1.84s-5.11-.99-5.11-.99l237.35-46.94,230.06,45.49,6.98,1.48-4.8,.96v.37s-231.93,45.87-231.93,45.87l-227.93-45.07Zm227.63-45.27l-218.63,43.24,218.93,43.3,218.64-43.24-218.94-43.29Z" />
                            </g>
                            <g className="k3b3base">
                              <polygon className={`k3b3 ${isHovered33 ? 'act' : ''}`}
                                onMouseEnter={() => setIsHovered33(true)}
                                onMouseLeave={() => setIsHovered33(false)} points="1024.27 1386.27 1251.39 1341.35 1251.39 1431.12 1251.62 1431.17 1024.5 1476.09 1024.5 1386.32 1024.27 1386.27" />
                              <path class="cls-1 k3b3path" d="M1019.67,1387.29l-5.13-.99,241.67-47.8v91.66s5.16,.99,5.16,.99l-241.7,47.8v-91.66Zm226.89-43.07l-217.24,42.97v86.03s217.25-42.96,217.25-42.96v-86.04Z" />
                            </g>
                            <g>
                              <polygon class="cls-2" points="796.56 1341.27 796.64 1341.26 1024.27 1386.27 1024.05 1386.32 1024.05 1476.17 1023.97 1476.19 796.34 1431.16 796.56 1431.12 796.56 1341.27" />
                              <path class="cls-1" d="M793.93,1432.59l-6.96-1.46,4.77-.96v-88.9s.08-.99,.08-.99l4.88-.92,229.98,45.48,7.1,1.5-4.92,.95v89.8s-4.8,1.03-4.8,1.03l-230.13-45.52Zm7.45-88.49v86.15s217.84,43.09,217.84,43.09v-86.16s-217.84-43.08-217.84-43.08Z" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="1024.05 1470.74 1024.5 1470.5 1024.5 1471.56 1024.27 1471.69 1024.05 1471.56 1024.05 1470.74" />
                              <polygon class="cls-1" points="1024.05 1477.06 1029.32 1474.44 1029.32 1462.25 1019.67 1467.68 1019.22 1473.63 1021.75 1475.81 1024.05 1477.06" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="1024.27 1386.27 1024.5 1386.32 1024.5 1476.09 1024.05 1476.17 1024.05 1386.32 1024.27 1386.27" />
                              <polygon class="cls-1" points="1019.22 1478.99 1029.32 1477.05 1029.32 1385.35 1024.5 1384.42 1019.45 1385.31 1019.22 1386.32 1019.22 1478.99" />
                            </g>
                          </g>
                          <g>
                            <g>
                              <polygon class="cls-2" points="1023.39 1386.35 796.27 1431.27 568.64 1386.26 568.56 1386.27 568.57 1385.99 568.34 1385.94 795.97 1340.93 1023.62 1385.94 1023.39 1385.99 1023.39 1386.35" />
                              <path class="cls-1" d="M568.34,1388.1l-4.61,.69v-1.84s-5.11-.99-5.11-.99l237.35-46.94,230.06,45.49,6.98,1.48-4.8,.96v.37s-231.93,45.87-231.93,45.87l-227.93-45.07Zm227.63-45.27l-218.63,43.24,218.93,43.3,218.64-43.24-218.94-43.29Z" />
                            </g>
                            <g className="k3b2base">
                              <polygon className={`k3b2 ${isHovered32 ? 'act' : ''}`}
                                onMouseEnter={() => setIsHovered32(true)}
                                onMouseLeave={() => setIsHovered32(false)} points="796.27 1431.27 1023.39 1386.35 1023.39 1476.12 1023.62 1476.17 796.5 1521.09 796.5 1431.32 796.27 1431.27" />
                              <path class="cls-1 k3b2path" d="M791.67,1432.29l-5.13-.99,241.67-47.8v91.66s5.16,.99,5.16,.99l-241.7,47.8v-91.66Zm226.89-43.07l-217.24,42.97v86.03s217.25-42.96,217.25-42.96v-86.04Z" />
                            </g>
                            <g className="k3b2base">
                              <polygon className={`k3b2 ${isHovered32 ? 'act' : ''}`}
                                onMouseEnter={() => setIsHovered32(true)}
                                onMouseLeave={() => setIsHovered32(false)} points="568.56 1386.27 568.64 1386.26 796.27 1431.27 796.05 1431.32 796.05 1521.17 795.97 1521.19 568.34 1476.16 568.56 1476.12 568.56 1386.27" />
                              <path class="cls-1 k3b2path" d="M565.93,1477.59l-6.96-1.46,4.77-.96v-88.9s.08-.99,.08-.99l4.88-.92,229.98,45.48,7.1,1.5-4.92,.95v89.8s-4.8,1.03-4.8,1.03l-230.13-45.52Zm7.45-88.49v86.15s217.84,43.09,217.84,43.09v-86.16s-217.84-43.08-217.84-43.08Z" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="796.05 1515.74 796.5 1515.5 796.5 1516.56 796.27 1516.69 796.05 1516.56 796.05 1515.74" />
                              <polygon class="cls-1" points="796.05 1522.06 801.32 1519.44 801.32 1507.25 791.67 1512.68 791.22 1518.63 793.75 1520.81 796.05 1522.06" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="796.27 1431.27 796.5 1431.32 796.5 1521.09 796.05 1521.17 796.05 1431.32 796.27 1431.27" />
                              <polygon class="cls-1" points="791.22 1523.99 801.32 1522.05 801.32 1430.35 796.5 1429.42 791.45 1430.31 791.22 1431.32 791.22 1523.99" />
                            </g>
                          </g>
                        </g>
                        <g>
                          <g>
                            <g>
                              <polygon class="cls-2" points="1479.39 1110.35 1252.27 1155.27 1024.64 1110.26 1024.56 1110.27 1024.57 1109.99 1024.34 1109.94 1251.97 1064.93 1479.62 1109.94 1479.39 1109.99 1479.39 1110.35" />
                              <path class="cls-1" d="M1024.34,1112.1l-4.61,.69v-1.84s-5.11-.99-5.11-.99l237.35-46.94,230.06,45.49,6.98,1.48-4.8,.96v.37s-231.93,45.87-231.93,45.87l-227.93-45.07Zm227.63-45.27l-218.63,43.24,218.93,43.3,218.64-43.24-218.94-43.29Z" />
                            </g>
                            <g>
                              <polygon class="cls-2" points="1252.27 1155.27 1479.39 1110.35 1479.39 1200.12 1479.62 1200.17 1252.5 1245.09 1252.5 1155.32 1252.27 1155.27" />
                              <path class="cls-1" d="M1247.67,1156.29l-5.13-.99,241.67-47.8v91.66s5.16,.99,5.16,.99l-241.7,47.8v-91.66Zm226.89-43.07l-217.24,42.97v86.03s217.25-42.96,217.25-42.96v-86.04Z" />
                            </g>
                            <g>
                              <polygon class="cls-2" points="1024.56 1110.27 1024.64 1110.26 1252.27 1155.27 1252.05 1155.32 1252.05 1245.17 1251.97 1245.19 1024.34 1200.16 1024.56 1200.12 1024.56 1110.27" />
                              <path class="cls-1" d="M1021.93,1201.59l-6.96-1.46,4.77-.96v-88.9s.08-.99,.08-.99l4.88-.92,229.98,45.48,7.1,1.5-4.92,.95v89.8s-4.8,1.03-4.8,1.03l-230.13-45.52Zm7.45-88.49v86.15s217.84,43.09,217.84,43.09v-86.16s-217.84-43.08-217.84-43.08Z" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="1252.05 1239.74 1252.5 1239.5 1252.5 1240.56 1252.27 1240.69 1252.05 1240.56 1252.05 1239.74" />
                              <polygon class="cls-1" points="1252.05 1246.06 1257.32 1243.44 1257.32 1231.25 1247.67 1236.68 1247.22 1242.63 1249.75 1244.81 1252.05 1246.06" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="1252.27 1155.27 1252.5 1155.32 1252.5 1245.09 1252.05 1245.17 1252.05 1155.32 1252.27 1155.27" />
                              <polygon class="cls-1" points="1247.22 1247.99 1257.32 1246.05 1257.32 1154.35 1252.5 1153.42 1247.45 1154.31 1247.22 1155.32 1247.22 1247.99" />
                            </g>
                          </g>
                          <g>
                            <g>
                              <polygon class="cls-2" points="1251.39 1155.35 1024.27 1200.27 796.64 1155.26 796.56 1155.27 796.57 1154.99 796.34 1154.94 1023.97 1109.93 1251.62 1154.94 1251.39 1154.99 1251.39 1155.35" />
                              <path class="cls-1" d="M796.34,1157.1l-4.61,.69v-1.84s-5.11-.99-5.11-.99l237.35-46.94,230.06,45.49,6.98,1.48-4.8,.96v.37s-231.93,45.87-231.93,45.87l-227.93-45.07Zm227.63-45.27l-218.63,43.24,218.93,43.3,218.64-43.24-218.94-43.29Z" />
                            </g>
                            <g>
                              <polygon class="cls-2" points="1024.27 1200.27 1251.39 1155.35 1251.39 1245.12 1251.62 1245.17 1024.5 1290.09 1024.5 1200.32 1024.27 1200.27" />
                              <path class="cls-1" d="M1019.67,1201.29l-5.13-.99,241.67-47.8v91.66s5.16,.99,5.16,.99l-241.7,47.8v-91.66Zm226.89-43.07l-217.24,42.97v86.03s217.25-42.96,217.25-42.96v-86.04Z" />
                            </g>
                            <g>
                              <polygon class="cls-2" points="796.56 1155.27 796.64 1155.26 1024.27 1200.27 1024.05 1200.32 1024.05 1290.17 1023.97 1290.19 796.34 1245.16 796.56 1245.12 796.56 1155.27" />
                              <path class="cls-1" d="M793.93,1246.59l-6.96-1.46,4.77-.96v-88.9s.08-.99,.08-.99l4.88-.92,229.98,45.48,7.1,1.5-4.92,.95v89.8s-4.8,1.03-4.8,1.03l-230.13-45.52Zm7.45-88.49v86.15s217.84,43.09,217.84,43.09v-86.16s-217.84-43.08-217.84-43.08Z" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="1024.05 1284.74 1024.5 1284.5 1024.5 1285.56 1024.27 1285.69 1024.05 1285.56 1024.05 1284.74" />
                              <polygon class="cls-1" points="1024.05 1291.06 1029.32 1288.44 1029.32 1276.25 1019.67 1281.68 1019.22 1287.63 1021.75 1289.81 1024.05 1291.06" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="1024.27 1200.27 1024.5 1200.32 1024.5 1290.09 1024.05 1290.17 1024.05 1200.32 1024.27 1200.27" />
                              <polygon class="cls-1" points="1019.22 1292.99 1029.32 1291.05 1029.32 1199.35 1024.5 1198.42 1019.45 1199.31 1019.22 1200.32 1019.22 1292.99" />
                            </g>
                          </g>
                          <g>
                            <g>
                              <polygon class="cls-2" points="1021.39 1201.35 794.27 1246.27 566.64 1201.26 566.56 1201.27 566.57 1200.99 566.34 1200.94 793.97 1155.93 1021.62 1200.94 1021.39 1200.99 1021.39 1201.35" />
                              <path class="cls-1" d="M566.34,1203.1l-4.61,.69v-1.84s-5.11-.99-5.11-.99l237.35-46.94,230.06,45.49,6.98,1.48-4.8,.96v.37s-231.93,45.87-231.93,45.87l-227.93-45.07Zm227.63-45.27l-218.63,43.24,218.93,43.3,218.64-43.24-218.94-43.29Z" />
                            </g>
                            <g>
                              <polygon class="cls-2" points="794.27 1246.27 1021.39 1201.35 1021.39 1291.12 1021.62 1291.17 794.5 1336.09 794.5 1246.32 794.27 1246.27" />
                              <path class="cls-1" d="M789.67,1247.29l-5.13-.99,241.67-47.8v91.66s5.16,.99,5.16,.99l-241.7,47.8v-91.66Zm226.89-43.07l-217.24,42.97v86.03s217.25-42.96,217.25-42.96v-86.04Z" />
                            </g>
                            <g>
                              <polygon class="cls-2" points="566.56 1201.27 566.64 1201.26 794.27 1246.27 794.05 1246.32 794.05 1336.17 793.97 1336.19 566.34 1291.16 566.56 1291.12 566.56 1201.27" />
                              <path class="cls-1" d="M563.93,1292.59l-6.96-1.46,4.77-.96v-88.9s.08-.99,.08-.99l4.88-.92,229.98,45.48,7.1,1.5-4.92,.95v89.8s-4.8,1.03-4.8,1.03l-230.13-45.52Zm7.45-88.49v86.15s217.84,43.09,217.84,43.09v-86.16s-217.84-43.08-217.84-43.08Z" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="794.05 1330.74 794.5 1330.5 794.5 1331.56 794.27 1331.69 794.05 1331.56 794.05 1330.74" />
                              <polygon class="cls-1" points="794.05 1337.06 799.32 1334.44 799.32 1322.25 789.67 1327.68 789.22 1333.63 791.75 1335.81 794.05 1337.06" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="794.27 1246.27 794.5 1246.32 794.5 1336.09 794.05 1336.17 794.05 1246.32 794.27 1246.27" />
                              <polygon class="cls-1" points="789.22 1338.99 799.32 1337.05 799.32 1245.35 794.5 1244.42 789.45 1245.31 789.22 1246.32 789.22 1338.99" />
                            </g>
                          </g>
                          <g>
                            <g>
                              <polygon class="cls-2" points="793.39 1246.35 566.27 1291.27 338.64 1246.26 338.56 1246.27 338.57 1245.99 338.34 1245.94 565.97 1200.93 793.62 1245.94 793.39 1245.99 793.39 1246.35" />
                              <path class="cls-1" d="M338.34,1248.1l-4.61,.69v-1.84s-5.11-.99-5.11-.99l237.35-46.94,230.06,45.49,6.98,1.48-4.8,.96v.37s-231.93,45.87-231.93,45.87l-227.93-45.07Zm227.63-45.27l-218.63,43.24,218.93,43.3,218.64-43.24-218.94-43.29Z" />
                            </g>
                            <g>
                              <polygon class="cls-2" points="566.27 1291.27 793.39 1246.35 793.39 1336.12 793.62 1336.17 566.5 1381.09 566.5 1291.32 566.27 1291.27" />
                              <path class="cls-1" d="M561.67,1292.29l-5.13-.99,241.67-47.8v91.66s5.16,.99,5.16,.99l-241.7,47.8v-91.66Zm226.89-43.07l-217.24,42.97v86.03s217.25-42.96,217.25-42.96v-86.04Z" />
                            </g>
                            <g className="k4b1base">
                              <polygon className={`k4b1 ${isHovered41 ? 'act' : ''}`}
                                onMouseEnter={() => setIsHovered41(true)}
                                onMouseLeave={() => setIsHovered41(false)} points="338.56 1246.27 338.64 1246.26 566.27 1291.27 566.05 1291.32 566.05 1381.17 565.97 1381.19 338.34 1336.16 338.56 1336.12 338.56 1246.27" />
                              <path class="cls-1 k4b1path" d="M335.93,1337.59l-6.96-1.46,4.77-.96v-88.9s.08-.99,.08-.99l4.88-.92,229.98,45.48,7.1,1.5-4.92,.95v89.8s-4.8,1.03-4.8,1.03l-230.13-45.52Zm7.45-88.49v86.15s217.84,43.09,217.84,43.09v-86.16s-217.84-43.08-217.84-43.08Z" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="566.05 1375.74 566.5 1375.5 566.5 1376.56 566.27 1376.69 566.05 1376.56 566.05 1375.74" />
                              <polygon class="cls-1" points="566.05 1382.06 571.32 1379.44 571.32 1367.25 561.67 1372.68 561.22 1378.63 563.75 1380.81 566.05 1382.06" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="566.27 1291.27 566.5 1291.32 566.5 1381.09 566.05 1381.17 566.05 1291.32 566.27 1291.27" />
                              <polygon class="cls-1" points="561.22 1383.99 571.32 1382.05 571.32 1290.35 566.5 1289.42 561.45 1290.31 561.22 1291.32 561.22 1383.99" />
                            </g>
                          </g>
                          <g>
                            <g>
                              <polygon class="cls-2" points="1709.39 1156.35 1482.27 1201.27 1254.64 1156.26 1254.56 1156.27 1254.57 1155.99 1254.34 1155.94 1481.97 1110.93 1709.62 1155.94 1709.39 1155.99 1709.39 1156.35" />
                              <path class="cls-1" d="M1254.34,1158.1l-4.61,.69v-1.84s-5.11-.99-5.11-.99l237.35-46.94,230.06,45.49,6.98,1.48-4.8,.96v.37s-231.93,45.87-231.93,45.87l-227.93-45.07Zm227.63-45.27l-218.63,43.24,218.93,43.3,218.64-43.24-218.94-43.29Z" />
                            </g>
                            <g className="k4b5base">
                              <polygon className={`k4b5 ${isHovered45 ? 'act' : ''}`}
                                onMouseEnter={() => setIsHovered45(true)}
                                onMouseLeave={() => setIsHovered45(false)} points="1482.27 1201.27 1709.39 1156.35 1709.39 1246.12 1709.62 1246.17 1482.5 1291.09 1482.5 1201.32 1482.27 1201.27" />
                              <path class="cls-1 k4b5path" d="M1477.67,1202.29l-5.13-.99,241.67-47.8v91.66s5.16,.99,5.16,.99l-241.7,47.8v-91.66Zm226.89-43.07l-217.24,42.97v86.03s217.25-42.96,217.25-42.96v-86.04Z" />
                            </g>
                            <g>
                              <polygon class="cls-2" points="1254.56 1156.27 1254.64 1156.26 1482.27 1201.27 1482.05 1201.32 1482.05 1291.17 1481.97 1291.19 1254.34 1246.16 1254.56 1246.12 1254.56 1156.27" />
                              <path class="cls-1" d="M1251.93,1247.59l-6.96-1.46,4.77-.96v-88.9s.08-.99,.08-.99l4.88-.92,229.98,45.48,7.1,1.5-4.92,.95v89.8s-4.8,1.03-4.8,1.03l-230.13-45.52Zm7.45-88.49v86.15s217.84,43.09,217.84,43.09v-86.16s-217.84-43.08-217.84-43.08Z" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="1482.05 1285.74 1482.5 1285.5 1482.5 1286.56 1482.27 1286.69 1482.05 1286.56 1482.05 1285.74" />
                              <polygon class="cls-1" points="1482.05 1292.06 1487.32 1289.44 1487.32 1277.25 1477.67 1282.68 1477.22 1288.63 1479.75 1290.81 1482.05 1292.06" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="1482.27 1201.27 1482.5 1201.32 1482.5 1291.09 1482.05 1291.17 1482.05 1201.32 1482.27 1201.27" />
                              <polygon class="cls-1" points="1477.22 1293.99 1487.32 1292.05 1487.32 1200.35 1482.5 1199.42 1477.45 1200.31 1477.22 1201.32 1477.22 1293.99" />
                            </g>
                          </g>
                          <g>
                            <g>
                              <polygon class="cls-2" points="1481.39 1201.35 1254.27 1246.27 1026.64 1201.26 1026.56 1201.27 1026.57 1200.99 1026.34 1200.94 1253.97 1155.93 1481.62 1200.94 1481.39 1200.99 1481.39 1201.35" />
                              <path class="cls-1" d="M1026.34,1203.1l-4.61,.69v-1.84s-5.11-.99-5.11-.99l237.35-46.94,230.06,45.49,6.98,1.48-4.8,.96v.37s-231.93,45.87-231.93,45.87l-227.93-45.07Zm227.63-45.27l-218.63,43.24,218.93,43.3,218.64-43.24-218.94-43.29Z" />
                            </g>
                            <g className="k4b4base">
                              <polygon className={`k4b4 ${isHovered44 ? 'act' : ''}`}
                                onMouseEnter={() => setIsHovered44(true)}
                                onMouseLeave={() => setIsHovered44(false)} points="1254.27 1246.27 1481.39 1201.35 1481.39 1291.12 1481.62 1291.17 1254.5 1336.09 1254.5 1246.32 1254.27 1246.27" />
                              <path class="cls-1 k4b4path" d="M1249.67,1247.29l-5.13-.99,241.67-47.8v91.66s5.16,.99,5.16,.99l-241.7,47.8v-91.66Zm226.89-43.07l-217.24,42.97v86.03s217.25-42.96,217.25-42.96v-86.04Z" />
                            </g>
                            <g>
                              <polygon class="cls-2" points="1026.56 1201.27 1026.64 1201.26 1254.27 1246.27 1254.05 1246.32 1254.05 1336.17 1253.97 1336.19 1026.34 1291.16 1026.56 1291.12 1026.56 1201.27" />
                              <path class="cls-1" d="M1023.93,1292.59l-6.96-1.46,4.77-.96v-88.9s.08-.99,.08-.99l4.88-.92,229.98,45.48,7.1,1.5-4.92,.95v89.8s-4.8,1.03-4.8,1.03l-230.13-45.52Zm7.45-88.49v86.15s217.84,43.09,217.84,43.09v-86.16s-217.84-43.08-217.84-43.08Z" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="1254.05 1330.74 1254.5 1330.5 1254.5 1331.56 1254.27 1331.69 1254.05 1331.56 1254.05 1330.74" />
                              <polygon class="cls-1" points="1254.05 1337.06 1259.32 1334.44 1259.32 1322.25 1249.67 1327.68 1249.22 1333.63 1251.75 1335.81 1254.05 1337.06" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="1254.27 1246.27 1254.5 1246.32 1254.5 1336.09 1254.05 1336.17 1254.05 1246.32 1254.27 1246.27" />
                              <polygon class="cls-1" points="1249.22 1338.99 1259.32 1337.05 1259.32 1245.35 1254.5 1244.42 1249.45 1245.31 1249.22 1246.32 1249.22 1338.99" />
                            </g>
                          </g>
                          <g>
                            <g>
                              <polygon class="cls-2" points="1251.39 1247.35 1024.27 1292.27 796.64 1247.26 796.56 1247.27 796.57 1246.99 796.34 1246.94 1023.97 1201.93 1251.62 1246.94 1251.39 1246.99 1251.39 1247.35" />
                              <path class="cls-1" d="M796.34,1249.1l-4.61,.69v-1.84s-5.11-.99-5.11-.99l237.35-46.94,230.06,45.49,6.98,1.48-4.8,.96v.37s-231.93,45.87-231.93,45.87l-227.93-45.07Zm227.63-45.27l-218.63,43.24,218.93,43.3,218.64-43.24-218.94-43.29Z" />
                            </g>
                            <g className="k4b3base">
                              <polygon className={`k4b3 ${isHovered43 ? 'act' : ''}`}
                                onMouseEnter={() => setIsHovered43(true)}
                                onMouseLeave={() => setIsHovered43(false)} points="1024.27 1292.27 1251.39 1247.35 1251.39 1337.12 1251.62 1337.17 1024.5 1382.09 1024.5 1292.32 1024.27 1292.27" />
                              <path class="cls-1 k4b3path" d="M1019.67,1293.29l-5.13-.99,241.67-47.8v91.66s5.16,.99,5.16,.99l-241.7,47.8v-91.66Zm226.89-43.07l-217.24,42.97v86.03s217.25-42.96,217.25-42.96v-86.04Z" />
                            </g>
                            <g>
                              <polygon class="cls-2" points="796.56 1247.27 796.64 1247.26 1024.27 1292.27 1024.05 1292.32 1024.05 1382.17 1023.97 1382.19 796.34 1337.16 796.56 1337.12 796.56 1247.27" />
                              <path class="cls-1" d="M793.93,1338.59l-6.96-1.46,4.77-.96v-88.9s.08-.99,.08-.99l4.88-.92,229.98,45.48,7.1,1.5-4.92,.95v89.8s-4.8,1.03-4.8,1.03l-230.13-45.52Zm7.45-88.49v86.15s217.84,43.09,217.84,43.09v-86.16s-217.84-43.08-217.84-43.08Z" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="1024.05 1376.74 1024.5 1376.5 1024.5 1377.56 1024.27 1377.69 1024.05 1377.56 1024.05 1376.74" />
                              <polygon class="cls-1" points="1024.05 1383.06 1029.32 1380.44 1029.32 1368.25 1019.67 1373.68 1019.22 1379.63 1021.75 1381.81 1024.05 1383.06" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="1024.27 1292.27 1024.5 1292.32 1024.5 1382.09 1024.05 1382.17 1024.05 1292.32 1024.27 1292.27" />
                              <polygon class="cls-1" points="1019.22 1384.99 1029.32 1383.05 1029.32 1291.35 1024.5 1290.42 1019.45 1291.31 1019.22 1292.32 1019.22 1384.99" />
                            </g>
                          </g>
                          <g>
                            <g>
                              <polygon class="cls-2" points="1023.39 1292.35 796.27 1337.27 568.64 1292.26 568.56 1292.27 568.57 1291.99 568.34 1291.94 795.97 1246.93 1023.62 1291.94 1023.39 1291.99 1023.39 1292.35" />
                              <path class="cls-1" d="M568.34,1294.1l-4.61,.69v-1.84s-5.11-.99-5.11-.99l237.35-46.94,230.06,45.49,6.98,1.48-4.8,.96v.37s-231.93,45.87-231.93,45.87l-227.93-45.07Zm227.63-45.27l-218.63,43.24,218.93,43.3,218.64-43.24-218.94-43.29Z" />
                            </g>
                            <g className="k4b2base">
                              <polygon className={`k4b2 ${isHovered42 ? 'act' : ''}`}
                                onMouseEnter={() => setIsHovered42(true)}
                                onMouseLeave={() => setIsHovered42(false)} points="796.27 1337.27 1023.39 1292.35 1023.39 1382.12 1023.62 1382.17 796.5 1427.09 796.5 1337.32 796.27 1337.27" />
                              <path class="cls-1 k4b2path" d="M791.67,1338.29l-5.13-.99,241.67-47.8v91.66s5.16,.99,5.16,.99l-241.7,47.8v-91.66Zm226.89-43.07l-217.24,42.97v86.03s217.25-42.96,217.25-42.96v-86.04Z" />
                            </g>
                            <g className="k4b2base">
                              <polygon className={`k4b2 ${isHovered42 ? 'act' : ''}`}
                                onMouseEnter={() => setIsHovered42(true)}
                                onMouseLeave={() => setIsHovered42(false)} points="568.56 1292.27 568.64 1292.26 796.27 1337.27 796.05 1337.32 796.05 1427.17 795.97 1427.19 568.34 1382.16 568.56 1382.12 568.56 1292.27" />
                              <path class="cls-1 k4b2path" d="M565.93,1383.59l-6.96-1.46,4.77-.96v-88.9s.08-.99,.08-.99l4.88-.92,229.98,45.48,7.1,1.5-4.92,.95v89.8s-4.8,1.03-4.8,1.03l-230.13-45.52Zm7.45-88.49v86.15s217.84,43.09,217.84,43.09v-86.16s-217.84-43.08-217.84-43.08Z" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="796.05 1421.74 796.5 1421.5 796.5 1422.56 796.27 1422.69 796.05 1422.56 796.05 1421.74" />
                              <polygon class="cls-1" points="796.05 1428.06 801.32 1425.44 801.32 1413.25 791.67 1418.68 791.22 1424.63 793.75 1426.81 796.05 1428.06" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="796.27 1337.27 796.5 1337.32 796.5 1427.09 796.05 1427.17 796.05 1337.32 796.27 1337.27" />
                              <polygon class="cls-1" points="791.22 1429.99 801.32 1428.05 801.32 1336.35 796.5 1335.42 791.45 1336.31 791.22 1337.32 791.22 1429.99" />
                            </g>
                          </g>
                        </g>
                        <g>
                          <g>
                            <g>
                              <polygon class="cls-2" points="1479.39 1015.35 1252.27 1060.27 1024.64 1015.26 1024.56 1015.27 1024.57 1014.99 1024.34 1014.94 1251.97 969.93 1479.62 1014.94 1479.39 1014.99 1479.39 1015.35" />
                              <path class="cls-1" d="M1024.34,1017.1l-4.61,.69v-1.84s-5.11-.99-5.11-.99l237.35-46.94,230.06,45.49,6.98,1.48-4.8,.96v.37s-231.93,45.87-231.93,45.87l-227.93-45.07Zm227.63-45.27l-218.63,43.24,218.93,43.3,218.64-43.24-218.94-43.29Z" />
                            </g>
                            <g>
                              <polygon class="cls-2" points="1252.27 1060.27 1479.39 1015.35 1479.39 1105.12 1479.62 1105.17 1252.5 1150.09 1252.5 1060.32 1252.27 1060.27" />
                              <path class="cls-1" d="M1247.67,1061.29l-5.13-.99,241.67-47.8v91.66s5.16,.99,5.16,.99l-241.7,47.8v-91.66Zm226.89-43.07l-217.24,42.97v86.03s217.25-42.96,217.25-42.96v-86.04Z" />
                            </g>
                            <g>
                              <polygon class="cls-2" points="1024.56 1015.27 1024.64 1015.26 1252.27 1060.27 1252.05 1060.32 1252.05 1150.17 1251.97 1150.19 1024.34 1105.16 1024.56 1105.12 1024.56 1015.27" />
                              <path class="cls-1" d="M1021.93,1106.59l-6.96-1.46,4.77-.96v-88.9s.08-.99,.08-.99l4.88-.92,229.98,45.48,7.1,1.5-4.92,.95v89.8s-4.8,1.03-4.8,1.03l-230.13-45.52Zm7.45-88.49v86.15s217.84,43.09,217.84,43.09v-86.16s-217.84-43.08-217.84-43.08Z" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="1252.05 1144.74 1252.5 1144.5 1252.5 1145.56 1252.27 1145.69 1252.05 1145.56 1252.05 1144.74" />
                              <polygon class="cls-1" points="1252.05 1151.06 1257.32 1148.44 1257.32 1136.25 1247.67 1141.68 1247.22 1147.63 1249.75 1149.81 1252.05 1151.06" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="1252.27 1060.27 1252.5 1060.32 1252.5 1150.09 1252.05 1150.17 1252.05 1060.32 1252.27 1060.27" />
                              <polygon class="cls-1" points="1247.22 1152.99 1257.32 1151.05 1257.32 1059.35 1252.5 1058.42 1247.45 1059.31 1247.22 1060.32 1247.22 1152.99" />
                            </g>
                          </g>
                          <g>
                            <g>
                              <polygon class="cls-2" points="1251.39 1060.35 1024.27 1105.27 796.64 1060.26 796.56 1060.27 796.57 1059.99 796.34 1059.94 1023.97 1014.93 1251.62 1059.94 1251.39 1059.99 1251.39 1060.35" />
                              <path class="cls-1" d="M796.34,1062.1l-4.61,.69v-1.84s-5.11-.99-5.11-.99l237.35-46.94,230.06,45.49,6.98,1.48-4.8,.96v.37s-231.93,45.87-231.93,45.87l-227.93-45.07Zm227.63-45.27l-218.63,43.24,218.93,43.3,218.64-43.24-218.94-43.29Z" />
                            </g>
                            <g>
                              <polygon class="cls-2" points="1024.27 1105.27 1251.39 1060.35 1251.39 1150.12 1251.62 1150.17 1024.5 1195.09 1024.5 1105.32 1024.27 1105.27" />
                              <path class="cls-1" d="M1019.67,1106.29l-5.13-.99,241.67-47.8v91.66s5.16,.99,5.16,.99l-241.7,47.8v-91.66Zm226.89-43.07l-217.24,42.97v86.03s217.25-42.96,217.25-42.96v-86.04Z" />
                            </g>
                            <g>
                              <polygon class="cls-2" points="796.56 1060.27 796.64 1060.26 1024.27 1105.27 1024.05 1105.32 1024.05 1195.17 1023.97 1195.19 796.34 1150.16 796.56 1150.12 796.56 1060.27" />
                              <path class="cls-1" d="M793.93,1151.59l-6.96-1.46,4.77-.96v-88.9s.08-.99,.08-.99l4.88-.92,229.98,45.48,7.1,1.5-4.92,.95v89.8s-4.8,1.03-4.8,1.03l-230.13-45.52Zm7.45-88.49v86.15s217.84,43.09,217.84,43.09v-86.16s-217.84-43.08-217.84-43.08Z" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="1024.05 1189.74 1024.5 1189.5 1024.5 1190.56 1024.27 1190.69 1024.05 1190.56 1024.05 1189.74" />
                              <polygon class="cls-1" points="1024.05 1196.06 1029.32 1193.44 1029.32 1181.25 1019.67 1186.68 1019.22 1192.63 1021.75 1194.81 1024.05 1196.06" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="1024.27 1105.27 1024.5 1105.32 1024.5 1195.09 1024.05 1195.17 1024.05 1105.32 1024.27 1105.27" />
                              <polygon class="cls-1" points="1019.22 1197.99 1029.32 1196.05 1029.32 1104.35 1024.5 1103.42 1019.45 1104.31 1019.22 1105.32 1019.22 1197.99" />
                            </g>
                          </g>
                          <g>
                            <g>
                              <polygon class="cls-2" points="1021.39 1106.35 794.27 1151.27 566.64 1106.26 566.56 1106.27 566.57 1105.99 566.34 1105.94 793.97 1060.93 1021.62 1105.94 1021.39 1105.99 1021.39 1106.35" />
                              <path class="cls-1" d="M566.34,1108.1l-4.61,.69v-1.84s-5.11-.99-5.11-.99l237.35-46.94,230.06,45.49,6.98,1.48-4.8,.96v.37s-231.93,45.87-231.93,45.87l-227.93-45.07Zm227.63-45.27l-218.63,43.24,218.93,43.3,218.64-43.24-218.94-43.29Z" />
                            </g>
                            <g>
                              <polygon class="cls-2" points="794.27 1151.27 1021.39 1106.35 1021.39 1196.12 1021.62 1196.17 794.5 1241.09 794.5 1151.32 794.27 1151.27" />
                              <path class="cls-1" d="M789.67,1152.29l-5.13-.99,241.67-47.8v91.66s5.16,.99,5.16,.99l-241.7,47.8v-91.66Zm226.89-43.07l-217.24,42.97v86.03s217.25-42.96,217.25-42.96v-86.04Z" />
                            </g>
                            <g>
                              <polygon class="cls-2" points="566.56 1106.27 566.64 1106.26 794.27 1151.27 794.05 1151.32 794.05 1241.17 793.97 1241.19 566.34 1196.16 566.56 1196.12 566.56 1106.27" />
                              <path class="cls-1" d="M563.93,1197.59l-6.96-1.46,4.77-.96v-88.9s.08-.99,.08-.99l4.88-.92,229.98,45.48,7.1,1.5-4.92,.95v89.8s-4.8,1.03-4.8,1.03l-230.13-45.52Zm7.45-88.49v86.15s217.84,43.09,217.84,43.09v-86.16s-217.84-43.08-217.84-43.08Z" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="794.05 1235.74 794.5 1235.5 794.5 1236.56 794.27 1236.69 794.05 1236.56 794.05 1235.74" />
                              <polygon class="cls-1" points="794.05 1242.06 799.32 1239.44 799.32 1227.25 789.67 1232.68 789.22 1238.63 791.75 1240.81 794.05 1242.06" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="794.27 1151.27 794.5 1151.32 794.5 1241.09 794.05 1241.17 794.05 1151.32 794.27 1151.27" />
                              <polygon class="cls-1" points="789.22 1243.99 799.32 1242.05 799.32 1150.35 794.5 1149.42 789.45 1150.31 789.22 1151.32 789.22 1243.99" />
                            </g>
                          </g>
                          <g>
                            <g>
                              <polygon class="cls-2" points="793.39 1151.35 566.27 1196.27 338.64 1151.26 338.56 1151.27 338.57 1150.99 338.34 1150.94 565.97 1105.93 793.62 1150.94 793.39 1150.99 793.39 1151.35" />
                              <path class="cls-1" d="M338.34,1153.1l-4.61,.69v-1.84s-5.11-.99-5.11-.99l237.35-46.94,230.06,45.49,6.98,1.48-4.8,.96v.37s-231.93,45.87-231.93,45.87l-227.93-45.07Zm227.63-45.27l-218.63,43.24,218.93,43.3,218.64-43.24-218.94-43.29Z" />
                            </g>
                            <g>
                              <polygon class="cls-2" points="566.27 1196.27 793.39 1151.35 793.39 1241.12 793.62 1241.17 566.5 1286.09 566.5 1196.32 566.27 1196.27" />
                              <path class="cls-1" d="M561.67,1197.29l-5.13-.99,241.67-47.8v91.66s5.16,.99,5.16,.99l-241.7,47.8v-91.66Zm226.89-43.07l-217.24,42.97v86.03s217.25-42.96,217.25-42.96v-86.04Z" />
                            </g>
                            <g className="k5b1base">
                              <polygon className={`k5b1 ${isHovered51 ? 'act' : ''}`}
                                onMouseEnter={() => setIsHovered51(true)}
                                onMouseLeave={() => setIsHovered51(false)} points="338.56 1151.27 338.64 1151.26 566.27 1196.27 566.05 1196.32 566.05 1286.17 565.97 1286.19 338.34 1241.16 338.56 1241.12 338.56 1151.27" />
                              <path class="cls-1 k5b1path" d="M335.93,1242.59l-6.96-1.46,4.77-.96v-88.9s.08-.99,.08-.99l4.88-.92,229.98,45.48,7.1,1.5-4.92,.95v89.8s-4.8,1.03-4.8,1.03l-230.13-45.52Zm7.45-88.49v86.15s217.84,43.09,217.84,43.09v-86.16s-217.84-43.08-217.84-43.08Z" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="566.05 1280.74 566.5 1280.5 566.5 1281.56 566.27 1281.69 566.05 1281.56 566.05 1280.74" />
                              <polygon class="cls-1" points="566.05 1287.06 571.32 1284.44 571.32 1272.25 561.67 1277.68 561.22 1283.63 563.75 1285.81 566.05 1287.06" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="566.27 1196.27 566.5 1196.32 566.5 1286.09 566.05 1286.17 566.05 1196.32 566.27 1196.27" />
                              <polygon class="cls-1" points="561.22 1288.99 571.32 1287.05 571.32 1195.35 566.5 1194.42 561.45 1195.31 561.22 1196.32 561.22 1288.99" />
                            </g>
                          </g>
                          <g>
                            <g>
                              <polygon class="cls-2" points="1709.39 1061.35 1482.27 1106.27 1254.64 1061.26 1254.56 1061.27 1254.57 1060.99 1254.34 1060.94 1481.97 1015.93 1709.62 1060.94 1709.39 1060.99 1709.39 1061.35" />
                              <path class="cls-1" d="M1254.34,1063.1l-4.61,.69v-1.84s-5.11-.99-5.11-.99l237.35-46.94,230.06,45.49,6.98,1.48-4.8,.96v.37s-231.93,45.87-231.93,45.87l-227.93-45.07Zm227.63-45.27l-218.63,43.24,218.93,43.3,218.64-43.24-218.94-43.29Z" />
                            </g>
                            <g className="k5b5base">
                              <polygon className={`k5b5 ${isHovered55 ? 'act' : ''}`}
                                onMouseEnter={() => setIsHovered55(true)}
                                onMouseLeave={() => setIsHovered55(false)} points="1482.27 1106.27 1709.39 1061.35 1709.39 1151.12 1709.62 1151.17 1482.5 1196.09 1482.5 1106.32 1482.27 1106.27" />
                              <path class="cls-1 k5b5path" d="M1477.67,1107.29l-5.13-.99,241.67-47.8v91.66s5.16,.99,5.16,.99l-241.7,47.8v-91.66Zm226.89-43.07l-217.24,42.97v86.03s217.25-42.96,217.25-42.96v-86.04Z" />
                            </g>
                            <g>
                              <polygon class="cls-2" points="1254.56 1061.27 1254.64 1061.26 1482.27 1106.27 1482.05 1106.32 1482.05 1196.17 1481.97 1196.19 1254.34 1151.16 1254.56 1151.12 1254.56 1061.27" />
                              <path class="cls-1" d="M1251.93,1152.59l-6.96-1.46,4.77-.96v-88.9s.08-.99,.08-.99l4.88-.92,229.98,45.48,7.1,1.5-4.92,.95v89.8s-4.8,1.03-4.8,1.03l-230.13-45.52Zm7.45-88.49v86.15s217.84,43.09,217.84,43.09v-86.16s-217.84-43.08-217.84-43.08Z" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="1482.05 1190.74 1482.5 1190.5 1482.5 1191.56 1482.27 1191.69 1482.05 1191.56 1482.05 1190.74" />
                              <polygon class="cls-1" points="1482.05 1197.06 1487.32 1194.44 1487.32 1182.25 1477.67 1187.68 1477.22 1193.63 1479.75 1195.81 1482.05 1197.06" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="1482.27 1106.27 1482.5 1106.32 1482.5 1196.09 1482.05 1196.17 1482.05 1106.32 1482.27 1106.27" />
                              <polygon class="cls-1" points="1477.22 1198.99 1487.32 1197.05 1487.32 1105.35 1482.5 1104.42 1477.45 1105.31 1477.22 1106.32 1477.22 1198.99" />
                            </g>
                          </g>
                          <g>
                            <g>
                              <polygon class="cls-2" points="1481.39 1106.35 1254.27 1151.27 1026.64 1106.26 1026.56 1106.27 1026.57 1105.99 1026.34 1105.94 1253.97 1060.93 1481.62 1105.94 1481.39 1105.99 1481.39 1106.35" />
                              <path class="cls-1" d="M1026.34,1108.1l-4.61,.69v-1.84s-5.11-.99-5.11-.99l237.35-46.94,230.06,45.49,6.98,1.48-4.8,.96v.37s-231.93,45.87-231.93,45.87l-227.93-45.07Zm227.63-45.27l-218.63,43.24,218.93,43.3,218.64-43.24-218.94-43.29Z" />
                            </g>
                            <g className="k5b4base">
                              <polygon className={`k5b4 ${isHovered54 ? 'act' : ''}`}
                                onMouseEnter={() => setIsHovered54(true)}
                                onMouseLeave={() => setIsHovered54(false)} points="1254.27 1151.27 1481.39 1106.35 1481.39 1196.12 1481.62 1196.17 1254.5 1241.09 1254.5 1151.32 1254.27 1151.27" />
                              <path class="cls-1 k5b4path" d="M1249.67,1152.29l-5.13-.99,241.67-47.8v91.66s5.16,.99,5.16,.99l-241.7,47.8v-91.66Zm226.89-43.07l-217.24,42.97v86.03s217.25-42.96,217.25-42.96v-86.04Z" />
                            </g>
                            <g>
                              <polygon class="cls-2" points="1026.56 1106.27 1026.64 1106.26 1254.27 1151.27 1254.05 1151.32 1254.05 1241.17 1253.97 1241.19 1026.34 1196.16 1026.56 1196.12 1026.56 1106.27" />
                              <path class="cls-1" d="M1023.93,1197.59l-6.96-1.46,4.77-.96v-88.9s.08-.99,.08-.99l4.88-.92,229.98,45.48,7.1,1.5-4.92,.95v89.8s-4.8,1.03-4.8,1.03l-230.13-45.52Zm7.45-88.49v86.15s217.84,43.09,217.84,43.09v-86.16s-217.84-43.08-217.84-43.08Z" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="1254.05 1235.74 1254.5 1235.5 1254.5 1236.56 1254.27 1236.69 1254.05 1236.56 1254.05 1235.74" />
                              <polygon class="cls-1" points="1254.05 1242.06 1259.32 1239.44 1259.32 1227.25 1249.67 1232.68 1249.22 1238.63 1251.75 1240.81 1254.05 1242.06" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="1254.27 1151.27 1254.5 1151.32 1254.5 1241.09 1254.05 1241.17 1254.05 1151.32 1254.27 1151.27" />
                              <polygon class="cls-1" points="1249.22 1243.99 1259.32 1242.05 1259.32 1150.35 1254.5 1149.42 1249.45 1150.31 1249.22 1151.32 1249.22 1243.99" />
                            </g>
                          </g>
                          <g>
                            <g>
                              <polygon class="cls-2" points="1251.39 1152.35 1024.27 1197.27 796.64 1152.26 796.56 1152.27 796.57 1151.99 796.34 1151.94 1023.97 1106.93 1251.62 1151.94 1251.39 1151.99 1251.39 1152.35" />
                              <path class="cls-1" d="M796.34,1154.1l-4.61,.69v-1.84s-5.11-.99-5.11-.99l237.35-46.94,230.06,45.49,6.98,1.48-4.8,.96v.37s-231.93,45.87-231.93,45.87l-227.93-45.07Zm227.63-45.27l-218.63,43.24,218.93,43.3,218.64-43.24-218.94-43.29Z" />
                            </g>
                            <g className="k5b3base">
                              <polygon className={`k5b3 ${isHovered53 ? 'act' : ''}`}
                                onMouseEnter={() => setIsHovered53(true)}
                                onMouseLeave={() => setIsHovered53(false)} points="1024.27 1197.27 1251.39 1152.35 1251.39 1242.12 1251.62 1242.17 1024.5 1287.09 1024.5 1197.32 1024.27 1197.27" />
                              <path class="cls-1 k5b3path" d="M1019.67,1198.29l-5.13-.99,241.67-47.8v91.66s5.16,.99,5.16,.99l-241.7,47.8v-91.66Zm226.89-43.07l-217.24,42.97v86.03s217.25-42.96,217.25-42.96v-86.04Z" />
                            </g>
                            <g>
                              <polygon class="cls-2" points="796.56 1152.27 796.64 1152.26 1024.27 1197.27 1024.05 1197.32 1024.05 1287.17 1023.97 1287.19 796.34 1242.16 796.56 1242.12 796.56 1152.27" />
                              <path class="cls-1" d="M793.93,1243.59l-6.96-1.46,4.77-.96v-88.9s.08-.99,.08-.99l4.88-.92,229.98,45.48,7.1,1.5-4.92,.95v89.8s-4.8,1.03-4.8,1.03l-230.13-45.52Zm7.45-88.49v86.15s217.84,43.09,217.84,43.09v-86.16s-217.84-43.08-217.84-43.08Z" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="1024.05 1281.74 1024.5 1281.5 1024.5 1282.56 1024.27 1282.69 1024.05 1282.56 1024.05 1281.74" />
                              <polygon class="cls-1" points="1024.05 1288.06 1029.32 1285.44 1029.32 1273.25 1019.67 1278.68 1019.22 1284.63 1021.75 1286.81 1024.05 1288.06" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="1024.27 1197.27 1024.5 1197.32 1024.5 1287.09 1024.05 1287.17 1024.05 1197.32 1024.27 1197.27" />
                              <polygon class="cls-1" points="1019.22 1289.99 1029.32 1288.05 1029.32 1196.35 1024.5 1195.42 1019.45 1196.31 1019.22 1197.32 1019.22 1289.99" />
                            </g>
                          </g>
                          <g>
                            <g>
                              <polygon class="cls-2" points="1023.39 1197.35 796.27 1242.27 568.64 1197.26 568.56 1197.27 568.57 1196.99 568.34 1196.94 795.97 1151.93 1023.62 1196.94 1023.39 1196.99 1023.39 1197.35" />
                              <path class="cls-1" d="M568.34,1199.1l-4.61,.69v-1.84s-5.11-.99-5.11-.99l237.35-46.94,230.06,45.49,6.98,1.48-4.8,.96v.37s-231.93,45.87-231.93,45.87l-227.93-45.07Zm227.63-45.27l-218.63,43.24,218.93,43.3,218.64-43.24-218.94-43.29Z" />
                            </g>
                            <g className="k5b2base">
                              <polygon className={`k5b2 ${isHovered52 ? 'act' : ''}`}
                                onMouseEnter={() => setIsHovered52(true)}
                                onMouseLeave={() => setIsHovered52(false)} points="796.27 1242.27 1023.39 1197.35 1023.39 1287.12 1023.62 1287.17 796.5 1332.09 796.5 1242.32 796.27 1242.27" />
                              <path class="cls-1 k5b2path" d="M791.67,1243.29l-5.13-.99,241.67-47.8v91.66s5.16,.99,5.16,.99l-241.7,47.8v-91.66Zm226.89-43.07l-217.24,42.97v86.03s217.25-42.96,217.25-42.96v-86.04Z" />
                            </g>
                            <g className="k5b2base">
                              <polygon className={`k5b2 ${isHovered52 ? 'act' : ''}`}
                                onMouseEnter={() => setIsHovered52(true)}
                                onMouseLeave={() => setIsHovered52(false)} points="568.56 1197.27 568.64 1197.26 796.27 1242.27 796.05 1242.32 796.05 1332.17 795.97 1332.19 568.34 1287.16 568.56 1287.12 568.56 1197.27" />
                              <path class="cls-1 k5b2path" d="M565.93,1288.59l-6.96-1.46,4.77-.96v-88.9s.08-.99,.08-.99l4.88-.92,229.98,45.48,7.1,1.5-4.92,.95v89.8s-4.8,1.03-4.8,1.03l-230.13-45.52Zm7.45-88.49v86.15s217.84,43.09,217.84,43.09v-86.16s-217.84-43.08-217.84-43.08Z" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="796.05 1326.74 796.5 1326.5 796.5 1327.56 796.27 1327.69 796.05 1327.56 796.05 1326.74" />
                              <polygon class="cls-1" points="796.05 1333.06 801.32 1330.44 801.32 1318.25 791.67 1323.68 791.22 1329.63 793.75 1331.81 796.05 1333.06" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="796.27 1242.27 796.5 1242.32 796.5 1332.09 796.05 1332.17 796.05 1242.32 796.27 1242.27" />
                              <polygon class="cls-1" points="791.22 1334.99 801.32 1333.05 801.32 1241.35 796.5 1240.42 791.45 1241.31 791.22 1242.32 791.22 1334.99" />
                            </g>
                          </g>
                        </g>
                        <g>
                          <g>
                            <polygon class="cls-2" points="1479.39 921.35 1252.27 966.27 1024.64 921.26 1024.56 921.27 1024.57 920.99 1024.34 920.94 1251.97 875.93 1479.62 920.94 1479.39 920.99 1479.39 921.35" />
                            <path class="cls-1" d="M1024.34,923.1l-4.61,.69v-1.84s-5.11-.99-5.11-.99l237.35-46.94,230.06,45.49,6.98,1.48-4.8,.96v.37s-231.93,45.87-231.93,45.87l-227.93-45.07Zm227.63-45.27l-218.63,43.24,218.93,43.3,218.64-43.24-218.94-43.29Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="1252.27 966.27 1479.39 921.35 1479.39 1011.12 1479.62 1011.17 1252.5 1056.09 1252.5 966.32 1252.27 966.27" />
                            <path class="cls-1" d="M1247.67,967.29l-5.13-.99,241.67-47.8v91.66s5.16,.99,5.16,.99l-241.7,47.8v-91.66Zm226.89-43.07l-217.24,42.97v86.03s217.25-42.96,217.25-42.96v-86.04Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="1024.56 921.27 1024.64 921.26 1252.27 966.27 1252.05 966.32 1252.05 1056.17 1251.97 1056.19 1024.34 1011.16 1024.56 1011.12 1024.56 921.27" />
                            <path class="cls-1" d="M1021.93,1012.59l-6.96-1.46,4.77-.96v-88.9s.08-.99,.08-.99l4.88-.92,229.98,45.48,7.1,1.5-4.92,.95v89.8s-4.8,1.03-4.8,1.03l-230.13-45.52Zm7.45-88.49v86.15s217.84,43.09,217.84,43.09v-86.16s-217.84-43.08-217.84-43.08Z" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1252.05 1050.74 1252.5 1050.5 1252.5 1051.56 1252.27 1051.69 1252.05 1051.56 1252.05 1050.74" />
                            <polygon class="cls-1" points="1252.05 1057.06 1257.32 1054.44 1257.32 1042.25 1247.67 1047.68 1247.22 1053.63 1249.75 1055.81 1252.05 1057.06" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1252.27 966.27 1252.5 966.32 1252.5 1056.09 1252.05 1056.17 1252.05 966.32 1252.27 966.27" />
                            <polygon class="cls-1" points="1247.22 1058.99 1257.32 1057.05 1257.32 965.35 1252.5 964.42 1247.45 965.31 1247.22 966.32 1247.22 1058.99" />
                          </g>
                        </g>
                        <g>
                          <g>
                            <polygon class="cls-2" points="1251.39 966.35 1024.27 1011.27 796.64 966.26 796.56 966.27 796.57 965.99 796.34 965.94 1023.97 920.93 1251.62 965.94 1251.39 965.99 1251.39 966.35" />
                            <path class="cls-1" d="M796.34,968.1l-4.61,.69v-1.84s-5.11-.99-5.11-.99l237.35-46.94,230.06,45.49,6.98,1.48-4.8,.96v.37s-231.93,45.87-231.93,45.87l-227.93-45.07Zm227.63-45.27l-218.63,43.24,218.93,43.3,218.64-43.24-218.94-43.29Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="1024.27 1011.27 1251.39 966.35 1251.39 1056.12 1251.62 1056.17 1024.5 1101.09 1024.5 1011.32 1024.27 1011.27" />
                            <path class="cls-1" d="M1019.67,1012.29l-5.13-.99,241.67-47.8v91.66s5.16,.99,5.16,.99l-241.7,47.8v-91.66Zm226.89-43.07l-217.24,42.97v86.03s217.25-42.96,217.25-42.96v-86.04Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="796.56 966.27 796.64 966.26 1024.27 1011.27 1024.05 1011.32 1024.05 1101.17 1023.97 1101.19 796.34 1056.16 796.56 1056.12 796.56 966.27" />
                            <path class="cls-1" d="M793.93,1057.59l-6.96-1.46,4.77-.96v-88.9s.08-.99,.08-.99l4.88-.92,229.98,45.48,7.1,1.5-4.92,.95v89.8s-4.8,1.03-4.8,1.03l-230.13-45.52Zm7.45-88.49v86.15s217.84,43.09,217.84,43.09v-86.16s-217.84-43.08-217.84-43.08Z" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1024.05 1095.74 1024.5 1095.5 1024.5 1096.56 1024.27 1096.69 1024.05 1096.56 1024.05 1095.74" />
                            <polygon class="cls-1" points="1024.05 1102.06 1029.32 1099.44 1029.32 1087.25 1019.67 1092.68 1019.22 1098.63 1021.75 1100.81 1024.05 1102.06" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1024.27 1011.27 1024.5 1011.32 1024.5 1101.09 1024.05 1101.17 1024.05 1011.32 1024.27 1011.27" />
                            <polygon class="cls-1" points="1019.22 1103.99 1029.32 1102.05 1029.32 1010.35 1024.5 1009.42 1019.45 1010.31 1019.22 1011.32 1019.22 1103.99" />
                          </g>
                        </g>
                        <g>
                          <g>
                            <polygon class="cls-2" points="1021.39 1012.35 794.27 1057.27 566.64 1012.26 566.56 1012.27 566.57 1011.99 566.34 1011.94 793.97 966.93 1021.62 1011.94 1021.39 1011.99 1021.39 1012.35" />
                            <path class="cls-1" d="M566.34,1014.1l-4.61,.69v-1.84s-5.11-.99-5.11-.99l237.35-46.94,230.06,45.49,6.98,1.48-4.8,.96v.37s-231.93,45.87-231.93,45.87l-227.93-45.07Zm227.63-45.27l-218.63,43.24,218.93,43.3,218.64-43.24-218.94-43.29Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="794.27 1057.27 1021.39 1012.35 1021.39 1102.12 1021.62 1102.17 794.5 1147.09 794.5 1057.32 794.27 1057.27" />
                            <path class="cls-1" d="M789.67,1058.29l-5.13-.99,241.67-47.8v91.66s5.16,.99,5.16,.99l-241.7,47.8v-91.66Zm226.89-43.07l-217.24,42.97v86.03s217.25-42.96,217.25-42.96v-86.04Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="566.56 1012.27 566.64 1012.26 794.27 1057.27 794.05 1057.32 794.05 1147.17 793.97 1147.19 566.34 1102.16 566.56 1102.12 566.56 1012.27" />
                            <path class="cls-1" d="M563.93,1103.59l-6.96-1.46,4.77-.96v-88.9s.08-.99,.08-.99l4.88-.92,229.98,45.48,7.1,1.5-4.92,.95v89.8s-4.8,1.03-4.8,1.03l-230.13-45.52Zm7.45-88.49v86.15s217.84,43.09,217.84,43.09v-86.16s-217.84-43.08-217.84-43.08Z" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="794.05 1141.74 794.5 1141.5 794.5 1142.56 794.27 1142.69 794.05 1142.56 794.05 1141.74" />
                            <polygon class="cls-1" points="794.05 1148.06 799.32 1145.44 799.32 1133.25 789.67 1138.68 789.22 1144.63 791.75 1146.81 794.05 1148.06" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="794.27 1057.27 794.5 1057.32 794.5 1147.09 794.05 1147.17 794.05 1057.32 794.27 1057.27" />
                            <polygon class="cls-1" points="789.22 1149.99 799.32 1148.05 799.32 1056.35 794.5 1055.42 789.45 1056.31 789.22 1057.32 789.22 1149.99" />
                          </g>
                        </g>
                        <g>
                          <g>
                            <polygon class="cls-2" points="793.39 1057.35 566.27 1102.27 338.64 1057.26 338.56 1057.27 338.57 1056.99 338.34 1056.94 565.97 1011.93 793.62 1056.94 793.39 1056.99 793.39 1057.35" />
                            <path class="cls-1" d="M338.34,1059.1l-4.61,.69v-1.84s-5.11-.99-5.11-.99l237.35-46.94,230.06,45.49,6.98,1.48-4.8,.96v.37s-231.93,45.87-231.93,45.87l-227.93-45.07Zm227.63-45.27l-218.63,43.24,218.93,43.3,218.64-43.24-218.94-43.29Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="566.27 1102.27 793.39 1057.35 793.39 1147.12 793.62 1147.17 566.5 1192.09 566.5 1102.32 566.27 1102.27" />
                            <path class="cls-1" d="M561.67,1103.29l-5.13-.99,241.67-47.8v91.66s5.16,.99,5.16,.99l-241.7,47.8v-91.66Zm226.89-43.07l-217.24,42.97v86.03s217.25-42.96,217.25-42.96v-86.04Z" />
                          </g>
                          <g className="k6b1base">
                            <polygon className={`k6b1 ${isHovered61 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered61(true)}
                              onMouseLeave={() => setIsHovered61(false)} points="338.56 1057.27 338.64 1057.26 566.27 1102.27 566.05 1102.32 566.05 1192.17 565.97 1192.19 338.34 1147.16 338.56 1147.12 338.56 1057.27" />
                            <path class="cls-1 k6b1path" d="M335.93,1148.59l-6.96-1.46,4.77-.96v-88.9s.08-.99,.08-.99l4.88-.92,229.98,45.48,7.1,1.5-4.92,.95v89.8s-4.8,1.03-4.8,1.03l-230.13-45.52Zm7.45-88.49v86.15s217.84,43.09,217.84,43.09v-86.16s-217.84-43.08-217.84-43.08Z" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="566.05 1186.74 566.5 1186.5 566.5 1187.56 566.27 1187.69 566.05 1187.56 566.05 1186.74" />
                            <polygon class="cls-1" points="566.05 1193.06 571.32 1190.44 571.32 1178.25 561.67 1183.68 561.22 1189.63 563.75 1191.81 566.05 1193.06" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="566.27 1102.27 566.5 1102.32 566.5 1192.09 566.05 1192.17 566.05 1102.32 566.27 1102.27" />
                            <polygon class="cls-1" points="561.22 1194.99 571.32 1193.05 571.32 1101.35 566.5 1100.42 561.45 1101.31 561.22 1102.32 561.22 1194.99" />
                          </g>
                        </g>
                        <g>
                          <g>
                            <polygon class="cls-2" points="1709.39 967.35 1482.27 1012.27 1254.64 967.26 1254.56 967.27 1254.57 966.99 1254.34 966.94 1481.97 921.93 1709.62 966.94 1709.39 966.99 1709.39 967.35" />
                            <path class="cls-1" d="M1254.34,969.1l-4.61,.69v-1.84s-5.11-.99-5.11-.99l237.35-46.94,230.06,45.49,6.98,1.48-4.8,.96v.37s-231.93,45.87-231.93,45.87l-227.93-45.07Zm227.63-45.27l-218.63,43.24,218.93,43.3,218.64-43.24-218.94-43.29Z" />
                          </g>
                          <g className="k6b5base">
                            <polygon className={`k6b5 ${isHovered65 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered65(true)}
                              onMouseLeave={() => setIsHovered65(false)} points="1482.27 1012.27 1709.39 967.35 1709.39 1057.12 1709.62 1057.17 1482.5 1102.09 1482.5 1012.32 1482.27 1012.27" />
                            <path class="cls-1 k6b5path" d="M1477.67,1013.29l-5.13-.99,241.67-47.8v91.66s5.16,.99,5.16,.99l-241.7,47.8v-91.66Zm226.89-43.07l-217.24,42.97v86.03s217.25-42.96,217.25-42.96v-86.04Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="1254.56 967.27 1254.64 967.26 1482.27 1012.27 1482.05 1012.32 1482.05 1102.17 1481.97 1102.19 1254.34 1057.16 1254.56 1057.12 1254.56 967.27" />
                            <path class="cls-1" d="M1251.93,1058.59l-6.96-1.46,4.77-.96v-88.9s.08-.99,.08-.99l4.88-.92,229.98,45.48,7.1,1.5-4.92,.95v89.8s-4.8,1.03-4.8,1.03l-230.13-45.52Zm7.45-88.49v86.15s217.84,43.09,217.84,43.09v-86.16s-217.84-43.08-217.84-43.08Z" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1482.05 1096.74 1482.5 1096.5 1482.5 1097.56 1482.27 1097.69 1482.05 1097.56 1482.05 1096.74" />
                            <polygon class="cls-1" points="1482.05 1103.06 1487.32 1100.44 1487.32 1088.25 1477.67 1093.68 1477.22 1099.63 1479.75 1101.81 1482.05 1103.06" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1482.27 1012.27 1482.5 1012.32 1482.5 1102.09 1482.05 1102.17 1482.05 1012.32 1482.27 1012.27" />
                            <polygon class="cls-1" points="1477.22 1104.99 1487.32 1103.05 1487.32 1011.35 1482.5 1010.42 1477.45 1011.31 1477.22 1012.32 1477.22 1104.99" />
                          </g>
                        </g>
                        <g>
                          <g>
                            <polygon class="cls-2" points="1481.39 1012.35 1254.27 1057.27 1026.64 1012.26 1026.56 1012.27 1026.57 1011.99 1026.34 1011.94 1253.97 966.93 1481.62 1011.94 1481.39 1011.99 1481.39 1012.35" />
                            <path class="cls-1" d="M1026.34,1014.1l-4.61,.69v-1.84s-5.11-.99-5.11-.99l237.35-46.94,230.06,45.49,6.98,1.48-4.8,.96v.37s-231.93,45.87-231.93,45.87l-227.93-45.07Zm227.63-45.27l-218.63,43.24,218.93,43.3,218.64-43.24-218.94-43.29Z" />
                          </g>
                          <g className="k6b4base">
                            <polygon className={`k6b4 ${isHovered64 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered64(true)}
                              onMouseLeave={() => setIsHovered64(false)} points="1254.27 1057.27 1481.39 1012.35 1481.39 1102.12 1481.62 1102.17 1254.5 1147.09 1254.5 1057.32 1254.27 1057.27" />
                            <path class="cls-1 k6b4path" d="M1249.67,1058.29l-5.13-.99,241.67-47.8v91.66s5.16,.99,5.16,.99l-241.7,47.8v-91.66Zm226.89-43.07l-217.24,42.97v86.03s217.25-42.96,217.25-42.96v-86.04Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="1026.56 1012.27 1026.64 1012.26 1254.27 1057.27 1254.05 1057.32 1254.05 1147.17 1253.97 1147.19 1026.34 1102.16 1026.56 1102.12 1026.56 1012.27" />
                            <path class="cls-1" d="M1023.93,1103.59l-6.96-1.46,4.77-.96v-88.9s.08-.99,.08-.99l4.88-.92,229.98,45.48,7.1,1.5-4.92,.95v89.8s-4.8,1.03-4.8,1.03l-230.13-45.52Zm7.45-88.49v86.15s217.84,43.09,217.84,43.09v-86.16s-217.84-43.08-217.84-43.08Z" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1254.05 1141.74 1254.5 1141.5 1254.5 1142.56 1254.27 1142.69 1254.05 1142.56 1254.05 1141.74" />
                            <polygon class="cls-1" points="1254.05 1148.06 1259.32 1145.44 1259.32 1133.25 1249.67 1138.68 1249.22 1144.63 1251.75 1146.81 1254.05 1148.06" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1254.27 1057.27 1254.5 1057.32 1254.5 1147.09 1254.05 1147.17 1254.05 1057.32 1254.27 1057.27" />
                            <polygon class="cls-1" points="1249.22 1149.99 1259.32 1148.05 1259.32 1056.35 1254.5 1055.42 1249.45 1056.31 1249.22 1057.32 1249.22 1149.99" />
                          </g>
                        </g>
                        <g>
                          <g>
                            <polygon class="cls-2" points="1251.39 1058.35 1024.27 1103.27 796.64 1058.26 796.56 1058.27 796.57 1057.99 796.34 1057.94 1023.97 1012.93 1251.62 1057.94 1251.39 1057.99 1251.39 1058.35" />
                            <path class="cls-1" d="M796.34,1060.1l-4.61,.69v-1.84s-5.11-.99-5.11-.99l237.35-46.94,230.06,45.49,6.98,1.48-4.8,.96v.37s-231.93,45.87-231.93,45.87l-227.93-45.07Zm227.63-45.27l-218.63,43.24,218.93,43.3,218.64-43.24-218.94-43.29Z" />
                          </g>
                          <g className="k6b3base">
                            <polygon className={`k6b3 ${isHovered63 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered63(true)}
                              onMouseLeave={() => setIsHovered63(false)} points="1024.27 1103.27 1251.39 1058.35 1251.39 1148.12 1251.62 1148.17 1024.5 1193.09 1024.5 1103.32 1024.27 1103.27" />
                            <path class="cls-1 k6b3path" d="M1019.67,1104.29l-5.13-.99,241.67-47.8v91.66s5.16,.99,5.16,.99l-241.7,47.8v-91.66Zm226.89-43.07l-217.24,42.97v86.03s217.25-42.96,217.25-42.96v-86.04Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="796.56 1058.27 796.64 1058.26 1024.27 1103.27 1024.05 1103.32 1024.05 1193.17 1023.97 1193.19 796.34 1148.16 796.56 1148.12 796.56 1058.27" />
                            <path class="cls-1" d="M793.93,1149.59l-6.96-1.46,4.77-.96v-88.9s.08-.99,.08-.99l4.88-.92,229.98,45.48,7.1,1.5-4.92,.95v89.8s-4.8,1.03-4.8,1.03l-230.13-45.52Zm7.45-88.49v86.15s217.84,43.09,217.84,43.09v-86.16s-217.84-43.08-217.84-43.08Z" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1024.05 1187.74 1024.5 1187.5 1024.5 1188.56 1024.27 1188.69 1024.05 1188.56 1024.05 1187.74" />
                            <polygon class="cls-1" points="1024.05 1194.06 1029.32 1191.44 1029.32 1179.25 1019.67 1184.68 1019.22 1190.63 1021.75 1192.81 1024.05 1194.06" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1024.27 1103.27 1024.5 1103.32 1024.5 1193.09 1024.05 1193.17 1024.05 1103.32 1024.27 1103.27" />
                            <polygon class="cls-1" points="1019.22 1195.99 1029.32 1194.05 1029.32 1102.35 1024.5 1101.42 1019.45 1102.31 1019.22 1103.32 1019.22 1195.99" />
                          </g>
                        </g>
                        <g>
                          <g>
                            <polygon class="cls-1" points="1023.39 1103.35 796.27 1148.27 568.64 1103.26 568.56 1103.27 568.57 1102.99 568.34 1102.94 795.97 1057.93 1023.62 1102.94 1023.39 1102.99 1023.39 1103.35" />
                            <path class="cls-1" d="M568.34,1105.1l-4.61,.69v-1.84s-5.11-.99-5.11-.99l237.35-46.94,230.06,45.49,6.98,1.48-4.8,.96v.37s-231.93,45.87-231.93,45.87l-227.93-45.07Zm227.63-45.27l-218.63,43.24,218.93,43.3,218.64-43.24-218.94-43.29Z" />
                          </g>
                          <g className="k6b2base">
                            <polygon className={`k6b2 ${isHovered62 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered62(true)}
                              onMouseLeave={() => setIsHovered62(false)} points="796.27 1148.27 1023.39 1103.35 1023.39 1193.12 1023.62 1193.17 796.5 1238.09 796.5 1148.32 796.27 1148.27" />
                            <path class="cls-1 k6b2path" d="M791.67,1149.29l-5.13-.99,241.67-47.8v91.66s5.16,.99,5.16,.99l-241.7,47.8v-91.66Zm226.89-43.07l-217.24,42.97v86.03s217.25-42.96,217.25-42.96v-86.04Z" />
                          </g>
                          <g className="k6b2base">
                            <polygon className={`k6b2 ${isHovered62 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered62(true)}
                              onMouseLeave={() => setIsHovered62(false)} points="568.56 1103.27 568.64 1103.26 796.27 1148.27 796.05 1148.32 796.05 1238.17 795.97 1238.19 568.34 1193.16 568.56 1193.12 568.56 1103.27" />
                            <path class="cls-1 k6b2path" d="M565.93,1194.59l-6.96-1.46,4.77-.96v-88.9s.08-.99,.08-.99l4.88-.92,229.98,45.48,7.1,1.5-4.92,.95v89.8s-4.8,1.03-4.8,1.03l-230.13-45.52Zm7.45-88.49v86.15s217.84,43.09,217.84,43.09v-86.16s-217.84-43.08-217.84-43.08Z" />
                          </g>
                          <g>
                            <polygon class="cls-1" points="796.05 1232.74 796.5 1232.5 796.5 1233.56 796.27 1233.69 796.05 1233.56 796.05 1232.74" />
                            <polygon class="cls-1" points="796.05 1239.06 801.32 1236.44 801.32 1224.25 791.67 1229.68 791.22 1235.63 793.75 1237.81 796.05 1239.06" />
                          </g>
                          <g>
                            <polygon class="cls-1" points="796.27 1148.27 796.5 1148.32 796.5 1238.09 796.05 1238.17 796.05 1148.32 796.27 1148.27" />
                            <polygon class="cls-1" points="791.22 1240.99 801.32 1239.05 801.32 1147.35 796.5 1146.42 791.45 1147.31 791.22 1148.32 791.22 1240.99" />
                          </g>
                        </g>
                        <g>
                          <g>
                            <g>
                              <polygon class="cls-2" points="1479.39 827.35 1252.27 872.27 1024.64 827.26 1024.56 827.27 1024.57 826.99 1024.34 826.94 1251.97 781.93 1479.62 826.94 1479.39 826.99 1479.39 827.35" />
                              <path class="cls-1" d="M1024.34,829.1l-4.61,.69v-1.84s-5.11-.99-5.11-.99l237.35-46.94,230.06,45.49,6.98,1.48-4.8,.96v.37s-231.93,45.87-231.93,45.87l-227.93-45.07Zm227.63-45.27l-218.63,43.24,218.93,43.3,218.64-43.24-218.94-43.29Z" />
                            </g>
                            <g>
                              <polygon class="cls-2" points="1252.27 872.27 1479.39 827.35 1479.39 917.12 1479.62 917.17 1252.5 962.09 1252.5 872.32 1252.27 872.27" />
                              <path class="cls-1" d="M1247.67,873.29l-5.13-.99,241.67-47.8v91.66s5.16,.99,5.16,.99l-241.7,47.8v-91.66Zm226.89-43.07l-217.24,42.97v86.03s217.25-42.96,217.25-42.96v-86.04Z" />
                            </g>
                            <g>
                              <polygon class="cls-2" points="1024.56 827.27 1024.64 827.26 1252.27 872.27 1252.05 872.32 1252.05 962.17 1251.97 962.19 1024.34 917.16 1024.56 917.12 1024.56 827.27" />
                              <path class="cls-1" d="M1021.93,918.59l-6.96-1.46,4.77-.96v-88.9s.08-.99,.08-.99l4.88-.92,229.98,45.48,7.1,1.5-4.92,.95v89.8s-4.8,1.03-4.8,1.03l-230.13-45.52Zm7.45-88.49v86.15s217.84,43.09,217.84,43.09v-86.16s-217.84-43.08-217.84-43.08Z" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="1252.05 956.74 1252.5 956.5 1252.5 957.56 1252.27 957.69 1252.05 957.56 1252.05 956.74" />
                              <polygon class="cls-1" points="1252.05 963.06 1257.32 960.44 1257.32 948.25 1247.67 953.68 1247.22 959.63 1249.75 961.81 1252.05 963.06" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="1252.27 872.27 1252.5 872.32 1252.5 962.09 1252.05 962.17 1252.05 872.32 1252.27 872.27" />
                              <polygon class="cls-1" points="1247.22 964.99 1257.32 963.05 1257.32 871.35 1252.5 870.42 1247.45 871.31 1247.22 872.32 1247.22 964.99" />
                            </g>
                          </g>
                          <g>
                            <g>
                              <polygon class="cls-2" points="1251.39 872.35 1024.27 917.27 796.64 872.26 796.56 872.27 796.57 871.99 796.34 871.94 1023.97 826.93 1251.62 871.94 1251.39 871.99 1251.39 872.35" />
                              <path class="cls-1" d="M796.34,874.1l-4.61,.69v-1.84s-5.11-.99-5.11-.99l237.35-46.94,230.06,45.49,6.98,1.48-4.8,.96v.37s-231.93,45.87-231.93,45.87l-227.93-45.07Zm227.63-45.27l-218.63,43.24,218.93,43.3,218.64-43.24-218.94-43.29Z" />
                            </g>
                            <g>
                              <polygon class="cls-2" points="1024.27 917.27 1251.39 872.35 1251.39 962.12 1251.62 962.17 1024.5 1007.09 1024.5 917.32 1024.27 917.27" />
                              <path class="cls-1" d="M1019.67,918.29l-5.13-.99,241.67-47.8v91.66s5.16,.99,5.16,.99l-241.7,47.8v-91.66Zm226.89-43.07l-217.24,42.97v86.03s217.25-42.96,217.25-42.96v-86.04Z" />
                            </g>
                            <g>
                              <polygon class="cls-2" points="796.56 872.27 796.64 872.26 1024.27 917.27 1024.05 917.32 1024.05 1007.17 1023.97 1007.19 796.34 962.16 796.56 962.12 796.56 872.27" />
                              <path class="cls-1" d="M793.93,963.59l-6.96-1.46,4.77-.96v-88.9s.08-.99,.08-.99l4.88-.92,229.98,45.48,7.1,1.5-4.92,.95v89.8s-4.8,1.03-4.8,1.03l-230.13-45.52Zm7.45-88.49v86.15s217.84,43.09,217.84,43.09v-86.16s-217.84-43.08-217.84-43.08Z" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="1024.05 1001.74 1024.5 1001.5 1024.5 1002.56 1024.27 1002.69 1024.05 1002.56 1024.05 1001.74" />
                              <polygon class="cls-1" points="1024.05 1008.06 1029.32 1005.44 1029.32 993.25 1019.67 998.68 1019.22 1004.63 1021.75 1006.81 1024.05 1008.06" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="1024.27 917.27 1024.5 917.32 1024.5 1007.09 1024.05 1007.17 1024.05 917.32 1024.27 917.27" />
                              <polygon class="cls-1" points="1019.22 1009.99 1029.32 1008.05 1029.32 916.35 1024.5 915.42 1019.45 916.31 1019.22 917.32 1019.22 1009.99" />
                            </g>
                          </g>
                          <g>
                            <g>
                              <polygon class="cls-2" points="1021.39 918.35 794.27 963.27 566.64 918.26 566.56 918.27 566.57 917.99 566.34 917.94 793.97 872.93 1021.62 917.94 1021.39 917.99 1021.39 918.35" />
                              <path class="cls-1" d="M566.34,920.1l-4.61,.69v-1.84s-5.11-.99-5.11-.99l237.35-46.94,230.06,45.49,6.98,1.48-4.8,.96v.37s-231.93,45.87-231.93,45.87l-227.93-45.07Zm227.63-45.27l-218.63,43.24,218.93,43.3,218.64-43.24-218.94-43.29Z" />
                            </g>
                            <g>
                              <polygon class="cls-2" points="794.27 963.27 1021.39 918.35 1021.39 1008.12 1021.62 1008.17 794.5 1053.09 794.5 963.32 794.27 963.27" />
                              <path class="cls-1" d="M789.67,964.29l-5.13-.99,241.67-47.8v91.66s5.16,.99,5.16,.99l-241.7,47.8v-91.66Zm226.89-43.07l-217.24,42.97v86.03s217.25-42.96,217.25-42.96v-86.04Z" />
                            </g>
                            <g>
                              <polygon class="cls-2" points="566.56 918.27 566.64 918.26 794.27 963.27 794.05 963.32 794.05 1053.17 793.97 1053.19 566.34 1008.16 566.56 1008.12 566.56 918.27" />
                              <path class="cls-1" d="M563.93,1009.59l-6.96-1.46,4.77-.96v-88.9s.08-.99,.08-.99l4.88-.92,229.98,45.48,7.1,1.5-4.92,.95v89.8s-4.8,1.03-4.8,1.03l-230.13-45.52Zm7.45-88.49v86.15s217.84,43.09,217.84,43.09v-86.16s-217.84-43.08-217.84-43.08Z" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="794.05 1047.74 794.5 1047.5 794.5 1048.56 794.27 1048.69 794.05 1048.56 794.05 1047.74" />
                              <polygon class="cls-1" points="794.05 1054.06 799.32 1051.44 799.32 1039.25 789.67 1044.68 789.22 1050.63 791.75 1052.81 794.05 1054.06" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="794.27 963.27 794.5 963.32 794.5 1053.09 794.05 1053.17 794.05 963.32 794.27 963.27" />
                              <polygon class="cls-1" points="789.22 1055.99 799.32 1054.05 799.32 962.35 794.5 961.42 789.45 962.31 789.22 963.32 789.22 1055.99" />
                            </g>
                          </g>
                          <g>
                            <g>
                              <polygon class="cls-2" points="793.39 963.35 566.27 1008.27 338.64 963.26 338.56 963.27 338.57 962.99 338.34 962.94 565.97 917.93 793.62 962.94 793.39 962.99 793.39 963.35" />
                              <path class="cls-1" d="M338.34,965.1l-4.61,.69v-1.84s-5.11-.99-5.11-.99l237.35-46.94,230.06,45.49,6.98,1.48-4.8,.96v.37s-231.93,45.87-231.93,45.87l-227.93-45.07Zm227.63-45.27l-218.63,43.24,218.93,43.3,218.64-43.24-218.94-43.29Z" />
                            </g>
                            <g>
                              <polygon class="cls-2" points="566.27 1008.27 793.39 963.35 793.39 1053.12 793.62 1053.17 566.5 1098.09 566.5 1008.32 566.27 1008.27" />
                              <path class="cls-1" d="M561.67,1009.29l-5.13-.99,241.67-47.8v91.66s5.16,.99,5.16,.99l-241.7,47.8v-91.66Zm226.89-43.07l-217.24,42.97v86.03s217.25-42.96,217.25-42.96v-86.04Z" />
                            </g>
                            <g className="k7b1base">
                              <polygon className={`k7b1 ${isHovered71 ? 'act' : ''}`}
                                onMouseEnter={() => setIsHovered71(true)}
                                onMouseLeave={() => setIsHovered71(false)} points="338.56 963.27 338.64 963.26 566.27 1008.27 566.05 1008.32 566.05 1098.17 565.97 1098.19 338.34 1053.16 338.56 1053.12 338.56 963.27" />
                              <path class="cls-1 k7b1" d="M335.93,1054.59l-6.96-1.46,4.77-.96v-88.9s.08-.99,.08-.99l4.88-.92,229.98,45.48,7.1,1.5-4.92,.95v89.8s-4.8,1.03-4.8,1.03l-230.13-45.52Zm7.45-88.49v86.15s217.84,43.09,217.84,43.09v-86.16s-217.84-43.08-217.84-43.08Z" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="566.05 1092.74 566.5 1092.5 566.5 1093.56 566.27 1093.69 566.05 1093.56 566.05 1092.74" />
                              <polygon class="cls-1" points="566.05 1099.06 571.32 1096.44 571.32 1084.25 561.67 1089.68 561.22 1095.63 563.75 1097.81 566.05 1099.06" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="566.27 1008.27 566.5 1008.32 566.5 1098.09 566.05 1098.17 566.05 1008.32 566.27 1008.27" />
                              <polygon class="cls-1" points="561.22 1100.99 571.32 1099.05 571.32 1007.35 566.5 1006.42 561.45 1007.31 561.22 1008.32 561.22 1100.99" />
                            </g>
                          </g>
                          <g>
                            <g>
                              <polygon class="cls-2" points="1709.39 873.35 1482.27 918.27 1254.64 873.26 1254.56 873.27 1254.57 872.99 1254.34 872.94 1481.97 827.93 1709.62 872.94 1709.39 872.99 1709.39 873.35" />
                              <path class="cls-1" d="M1254.34,875.1l-4.61,.69v-1.84s-5.11-.99-5.11-.99l237.35-46.94,230.06,45.49,6.98,1.48-4.8,.96v.37s-231.93,45.87-231.93,45.87l-227.93-45.07Zm227.63-45.27l-218.63,43.24,218.93,43.3,218.64-43.24-218.94-43.29Z" />
                            </g>
                            <g className="k7b5base">
                              <polygon className={`k7b5 ${isHovered75 ? 'act' : ''}`}
                                onMouseEnter={() => setIsHovered75(true)}
                                onMouseLeave={() => setIsHovered75(false)} points="1482.27 918.27 1709.39 873.35 1709.39 963.12 1709.62 963.17 1482.5 1008.09 1482.5 918.32 1482.27 918.27" />
                              <path class="cls-1 k7b5path" d="M1477.67,919.29l-5.13-.99,241.67-47.8v91.66s5.16,.99,5.16,.99l-241.7,47.8v-91.66Zm226.89-43.07l-217.24,42.97v86.03s217.25-42.96,217.25-42.96v-86.04Z" />
                            </g>
                            <g>
                              <polygon class="cls-2" points="1254.56 873.27 1254.64 873.26 1482.27 918.27 1482.05 918.32 1482.05 1008.17 1481.97 1008.19 1254.34 963.16 1254.56 963.12 1254.56 873.27" />
                              <path class="cls-1" d="M1251.93,964.59l-6.96-1.46,4.77-.96v-88.9s.08-.99,.08-.99l4.88-.92,229.98,45.48,7.1,1.5-4.92,.95v89.8s-4.8,1.03-4.8,1.03l-230.13-45.52Zm7.45-88.49v86.15s217.84,43.09,217.84,43.09v-86.16s-217.84-43.08-217.84-43.08Z" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="1482.05 1002.74 1482.5 1002.5 1482.5 1003.56 1482.27 1003.69 1482.05 1003.56 1482.05 1002.74" />
                              <polygon class="cls-1" points="1482.05 1009.06 1487.32 1006.44 1487.32 994.25 1477.67 999.68 1477.22 1005.63 1479.75 1007.81 1482.05 1009.06" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="1482.27 918.27 1482.5 918.32 1482.5 1008.09 1482.05 1008.17 1482.05 918.32 1482.27 918.27" />
                              <polygon class="cls-1" points="1477.22 1010.99 1487.32 1009.05 1487.32 917.35 1482.5 916.42 1477.45 917.31 1477.22 918.32 1477.22 1010.99" />
                            </g>
                          </g>
                          <g>
                            <g>
                              <polygon class="cls-2" points="1481.39 918.35 1254.27 963.27 1026.64 918.26 1026.56 918.27 1026.57 917.99 1026.34 917.94 1253.97 872.93 1481.62 917.94 1481.39 917.99 1481.39 918.35" />
                              <path class="cls-1" d="M1026.34,920.1l-4.61,.69v-1.84s-5.11-.99-5.11-.99l237.35-46.94,230.06,45.49,6.98,1.48-4.8,.96v.37s-231.93,45.87-231.93,45.87l-227.93-45.07Zm227.63-45.27l-218.63,43.24,218.93,43.3,218.64-43.24-218.94-43.29Z" />
                            </g>
                            <g className="k7b4base">
                              <polygon className={`k7b4 ${isHovered74 ? 'act' : ''}`}
                                onMouseEnter={() => setIsHovered74(true)}
                                onMouseLeave={() => setIsHovered74(false)} points="1254.27 963.27 1481.39 918.35 1481.39 1008.12 1481.62 1008.17 1254.5 1053.09 1254.5 963.32 1254.27 963.27" />
                              <path class="cls-1 k7b4path" d="M1249.67,964.29l-5.13-.99,241.67-47.8v91.66s5.16,.99,5.16,.99l-241.7,47.8v-91.66Zm226.89-43.07l-217.24,42.97v86.03s217.25-42.96,217.25-42.96v-86.04Z" />
                            </g>
                            <g>
                              <polygon class="cls-2" points="1026.56 918.27 1026.64 918.26 1254.27 963.27 1254.05 963.32 1254.05 1053.17 1253.97 1053.19 1026.34 1008.16 1026.56 1008.12 1026.56 918.27" />
                              <path class="cls-1" d="M1023.93,1009.59l-6.96-1.46,4.77-.96v-88.9s.08-.99,.08-.99l4.88-.92,229.98,45.48,7.1,1.5-4.92,.95v89.8s-4.8,1.03-4.8,1.03l-230.13-45.52Zm7.45-88.49v86.15s217.84,43.09,217.84,43.09v-86.16s-217.84-43.08-217.84-43.08Z" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="1254.05 1047.74 1254.5 1047.5 1254.5 1048.56 1254.27 1048.69 1254.05 1048.56 1254.05 1047.74" />
                              <polygon class="cls-1" points="1254.05 1054.06 1259.32 1051.44 1259.32 1039.25 1249.67 1044.68 1249.22 1050.63 1251.75 1052.81 1254.05 1054.06" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="1254.27 963.27 1254.5 963.32 1254.5 1053.09 1254.05 1053.17 1254.05 963.32 1254.27 963.27" />
                              <polygon class="cls-1" points="1249.22 1055.99 1259.32 1054.05 1259.32 962.35 1254.5 961.42 1249.45 962.31 1249.22 963.32 1249.22 1055.99" />
                            </g>
                          </g>
                          <g>
                            <g>
                              <polygon class="cls-2" points="1251.39 964.35 1024.27 1009.27 796.64 964.26 796.56 964.27 796.57 963.99 796.34 963.94 1023.97 918.93 1251.62 963.94 1251.39 963.99 1251.39 964.35" />
                              <path class="cls-1" d="M796.34,966.1l-4.61,.69v-1.84s-5.11-.99-5.11-.99l237.35-46.94,230.06,45.49,6.98,1.48-4.8,.96v.37s-231.93,45.87-231.93,45.87l-227.93-45.07Zm227.63-45.27l-218.63,43.24,218.93,43.3,218.64-43.24-218.94-43.29Z" />
                            </g>
                            <g className="k7b3base">
                              <polygon className={`k7b3 ${isHovered73 ? 'act' : ''}`}
                                onMouseEnter={() => setIsHovered73(true)}
                                onMouseLeave={() => setIsHovered73(false)} points="1024.27 1009.27 1251.39 964.35 1251.39 1054.12 1251.62 1054.17 1024.5 1099.09 1024.5 1009.32 1024.27 1009.27" />
                              <path class="cls-1 k7b3path" d="M1019.67,1010.29l-5.13-.99,241.67-47.8v91.66s5.16,.99,5.16,.99l-241.7,47.8v-91.66Zm226.89-43.07l-217.24,42.97v86.03s217.25-42.96,217.25-42.96v-86.04Z" />
                            </g>
                            <g>
                              <polygon class="cls-2" points="796.56 964.27 796.64 964.26 1024.27 1009.27 1024.05 1009.32 1024.05 1099.17 1023.97 1099.19 796.34 1054.16 796.56 1054.12 796.56 964.27" />
                              <path class="cls-1" d="M793.93,1055.59l-6.96-1.46,4.77-.96v-88.9s.08-.99,.08-.99l4.88-.92,229.98,45.48,7.1,1.5-4.92,.95v89.8s-4.8,1.03-4.8,1.03l-230.13-45.52Zm7.45-88.49v86.15s217.84,43.09,217.84,43.09v-86.16s-217.84-43.08-217.84-43.08Z" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="1024.05 1093.74 1024.5 1093.5 1024.5 1094.56 1024.27 1094.69 1024.05 1094.56 1024.05 1093.74" />
                              <polygon class="cls-1" points="1024.05 1100.06 1029.32 1097.44 1029.32 1085.25 1019.67 1090.68 1019.22 1096.63 1021.75 1098.81 1024.05 1100.06" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="1024.27 1009.27 1024.5 1009.32 1024.5 1099.09 1024.05 1099.17 1024.05 1009.32 1024.27 1009.27" />
                              <polygon class="cls-1" points="1019.22 1101.99 1029.32 1100.05 1029.32 1008.35 1024.5 1007.42 1019.45 1008.31 1019.22 1009.32 1019.22 1101.99" />
                            </g>
                          </g>
                          <g>
                            <g>
                              <polygon class="cls-2" points="1023.39 1009.35 796.27 1054.27 568.64 1009.26 568.56 1009.27 568.57 1008.99 568.34 1008.94 795.97 963.93 1023.62 1008.94 1023.39 1008.99 1023.39 1009.35" />
                              <path class="cls-1" d="M568.34,1011.1l-4.61,.69v-1.84s-5.11-.99-5.11-.99l237.35-46.94,230.06,45.49,6.98,1.48-4.8,.96v.37s-231.93,45.87-231.93,45.87l-227.93-45.07Zm227.63-45.27l-218.63,43.24,218.93,43.3,218.64-43.24-218.94-43.29Z" />
                            </g>
                            <g className="k7b2base">
                              <polygon className={`k7b2 ${isHovered72 ? 'act' : ''}`}
                                onMouseEnter={() => setIsHovered72(true)}
                                onMouseLeave={() => setIsHovered72(false)} points="796.27 1054.27 1023.39 1009.35 1023.39 1099.12 1023.62 1099.17 796.5 1144.09 796.5 1054.32 796.27 1054.27" />
                              <path class="cls-1 k7b2path" d="M791.67,1055.29l-5.13-.99,241.67-47.8v91.66s5.16,.99,5.16,.99l-241.7,47.8v-91.66Zm226.89-43.07l-217.24,42.97v86.03s217.25-42.96,217.25-42.96v-86.04Z" />
                            </g>
                            <g className="k7b2base">
                              <polygon className={`k7b2 ${isHovered72 ? 'act' : ''}`}
                                onMouseEnter={() => setIsHovered72(true)}
                                onMouseLeave={() => setIsHovered72(false)} points="568.56 1009.27 568.64 1009.26 796.27 1054.27 796.05 1054.32 796.05 1144.17 795.97 1144.19 568.34 1099.16 568.56 1099.12 568.56 1009.27" />
                              <path class="cls-1 k7b2path" d="M565.93,1100.59l-6.96-1.46,4.77-.96v-88.9s.08-.99,.08-.99l4.88-.92,229.98,45.48,7.1,1.5-4.92,.95v89.8s-4.8,1.03-4.8,1.03l-230.13-45.52Zm7.45-88.49v86.15s217.84,43.09,217.84,43.09v-86.16s-217.84-43.08-217.84-43.08Z" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="796.05 1138.74 796.5 1138.5 796.5 1139.56 796.27 1139.69 796.05 1139.56 796.05 1138.74" />
                              <polygon class="cls-1" points="796.05 1145.06 801.32 1142.44 801.32 1130.25 791.67 1135.68 791.22 1141.63 793.75 1143.81 796.05 1145.06" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="796.27 1054.27 796.5 1054.32 796.5 1144.09 796.05 1144.17 796.05 1054.32 796.27 1054.27" />
                              <polygon class="cls-1" points="791.22 1146.99 801.32 1145.05 801.32 1053.35 796.5 1052.42 791.45 1053.31 791.22 1054.32 791.22 1146.99" />
                            </g>
                          </g>
                        </g>
                        <g>
                          <g>
                            <g>
                              <polygon class="cls-3" points="1479.39 733.35 1252.27 778.27 1024.64 733.26 1024.56 733.27 1024.57 732.99 1024.34 732.94 1251.97 687.93 1479.62 732.94 1479.39 732.99 1479.39 733.35" />
                              <path class="cls-1" d="M1024.34,735.1l-4.61,.69v-1.84s-5.11-.99-5.11-.99l237.35-46.94,230.06,45.49,6.98,1.48-4.8,.96v.37s-231.93,45.87-231.93,45.87l-227.93-45.07Zm227.63-45.27l-218.63,43.24,218.93,43.3,218.64-43.24-218.94-43.29Z" />
                            </g>
                            <g>
                              <polygon class="cls-2" points="1252.27 778.27 1479.39 733.35 1479.39 823.12 1479.62 823.17 1252.5 868.09 1252.5 778.32 1252.27 778.27" />
                              <path class="cls-1" d="M1247.67,779.29l-5.13-.99,241.67-47.8v91.66s5.16,.99,5.16,.99l-241.7,47.8v-91.66Zm226.89-43.07l-217.24,42.97v86.03s217.25-42.96,217.25-42.96v-86.04Z" />
                            </g>
                            <g>
                              <polygon class="cls-2" points="1024.56 733.27 1024.64 733.26 1252.27 778.27 1252.05 778.32 1252.05 868.17 1251.97 868.19 1024.34 823.16 1024.56 823.12 1024.56 733.27" />
                              <path class="cls-1" d="M1021.93,824.59l-6.96-1.46,4.77-.96v-88.9s.08-.99,.08-.99l4.88-.92,229.98,45.48,7.1,1.5-4.92,.95v89.8s-4.8,1.03-4.8,1.03l-230.13-45.52Zm7.45-88.49v86.15s217.84,43.09,217.84,43.09v-86.16s-217.84-43.08-217.84-43.08Z" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="1252.05 862.74 1252.5 862.5 1252.5 863.56 1252.27 863.69 1252.05 863.56 1252.05 862.74" />
                              <polygon class="cls-1" points="1252.05 869.06 1257.32 866.44 1257.32 854.25 1247.67 859.68 1247.22 865.63 1249.75 867.81 1252.05 869.06" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="1252.27 778.27 1252.5 778.32 1252.5 868.09 1252.05 868.17 1252.05 778.32 1252.27 778.27" />
                              <polygon class="cls-1" points="1247.22 870.99 1257.32 869.05 1257.32 777.35 1252.5 776.42 1247.45 777.31 1247.22 778.32 1247.22 870.99" />
                            </g>
                          </g>
                          <g>
                            <g>
                              <polygon class="cls-3" points="1251.39 778.35 1024.27 823.27 796.64 778.26 796.56 778.27 796.57 777.99 796.34 777.94 1023.97 732.93 1251.62 777.94 1251.39 777.99 1251.39 778.35" />
                              <path class="cls-1" d="M796.34,780.1l-4.61,.69v-1.84s-5.11-.99-5.11-.99l237.35-46.94,230.06,45.49,6.98,1.48-4.8,.96v.37s-231.93,45.87-231.93,45.87l-227.93-45.07Zm227.63-45.27l-218.63,43.24,218.93,43.3,218.64-43.24-218.94-43.29Z" />
                            </g>
                            <g>
                              <polygon class="cls-2" points="1024.27 823.27 1251.39 778.35 1251.39 868.12 1251.62 868.17 1024.5 913.09 1024.5 823.32 1024.27 823.27" />
                              <path class="cls-1" d="M1019.67,824.29l-5.13-.99,241.67-47.8v91.66s5.16,.99,5.16,.99l-241.7,47.8v-91.66Zm226.89-43.07l-217.24,42.97v86.03s217.25-42.96,217.25-42.96v-86.04Z" />
                            </g>
                            <g>
                              <polygon class="cls-2" points="796.56 778.27 796.64 778.26 1024.27 823.27 1024.05 823.32 1024.05 913.17 1023.97 913.19 796.34 868.16 796.56 868.12 796.56 778.27" />
                              <path class="cls-1" d="M793.93,869.59l-6.96-1.46,4.77-.96v-88.9s.08-.99,.08-.99l4.88-.92,229.98,45.48,7.1,1.5-4.92,.95v89.8s-4.8,1.03-4.8,1.03l-230.13-45.52Zm7.45-88.49v86.15s217.84,43.09,217.84,43.09v-86.16s-217.84-43.08-217.84-43.08Z" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="1024.05 907.74 1024.5 907.5 1024.5 908.56 1024.27 908.69 1024.05 908.56 1024.05 907.74" />
                              <polygon class="cls-1" points="1024.05 914.06 1029.32 911.44 1029.32 899.25 1019.67 904.68 1019.22 910.63 1021.75 912.81 1024.05 914.06" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="1024.27 823.27 1024.5 823.32 1024.5 913.09 1024.05 913.17 1024.05 823.32 1024.27 823.27" />
                              <polygon class="cls-1" points="1019.22 915.99 1029.32 914.05 1029.32 822.35 1024.5 821.42 1019.45 822.31 1019.22 823.32 1019.22 915.99" />
                            </g>
                          </g>
                          <g>
                            <g>
                              <polygon class="cls-3" points="1021.39 824.35 794.27 869.27 566.64 824.26 566.56 824.27 566.57 823.99 566.34 823.94 793.97 778.93 1021.62 823.94 1021.39 823.99 1021.39 824.35" />
                              <path class="cls-1" d="M566.34,826.1l-4.61,.69v-1.84s-5.11-.99-5.11-.99l237.35-46.94,230.06,45.49,6.98,1.48-4.8,.96v.37s-231.93,45.87-231.93,45.87l-227.93-45.07Zm227.63-45.27l-218.63,43.24,218.93,43.3,218.64-43.24-218.94-43.29Z" />
                            </g>
                            <g>
                              <polygon class="cls-2" points="794.27 869.27 1021.39 824.35 1021.39 914.12 1021.62 914.17 794.5 959.09 794.5 869.32 794.27 869.27" />
                              <path class="cls-1" d="M789.67,870.29l-5.13-.99,241.67-47.8v91.66s5.16,.99,5.16,.99l-241.7,47.8v-91.66Zm226.89-43.07l-217.24,42.97v86.03s217.25-42.96,217.25-42.96v-86.04Z" />
                            </g>
                            <g>
                              <polygon class="cls-2" points="566.56 824.27 566.64 824.26 794.27 869.27 794.05 869.32 794.05 959.17 793.97 959.19 566.34 914.16 566.56 914.12 566.56 824.27" />
                              <path class="cls-1" d="M563.93,915.59l-6.96-1.46,4.77-.96v-88.9s.08-.99,.08-.99l4.88-.92,229.98,45.48,7.1,1.5-4.92,.95v89.8s-4.8,1.03-4.8,1.03l-230.13-45.52Zm7.45-88.49v86.15s217.84,43.09,217.84,43.09v-86.16s-217.84-43.08-217.84-43.08Z" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="794.05 953.74 794.5 953.5 794.5 954.56 794.27 954.69 794.05 954.56 794.05 953.74" />
                              <polygon class="cls-1" points="794.05 960.06 799.32 957.44 799.32 945.25 789.67 950.68 789.22 956.63 791.75 958.81 794.05 960.06" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="794.27 869.27 794.5 869.32 794.5 959.09 794.05 959.17 794.05 869.32 794.27 869.27" />
                              <polygon class="cls-1" points="789.22 961.99 799.32 960.05 799.32 868.35 794.5 867.42 789.45 868.31 789.22 869.32 789.22 961.99" />
                            </g>
                          </g>
                          <g>
                            <g>
                              <polygon class="cls-3" points="793.39 869.35 566.27 914.27 338.64 869.26 338.56 869.27 338.57 868.99 338.34 868.94 565.97 823.93 793.62 868.94 793.39 868.99 793.39 869.35" />
                              <path class="cls-1" d="M338.34,871.1l-4.61,.69v-1.84s-5.11-.99-5.11-.99l237.35-46.94,230.06,45.49,6.98,1.48-4.8,.96v.37s-231.93,45.87-231.93,45.87l-227.93-45.07Zm227.63-45.27l-218.63,43.24,218.93,43.3,218.64-43.24-218.94-43.29Z" />
                            </g>
                            <g>
                              <polygon class="cls-2" points="566.27 914.27 793.39 869.35 793.39 959.12 793.62 959.17 566.5 1004.09 566.5 914.32 566.27 914.27" />
                              <path class="cls-1" d="M561.67,915.29l-5.13-.99,241.67-47.8v91.66s5.16,.99,5.16,.99l-241.7,47.8v-91.66Zm226.89-43.07l-217.24,42.97v86.03s217.25-42.96,217.25-42.96v-86.04Z" />
                            </g>
                            <g className="k8b1base">
                              <polygon className={`k8b1 ${isHovered81 ? 'act' : ''}`}
                                onMouseEnter={() => setIsHovered81(true)}
                                onMouseLeave={() => setIsHovered81(false)} points="338.56 869.27 338.64 869.26 566.27 914.27 566.05 914.32 566.05 1004.17 565.97 1004.19 338.34 959.16 338.56 959.12 338.56 869.27" />
                              <path class="cls-1 k8b1path" d="M335.93,960.59l-6.96-1.46,4.77-.96v-88.9s.08-.99,.08-.99l4.88-.92,229.98,45.48,7.1,1.5-4.92,.95v89.8s-4.8,1.03-4.8,1.03l-230.13-45.52Zm7.45-88.49v86.15s217.84,43.09,217.84,43.09v-86.16s-217.84-43.08-217.84-43.08Z" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="566.05 998.74 566.5 998.5 566.5 999.56 566.27 999.69 566.05 999.56 566.05 998.74" />
                              <polygon class="cls-1" points="566.05 1005.06 571.32 1002.44 571.32 990.25 561.67 995.68 561.22 1001.63 563.75 1003.81 566.05 1005.06" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="566.27 914.27 566.5 914.32 566.5 1004.09 566.05 1004.17 566.05 914.32 566.27 914.27" />
                              <polygon class="cls-1" points="561.22 1006.99 571.32 1005.05 571.32 913.35 566.5 912.42 561.45 913.31 561.22 914.32 561.22 1006.99" />
                            </g>
                          </g>
                          <g>
                            <g>
                              <polygon class="cls-3" points="1709.39 779.35 1482.27 824.27 1254.64 779.26 1254.56 779.27 1254.57 778.99 1254.34 778.94 1481.97 733.93 1709.62 778.94 1709.39 778.99 1709.39 779.35" />
                              <path class="cls-1" d="M1254.34,781.1l-4.61,.69v-1.84s-5.11-.99-5.11-.99l237.35-46.94,230.06,45.49,6.98,1.48-4.8,.96v.37s-231.93,45.87-231.93,45.87l-227.93-45.07Zm227.63-45.27l-218.63,43.24,218.93,43.3,218.64-43.24-218.94-43.29Z" />
                            </g>
                            <g className="k8b5base">
                              <polygon className={`k8b5 ${isHovered85 ? 'act' : ''}`}
                                onMouseEnter={() => setIsHovered85(true)}
                                onMouseLeave={() => setIsHovered85(false)} points="1482.27 824.27 1709.39 779.35 1709.39 869.12 1709.62 869.17 1482.5 914.09 1482.5 824.32 1482.27 824.27" />
                              <path class="cls-1 k8b5path" d="M1477.67,825.29l-5.13-.99,241.67-47.8v91.66s5.16,.99,5.16,.99l-241.7,47.8v-91.66Zm226.89-43.07l-217.24,42.97v86.03s217.25-42.96,217.25-42.96v-86.04Z" />
                            </g>
                            <g>
                              <polygon class="cls-2" points="1254.56 779.27 1254.64 779.26 1482.27 824.27 1482.05 824.32 1482.05 914.17 1481.97 914.19 1254.34 869.16 1254.56 869.12 1254.56 779.27" />
                              <path class="cls-1" d="M1251.93,870.59l-6.96-1.46,4.77-.96v-88.9s.08-.99,.08-.99l4.88-.92,229.98,45.48,7.1,1.5-4.92,.95v89.8s-4.8,1.03-4.8,1.03l-230.13-45.52Zm7.45-88.49v86.15s217.84,43.09,217.84,43.09v-86.16s-217.84-43.08-217.84-43.08Z" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="1482.05 908.74 1482.5 908.5 1482.5 909.56 1482.27 909.69 1482.05 909.56 1482.05 908.74" />
                              <polygon class="cls-1" points="1482.05 915.06 1487.32 912.44 1487.32 900.25 1477.67 905.68 1477.22 911.63 1479.75 913.81 1482.05 915.06" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="1482.27 824.27 1482.5 824.32 1482.5 914.09 1482.05 914.17 1482.05 824.32 1482.27 824.27" />
                              <polygon class="cls-1" points="1477.22 916.99 1487.32 915.05 1487.32 823.35 1482.5 822.42 1477.45 823.31 1477.22 824.32 1477.22 916.99" />
                            </g>
                          </g>
                          <g>
                            <g>
                              <polygon class="cls-3" points="1481.39 824.35 1254.27 869.27 1026.64 824.26 1026.56 824.27 1026.57 823.99 1026.34 823.94 1253.97 778.93 1481.62 823.94 1481.39 823.99 1481.39 824.35" />
                              <path class="cls-1" d="M1026.34,826.1l-4.61,.69v-1.84s-5.11-.99-5.11-.99l237.35-46.94,230.06,45.49,6.98,1.48-4.8,.96v.37s-231.93,45.87-231.93,45.87l-227.93-45.07Zm227.63-45.27l-218.63,43.24,218.93,43.3,218.64-43.24-218.94-43.29Z" />
                            </g>
                            <g className="k8b4base">
                              <polygon className={`k8b4 ${isHovered84 ? 'act' : ''}`}
                                onMouseEnter={() => setIsHovered84(true)}
                                onMouseLeave={() => setIsHovered84(false)} points="1254.27 869.27 1481.39 824.35 1481.39 914.12 1481.62 914.17 1254.5 959.09 1254.5 869.32 1254.27 869.27" />
                              <path class="cls-1 k8b4" d="M1249.67,870.29l-5.13-.99,241.67-47.8v91.66s5.16,.99,5.16,.99l-241.7,47.8v-91.66Zm226.89-43.07l-217.24,42.97v86.03s217.25-42.96,217.25-42.96v-86.04Z" />
                            </g>
                            <g>
                              <polygon class="cls-2" points="1026.56 824.27 1026.64 824.26 1254.27 869.27 1254.05 869.32 1254.05 959.17 1253.97 959.19 1026.34 914.16 1026.56 914.12 1026.56 824.27" />
                              <path class="cls-1" d="M1023.93,915.59l-6.96-1.46,4.77-.96v-88.9s.08-.99,.08-.99l4.88-.92,229.98,45.48,7.1,1.5-4.92,.95v89.8s-4.8,1.03-4.8,1.03l-230.13-45.52Zm7.45-88.49v86.15s217.84,43.09,217.84,43.09v-86.16s-217.84-43.08-217.84-43.08Z" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="1254.05 953.74 1254.5 953.5 1254.5 954.56 1254.27 954.69 1254.05 954.56 1254.05 953.74" />
                              <polygon class="cls-1" points="1254.05 960.06 1259.32 957.44 1259.32 945.25 1249.67 950.68 1249.22 956.63 1251.75 958.81 1254.05 960.06" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="1254.27 869.27 1254.5 869.32 1254.5 959.09 1254.05 959.17 1254.05 869.32 1254.27 869.27" />
                              <polygon class="cls-1" points="1249.22 961.99 1259.32 960.05 1259.32 868.35 1254.5 867.42 1249.45 868.31 1249.22 869.32 1249.22 961.99" />
                            </g>
                          </g>
                          <g>
                            <g>
                              <polygon class="cls-3" points="1251.39 870.35 1024.27 915.27 796.64 870.26 796.56 870.27 796.57 869.99 796.34 869.94 1023.97 824.93 1251.62 869.94 1251.39 869.99 1251.39 870.35" />
                              <path class="cls-1" d="M796.34,872.1l-4.61,.69v-1.84s-5.11-.99-5.11-.99l237.35-46.94,230.06,45.49,6.98,1.48-4.8,.96v.37s-231.93,45.87-231.93,45.87l-227.93-45.07Zm227.63-45.27l-218.63,43.24,218.93,43.3,218.64-43.24-218.94-43.29Z" />
                            </g>
                            <g className="k8b3base">
                              <polygon className={`k8b3 ${isHovered83 ? 'act' : ''}`}
                                onMouseEnter={() => setIsHovered83(true)}
                                onMouseLeave={() => setIsHovered83(false)} points="1024.27 915.27 1251.39 870.35 1251.39 960.12 1251.62 960.17 1024.5 1005.09 1024.5 915.32 1024.27 915.27" />
                              <path class="cls-1 k8b3" d="M1019.67,916.29l-5.13-.99,241.67-47.8v91.66s5.16,.99,5.16,.99l-241.7,47.8v-91.66Zm226.89-43.07l-217.24,42.97v86.03s217.25-42.96,217.25-42.96v-86.04Z" />
                            </g>
                            <g>
                              <polygon class="cls-2" points="796.56 870.27 796.64 870.26 1024.27 915.27 1024.05 915.32 1024.05 1005.17 1023.97 1005.19 796.34 960.16 796.56 960.12 796.56 870.27" />
                              <path class="cls-1" d="M793.93,961.59l-6.96-1.46,4.77-.96v-88.9s.08-.99,.08-.99l4.88-.92,229.98,45.48,7.1,1.5-4.92,.95v89.8s-4.8,1.03-4.8,1.03l-230.13-45.52Zm7.45-88.49v86.15s217.84,43.09,217.84,43.09v-86.16s-217.84-43.08-217.84-43.08Z" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="1024.05 999.74 1024.5 999.5 1024.5 1000.56 1024.27 1000.69 1024.05 1000.56 1024.05 999.74" />
                              <polygon class="cls-1" points="1024.05 1006.06 1029.32 1003.44 1029.32 991.25 1019.67 996.68 1019.22 1002.63 1021.75 1004.81 1024.05 1006.06" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="1024.27 915.27 1024.5 915.32 1024.5 1005.09 1024.05 1005.17 1024.05 915.32 1024.27 915.27" />
                              <polygon class="cls-1" points="1019.22 1007.99 1029.32 1006.05 1029.32 914.35 1024.5 913.42 1019.45 914.31 1019.22 915.32 1019.22 1007.99" />
                            </g>
                          </g>
                          <g>
                            <g>
                              <polygon class="cls-3" points="1023.39 915.35 796.27 960.27 568.64 915.26 568.56 915.27 568.57 914.99 568.34 914.94 795.97 869.93 1023.62 914.94 1023.39 914.99 1023.39 915.35" />
                              <path class="cls-1" d="M568.34,917.1l-4.61,.69v-1.84s-5.11-.99-5.11-.99l237.35-46.94,230.06,45.49,6.98,1.48-4.8,.96v.37s-231.93,45.87-231.93,45.87l-227.93-45.07Zm227.63-45.27l-218.63,43.24,218.93,43.3,218.64-43.24-218.94-43.29Z" />
                            </g>
                            <g className="k8b2base">
                              <polygon className={`k8b2 ${isHovered82 ? 'act' : ''}`}
                                onMouseEnter={() => setIsHovered82(true)}
                                onMouseLeave={() => setIsHovered82(false)} points="796.27 960.27 1023.39 915.35 1023.39 1005.12 1023.62 1005.17 796.5 1050.09 796.5 960.32 796.27 960.27" />
                              <path class="cls-1 k8b2path" d="M791.67,961.29l-5.13-.99,241.67-47.8v91.66s5.16,.99,5.16,.99l-241.7,47.8v-91.66Zm226.89-43.07l-217.24,42.97v86.03s217.25-42.96,217.25-42.96v-86.04Z" />
                            </g>
                            <g className="k8b2base">
                              <polygon className={`k8b2 ${isHovered82 ? 'act' : ''}`}
                                onMouseEnter={() => setIsHovered82(true)}
                                onMouseLeave={() => setIsHovered82(false)} points="568.56 915.27 568.64 915.26 796.27 960.27 796.05 960.32 796.05 1050.17 795.97 1050.19 568.34 1005.16 568.56 1005.12 568.56 915.27" />
                              <path class="cls-1 k8b2path" d="M565.93,1006.59l-6.96-1.46,4.77-.96v-88.9s.08-.99,.08-.99l4.88-.92,229.98,45.48,7.1,1.5-4.92,.95v89.8s-4.8,1.03-4.8,1.03l-230.13-45.52Zm7.45-88.49v86.15s217.84,43.09,217.84,43.09v-86.16s-217.84-43.08-217.84-43.08Z" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="796.05 1044.74 796.5 1044.5 796.5 1045.56 796.27 1045.69 796.05 1045.56 796.05 1044.74" />
                              <polygon class="cls-1" points="796.05 1051.06 801.32 1048.44 801.32 1036.25 791.67 1041.68 791.22 1047.63 793.75 1049.81 796.05 1051.06" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="796.27 960.27 796.5 960.32 796.5 1050.09 796.05 1050.17 796.05 960.32 796.27 960.27" />
                              <polygon class="cls-1" points="791.22 1052.99 801.32 1051.05 801.32 959.35 796.5 958.42 791.45 959.31 791.22 960.32 791.22 1052.99" />
                            </g>
                          </g>
                        </g>
                        <g>
                          <g>
                            <g>
                              <polygon class="cls-3" points="1479.39 638.35 1252.27 683.27 1024.64 638.26 1024.56 638.27 1024.57 637.99 1024.34 637.94 1251.97 592.93 1479.62 637.94 1479.39 637.99 1479.39 638.35" />
                              <path class="cls-1" d="M1024.34,640.1l-4.61,.69v-1.84s-5.11-.99-5.11-.99l237.35-46.94,230.06,45.49,6.98,1.48-4.8,.96v.37s-231.93,45.87-231.93,45.87l-227.93-45.07Zm227.63-45.27l-218.63,43.24,218.93,43.3,218.64-43.24-218.94-43.29Z" />
                            </g>
                            <g>
                              <polygon class="cls-2" points="1252.27 683.27 1479.39 638.35 1479.39 728.12 1479.62 728.17 1252.5 773.09 1252.5 683.32 1252.27 683.27" />
                              <path class="cls-1" d="M1247.67,684.29l-5.13-.99,241.67-47.8v91.66s5.16,.99,5.16,.99l-241.7,47.8v-91.66Zm226.89-43.07l-217.24,42.97v86.03s217.25-42.96,217.25-42.96v-86.04Z" />
                            </g>
                            <g>
                              <polygon class="cls-2" points="1024.56 638.27 1024.64 638.26 1252.27 683.27 1252.05 683.32 1252.05 773.17 1251.97 773.19 1024.34 728.16 1024.56 728.12 1024.56 638.27" />
                              <path class="cls-1" d="M1021.93,729.59l-6.96-1.46,4.77-.96v-88.9s.08-.99,.08-.99l4.88-.92,229.98,45.48,7.1,1.5-4.92,.95v89.8s-4.8,1.03-4.8,1.03l-230.13-45.52Zm7.45-88.49v86.15s217.84,43.09,217.84,43.09v-86.16s-217.84-43.08-217.84-43.08Z" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="1252.05 767.74 1252.5 767.5 1252.5 768.56 1252.27 768.69 1252.05 768.56 1252.05 767.74" />
                              <polygon class="cls-1" points="1252.05 774.06 1257.32 771.44 1257.32 759.25 1247.67 764.68 1247.22 770.63 1249.75 772.81 1252.05 774.06" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="1252.27 683.27 1252.5 683.32 1252.5 773.09 1252.05 773.17 1252.05 683.32 1252.27 683.27" />
                              <polygon class="cls-1" points="1247.22 775.99 1257.32 774.05 1257.32 682.35 1252.5 681.42 1247.45 682.31 1247.22 683.32 1247.22 775.99" />
                            </g>
                          </g>
                          <g>
                            <g>
                              <polygon class="cls-3" points="1251.39 683.35 1024.27 728.27 796.64 683.26 796.56 683.27 796.57 682.99 796.34 682.94 1023.97 637.93 1251.62 682.94 1251.39 682.99 1251.39 683.35" />
                              <path class="cls-1" d="M796.34,685.1l-4.61,.69v-1.84s-5.11-.99-5.11-.99l237.35-46.94,230.06,45.49,6.98,1.48-4.8,.96v.37s-231.93,45.87-231.93,45.87l-227.93-45.07Zm227.63-45.27l-218.63,43.24,218.93,43.3,218.64-43.24-218.94-43.29Z" />
                            </g>
                            <g>
                              <polygon class="cls-2" points="1024.27 728.27 1251.39 683.35 1251.39 773.12 1251.62 773.17 1024.5 818.09 1024.5 728.32 1024.27 728.27" />
                              <path class="cls-1" d="M1019.67,729.29l-5.13-.99,241.67-47.8v91.66s5.16,.99,5.16,.99l-241.7,47.8v-91.66Zm226.89-43.07l-217.24,42.97v86.03s217.25-42.96,217.25-42.96v-86.04Z" />
                            </g>
                            <g>
                              <polygon class="cls-2" points="796.56 683.27 796.64 683.26 1024.27 728.27 1024.05 728.32 1024.05 818.17 1023.97 818.19 796.34 773.16 796.56 773.12 796.56 683.27" />
                              <path class="cls-1" d="M793.93,774.59l-6.96-1.46,4.77-.96v-88.9s.08-.99,.08-.99l4.88-.92,229.98,45.48,7.1,1.5-4.92,.95v89.8s-4.8,1.03-4.8,1.03l-230.13-45.52Zm7.45-88.49v86.15s217.84,43.09,217.84,43.09v-86.16s-217.84-43.08-217.84-43.08Z" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="1024.05 812.74 1024.5 812.5 1024.5 813.56 1024.27 813.69 1024.05 813.56 1024.05 812.74" />
                              <polygon class="cls-1" points="1024.05 819.06 1029.32 816.44 1029.32 804.25 1019.67 809.68 1019.22 815.63 1021.75 817.81 1024.05 819.06" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="1024.27 728.27 1024.5 728.32 1024.5 818.09 1024.05 818.17 1024.05 728.32 1024.27 728.27" />
                              <polygon class="cls-1" points="1019.22 820.99 1029.32 819.05 1029.32 727.35 1024.5 726.42 1019.45 727.31 1019.22 728.32 1019.22 820.99" />
                            </g>
                          </g>
                          <g>
                            <g>
                              <polygon class="cls-3" points="1021.39 729.35 794.27 774.27 566.64 729.26 566.56 729.27 566.57 728.99 566.34 728.94 793.97 683.93 1021.62 728.94 1021.39 728.99 1021.39 729.35" />
                              <path class="cls-1" d="M566.34,731.1l-4.61,.69v-1.84s-5.11-.99-5.11-.99l237.35-46.94,230.06,45.49,6.98,1.48-4.8,.96v.37s-231.93,45.87-231.93,45.87l-227.93-45.07Zm227.63-45.27l-218.63,43.24,218.93,43.3,218.64-43.24-218.94-43.29Z" />
                            </g>
                            <g>
                              <polygon class="cls-2" points="794.27 774.27 1021.39 729.35 1021.39 819.12 1021.62 819.17 794.5 864.09 794.5 774.32 794.27 774.27" />
                              <path class="cls-1" d="M789.67,775.29l-5.13-.99,241.67-47.8v91.66s5.16,.99,5.16,.99l-241.7,47.8v-91.66Zm226.89-43.07l-217.24,42.97v86.03s217.25-42.96,217.25-42.96v-86.04Z" />
                            </g>
                            <g>
                              <polygon class="cls-2" points="566.56 729.27 566.64 729.26 794.27 774.27 794.05 774.32 794.05 864.17 793.97 864.19 566.34 819.16 566.56 819.12 566.56 729.27" />
                              <path class="cls-1" d="M563.93,820.59l-6.96-1.46,4.77-.96v-88.9s.08-.99,.08-.99l4.88-.92,229.98,45.48,7.1,1.5-4.92,.95v89.8s-4.8,1.03-4.8,1.03l-230.13-45.52Zm7.45-88.49v86.15s217.84,43.09,217.84,43.09v-86.16s-217.84-43.08-217.84-43.08Z" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="794.05 858.74 794.5 858.5 794.5 859.56 794.27 859.69 794.05 859.56 794.05 858.74" />
                              <polygon class="cls-1" points="794.05 865.06 799.32 862.44 799.32 850.25 789.67 855.68 789.22 861.63 791.75 863.81 794.05 865.06" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="794.27 774.27 794.5 774.32 794.5 864.09 794.05 864.17 794.05 774.32 794.27 774.27" />
                              <polygon class="cls-1" points="789.22 866.99 799.32 865.05 799.32 773.35 794.5 772.42 789.45 773.31 789.22 774.32 789.22 866.99" />
                            </g>
                          </g>
                          <g>
                            <g>
                              <polygon class="cls-3" points="793.39 774.35 566.27 819.27 338.64 774.26 338.56 774.27 338.57 773.99 338.34 773.94 565.97 728.93 793.62 773.94 793.39 773.99 793.39 774.35" />
                              <path class="cls-1" d="M338.34,776.1l-4.61,.69v-1.84s-5.11-.99-5.11-.99l237.35-46.94,230.06,45.49,6.98,1.48-4.8,.96v.37s-231.93,45.87-231.93,45.87l-227.93-45.07Zm227.63-45.27l-218.63,43.24,218.93,43.3,218.64-43.24-218.94-43.29Z" />
                            </g>
                            <g>
                              <polygon class="cls-2" points="566.27 819.27 793.39 774.35 793.39 864.12 793.62 864.17 566.5 909.09 566.5 819.32 566.27 819.27" />
                              <path class="cls-1" d="M561.67,820.29l-5.13-.99,241.67-47.8v91.66s5.16,.99,5.16,.99l-241.7,47.8v-91.66Zm226.89-43.07l-217.24,42.97v86.03s217.25-42.96,217.25-42.96v-86.04Z" />
                            </g>
                            <g className="k9b1base">
                              <polygon className={`k9b1 ${isHovered91 ? 'act' : ''}`}
                                onMouseEnter={() => setIsHovered91(true)}
                                onMouseLeave={() => setIsHovered91(false)} points="338.56 774.27 338.64 774.26 566.27 819.27 566.05 819.32 566.05 909.17 565.97 909.19 338.34 864.16 338.56 864.12 338.56 774.27" />
                              <path class="cls-1 k9b1path" d="M335.93,865.59l-6.96-1.46,4.77-.96v-88.9s.08-.99,.08-.99l4.88-.92,229.98,45.48,7.1,1.5-4.92,.95v89.8s-4.8,1.03-4.8,1.03l-230.13-45.52Zm7.45-88.49v86.15s217.84,43.09,217.84,43.09v-86.16s-217.84-43.08-217.84-43.08Z" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="566.05 903.74 566.5 903.5 566.5 904.56 566.27 904.69 566.05 904.56 566.05 903.74" />
                              <polygon class="cls-1" points="566.05 910.06 571.32 907.44 571.32 895.25 561.67 900.68 561.22 906.63 563.75 908.81 566.05 910.06" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="566.27 819.27 566.5 819.32 566.5 909.09 566.05 909.17 566.05 819.32 566.27 819.27" />
                              <polygon class="cls-1" points="561.22 911.99 571.32 910.05 571.32 818.35 566.5 817.42 561.45 818.31 561.22 819.32 561.22 911.99" />
                            </g>
                          </g>
                          <g>
                            <g>
                              <polygon class="cls-3" points="1709.39 684.35 1482.27 729.27 1254.64 684.26 1254.56 684.27 1254.57 683.99 1254.34 683.94 1481.97 638.93 1709.62 683.94 1709.39 683.99 1709.39 684.35" />
                              <path class="cls-1" d="M1254.34,686.1l-4.61,.69v-1.84s-5.11-.99-5.11-.99l237.35-46.94,230.06,45.49,6.98,1.48-4.8,.96v.37s-231.93,45.87-231.93,45.87l-227.93-45.07Zm227.63-45.27l-218.63,43.24,218.93,43.3,218.64-43.24-218.94-43.29Z" />
                            </g>
                            <g className="k9b5base">
                              <polygon className={`k9b5 ${isHovered95 ? 'act' : ''}`}
                                onMouseEnter={() => setIsHovered95(true)}
                                onMouseLeave={() => setIsHovered95(false)} points="1482.27 729.27 1709.39 684.35 1709.39 774.12 1709.62 774.17 1482.5 819.09 1482.5 729.32 1482.27 729.27" />
                              <path class="cls-1 k9b5path" d="M1477.67,730.29l-5.13-.99,241.67-47.8v91.66s5.16,.99,5.16,.99l-241.7,47.8v-91.66Zm226.89-43.07l-217.24,42.97v86.03s217.25-42.96,217.25-42.96v-86.04Z" />
                            </g>
                            <g>
                              <polygon class="cls-2" points="1254.56 684.27 1254.64 684.26 1482.27 729.27 1482.05 729.32 1482.05 819.17 1481.97 819.19 1254.34 774.16 1254.56 774.12 1254.56 684.27" />
                              <path class="cls-1" d="M1251.93,775.59l-6.96-1.46,4.77-.96v-88.9s.08-.99,.08-.99l4.88-.92,229.98,45.48,7.1,1.5-4.92,.95v89.8s-4.8,1.03-4.8,1.03l-230.13-45.52Zm7.45-88.49v86.15s217.84,43.09,217.84,43.09v-86.16s-217.84-43.08-217.84-43.08Z" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="1482.05 813.74 1482.5 813.5 1482.5 814.56 1482.27 814.69 1482.05 814.56 1482.05 813.74" />
                              <polygon class="cls-1" points="1482.05 820.06 1487.32 817.44 1487.32 805.25 1477.67 810.68 1477.22 816.63 1479.75 818.81 1482.05 820.06" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="1482.27 729.27 1482.5 729.32 1482.5 819.09 1482.05 819.17 1482.05 729.32 1482.27 729.27" />
                              <polygon class="cls-1" points="1477.22 821.99 1487.32 820.05 1487.32 728.35 1482.5 727.42 1477.45 728.31 1477.22 729.32 1477.22 821.99" />
                            </g>
                          </g>
                          <g>
                            <g>
                              <polygon class="cls-3" points="1481.39 729.35 1254.27 774.27 1026.64 729.26 1026.56 729.27 1026.57 728.99 1026.34 728.94 1253.97 683.93 1481.62 728.94 1481.39 728.99 1481.39 729.35" />
                              <path class="cls-1" d="M1026.34,731.1l-4.61,.69v-1.84s-5.11-.99-5.11-.99l237.35-46.94,230.06,45.49,6.98,1.48-4.8,.96v.37s-231.93,45.87-231.93,45.87l-227.93-45.07Zm227.63-45.27l-218.63,43.24,218.93,43.3,218.64-43.24-218.94-43.29Z" />
                            </g>
                            <g className="k9b4base">
                              <polygon className={`k9b4 ${isHovered94 ? 'act' : ''}`}
                                onMouseEnter={() => setIsHovered94(true)}
                                onMouseLeave={() => setIsHovered94(false)} points="1254.27 774.27 1481.39 729.35 1481.39 819.12 1481.62 819.17 1254.5 864.09 1254.5 774.32 1254.27 774.27" />
                              <path class="cls-1 k9b4path" d="M1249.67,775.29l-5.13-.99,241.67-47.8v91.66s5.16,.99,5.16,.99l-241.7,47.8v-91.66Zm226.89-43.07l-217.24,42.97v86.03s217.25-42.96,217.25-42.96v-86.04Z" />
                            </g>
                            <g>
                              <polygon class="cls-2" points="1026.56 729.27 1026.64 729.26 1254.27 774.27 1254.05 774.32 1254.05 864.17 1253.97 864.19 1026.34 819.16 1026.56 819.12 1026.56 729.27" />
                              <path class="cls-1" d="M1023.93,820.59l-6.96-1.46,4.77-.96v-88.9s.08-.99,.08-.99l4.88-.92,229.98,45.48,7.1,1.5-4.92,.95v89.8s-4.8,1.03-4.8,1.03l-230.13-45.52Zm7.45-88.49v86.15s217.84,43.09,217.84,43.09v-86.16s-217.84-43.08-217.84-43.08Z" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="1254.05 858.74 1254.5 858.5 1254.5 859.56 1254.27 859.69 1254.05 859.56 1254.05 858.74" />
                              <polygon class="cls-1" points="1254.05 865.06 1259.32 862.44 1259.32 850.25 1249.67 855.68 1249.22 861.63 1251.75 863.81 1254.05 865.06" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="1254.27 774.27 1254.5 774.32 1254.5 864.09 1254.05 864.17 1254.05 774.32 1254.27 774.27" />
                              <polygon class="cls-1" points="1249.22 866.99 1259.32 865.05 1259.32 773.35 1254.5 772.42 1249.45 773.31 1249.22 774.32 1249.22 866.99" />
                            </g>
                          </g>
                          <g>
                            <g>
                              <polygon class="cls-3" points="1251.39 775.35 1024.27 820.27 796.64 775.26 796.56 775.27 796.57 774.99 796.34 774.94 1023.97 729.93 1251.62 774.94 1251.39 774.99 1251.39 775.35" />
                              <path class="cls-1" d="M796.34,777.1l-4.61,.69v-1.84s-5.11-.99-5.11-.99l237.35-46.94,230.06,45.49,6.98,1.48-4.8,.96v.37s-231.93,45.87-231.93,45.87l-227.93-45.07Zm227.63-45.27l-218.63,43.24,218.93,43.3,218.64-43.24-218.94-43.29Z" />
                            </g>
                            <g className="k9b3base">
                              <polygon className={`k9b3 ${isHovered93 ? 'act' : ''}`}
                                onMouseEnter={() => setIsHovered93(true)}
                                onMouseLeave={() => setIsHovered93(false)} points="1024.27 820.27 1251.39 775.35 1251.39 865.12 1251.62 865.17 1024.5 910.09 1024.5 820.32 1024.27 820.27" />
                              <path class="cls-1 k9b3path" d="M1019.67,821.29l-5.13-.99,241.67-47.8v91.66s5.16,.99,5.16,.99l-241.7,47.8v-91.66Zm226.89-43.07l-217.24,42.97v86.03s217.25-42.96,217.25-42.96v-86.04Z" />
                            </g>
                            <g>
                              <polygon class="cls-2" points="796.56 775.27 796.64 775.26 1024.27 820.27 1024.05 820.32 1024.05 910.17 1023.97 910.19 796.34 865.16 796.56 865.12 796.56 775.27" />
                              <path class="cls-1" d="M793.93,866.59l-6.96-1.46,4.77-.96v-88.9s.08-.99,.08-.99l4.88-.92,229.98,45.48,7.1,1.5-4.92,.95v89.8s-4.8,1.03-4.8,1.03l-230.13-45.52Zm7.45-88.49v86.15s217.84,43.09,217.84,43.09v-86.16s-217.84-43.08-217.84-43.08Z" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="1024.05 904.74 1024.5 904.5 1024.5 905.56 1024.27 905.69 1024.05 905.56 1024.05 904.74" />
                              <polygon class="cls-1" points="1024.05 911.06 1029.32 908.44 1029.32 896.25 1019.67 901.68 1019.22 907.63 1021.75 909.81 1024.05 911.06" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="1024.27 820.27 1024.5 820.32 1024.5 910.09 1024.05 910.17 1024.05 820.32 1024.27 820.27" />
                              <polygon class="cls-1" points="1019.22 912.99 1029.32 911.05 1029.32 819.35 1024.5 818.42 1019.45 819.31 1019.22 820.32 1019.22 912.99" />
                            </g>
                          </g>
                          <g>
                            <g>
                              <polygon class="cls-3" points="1023.39 820.35 796.27 865.27 568.64 820.26 568.56 820.27 568.57 819.99 568.34 819.94 795.97 774.93 1023.62 819.94 1023.39 819.99 1023.39 820.35" />
                              <path class="cls-1" d="M568.34,822.1l-4.61,.69v-1.84s-5.11-.99-5.11-.99l237.35-46.94,230.06,45.49,6.98,1.48-4.8,.96v.37s-231.93,45.87-231.93,45.87l-227.93-45.07Zm227.63-45.27l-218.63,43.24,218.93,43.3,218.64-43.24-218.94-43.29Z" />
                            </g>
                            <g className="k9b2base">
                              <polygon className={`k9b2 ${isHovered92 ? 'act' : ''}`}
                                onMouseEnter={() => setIsHovered92(true)}
                                onMouseLeave={() => setIsHovered92(false)} points="796.27 865.27 1023.39 820.35 1023.39 910.12 1023.62 910.17 796.5 955.09 796.5 865.32 796.27 865.27" />
                              <path class="cls-1 k9b2path" d="M791.67,866.29l-5.13-.99,241.67-47.8v91.66s5.16,.99,5.16,.99l-241.7,47.8v-91.66Zm226.89-43.07l-217.24,42.97v86.03s217.25-42.96,217.25-42.96v-86.04Z" />
                            </g>
                            <g className="k9b2base">
                              <polygon className={`k9b2 ${isHovered92 ? 'act' : ''}`}
                                onMouseEnter={() => setIsHovered92(true)}
                                onMouseLeave={() => setIsHovered92(false)} points="568.56 820.27 568.64 820.26 796.27 865.27 796.05 865.32 796.05 955.17 795.97 955.19 568.34 910.16 568.56 910.12 568.56 820.27" />
                              <path class="cls-1 k9b2path" d="M565.93,911.59l-6.96-1.46,4.77-.96v-88.9s.08-.99,.08-.99l4.88-.92,229.98,45.48,7.1,1.5-4.92,.95v89.8s-4.8,1.03-4.8,1.03l-230.13-45.52Zm7.45-88.49v86.15s217.84,43.09,217.84,43.09v-86.16s-217.84-43.08-217.84-43.08Z" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="796.05 949.74 796.5 949.5 796.5 950.56 796.27 950.69 796.05 950.56 796.05 949.74" />
                              <polygon class="cls-1" points="796.05 956.06 801.32 953.44 801.32 941.25 791.67 946.68 791.22 952.63 793.75 954.81 796.05 956.06" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="796.27 865.27 796.5 865.32 796.5 955.09 796.05 955.17 796.05 865.32 796.27 865.27" />
                              <polygon class="cls-1" points="791.22 957.99 801.32 956.05 801.32 864.35 796.5 863.42 791.45 864.31 791.22 865.32 791.22 957.99" />
                            </g>
                          </g>
                        </g>
                        <g>
                          <g>
                            <g>
                              <polygon class="cls-3" points="1479.39 544.35 1252.27 589.27 1024.64 544.26 1024.56 544.27 1024.57 543.99 1024.34 543.94 1251.97 498.93 1479.62 543.94 1479.39 543.99 1479.39 544.35" />
                              <path class="cls-1" d="M1024.34,546.1l-4.61,.69v-1.84s-5.11-.99-5.11-.99l237.35-46.94,230.06,45.49,6.98,1.48-4.8,.96v.37s-231.93,45.87-231.93,45.87l-227.93-45.07Zm227.63-45.27l-218.63,43.24,218.93,43.3,218.64-43.24-218.94-43.29Z" />
                            </g>
                            <g>
                              <polygon class="cls-2" points="1252.27 589.27 1479.39 544.35 1479.39 634.12 1479.62 634.17 1252.5 679.09 1252.5 589.32 1252.27 589.27" />
                              <path class="cls-1" d="M1247.67,590.29l-5.13-.99,241.67-47.8v91.66s5.16,.99,5.16,.99l-241.7,47.8v-91.66Zm226.89-43.07l-217.24,42.97v86.03s217.25-42.96,217.25-42.96v-86.04Z" />
                            </g>
                            <g>
                              <polygon class="cls-2" points="1024.56 544.27 1024.64 544.26 1252.27 589.27 1252.05 589.32 1252.05 679.17 1251.97 679.19 1024.34 634.16 1024.56 634.12 1024.56 544.27" />
                              <path class="cls-1" d="M1021.93,635.59l-6.96-1.46,4.77-.96v-88.9s.08-.99,.08-.99l4.88-.92,229.98,45.48,7.1,1.5-4.92,.95v89.8s-4.8,1.03-4.8,1.03l-230.13-45.52Zm7.45-88.49v86.15s217.84,43.09,217.84,43.09v-86.16s-217.84-43.08-217.84-43.08Z" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="1252.05 673.74 1252.5 673.5 1252.5 674.56 1252.27 674.69 1252.05 674.56 1252.05 673.74" />
                              <polygon class="cls-1" points="1252.05 680.06 1257.32 677.44 1257.32 665.25 1247.67 670.68 1247.22 676.63 1249.75 678.81 1252.05 680.06" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="1252.27 589.27 1252.5 589.32 1252.5 679.09 1252.05 679.17 1252.05 589.32 1252.27 589.27" />
                              <polygon class="cls-1" points="1247.22 681.99 1257.32 680.05 1257.32 588.35 1252.5 587.42 1247.45 588.31 1247.22 589.32 1247.22 681.99" />
                            </g>
                          </g>
                          <g>
                            <g>
                              <polygon class="cls-3" points="1251.39 589.35 1024.27 634.27 796.64 589.26 796.56 589.27 796.57 588.99 796.34 588.94 1023.97 543.93 1251.62 588.94 1251.39 588.99 1251.39 589.35" />
                              <path class="cls-1" d="M796.34,591.1l-4.61,.69v-1.84s-5.11-.99-5.11-.99l237.35-46.94,230.06,45.49,6.98,1.48-4.8,.96v.37s-231.93,45.87-231.93,45.87l-227.93-45.07Zm227.63-45.27l-218.63,43.24,218.93,43.3,218.64-43.24-218.94-43.29Z" />
                            </g>
                            <g>
                              <polygon class="cls-2" points="1024.27 634.27 1251.39 589.35 1251.39 679.12 1251.62 679.17 1024.5 724.09 1024.5 634.32 1024.27 634.27" />
                              <path class="cls-1" d="M1019.67,635.29l-5.13-.99,241.67-47.8v91.66s5.16,.99,5.16,.99l-241.7,47.8v-91.66Zm226.89-43.07l-217.24,42.97v86.03s217.25-42.96,217.25-42.96v-86.04Z" />
                            </g>
                            <g>
                              <polygon class="cls-2" points="796.56 589.27 796.64 589.26 1024.27 634.27 1024.05 634.32 1024.05 724.17 1023.97 724.19 796.34 679.16 796.56 679.12 796.56 589.27" />
                              <path class="cls-1" d="M793.93,680.59l-6.96-1.46,4.77-.96v-88.9s.08-.99,.08-.99l4.88-.92,229.98,45.48,7.1,1.5-4.92,.95v89.8s-4.8,1.03-4.8,1.03l-230.13-45.52Zm7.45-88.49v86.15s217.84,43.09,217.84,43.09v-86.16s-217.84-43.08-217.84-43.08Z" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="1024.05 718.74 1024.5 718.5 1024.5 719.56 1024.27 719.69 1024.05 719.56 1024.05 718.74" />
                              <polygon class="cls-1" points="1024.05 725.06 1029.32 722.44 1029.32 710.25 1019.67 715.68 1019.22 721.63 1021.75 723.81 1024.05 725.06" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="1024.27 634.27 1024.5 634.32 1024.5 724.09 1024.05 724.17 1024.05 634.32 1024.27 634.27" />
                              <polygon class="cls-1" points="1019.22 726.99 1029.32 725.05 1029.32 633.35 1024.5 632.42 1019.45 633.31 1019.22 634.32 1019.22 726.99" />
                            </g>
                          </g>
                          <g>
                            <g>
                              <polygon class="cls-3" points="1021.39 635.35 794.27 680.27 566.64 635.26 566.56 635.27 566.57 634.99 566.34 634.94 793.97 589.93 1021.62 634.94 1021.39 634.99 1021.39 635.35" />
                              <path class="cls-1" d="M566.34,637.1l-4.61,.69v-1.84s-5.11-.99-5.11-.99l237.35-46.94,230.06,45.49,6.98,1.48-4.8,.96v.37s-231.93,45.87-231.93,45.87l-227.93-45.07Zm227.63-45.27l-218.63,43.24,218.93,43.3,218.64-43.24-218.94-43.29Z" />
                            </g>
                            <g>
                              <polygon class="cls-2" points="794.27 680.27 1021.39 635.35 1021.39 725.12 1021.62 725.17 794.5 770.09 794.5 680.32 794.27 680.27" />
                              <path class="cls-1" d="M789.67,681.29l-5.13-.99,241.67-47.8v91.66s5.16,.99,5.16,.99l-241.7,47.8v-91.66Zm226.89-43.07l-217.24,42.97v86.03s217.25-42.96,217.25-42.96v-86.04Z" />
                            </g>
                            <g>
                              <polygon class="cls-2" points="566.56 635.27 566.64 635.26 794.27 680.27 794.05 680.32 794.05 770.17 793.97 770.19 566.34 725.16 566.56 725.12 566.56 635.27" />
                              <path class="cls-1" d="M563.93,726.59l-6.96-1.46,4.77-.96v-88.9s.08-.99,.08-.99l4.88-.92,229.98,45.48,7.1,1.5-4.92,.95v89.8s-4.8,1.03-4.8,1.03l-230.13-45.52Zm7.45-88.49v86.15s217.84,43.09,217.84,43.09v-86.16s-217.84-43.08-217.84-43.08Z" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="794.05 764.74 794.5 764.5 794.5 765.56 794.27 765.69 794.05 765.56 794.05 764.74" />
                              <polygon class="cls-1" points="794.05 771.06 799.32 768.44 799.32 756.25 789.67 761.68 789.22 767.63 791.75 769.81 794.05 771.06" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="794.27 680.27 794.5 680.32 794.5 770.09 794.05 770.17 794.05 680.32 794.27 680.27" />
                              <polygon class="cls-1" points="789.22 772.99 799.32 771.05 799.32 679.35 794.5 678.42 789.45 679.31 789.22 680.32 789.22 772.99" />
                            </g>
                          </g>
                          <g>
                            <g>
                              <polygon class="cls-3" points="793.39 680.35 566.27 725.27 338.64 680.26 338.56 680.27 338.57 679.99 338.34 679.94 565.97 634.93 793.62 679.94 793.39 679.99 793.39 680.35" />
                              <path class="cls-1" d="M338.34,682.1l-4.61,.69v-1.84s-5.11-.99-5.11-.99l237.35-46.94,230.06,45.49,6.98,1.48-4.8,.96v.37s-231.93,45.87-231.93,45.87l-227.93-45.07Zm227.63-45.27l-218.63,43.24,218.93,43.3,218.64-43.24-218.94-43.29Z" />
                            </g>
                            <g>
                              <polygon class="cls-2" points="566.27 725.27 793.39 680.35 793.39 770.12 793.62 770.17 566.5 815.09 566.5 725.32 566.27 725.27" />
                              <path class="cls-1" d="M561.67,726.29l-5.13-.99,241.67-47.8v91.66s5.16,.99,5.16,.99l-241.7,47.8v-91.66Zm226.89-43.07l-217.24,42.97v86.03s217.25-42.96,217.25-42.96v-86.04Z" />
                            </g>
                            <g className="k10b1base">
                              <polygon className={`k10b1 ${isHovered101 ? 'act' : ''}`}
                                onMouseEnter={() => setIsHovered101(true)}
                                onMouseLeave={() => setIsHovered101(false)} points="338.56 680.27 338.64 680.26 566.27 725.27 566.05 725.32 566.05 815.17 565.97 815.19 338.34 770.16 338.56 770.12 338.56 680.27" />
                              <path class="cls-1 k10b1path" d="M335.93,771.59l-6.96-1.46,4.77-.96v-88.9s.08-.99,.08-.99l4.88-.92,229.98,45.48,7.1,1.5-4.92,.95v89.8s-4.8,1.03-4.8,1.03l-230.13-45.52Zm7.45-88.49v86.15s217.84,43.09,217.84,43.09v-86.16s-217.84-43.08-217.84-43.08Z" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="566.05 809.74 566.5 809.5 566.5 810.56 566.27 810.69 566.05 810.56 566.05 809.74" />
                              <polygon class="cls-1" points="566.05 816.06 571.32 813.44 571.32 801.25 561.67 806.68 561.22 812.63 563.75 814.81 566.05 816.06" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="566.27 725.27 566.5 725.32 566.5 815.09 566.05 815.17 566.05 725.32 566.27 725.27" />
                              <polygon class="cls-1" points="561.22 817.99 571.32 816.05 571.32 724.35 566.5 723.42 561.45 724.31 561.22 725.32 561.22 817.99" />
                            </g>
                          </g>
                          <g>
                            <g>
                              <polygon class="cls-3" points="1709.39 590.35 1482.27 635.27 1254.64 590.26 1254.56 590.27 1254.57 589.99 1254.34 589.94 1481.97 544.93 1709.62 589.94 1709.39 589.99 1709.39 590.35" />
                              <path class="cls-1" d="M1254.34,592.1l-4.61,.69v-1.84s-5.11-.99-5.11-.99l237.35-46.94,230.06,45.49,6.98,1.48-4.8,.96v.37s-231.93,45.87-231.93,45.87l-227.93-45.07Zm227.63-45.27l-218.63,43.24,218.93,43.3,218.64-43.24-218.94-43.29Z" />
                            </g>
                            <g className="k10b5base">
                              <polygon className={`k10b5 ${isHovered105 ? 'act' : ''}`}
                                onMouseEnter={() => setIsHovered105(true)}
                                onMouseLeave={() => setIsHovered105(false)} points="1482.27 635.27 1709.39 590.35 1709.39 680.12 1709.62 680.17 1482.5 725.09 1482.5 635.32 1482.27 635.27" />
                              <path class="cls-1 k10b5path" d="M1477.67,636.29l-5.13-.99,241.67-47.8v91.66s5.16,.99,5.16,.99l-241.7,47.8v-91.66Zm226.89-43.07l-217.24,42.97v86.03s217.25-42.96,217.25-42.96v-86.04Z" />
                            </g>
                            <g>
                              <polygon class="cls-2" points="1254.56 590.27 1254.64 590.26 1482.27 635.27 1482.05 635.32 1482.05 725.17 1481.97 725.19 1254.34 680.16 1254.56 680.12 1254.56 590.27" />
                              <path class="cls-1" d="M1251.93,681.59l-6.96-1.46,4.77-.96v-88.9s.08-.99,.08-.99l4.88-.92,229.98,45.48,7.1,1.5-4.92,.95v89.8s-4.8,1.03-4.8,1.03l-230.13-45.52Zm7.45-88.49v86.15s217.84,43.09,217.84,43.09v-86.16s-217.84-43.08-217.84-43.08Z" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="1482.05 719.74 1482.5 719.5 1482.5 720.56 1482.27 720.69 1482.05 720.56 1482.05 719.74" />
                              <polygon class="cls-1" points="1482.05 726.06 1487.32 723.44 1487.32 711.25 1477.67 716.68 1477.22 722.63 1479.75 724.81 1482.05 726.06" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="1482.27 635.27 1482.5 635.32 1482.5 725.09 1482.05 725.17 1482.05 635.32 1482.27 635.27" />
                              <polygon class="cls-1" points="1477.22 727.99 1487.32 726.05 1487.32 634.35 1482.5 633.42 1477.45 634.31 1477.22 635.32 1477.22 727.99" />
                            </g>
                          </g>
                          <g>
                            <g>
                              <polygon class="cls-3" points="1481.39 635.35 1254.27 680.27 1026.64 635.26 1026.56 635.27 1026.57 634.99 1026.34 634.94 1253.97 589.93 1481.62 634.94 1481.39 634.99 1481.39 635.35" />
                              <path class="cls-1" d="M1026.34,637.1l-4.61,.69v-1.84s-5.11-.99-5.11-.99l237.35-46.94,230.06,45.49,6.98,1.48-4.8,.96v.37s-231.93,45.87-231.93,45.87l-227.93-45.07Zm227.63-45.27l-218.63,43.24,218.93,43.3,218.64-43.24-218.94-43.29Z" />
                            </g>
                            <g className="k10b4base">
                              <polygon className={`k10b4 ${isHovered104 ? 'act' : ''}`}
                                onMouseEnter={() => setIsHovered104(true)}
                                onMouseLeave={() => setIsHovered104(false)} points="1254.27 680.27 1481.39 635.35 1481.39 725.12 1481.62 725.17 1254.5 770.09 1254.5 680.32 1254.27 680.27" />
                              <path class="cls-1 k10b4path" d="M1249.67,681.29l-5.13-.99,241.67-47.8v91.66s5.16,.99,5.16,.99l-241.7,47.8v-91.66Zm226.89-43.07l-217.24,42.97v86.03s217.25-42.96,217.25-42.96v-86.04Z" />
                            </g>
                            <g>
                              <polygon class="cls-2" points="1026.56 635.27 1026.64 635.26 1254.27 680.27 1254.05 680.32 1254.05 770.17 1253.97 770.19 1026.34 725.16 1026.56 725.12 1026.56 635.27" />
                              <path class="cls-1" d="M1023.93,726.59l-6.96-1.46,4.77-.96v-88.9s.08-.99,.08-.99l4.88-.92,229.98,45.48,7.1,1.5-4.92,.95v89.8s-4.8,1.03-4.8,1.03l-230.13-45.52Zm7.45-88.49v86.15s217.84,43.09,217.84,43.09v-86.16s-217.84-43.08-217.84-43.08Z" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="1254.05 764.74 1254.5 764.5 1254.5 765.56 1254.27 765.69 1254.05 765.56 1254.05 764.74" />
                              <polygon class="cls-1" points="1254.05 771.06 1259.32 768.44 1259.32 756.25 1249.67 761.68 1249.22 767.63 1251.75 769.81 1254.05 771.06" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="1254.27 680.27 1254.5 680.32 1254.5 770.09 1254.05 770.17 1254.05 680.32 1254.27 680.27" />
                              <polygon class="cls-1" points="1249.22 772.99 1259.32 771.05 1259.32 679.35 1254.5 678.42 1249.45 679.31 1249.22 680.32 1249.22 772.99" />
                            </g>
                          </g>
                          <g>
                            <g>
                              <polygon class="cls-3" points="1251.39 681.35 1024.27 726.27 796.64 681.26 796.56 681.27 796.57 680.99 796.34 680.94 1023.97 635.93 1251.62 680.94 1251.39 680.99 1251.39 681.35" />
                              <path class="cls-1" d="M796.34,683.1l-4.61,.69v-1.84s-5.11-.99-5.11-.99l237.35-46.94,230.06,45.49,6.98,1.48-4.8,.96v.37s-231.93,45.87-231.93,45.87l-227.93-45.07Zm227.63-45.27l-218.63,43.24,218.93,43.3,218.64-43.24-218.94-43.29Z" />
                            </g>
                            <g className="k10b3base">
                              <polygon className={`k10b3 ${isHovered103 ? 'act' : ''}`}
                                onMouseEnter={() => setIsHovered103(true)}
                                onMouseLeave={() => setIsHovered103(false)} points="1024.27 726.27 1251.39 681.35 1251.39 771.12 1251.62 771.17 1024.5 816.09 1024.5 726.32 1024.27 726.27" />
                              <path class="cls-1 k10b3path" d="M1019.67,727.29l-5.13-.99,241.67-47.8v91.66s5.16,.99,5.16,.99l-241.7,47.8v-91.66Zm226.89-43.07l-217.24,42.97v86.03s217.25-42.96,217.25-42.96v-86.04Z" />
                            </g>
                            <g>
                              <polygon class="cls-2" points="796.56 681.27 796.64 681.26 1024.27 726.27 1024.05 726.32 1024.05 816.17 1023.97 816.19 796.34 771.16 796.56 771.12 796.56 681.27" />
                              <path class="cls-1" d="M793.93,772.59l-6.96-1.46,4.77-.96v-88.9s.08-.99,.08-.99l4.88-.92,229.98,45.48,7.1,1.5-4.92,.95v89.8s-4.8,1.03-4.8,1.03l-230.13-45.52Zm7.45-88.49v86.15s217.84,43.09,217.84,43.09v-86.16s-217.84-43.08-217.84-43.08Z" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="1024.05 810.74 1024.5 810.5 1024.5 811.56 1024.27 811.69 1024.05 811.56 1024.05 810.74" />
                              <polygon class="cls-1" points="1024.05 817.06 1029.32 814.44 1029.32 802.25 1019.67 807.68 1019.22 813.63 1021.75 815.81 1024.05 817.06" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="1024.27 726.27 1024.5 726.32 1024.5 816.09 1024.05 816.17 1024.05 726.32 1024.27 726.27" />
                              <polygon class="cls-1" points="1019.22 818.99 1029.32 817.05 1029.32 725.35 1024.5 724.42 1019.45 725.31 1019.22 726.32 1019.22 818.99" />
                            </g>
                          </g>
                          <g>
                            <g>
                              <polygon class="cls-3" points="1023.39 726.35 796.27 771.27 568.64 726.26 568.56 726.27 568.57 725.99 568.34 725.94 795.97 680.93 1023.62 725.94 1023.39 725.99 1023.39 726.35" />
                              <path class="cls-1" d="M568.34,728.1l-4.61,.69v-1.84s-5.11-.99-5.11-.99l237.35-46.94,230.06,45.49,6.98,1.48-4.8,.96v.37s-231.93,45.87-231.93,45.87l-227.93-45.07Zm227.63-45.27l-218.63,43.24,218.93,43.3,218.64-43.24-218.94-43.29Z" />
                            </g>
                            <g className="k10b2base">
                              <polygon className={`k10b2 ${isHovered102 ? 'act' : ''}`}
                                onMouseEnter={() => setIsHovered102(true)}
                                onMouseLeave={() => setIsHovered102(false)} points="796.27 771.27 1023.39 726.35 1023.39 816.12 1023.62 816.17 796.5 861.09 796.5 771.32 796.27 771.27" />
                              <path class="cls-1 k10b2path" d="M791.67,772.29l-5.13-.99,241.67-47.8v91.66s5.16,.99,5.16,.99l-241.7,47.8v-91.66Zm226.89-43.07l-217.24,42.97v86.03s217.25-42.96,217.25-42.96v-86.04Z" />
                            </g>
                            <g className="k10b2base">
                              <polygon className={`k10b2 ${isHovered102 ? 'act' : ''}`}
                                onMouseEnter={() => setIsHovered102(true)}
                                onMouseLeave={() => setIsHovered102(false)} points="568.56 726.27 568.64 726.26 796.27 771.27 796.05 771.32 796.05 861.17 795.97 861.19 568.34 816.16 568.56 816.12 568.56 726.27" />
                              <path class="cls-1 k10b2path" d="M565.93,817.59l-6.96-1.46,4.77-.96v-88.9s.08-.99,.08-.99l4.88-.92,229.98,45.48,7.1,1.5-4.92,.95v89.8s-4.8,1.03-4.8,1.03l-230.13-45.52Zm7.45-88.49v86.15s217.84,43.09,217.84,43.09v-86.16s-217.84-43.08-217.84-43.08Z" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="796.05 855.74 796.5 855.5 796.5 856.56 796.27 856.69 796.05 856.56 796.05 855.74" />
                              <polygon class="cls-1" points="796.05 862.06 801.32 859.44 801.32 847.25 791.67 852.68 791.22 858.63 793.75 860.81 796.05 862.06" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="796.27 771.27 796.5 771.32 796.5 861.09 796.05 861.17 796.05 771.32 796.27 771.27" />
                              <polygon class="cls-1" points="791.22 863.99 801.32 862.05 801.32 770.35 796.5 769.42 791.45 770.31 791.22 771.32 791.22 863.99" />
                            </g>
                          </g>
                        </g>
                        <g>
                          <g>
                            <g>
                              <polygon class="cls-3" points="1479.39 450.35 1252.27 495.27 1024.64 450.26 1024.56 450.27 1024.57 449.99 1024.34 449.94 1251.97 404.93 1479.62 449.94 1479.39 449.99 1479.39 450.35" />
                              <path class="cls-1" d="M1024.34,452.1l-4.61,.69v-1.84s-5.11-.99-5.11-.99l237.35-46.94,230.06,45.49,6.98,1.48-4.8,.96v.37s-231.93,45.87-231.93,45.87l-227.93-45.07Zm227.63-45.27l-218.63,43.24,218.93,43.3,218.64-43.24-218.94-43.29Z" />
                            </g>
                            <g>
                              <polygon class="cls-2" points="1252.27 495.27 1479.39 450.35 1479.39 540.12 1479.62 540.17 1252.5 585.09 1252.5 495.32 1252.27 495.27" />
                              <path class="cls-1" d="M1247.67,496.29l-5.13-.99,241.67-47.8v91.66s5.16,.99,5.16,.99l-241.7,47.8v-91.66Zm226.89-43.07l-217.24,42.97v86.03s217.25-42.96,217.25-42.96v-86.04Z" />
                            </g>
                            <g>
                              <polygon class="cls-2" points="1024.56 450.27 1024.64 450.26 1252.27 495.27 1252.05 495.32 1252.05 585.17 1251.97 585.19 1024.34 540.16 1024.56 540.12 1024.56 450.27" />
                              <path class="cls-1" d="M1021.93,541.59l-6.96-1.46,4.77-.96v-88.9s.08-.99,.08-.99l4.88-.92,229.98,45.48,7.1,1.5-4.92,.95v89.8s-4.8,1.03-4.8,1.03l-230.13-45.52Zm7.45-88.49v86.15s217.84,43.09,217.84,43.09v-86.16s-217.84-43.08-217.84-43.08Z" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="1252.05 579.74 1252.5 579.5 1252.5 580.56 1252.27 580.69 1252.05 580.56 1252.05 579.74" />
                              <polygon class="cls-1" points="1252.05 586.06 1257.32 583.44 1257.32 571.25 1247.67 576.68 1247.22 582.63 1249.75 584.81 1252.05 586.06" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="1252.27 495.27 1252.5 495.32 1252.5 585.09 1252.05 585.17 1252.05 495.32 1252.27 495.27" />
                              <polygon class="cls-1" points="1247.22 587.99 1257.32 586.05 1257.32 494.35 1252.5 493.42 1247.45 494.31 1247.22 495.32 1247.22 587.99" />
                            </g>
                          </g>
                          <g>
                            <g>
                              <polygon class="cls-3" points="1251.39 495.35 1024.27 540.27 796.64 495.26 796.56 495.27 796.57 494.99 796.34 494.94 1023.97 449.93 1251.62 494.94 1251.39 494.99 1251.39 495.35" />
                              <path class="cls-1" d="M796.34,497.1l-4.61,.69v-1.84s-5.11-.99-5.11-.99l237.35-46.94,230.06,45.49,6.98,1.48-4.8,.96v.37s-231.93,45.87-231.93,45.87l-227.93-45.07Zm227.63-45.27l-218.63,43.24,218.93,43.3,218.64-43.24-218.94-43.29Z" />
                            </g>
                            <g>
                              <polygon class="cls-2" points="1024.27 540.27 1251.39 495.35 1251.39 585.12 1251.62 585.17 1024.5 630.09 1024.5 540.32 1024.27 540.27" />
                              <path class="cls-1" d="M1019.67,541.29l-5.13-.99,241.67-47.8v91.66s5.16,.99,5.16,.99l-241.7,47.8v-91.66Zm226.89-43.07l-217.24,42.97v86.03s217.25-42.96,217.25-42.96v-86.04Z" />
                            </g>
                            <g>
                              <polygon class="cls-2" points="796.56 495.27 796.64 495.26 1024.27 540.27 1024.05 540.32 1024.05 630.17 1023.97 630.19 796.34 585.16 796.56 585.12 796.56 495.27" />
                              <path class="cls-1" d="M793.93,586.59l-6.96-1.46,4.77-.96v-88.9s.08-.99,.08-.99l4.88-.92,229.98,45.48,7.1,1.5-4.92,.95v89.8s-4.8,1.03-4.8,1.03l-230.13-45.52Zm7.45-88.49v86.15s217.84,43.09,217.84,43.09v-86.16s-217.84-43.08-217.84-43.08Z" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="1024.05 624.74 1024.5 624.5 1024.5 625.56 1024.27 625.69 1024.05 625.56 1024.05 624.74" />
                              <polygon class="cls-1" points="1024.05 631.06 1029.32 628.44 1029.32 616.25 1019.67 621.68 1019.22 627.63 1021.75 629.81 1024.05 631.06" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="1024.27 540.27 1024.5 540.32 1024.5 630.09 1024.05 630.17 1024.05 540.32 1024.27 540.27" />
                              <polygon class="cls-1" points="1019.22 632.99 1029.32 631.05 1029.32 539.35 1024.5 538.42 1019.45 539.31 1019.22 540.32 1019.22 632.99" />
                            </g>
                          </g>
                          <g>
                            <g>
                              <polygon class="cls-3" points="1021.39 541.35 794.27 586.27 566.64 541.26 566.56 541.27 566.57 540.99 566.34 540.94 793.97 495.93 1021.62 540.94 1021.39 540.99 1021.39 541.35" />
                              <path class="cls-1" d="M566.34,543.1l-4.61,.69v-1.84s-5.11-.99-5.11-.99l237.35-46.94,230.06,45.49,6.98,1.48-4.8,.96v.37s-231.93,45.87-231.93,45.87l-227.93-45.07Zm227.63-45.27l-218.63,43.24,218.93,43.3,218.64-43.24-218.94-43.29Z" />
                            </g>
                            <g>
                              <polygon class="cls-2" points="794.27 586.27 1021.39 541.35 1021.39 631.12 1021.62 631.17 794.5 676.09 794.5 586.32 794.27 586.27" />
                              <path class="cls-1" d="M789.67,587.29l-5.13-.99,241.67-47.8v91.66s5.16,.99,5.16,.99l-241.7,47.8v-91.66Zm226.89-43.07l-217.24,42.97v86.03s217.25-42.96,217.25-42.96v-86.04Z" />
                            </g>
                            <g>
                              <polygon class="cls-2" points="566.56 541.27 566.64 541.26 794.27 586.27 794.05 586.32 794.05 676.17 793.97 676.19 566.34 631.16 566.56 631.12 566.56 541.27" />
                              <path class="cls-1" d="M563.93,632.59l-6.96-1.46,4.77-.96v-88.9s.08-.99,.08-.99l4.88-.92,229.98,45.48,7.1,1.5-4.92,.95v89.8s-4.8,1.03-4.8,1.03l-230.13-45.52Zm7.45-88.49v86.15s217.84,43.09,217.84,43.09v-86.16s-217.84-43.08-217.84-43.08Z" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="794.05 670.74 794.5 670.5 794.5 671.56 794.27 671.69 794.05 671.56 794.05 670.74" />
                              <polygon class="cls-1" points="794.05 677.06 799.32 674.44 799.32 662.25 789.67 667.68 789.22 673.63 791.75 675.81 794.05 677.06" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="794.27 586.27 794.5 586.32 794.5 676.09 794.05 676.17 794.05 586.32 794.27 586.27" />
                              <polygon class="cls-1" points="789.22 678.99 799.32 677.05 799.32 585.35 794.5 584.42 789.45 585.31 789.22 586.32 789.22 678.99" />
                            </g>
                          </g>
                          <g>
                            <g className="k11b1base">
                              <polygon className={`k11b1 ${isHovered111 ? 'act' : ''}`}
                                onMouseEnter={() => setIsHovered111(true)}
                                onMouseLeave={() => setIsHovered111(false)} points="793.39 586.35 566.27 631.27 338.64 586.26 338.56 586.27 338.57 585.99 338.34 585.94 565.97 540.93 793.62 585.94 793.39 585.99 793.39 586.35" />
                              <path class="cls-1 k11b1path" d="M338.34,588.1l-4.61,.69v-1.84s-5.11-.99-5.11-.99l237.35-46.94,230.06,45.49,6.98,1.48-4.8,.96v.37s-231.93,45.87-231.93,45.87l-227.93-45.07Zm227.63-45.27l-218.63,43.24,218.93,43.3,218.64-43.24-218.94-43.29Z" />
                            </g>
                            <g>
                              <polygon class="cls-2" points="566.27 631.27 793.39 586.35 793.39 676.12 793.62 676.17 566.5 721.09 566.5 631.32 566.27 631.27" />
                              <path class="cls-1" d="M561.67,632.29l-5.13-.99,241.67-47.8v91.66s5.16,.99,5.16,.99l-241.7,47.8v-91.66Zm226.89-43.07l-217.24,42.97v86.03s217.25-42.96,217.25-42.96v-86.04Z" />
                            </g>
                            <g className="k11b1base">
                              <polygon className={`k11b1 ${isHovered111 ? 'act' : ''}`}
                                onMouseEnter={() => setIsHovered111(true)}
                                onMouseLeave={() => setIsHovered111(false)} points="338.56 586.27 338.64 586.26 566.27 631.27 566.05 631.32 566.05 721.17 565.97 721.19 338.34 676.16 338.56 676.12 338.56 586.27" />
                              <path class="cls-1 k11b1path" d="M335.93,677.59l-6.96-1.46,4.77-.96v-88.9s.08-.99,.08-.99l4.88-.92,229.98,45.48,7.1,1.5-4.92,.95v89.8s-4.8,1.03-4.8,1.03l-230.13-45.52Zm7.45-88.49v86.15s217.84,43.09,217.84,43.09v-86.16s-217.84-43.08-217.84-43.08Z" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="566.05 715.74 566.5 715.5 566.5 716.56 566.27 716.69 566.05 716.56 566.05 715.74" />
                              <polygon class="cls-1" points="566.05 722.06 571.32 719.44 571.32 707.25 561.67 712.68 561.22 718.63 563.75 720.81 566.05 722.06" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="566.27 631.27 566.5 631.32 566.5 721.09 566.05 721.17 566.05 631.32 566.27 631.27" />
                              <polygon class="cls-1" points="561.22 723.99 571.32 722.05 571.32 630.35 566.5 629.42 561.45 630.31 561.22 631.32 561.22 723.99" />
                            </g>
                          </g>
                          <g>
                            <g className="k11b5base">
                              <polygon className={`k11b5 ${isHovered115 ? 'act' : ''}`}
                                onMouseEnter={() => setIsHovered115(true)}
                                onMouseLeave={() => setIsHovered115(false)} points="1709.39 496.35 1482.27 541.27 1254.64 496.26 1254.56 496.27 1254.57 495.99 1254.34 495.94 1481.97 450.93 1709.62 495.94 1709.39 495.99 1709.39 496.35" />
                              <path class="cls-1 k11b5path" d="M1254.34,498.1l-4.61,.69v-1.84s-5.11-.99-5.11-.99l237.35-46.94,230.06,45.49,6.98,1.48-4.8,.96v.37s-231.93,45.87-231.93,45.87l-227.93-45.07Zm227.63-45.27l-218.63,43.24,218.93,43.3,218.64-43.24-218.94-43.29Z" />
                            </g>
                            <g className="k11b5base">
                              <polygon className={`k11b5 ${isHovered115 ? 'act' : ''}`}
                                onMouseEnter={() => setIsHovered115(true)}
                                onMouseLeave={() => setIsHovered115(false)} points="1482.27 541.27 1709.39 496.35 1709.39 586.12 1709.62 586.17 1482.5 631.09 1482.5 541.32 1482.27 541.27" />
                              <path class="cls-1 k11b5path" d="M1477.67,542.29l-5.13-.99,241.67-47.8v91.66s5.16,.99,5.16,.99l-241.7,47.8v-91.66Zm226.89-43.07l-217.24,42.97v86.03s217.25-42.96,217.25-42.96v-86.04Z" />
                            </g>
                            <g>
                              <polygon class="cls-2" points="1254.56 496.27 1254.64 496.26 1482.27 541.27 1482.05 541.32 1482.05 631.17 1481.97 631.19 1254.34 586.16 1254.56 586.12 1254.56 496.27" />
                              <path class="cls-1" d="M1251.93,587.59l-6.96-1.46,4.77-.96v-88.9s.08-.99,.08-.99l4.88-.92,229.98,45.48,7.1,1.5-4.92,.95v89.8s-4.8,1.03-4.8,1.03l-230.13-45.52Zm7.45-88.49v86.15s217.84,43.09,217.84,43.09v-86.16s-217.84-43.08-217.84-43.08Z" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="1482.05 625.74 1482.5 625.5 1482.5 626.56 1482.27 626.69 1482.05 626.56 1482.05 625.74" />
                              <polygon class="cls-1" points="1482.05 632.06 1487.32 629.44 1487.32 617.25 1477.67 622.68 1477.22 628.63 1479.75 630.81 1482.05 632.06" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="1482.27 541.27 1482.5 541.32 1482.5 631.09 1482.05 631.17 1482.05 541.32 1482.27 541.27" />
                              <polygon class="cls-1" points="1477.22 633.99 1487.32 632.05 1487.32 540.35 1482.5 539.42 1477.45 540.31 1477.22 541.32 1477.22 633.99" />
                            </g>
                          </g>
                          <g>
                            <g className="k11b4base">
                              <polygon className={`k11b4 ${isHovered114 ? 'act' : ''}`}
                                onMouseEnter={() => setIsHovered114(true)}
                                onMouseLeave={() => setIsHovered114(false)} points="1481.39 541.35 1254.27 586.27 1026.64 541.26 1026.56 541.27 1026.57 540.99 1026.34 540.94 1253.97 495.93 1481.62 540.94 1481.39 540.99 1481.39 541.35" />
                              <path class="cls-1 k11b4path" d="M1026.34,543.1l-4.61,.69v-1.84s-5.11-.99-5.11-.99l237.35-46.94,230.06,45.49,6.98,1.48-4.8,.96v.37s-231.93,45.87-231.93,45.87l-227.93-45.07Zm227.63-45.27l-218.63,43.24,218.93,43.3,218.64-43.24-218.94-43.29Z" />
                            </g>
                            <g className="k11b4base">
                              <polygon className={`k11b4 ${isHovered114 ? 'act' : ''}`}
                                onMouseEnter={() => setIsHovered114(true)}
                                onMouseLeave={() => setIsHovered114(false)} points="1254.27 586.27 1481.39 541.35 1481.39 631.12 1481.62 631.17 1254.5 676.09 1254.5 586.32 1254.27 586.27" />
                              <path class="cls-1 k11b4path" d="M1249.67,587.29l-5.13-.99,241.67-47.8v91.66s5.16,.99,5.16,.99l-241.7,47.8v-91.66Zm226.89-43.07l-217.24,42.97v86.03s217.25-42.96,217.25-42.96v-86.04Z" />
                            </g>
                            <g>
                              <polygon class="cls-2" points="1026.56 541.27 1026.64 541.26 1254.27 586.27 1254.05 586.32 1254.05 676.17 1253.97 676.19 1026.34 631.16 1026.56 631.12 1026.56 541.27" />
                              <path class="cls-1" d="M1023.93,632.59l-6.96-1.46,4.77-.96v-88.9s.08-.99,.08-.99l4.88-.92,229.98,45.48,7.1,1.5-4.92,.95v89.8s-4.8,1.03-4.8,1.03l-230.13-45.52Zm7.45-88.49v86.15s217.84,43.09,217.84,43.09v-86.16s-217.84-43.08-217.84-43.08Z" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="1254.05 670.74 1254.5 670.5 1254.5 671.56 1254.27 671.69 1254.05 671.56 1254.05 670.74" />
                              <polygon class="cls-1" points="1254.05 677.06 1259.32 674.44 1259.32 662.25 1249.67 667.68 1249.22 673.63 1251.75 675.81 1254.05 677.06" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="1254.27 586.27 1254.5 586.32 1254.5 676.09 1254.05 676.17 1254.05 586.32 1254.27 586.27" />
                              <polygon class="cls-1" points="1249.22 678.99 1259.32 677.05 1259.32 585.35 1254.5 584.42 1249.45 585.31 1249.22 586.32 1249.22 678.99" />
                            </g>
                          </g>
                          <g>
                            <g className="k11b3base">
                              <polygon className={`k11b3 ${isHovered113 ? 'act' : ''}`}
                                onMouseEnter={() => setIsHovered113(true)}
                                onMouseLeave={() => setIsHovered113(false)} points="1251.39 587.35 1024.27 632.27 796.64 587.26 796.56 587.27 796.57 586.99 796.34 586.94 1023.97 541.93 1251.62 586.94 1251.39 586.99 1251.39 587.35" />
                              <path class="cls-1 k11b3path" d="M796.34,589.1l-4.61,.69v-1.84s-5.11-.99-5.11-.99l237.35-46.94,230.06,45.49,6.98,1.48-4.8,.96v.37s-231.93,45.87-231.93,45.87l-227.93-45.07Zm227.63-45.27l-218.63,43.24,218.93,43.3,218.64-43.24-218.94-43.29Z" />
                            </g>
                            <g className="k11b3base">
                              <polygon className={`k11b3 ${isHovered113 ? 'act' : ''}`}
                                onMouseEnter={() => setIsHovered113(true)}
                                onMouseLeave={() => setIsHovered113(false)} points="1024.27 632.27 1251.39 587.35 1251.39 677.12 1251.62 677.17 1024.5 722.09 1024.5 632.32 1024.27 632.27" />
                              <path class="cls-1 k11b3path" d="M1019.67,633.29l-5.13-.99,241.67-47.8v91.66s5.16,.99,5.16,.99l-241.7,47.8v-91.66Zm226.89-43.07l-217.24,42.97v86.03s217.25-42.96,217.25-42.96v-86.04Z" />
                            </g>
                            <g>
                              <polygon class="cls-2" points="796.56 587.27 796.64 587.26 1024.27 632.27 1024.05 632.32 1024.05 722.17 1023.97 722.19 796.34 677.16 796.56 677.12 796.56 587.27" />
                              <path class="cls-1" d="M793.93,678.59l-6.96-1.46,4.77-.96v-88.9s.08-.99,.08-.99l4.88-.92,229.98,45.48,7.1,1.5-4.92,.95v89.8s-4.8,1.03-4.8,1.03l-230.13-45.52Zm7.45-88.49v86.15s217.84,43.09,217.84,43.09v-86.16s-217.84-43.08-217.84-43.08Z" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="1024.05 716.74 1024.5 716.5 1024.5 717.56 1024.27 717.69 1024.05 717.56 1024.05 716.74" />
                              <polygon class="cls-1" points="1024.05 723.06 1029.32 720.44 1029.32 708.25 1019.67 713.68 1019.22 719.63 1021.75 721.81 1024.05 723.06" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="1024.27 632.27 1024.5 632.32 1024.5 722.09 1024.05 722.17 1024.05 632.32 1024.27 632.27" />
                              <polygon class="cls-1" points="1019.22 724.99 1029.32 723.05 1029.32 631.35 1024.5 630.42 1019.45 631.31 1019.22 632.32 1019.22 724.99" />
                            </g>
                          </g>
                          <g>
                            <g className="k11b2base">
                              <polygon className={`k11b2 ${isHovered112 ? 'act' : ''}`}
                                onMouseEnter={() => setIsHovered112(true)}
                                onMouseLeave={() => setIsHovered112(false)} points="1023.39 632.35 796.27 677.27 568.64 632.26 568.56 632.27 568.57 631.99 568.34 631.94 795.97 586.93 1023.62 631.94 1023.39 631.99 1023.39 632.35" />
                              <path class="cls-1 k11b2path" d="M568.34,634.1l-4.61,.69v-1.84s-5.11-.99-5.11-.99l237.35-46.94,230.06,45.49,6.98,1.48-4.8,.96v.37s-231.93,45.87-231.93,45.87l-227.93-45.07Zm227.63-45.27l-218.63,43.24,218.93,43.3,218.64-43.24-218.94-43.29Z" />
                            </g>
                            <g className="k11b2base">
                              <polygon className={`k11b2 ${isHovered112 ? 'act' : ''}`}
                                onMouseEnter={() => setIsHovered112(true)}
                                onMouseLeave={() => setIsHovered112(false)} points="796.27 677.27 1023.39 632.35 1023.39 722.12 1023.62 722.17 796.5 767.09 796.5 677.32 796.27 677.27" />
                              <path class="cls-1 k11b2" d="M791.67,678.29l-5.13-.99,241.67-47.8v91.66s5.16,.99,5.16,.99l-241.7,47.8v-91.66Zm226.89-43.07l-217.24,42.97v86.03s217.25-42.96,217.25-42.96v-86.04Z" />
                            </g>
                            <g className="k11b2base">
                              <polygon className={`k11b2 ${isHovered112 ? 'act' : ''}`}
                                onMouseEnter={() => setIsHovered112(true)}
                                onMouseLeave={() => setIsHovered112(false)} points="568.56 632.27 568.64 632.26 796.27 677.27 796.05 677.32 796.05 767.17 795.97 767.19 568.34 722.16 568.56 722.12 568.56 632.27" />
                              <path class="cls-1 k11b2path" d="M565.93,723.59l-6.96-1.46,4.77-.96v-88.9s.08-.99,.08-.99l4.88-.92,229.98,45.48,7.1,1.5-4.92,.95v89.8s-4.8,1.03-4.8,1.03l-230.13-45.52Zm7.45-88.49v86.15s217.84,43.09,217.84,43.09v-86.16s-217.84-43.08-217.84-43.08Z" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="796.05 761.74 796.5 761.5 796.5 762.56 796.27 762.69 796.05 762.56 796.05 761.74" />
                              <polygon class="cls-1" points="796.05 768.06 801.32 765.44 801.32 753.25 791.67 758.68 791.22 764.63 793.75 766.81 796.05 768.06" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="796.27 677.27 796.5 677.32 796.5 767.09 796.05 767.17 796.05 677.32 796.27 677.27" />
                              <polygon class="cls-1" points="791.22 769.99 801.32 768.05 801.32 676.35 796.5 675.42 791.45 676.31 791.22 677.32 791.22 769.99" />
                            </g>
                          </g>
                        </g>
                        <g>
                          <g>
                            <g className="k12b6base">
                              <polygon className={`k12b6 ${isHovered126 ? 'act' : ''}`}
                                onMouseEnter={() => setIsHovered126(true)}
                                onMouseLeave={() => setIsHovered126(false)} points="1402.86 378.05 1201.11 417.96 998.9 377.97 998.83 377.98 998.84 377.73 998.64 377.69 1200.84 337.7 1403.06 377.69 1402.86 377.73 1402.86 378.05" />
                              <path class="cls-1 k12b6path" d="M998.64,379.61l-4.09,.61v-1.64s-4.54-.88-4.54-.88l210.84-41.7,204.37,40.41,6.2,1.31-4.27,.85v.33s-206.03,40.74-206.03,40.74l-202.47-40.04Zm202.2-40.21l-194.21,38.41,194.48,38.46,194.22-38.41-194.49-38.46Z" />
                            </g>
                            <g>
                              <polygon class="cls-2" points="1201.11 417.96 1402.86 378.05 1402.86 457.79 1403.06 457.84 1201.31 497.74 1201.31 418 1201.11 417.96" />
                              <path class="cls-1" d="M1197.02,418.86l-4.56-.88,214.68-42.46v81.42s4.59,.88,4.59,.88l-214.71,42.47v-81.42Zm201.54-38.26l-192.98,38.17v76.43s192.99-38.16,192.99-38.16v-76.43Z" />
                            </g>
                            <g>
                              <polygon class="cls-2" points="998.83 377.98 998.9 377.97 1201.11 417.96 1200.91 418 1200.91 497.81 1200.84 497.83 998.64 457.83 998.83 457.79 998.83 377.98" />
                              <path class="cls-1" d="M996.5,459.1l-6.18-1.29,4.24-.85v-78.97s.07-.88,.07-.88l4.33-.82,204.3,40.4,6.31,1.33-4.37,.84v79.77s-4.27,.91-4.27,.91l-204.43-40.44Zm6.61-78.61v76.52s193.51,38.28,193.51,38.28v-76.53s-193.51-38.27-193.51-38.27Z" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="1200.91 492.99 1201.31 492.78 1201.31 493.72 1201.11 493.83 1200.91 493.72 1200.91 492.99" />
                              <polygon class="cls-1" points="1200.91 498.6 1205.59 496.28 1205.59 485.45 1197.02 490.27 1196.62 495.55 1198.87 497.5 1200.91 498.6" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="1201.11 417.96 1201.31 418 1201.31 497.74 1200.91 497.81 1200.91 418 1201.11 417.96" />
                              <polygon class="cls-1" points="1196.62 500.32 1205.59 498.6 1205.59 417.14 1201.31 416.31 1196.82 417.1 1196.62 418 1196.62 500.32" />
                            </g>
                          </g>
                          <g>
                            <g className="k12b7base">
                              <polygon className={`k12b7 ${isHovered127 ? 'act' : ''}`}
                                onMouseEnter={() => setIsHovered127(true)}
                                onMouseLeave={() => setIsHovered127(false)} points="1200.33 418.03 998.57 457.93 796.37 417.94 796.3 417.95 796.31 417.7 796.1 417.66 998.31 377.67 1200.53 417.66 1200.33 417.7 1200.33 418.03" />
                              <path class="cls-1 k12b7path" d="M796.1,419.58l-4.09,.61v-1.64s-4.54-.88-4.54-.88l210.84-41.7,204.37,40.41,6.2,1.31-4.27,.85v.33s-206.03,40.74-206.03,40.74l-202.47-40.04Zm202.2-40.21l-194.21,38.41,194.48,38.46,194.22-38.41-194.49-38.46Z" />
                            </g>
                            <g>
                              <polygon class="cls-2" points="998.57 457.93 1200.33 418.03 1200.33 497.77 1200.53 497.81 998.77 537.71 998.77 457.97 998.57 457.93" />
                              <path class="cls-1" d="M994.49,458.83l-4.56-.88,214.68-42.46v81.42s4.59,.88,4.59,.88l-214.71,42.47v-81.42Zm201.54-38.26l-192.98,38.17v76.43s192.99-38.16,192.99-38.16v-76.43Z" />
                            </g>
                            <g>
                              <polygon class="cls-2" points="796.3 417.95 796.37 417.94 998.57 457.93 998.37 457.97 998.37 537.78 998.31 537.8 796.1 497.8 796.3 497.77 796.3 417.95" />
                              <path class="cls-1" d="M793.96,499.08l-6.18-1.29,4.24-.85v-78.97s.07-.88,.07-.88l4.33-.82,204.3,40.4,6.31,1.33-4.37,.84v79.77s-4.27,.91-4.27,.91l-204.43-40.44Zm6.61-78.61v76.52s193.51,38.28,193.51,38.28v-76.53s-193.51-38.27-193.51-38.27Z" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="998.37 532.97 998.77 532.75 998.77 533.69 998.57 533.81 998.37 533.69 998.37 532.97" />
                              <polygon class="cls-1" points="998.37 538.57 1003.06 536.25 1003.06 525.43 994.49 530.24 994.09 535.53 996.33 537.47 998.37 538.57" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="998.57 457.93 998.77 457.97 998.77 537.71 998.37 537.78 998.37 457.97 998.57 457.93" />
                              <polygon class="cls-1" points="994.09 540.3 1003.06 538.57 1003.06 457.11 998.77 456.29 994.29 457.07 994.09 457.97 994.09 540.3" />
                            </g>
                          </g>
                          <g>
                            <g className="k12b8base">
                              <polygon className={`k12b8 ${isHovered128 ? 'act' : ''}`}
                                onMouseEnter={() => setIsHovered128(true)}
                                onMouseLeave={() => setIsHovered128(false)} points="996.02 458.89 794.26 498.79 592.06 458.81 591.99 458.82 592 458.56 591.79 458.52 794 418.54 996.22 458.52 996.02 458.56 996.02 458.89" />
                              <path class="cls-1 k12b8path" d="M591.79,460.45l-4.09,.61v-1.64s-4.54-.88-4.54-.88l210.84-41.7,204.37,40.41,6.2,1.31-4.27,.85v.33s-206.03,40.74-206.03,40.74l-202.47-40.04Zm202.2-40.21l-194.21,38.41,194.48,38.46,194.22-38.41-194.49-38.46Z" />
                            </g>
                            <g>
                              <polygon class="cls-2" points="794.26 498.79 996.02 458.89 996.02 538.63 996.22 538.67 794.46 578.57 794.46 498.83 794.26 498.79" />
                              <path class="cls-1" d="M790.18,499.69l-4.56-.88,214.68-42.46v81.42s4.59,.88,4.59,.88l-214.71,42.47v-81.42Zm201.54-38.26l-192.98,38.17v76.43s192.99-38.16,192.99-38.16v-76.43Z" />
                            </g>
                            <g>
                              <polygon class="cls-2" points="591.99 458.82 592.06 458.81 794.26 498.79 794.06 498.83 794.06 578.65 794 578.67 591.79 538.67 591.99 538.63 591.99 458.82" />
                              <path class="cls-1" d="M589.65,539.94l-6.18-1.29,4.24-.85v-78.97s.07-.88,.07-.88l4.33-.82,204.3,40.4,6.31,1.33-4.37,.84v79.77s-4.27,.91-4.27,.91l-204.43-40.44Zm6.61-78.61v76.52s193.51,38.28,193.51,38.28v-76.53s-193.51-38.27-193.51-38.27Z" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="794.06 573.83 794.46 573.62 794.46 574.55 794.26 574.67 794.06 574.55 794.06 573.83" />
                              <polygon class="cls-1" points="794.06 579.44 798.75 577.11 798.75 566.29 790.18 571.1 789.78 576.39 792.02 578.33 794.06 579.44" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="794.26 498.79 794.46 498.83 794.46 578.57 794.06 578.65 794.06 498.83 794.26 498.79" />
                              <polygon class="cls-1" points="789.78 581.16 798.75 579.43 798.75 497.97 794.46 497.15 789.98 497.93 789.78 498.83 789.78 581.16" />
                            </g>
                          </g>
                          <g>
                            <g className="k12b1base">
                              <polygon className={`k12b1 ${isHovered121 ? 'act' : ''}`}
                                onMouseEnter={() => setIsHovered121(true)}
                                onMouseLeave={() => setIsHovered121(false)} points="793.48 498.86 591.73 538.77 389.52 498.78 389.45 498.79 389.46 498.54 389.26 498.5 591.46 458.51 793.68 498.5 793.48 498.54 793.48 498.86" />
                              <path class="cls-1 k12b1path" d="M389.26,500.42l-4.09,.61v-1.64s-4.54-.88-4.54-.88l210.84-41.7,204.37,40.41,6.2,1.31-4.27,.85v.33s-206.03,40.74-206.03,40.74l-202.47-40.04Zm202.2-40.21l-194.21,38.41,194.48,38.46,194.22-38.41-194.49-38.46Z" />
                            </g>
                            <g>
                              <polygon class="cls-2" points="591.73 538.77 793.48 498.86 793.48 578.6 793.68 578.65 591.93 618.55 591.93 538.81 591.73 538.77" />
                              <path class="cls-1" d="M587.64,539.67l-4.56-.88,214.68-42.46v81.42s4.59,.88,4.59,.88l-214.71,42.47v-81.42Zm201.54-38.26l-192.98,38.17v76.43s192.99-38.16,192.99-38.16v-76.43Z" />
                            </g>
                            <g className="k12b1base">
                              <polygon className={`k12b1 ${isHovered121 ? 'act' : ''}`}
                                onMouseEnter={() => setIsHovered121(true)}
                                onMouseLeave={() => setIsHovered121(false)} points="389.45 498.79 389.52 498.78 591.73 538.77 591.53 538.81 591.53 618.62 591.46 618.64 389.26 578.64 389.45 578.6 389.45 498.79" />
                              <path class="cls-1 k12b1path" d="M387.12,579.91l-6.18-1.29,4.24-.85v-78.97s.07-.88,.07-.88l4.33-.82,204.3,40.4,6.31,1.33-4.37,.84v79.77s-4.27,.91-4.27,.91l-204.43-40.44Zm6.61-78.61v76.52s193.51,38.28,193.51,38.28v-76.53s-193.51-38.27-193.51-38.27Z" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="591.53 613.8 591.93 613.59 591.93 614.53 591.73 614.64 591.53 614.53 591.53 613.8" />
                              <polygon class="cls-1" points="591.53 619.41 596.21 617.09 596.21 606.26 587.64 611.08 587.24 616.36 589.49 618.31 591.53 619.41" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="591.73 538.77 591.93 538.81 591.93 618.55 591.53 618.62 591.53 538.81 591.73 538.77" />
                              <polygon class="cls-1" points="587.24 621.13 596.21 619.41 596.21 537.95 591.93 537.12 587.44 537.91 587.24 538.81 587.24 621.13" />
                            </g>
                          </g>
                          <g>
                            <g className="k12b5base">
                              <polygon className={`k12b5 ${isHovered125 ? 'act' : ''}`}
                                onMouseEnter={() => setIsHovered125(true)}
                                onMouseLeave={() => setIsHovered125(false)} points="1607.17 418.92 1405.42 458.82 1203.21 418.83 1203.14 418.84 1203.15 418.59 1202.95 418.55 1405.15 378.56 1607.37 418.55 1607.17 418.59 1607.17 418.92" />
                              <path class="cls-1 k12b5path" d="M1202.95,420.47l-4.09,.61v-1.64s-4.54-.88-4.54-.88l210.84-41.7,204.37,40.41,6.2,1.31-4.27,.85v.33s-206.03,40.74-206.03,40.74l-202.47-40.04Zm202.2-40.21l-194.21,38.41,194.48,38.46,194.22-38.41-194.49-38.46Z" />
                            </g>
                            <g className="k12b5base">
                              <polygon className={`k12b5 ${isHovered125 ? 'act' : ''}`}
                                onMouseEnter={() => setIsHovered125(true)}
                                onMouseLeave={() => setIsHovered125(false)} points="1405.42 458.82 1607.17 418.92 1607.17 498.66 1607.37 498.7 1405.62 538.6 1405.62 458.86 1405.42 458.82" />
                              <path class="cls-1 k12b5path" d="M1401.34,459.72l-4.56-.88,214.68-42.46v81.42s4.59,.88,4.59,.88l-214.71,42.47v-81.42Zm201.54-38.26l-192.98,38.17v76.43s192.99-38.16,192.99-38.16v-76.43Z" />
                            </g>
                            <g>
                              <polygon class="cls-2" points="1203.14 418.84 1203.21 418.83 1405.42 458.82 1405.22 458.86 1405.22 538.67 1405.15 538.69 1202.95 498.69 1203.14 498.66 1203.14 418.84" />
                              <path class="cls-1" d="M1200.81,499.96l-6.18-1.29,4.24-.85v-78.97s.07-.88,.07-.88l4.33-.82,204.3,40.4,6.31,1.33-4.37,.84v79.77s-4.27,.91-4.27,.91l-204.43-40.44Zm6.61-78.61v76.52s193.51,38.28,193.51,38.28v-76.53s-193.51-38.27-193.51-38.27Z" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="1405.22 533.85 1405.62 533.64 1405.62 534.58 1405.42 534.69 1405.22 534.58 1405.22 533.85" />
                              <polygon class="cls-1" points="1405.22 539.46 1409.9 537.14 1409.9 526.31 1401.34 531.13 1400.94 536.42 1403.18 538.36 1405.22 539.46" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="1405.42 458.82 1405.62 458.86 1405.62 538.6 1405.22 538.67 1405.22 458.86 1405.42 458.82" />
                              <polygon class="cls-1" points="1400.94 541.18 1409.9 539.46 1409.9 458 1405.62 457.17 1401.14 457.96 1400.94 458.86 1400.94 541.18" />
                            </g>
                          </g>
                          <g>
                            <g className="k12b4base">
                              <polygon className={`k12b4 ${isHovered124 ? 'act' : ''}`}
                                onMouseEnter={() => setIsHovered124(true)}
                                onMouseLeave={() => setIsHovered124(false)} points="1404.64 458.89 1202.88 498.79 1000.68 458.81 1000.61 458.82 1000.62 458.56 1000.41 458.52 1202.62 418.54 1404.84 458.52 1404.64 458.56 1404.64 458.89" />
                              <path class="cls-1 k12b4path" d="M1000.41,460.45l-4.09,.61v-1.64s-4.54-.88-4.54-.88l210.84-41.7,204.37,40.41,6.2,1.31-4.27,.85v.33s-206.03,40.74-206.03,40.74l-202.47-40.04Zm202.2-40.21l-194.21,38.41,194.48,38.46,194.22-38.41-194.49-38.46Z" />
                            </g>
                            <g className="k12b4base">
                              <polygon className={`k12b4 ${isHovered124 ? 'act' : ''}`}
                                onMouseEnter={() => setIsHovered124(true)}
                                onMouseLeave={() => setIsHovered124(false)} points="1202.88 498.79 1404.64 458.89 1404.64 538.63 1404.84 538.67 1203.08 578.57 1203.08 498.83 1202.88 498.79" />
                              <path class="cls-1 k12b4path" d="M1198.8,499.69l-4.56-.88,214.68-42.46v81.42s4.59,.88,4.59,.88l-214.71,42.47v-81.42Zm201.54-38.26l-192.98,38.17v76.43s192.99-38.16,192.99-38.16v-76.43Z" />
                            </g>
                            <g>
                              <polygon class="cls-2" points="1000.61 458.82 1000.68 458.81 1202.88 498.79 1202.68 498.83 1202.68 578.65 1202.62 578.67 1000.41 538.67 1000.61 538.63 1000.61 458.82" />
                              <path class="cls-1" d="M998.27,539.94l-6.18-1.29,4.24-.85v-78.97s.07-.88,.07-.88l4.33-.82,204.3,40.4,6.31,1.33-4.37,.84v79.77s-4.27,.91-4.27,.91l-204.43-40.44Zm6.61-78.61v76.52s193.51,38.28,193.51,38.28v-76.53s-193.51-38.27-193.51-38.27Z" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="1202.68 573.83 1203.08 573.62 1203.08 574.55 1202.88 574.67 1202.68 574.55 1202.68 573.83" />
                              <polygon class="cls-1" points="1202.68 579.44 1207.37 577.11 1207.37 566.29 1198.8 571.1 1198.4 576.39 1200.64 578.33 1202.68 579.44" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="1202.88 498.79 1203.08 498.83 1203.08 578.57 1202.68 578.65 1202.68 498.83 1202.88 498.79" />
                              <polygon class="cls-1" points="1198.4 581.16 1207.37 579.43 1207.37 497.97 1203.08 497.15 1198.6 497.93 1198.4 498.83 1198.4 581.16" />
                            </g>
                          </g>
                          <g>
                            <g className="k12b3base">
                              <polygon className={`k12b3 ${isHovered123 ? 'act' : ''}`}
                                onMouseEnter={() => setIsHovered123(true)}
                                onMouseLeave={() => setIsHovered123(false)} points="1200.33 499.75 998.57 539.65 796.37 499.67 796.3 499.68 796.31 499.43 796.1 499.39 998.31 459.4 1200.53 499.39 1200.33 499.43 1200.33 499.75" />
                              <path class="cls-1 k12b3path" d="M796.1,501.31l-4.09,.61v-1.64s-4.54-.88-4.54-.88l210.84-41.7,204.37,40.41,6.2,1.31-4.27,.85v.33s-206.03,40.74-206.03,40.74l-202.47-40.04Zm202.2-40.21l-194.21,38.41,194.48,38.46,194.22-38.41-194.49-38.46Z" />
                            </g>
                            <g className="k12b3base">
                              <polygon className={`k12b3 ${isHovered123 ? 'act' : ''}`}
                                onMouseEnter={() => setIsHovered123(true)}
                                onMouseLeave={() => setIsHovered123(false)} points="998.57 539.65 1200.33 499.75 1200.33 579.49 1200.53 579.54 998.77 619.44 998.77 539.69 998.57 539.65" />
                              <path class="cls-1 k12b3path" d="M994.49,540.56l-4.56-.88,214.68-42.46v81.42s4.59,.88,4.59,.88l-214.71,42.47v-81.42Zm201.54-38.26l-192.98,38.17v76.43s192.99-38.16,192.99-38.16v-76.43Z" />
                            </g>
                            <g>
                              <polygon class="cls-2" points="796.3 499.68 796.37 499.67 998.57 539.65 998.37 539.69 998.37 619.51 998.31 619.53 796.1 579.53 796.3 579.49 796.3 499.68" />
                              <path class="cls-1" d="M793.96,580.8l-6.18-1.29,4.24-.85v-78.97s.07-.88,.07-.88l4.33-.82,204.3,40.4,6.31,1.33-4.37,.84v79.77s-4.27,.91-4.27,.91l-204.43-40.44Zm6.61-78.61v76.52s193.51,38.28,193.51,38.28v-76.53s-193.51-38.27-193.51-38.27Z" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="998.37 614.69 998.77 614.48 998.77 615.41 998.57 615.53 998.37 615.41 998.37 614.69" />
                              <polygon class="cls-1" points="998.37 620.3 1003.06 617.97 1003.06 607.15 994.49 611.97 994.09 617.25 996.33 619.19 998.37 620.3" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="998.57 539.65 998.77 539.69 998.77 619.44 998.37 619.51 998.37 539.69 998.57 539.65" />
                              <polygon class="cls-1" points="994.09 622.02 1003.06 620.3 1003.06 538.84 998.77 538.01 994.29 538.8 994.09 539.69 994.09 622.02" />
                            </g>
                          </g>
                          <g>
                            <g className="k12b2base">
                              <polygon className={`k12b2 ${isHovered122 ? 'act' : ''}`}
                                onMouseEnter={() => setIsHovered122(true)}
                                onMouseLeave={() => setIsHovered122(false)} points="997.79 539.73 796.04 579.63 593.83 539.64 593.76 539.65 593.77 539.4 593.57 539.36 795.77 499.37 997.99 539.36 997.79 539.4 997.79 539.73" />
                              <path class="cls-1 k12b2path" d="M593.57,541.28l-4.09,.61v-1.64s-4.54-.88-4.54-.88l210.84-41.7,204.37,40.41,6.2,1.31-4.27,.85v.33s-206.03,40.74-206.03,40.74l-202.47-40.04Zm202.2-40.21l-194.21,38.41,194.48,38.46,194.22-38.41-194.49-38.46Z" />
                            </g>
                            <g className="k12b2base">
                              <polygon className={`k12b2 ${isHovered122 ? 'act' : ''}`}
                                onMouseEnter={() => setIsHovered122(true)}
                                onMouseLeave={() => setIsHovered122(false)} points="796.04 579.63 997.79 539.73 997.79 619.47 997.99 619.51 796.24 659.41 796.24 579.67 796.04 579.63" />
                              <path class="cls-1 k12b2path" d="M791.96,580.53l-4.56-.88,214.68-42.46v81.42s4.59,.88,4.59,.88l-214.71,42.47v-81.42Zm201.54-38.26l-192.98,38.17v76.43s192.99-38.16,192.99-38.16v-76.43Z" />
                            </g>
                            <g className="k12b2base">
                              <polygon className={`k12b2 ${isHovered122 ? 'act' : ''}`}
                                onMouseEnter={() => setIsHovered122(true)}
                                onMouseLeave={() => setIsHovered122(false)} points="593.76 539.65 593.83 539.64 796.04 579.63 795.84 579.67 795.84 659.48 795.77 659.5 593.57 619.5 593.76 619.47 593.76 539.65" />
                              <path class="cls-1 k12b2path" d="M591.43,620.77l-6.18-1.29,4.24-.85v-78.97s.07-.88,.07-.88l4.33-.82,204.3,40.4,6.31,1.33-4.37,.84v79.77s-4.27,.91-4.27,.91l-204.43-40.44Zm6.61-78.61v76.52s193.51,38.28,193.51,38.28v-76.53s-193.51-38.27-193.51-38.27Z" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="795.84 654.67 796.24 654.45 796.24 655.39 796.04 655.5 795.84 655.39 795.84 654.67" />
                              <polygon class="cls-1" points="795.84 660.27 800.52 657.95 800.52 647.12 791.96 651.94 791.56 657.23 793.8 659.17 795.84 660.27" />
                            </g>
                            <g>
                              <polygon class="cls-4" points="796.04 579.63 796.24 579.67 796.24 659.41 795.84 659.48 795.84 579.67 796.04 579.63" />
                              <polygon class="cls-1" points="791.56 661.99 800.52 660.27 800.52 578.81 796.24 577.98 791.76 578.77 791.56 579.67 791.56 661.99" />
                            </g>
                          </g>
                        </g>
                      </svg></SwiperSlide>
                    <SwiperSlide><svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2048 2048">
                      <g>
                        <g>
                          <g>
                            <polygon class="cls-2" points="568.61 1392.35 795.73 1437.27 1023.36 1392.26 1023.44 1392.27 1023.43 1391.99 1023.66 1391.94 796.03 1346.93 568.38 1391.94 568.61 1391.99 568.61 1392.35" />
                            <path class="cls-1" d="M795.73,1439.18l-231.94-45.87v-.37s-4.79-.96-4.79-.96l6.98-1.48,230.06-45.49,237.35,46.94-5.11,.99v1.84l-4.61-.69-227.93,45.07Zm-218.64-47.05l218.64,43.24,218.93-43.3-218.63-43.24-218.94,43.29Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="795.73 1437.27 568.61 1392.35 568.61 1482.12 568.38 1482.17 795.5 1527.09 795.5 1437.32 795.73 1437.27" />
                            <path class="cls-1" d="M800.33,1529.94l-241.7-47.8,5.16-.99v-91.66l241.67,47.8-5.13,.99v91.66Zm-226.9-48.69l217.25,42.96v-86.03l-217.24-42.97v86.04Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="1023.44 1392.27 1023.36 1392.26 795.73 1437.27 795.95 1437.32 795.95 1527.17 796.03 1527.19 1023.66 1482.16 1023.44 1482.12 1023.44 1392.27" />
                            <path class="cls-1" d="M795.93,1529.11l-4.8-1.03v-89.8l-4.92-.95,7.1-1.5,229.98-45.48,4.88,.92,.09,.99v88.9s4.76,.96,4.76,.96l-6.96,1.46-230.13,45.52Zm4.84-90.93v86.16l217.83-43.09v-86.15s-217.83,43.08-217.83,43.08Z" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="795.95 1521.74 795.5 1521.5 795.5 1522.56 795.73 1522.69 795.95 1522.56 795.95 1521.74" />
                            <polygon class="cls-1" points="795.95 1528.06 790.68 1525.44 790.68 1513.25 800.33 1518.68 800.78 1524.63 798.25 1526.81 795.95 1528.06" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="795.73 1437.27 795.5 1437.32 795.5 1527.09 795.95 1527.17 795.95 1437.32 795.73 1437.27" />
                            <polygon class="cls-1" points="800.78 1529.99 790.68 1528.05 790.68 1436.35 795.5 1435.42 800.55 1436.31 800.78 1437.32 800.78 1529.99" />
                          </g>
                        </g>
                        <g>
                          <g>
                            <polygon class="cls-2" points="796.61 1437.35 1023.73 1482.27 1251.36 1437.26 1251.44 1437.27 1251.43 1436.99 1251.66 1436.94 1024.03 1391.93 796.38 1436.94 796.61 1436.99 796.61 1437.35" />
                            <path class="cls-1" d="M1023.73,1484.18l-231.94-45.87v-.37s-4.79-.96-4.79-.96l6.98-1.48,230.06-45.49,237.35,46.94-5.11,.99v1.84l-4.61-.69-227.93,45.07Zm-218.64-47.05l218.64,43.24,218.93-43.3-218.63-43.24-218.94,43.29Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="1023.73 1482.27 796.61 1437.35 796.61 1527.12 796.38 1527.17 1023.5 1572.09 1023.5 1482.32 1023.73 1482.27" />
                            <path class="cls-1" d="M1028.33,1574.94l-241.7-47.8,5.16-.99v-91.66l241.67,47.8-5.13,.99v91.66Zm-226.9-48.69l217.25,42.96v-86.03l-217.24-42.97v86.04Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="1251.44 1437.27 1251.36 1437.26 1023.73 1482.27 1023.95 1482.32 1023.95 1572.17 1024.03 1572.19 1251.66 1527.16 1251.44 1527.12 1251.44 1437.27" />
                            <path class="cls-1" d="M1023.93,1574.11l-4.8-1.03v-89.8l-4.92-.95,7.1-1.5,229.98-45.48,4.88,.92,.09,.99v88.9s4.76,.96,4.76,.96l-6.96,1.46-230.13,45.52Zm4.84-90.93v86.16l217.83-43.09v-86.15s-217.83,43.08-217.83,43.08Z" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1023.95 1566.74 1023.5 1566.5 1023.5 1567.56 1023.73 1567.69 1023.95 1567.56 1023.95 1566.74" />
                            <polygon class="cls-1" points="1023.95 1573.06 1018.68 1570.44 1018.68 1558.25 1028.33 1563.68 1028.78 1569.63 1026.25 1571.81 1023.95 1573.06" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1023.73 1482.27 1023.5 1482.32 1023.5 1572.09 1023.95 1572.17 1023.95 1482.32 1023.73 1482.27" />
                            <polygon class="cls-1" points="1028.78 1574.99 1018.68 1573.05 1018.68 1481.35 1023.5 1480.42 1028.55 1481.31 1028.78 1482.32 1028.78 1574.99" />
                          </g>
                        </g>
                        <g>
                          <g>
                            <polygon class="cls-2" points="1026.61 1483.35 1253.73 1528.27 1481.36 1483.26 1481.44 1483.27 1481.43 1482.99 1481.66 1482.94 1254.03 1437.93 1026.38 1482.94 1026.61 1482.99 1026.61 1483.35" />
                            <path class="cls-1" d="M1253.73,1530.18l-231.94-45.87v-.37s-4.79-.96-4.79-.96l6.98-1.48,230.06-45.49,237.35,46.94-5.11,.99v1.84l-4.61-.69-227.93,45.07Zm-218.64-47.05l218.64,43.24,218.93-43.3-218.63-43.24-218.94,43.29Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="1253.73 1528.27 1026.61 1483.35 1026.61 1573.12 1026.38 1573.17 1253.5 1618.09 1253.5 1528.32 1253.73 1528.27" />
                            <path class="cls-1" d="M1258.33,1620.94l-241.7-47.8,5.16-.99v-91.66l241.67,47.8-5.13,.99v91.66Zm-226.9-48.69l217.25,42.96v-86.03l-217.24-42.97v86.04Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="1481.44 1483.27 1481.36 1483.26 1253.73 1528.27 1253.95 1528.32 1253.95 1618.17 1254.03 1618.19 1481.66 1573.16 1481.44 1573.12 1481.44 1483.27" />
                            <path class="cls-1" d="M1253.93,1620.11l-4.8-1.03v-89.8l-4.92-.95,7.1-1.5,229.98-45.48,4.88,.92,.09,.99v88.9s4.76,.96,4.76,.96l-6.96,1.46-230.13,45.52Zm4.84-90.93v86.16l217.83-43.09v-86.15s-217.83,43.08-217.83,43.08Z" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1253.95 1612.74 1253.5 1612.5 1253.5 1613.56 1253.73 1613.69 1253.95 1613.56 1253.95 1612.74" />
                            <polygon class="cls-1" points="1253.95 1619.06 1248.68 1616.44 1248.68 1604.25 1258.33 1609.68 1258.78 1615.63 1256.25 1617.81 1253.95 1619.06" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1253.73 1528.27 1253.5 1528.32 1253.5 1618.09 1253.95 1618.17 1253.95 1528.32 1253.73 1528.27" />
                            <polygon class="cls-1" points="1258.78 1620.99 1248.68 1619.05 1248.68 1527.35 1253.5 1526.42 1258.55 1527.31 1258.78 1528.32 1258.78 1620.99" />
                          </g>
                        </g>
                        <g>
                          <g>
                            <polygon class="cls-2" points="1254.61 1528.35 1481.73 1573.27 1709.36 1528.26 1709.44 1528.27 1709.43 1527.99 1709.66 1527.94 1482.03 1482.93 1254.38 1527.94 1254.61 1527.99 1254.61 1528.35" />
                            <path class="cls-1" d="M1481.73,1575.18l-231.94-45.87v-.37s-4.79-.96-4.79-.96l6.98-1.48,230.06-45.49,237.35,46.94-5.11,.99v1.84l-4.61-.69-227.93,45.07Zm-218.64-47.05l218.64,43.24,218.93-43.3-218.63-43.24-218.94,43.29Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="1481.73 1573.27 1254.61 1528.35 1254.61 1618.12 1254.38 1618.17 1481.5 1663.09 1481.5 1573.32 1481.73 1573.27" />
                            <path class="cls-1" d="M1486.33,1665.94l-241.7-47.8,5.16-.99v-91.66l241.67,47.8-5.13,.99v91.66Zm-226.9-48.69l217.25,42.96v-86.03l-217.24-42.97v86.04Z" />
                          </g>
                          <g className="k1b6base">
                            <polygon className={`k1b6 ${isHovered16 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered16(true)}
                              onMouseLeave={() => setIsHovered16(false)} points="1709.44 1528.27 1709.36 1528.26 1481.73 1573.27 1481.95 1573.32 1481.95 1663.17 1482.03 1663.19 1709.66 1618.16 1709.44 1618.12 1709.44 1528.27" />
                            <path class="cls-1 k1b6path" d="M1481.93,1665.11l-4.8-1.03v-89.8l-4.92-.95,7.1-1.5,229.98-45.48,4.88,.92,.09,.99v88.9s4.76,.96,4.76,.96l-6.96,1.46-230.13,45.52Zm4.84-90.93v86.16l217.83-43.09v-86.15s-217.83,43.08-217.83,43.08Z" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1481.95 1657.74 1481.5 1657.5 1481.5 1658.56 1481.73 1658.69 1481.95 1658.56 1481.95 1657.74" />
                            <polygon class="cls-1" points="1481.95 1664.06 1476.68 1661.44 1476.68 1649.25 1486.33 1654.68 1486.78 1660.63 1484.25 1662.81 1481.95 1664.06" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1481.73 1573.27 1481.5 1573.32 1481.5 1663.09 1481.95 1663.17 1481.95 1573.32 1481.73 1573.27" />
                            <polygon class="cls-1" points="1486.78 1665.99 1476.68 1664.05 1476.68 1572.35 1481.5 1571.42 1486.55 1572.31 1486.78 1573.32 1486.78 1665.99" />
                          </g>
                        </g>
                        <g>
                          <g>
                            <polygon class="cls-2" points="338.61 1438.35 565.73 1483.27 793.36 1438.26 793.44 1438.27 793.43 1437.99 793.66 1437.94 566.03 1392.93 338.38 1437.94 338.61 1437.99 338.61 1438.35" />
                            <path class="cls-1" d="M565.73,1485.18l-231.94-45.87v-.37s-4.79-.96-4.79-.96l6.98-1.48,230.06-45.49,237.35,46.94-5.11,.99v1.84l-4.61-.69-227.93,45.07Zm-218.64-47.05l218.64,43.24,218.93-43.3-218.63-43.24-218.94,43.29Z" />
                          </g>
                          <g className="k1b2base">
                            <polygon className={`k1b2 ${isHovered12 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered12(true)}
                              onMouseLeave={() => setIsHovered12(false)} points="565.73 1483.27 338.61 1438.35 338.61 1528.12 338.38 1528.17 565.5 1573.09 565.5 1483.32 565.73 1483.27" />
                            <path class="cls-1 k1b2path" d="M570.33,1575.94l-241.7-47.8,5.16-.99v-91.66l241.67,47.8-5.13,.99v91.66Zm-226.9-48.69l217.25,42.96v-86.03l-217.24-42.97v86.04Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="793.44 1438.27 793.36 1438.26 565.73 1483.27 565.95 1483.32 565.95 1573.17 566.03 1573.19 793.66 1528.16 793.44 1528.12 793.44 1438.27" />
                            <path class="cls-1" d="M565.93,1575.11l-4.8-1.03v-89.8l-4.92-.95,7.1-1.5,229.98-45.48,4.88,.92,.09,.99v88.9s4.76,.96,4.76,.96l-6.96,1.46-230.13,45.52Zm4.84-90.93v86.16l217.83-43.09v-86.15s-217.83,43.08-217.83,43.08Z" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="565.95 1567.74 565.5 1567.5 565.5 1568.56 565.73 1568.69 565.95 1568.56 565.95 1567.74" />
                            <polygon class="cls-1" points="565.95 1574.06 560.68 1571.44 560.68 1559.25 570.33 1564.68 570.78 1570.63 568.25 1572.81 565.95 1574.06" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="565.73 1483.27 565.5 1483.32 565.5 1573.09 565.95 1573.17 565.95 1483.32 565.73 1483.27" />
                            <polygon class="cls-1" points="570.78 1575.99 560.68 1574.05 560.68 1482.35 565.5 1481.42 570.55 1482.31 570.78 1483.32 570.78 1575.99" />
                          </g>
                        </g>
                        <g>
                          <g>
                            <polygon class="cls-2" points="566.61 1483.35 793.73 1528.27 1021.36 1483.26 1021.44 1483.27 1021.43 1482.99 1021.66 1482.94 794.03 1437.93 566.38 1482.94 566.61 1482.99 566.61 1483.35" />
                            <path class="cls-1" d="M793.73,1530.18l-231.94-45.87v-.37s-4.79-.96-4.79-.96l6.98-1.48,230.06-45.49,237.35,46.94-5.11,.99v1.84l-4.61-.69-227.93,45.07Zm-218.64-47.05l218.64,43.24,218.93-43.3-218.63-43.24-218.94,43.29Z" />
                          </g>
                          <g className="k1b3base">
                            <polygon className={`k1b3 ${isHovered13 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered13(true)}
                              onMouseLeave={() => setIsHovered13(false)} points="793.73 1528.27 566.61 1483.35 566.61 1573.12 566.38 1573.17 793.5 1618.09 793.5 1528.32 793.73 1528.27" />
                            <path class="cls-1 k1b3path" d="M798.33,1620.94l-241.7-47.8,5.16-.99v-91.66l241.67,47.8-5.13,.99v91.66Zm-226.9-48.69l217.25,42.96v-86.03l-217.24-42.97v86.04Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="1021.44 1483.27 1021.36 1483.26 793.73 1528.27 793.95 1528.32 793.95 1618.17 794.03 1618.19 1021.66 1573.16 1021.44 1573.12 1021.44 1483.27" />
                            <path class="cls-1" d="M793.93,1620.11l-4.8-1.03v-89.8l-4.92-.95,7.1-1.5,229.98-45.48,4.88,.92,.09,.99v88.9s4.76,.96,4.76,.96l-6.96,1.46-230.13,45.52Zm4.84-90.93v86.16l217.83-43.09v-86.15s-217.83,43.08-217.83,43.08Z" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="793.95 1612.74 793.5 1612.5 793.5 1613.56 793.73 1613.69 793.95 1613.56 793.95 1612.74" />
                            <polygon class="cls-1" points="793.95 1619.06 788.68 1616.44 788.68 1604.25 798.33 1609.68 798.78 1615.63 796.25 1617.81 793.95 1619.06" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="793.73 1528.27 793.5 1528.32 793.5 1618.09 793.95 1618.17 793.95 1528.32 793.73 1528.27" />
                            <polygon class="cls-1" points="798.78 1620.99 788.68 1619.05 788.68 1527.35 793.5 1526.42 798.55 1527.31 798.78 1528.32 798.78 1620.99" />
                          </g>
                        </g>
                        <g>
                          <g>
                            <polygon class="cls-2" points="796.61 1529.35 1023.73 1574.27 1251.36 1529.26 1251.44 1529.27 1251.43 1528.99 1251.66 1528.94 1024.03 1483.93 796.38 1528.94 796.61 1528.99 796.61 1529.35" />
                            <path class="cls-1" d="M1023.73,1576.18l-231.94-45.87v-.37s-4.79-.96-4.79-.96l6.98-1.48,230.06-45.49,237.35,46.94-5.11,.99v1.84l-4.61-.69-227.93,45.07Zm-218.64-47.05l218.64,43.24,218.93-43.3-218.63-43.24-218.94,43.29Z" />
                          </g>
                          <g className="k1b4base">
                            <polygon className={`k1b4 ${isHovered14 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered14(true)}
                              onMouseLeave={() => setIsHovered14(false)} points="1023.73 1574.27 796.61 1529.35 796.61 1619.12 796.38 1619.17 1023.5 1664.09 1023.5 1574.32 1023.73 1574.27" />
                            <path class="cls-1 k1b4path" d="M1028.33,1666.94l-241.7-47.8,5.16-.99v-91.66l241.67,47.8-5.13,.99v91.66Zm-226.9-48.69l217.25,42.96v-86.03l-217.24-42.97v86.04Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="1251.44 1529.27 1251.36 1529.26 1023.73 1574.27 1023.95 1574.32 1023.95 1664.17 1024.03 1664.19 1251.66 1619.16 1251.44 1619.12 1251.44 1529.27" />
                            <path class="cls-1" d="M1023.93,1666.11l-4.8-1.03v-89.8l-4.92-.95,7.1-1.5,229.98-45.48,4.88,.92,.09,.99v88.9s4.76,.96,4.76,.96l-6.96,1.46-230.13,45.52Zm4.84-90.93v86.16l217.83-43.09v-86.15s-217.83,43.08-217.83,43.08Z" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1023.95 1658.74 1023.5 1658.5 1023.5 1659.56 1023.73 1659.69 1023.95 1659.56 1023.95 1658.74" />
                            <polygon class="cls-1" points="1023.95 1665.06 1018.68 1662.44 1018.68 1650.25 1028.33 1655.68 1028.78 1661.63 1026.25 1663.81 1023.95 1665.06" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1023.73 1574.27 1023.5 1574.32 1023.5 1664.09 1023.95 1664.17 1023.95 1574.32 1023.73 1574.27" />
                            <polygon class="cls-1" points="1028.78 1666.99 1018.68 1665.05 1018.68 1573.35 1023.5 1572.42 1028.55 1573.31 1028.78 1574.32 1028.78 1666.99" />
                          </g>
                        </g>
                        <g>
                          <g>
                            <polygon class="cls-2" points="1024.61 1574.35 1251.73 1619.27 1479.36 1574.26 1479.44 1574.27 1479.43 1573.99 1479.66 1573.94 1252.03 1528.93 1024.38 1573.94 1024.61 1573.99 1024.61 1574.35" />
                            <path class="cls-1" d="M1251.73,1621.18l-231.94-45.87v-.37s-4.79-.96-4.79-.96l6.98-1.48,230.06-45.49,237.35,46.94-5.11,.99v1.84l-4.61-.69-227.93,45.07Zm-218.64-47.05l218.64,43.24,218.93-43.3-218.63-43.24-218.94,43.29Z" />
                          </g>
                          <g className="k1b5base">
                            <polygon className={`k1b5 ${isHovered15 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered15(true)}
                              onMouseLeave={() => setIsHovered15(false)} points="1251.73 1619.27 1024.61 1574.35 1024.61 1664.12 1024.38 1664.17 1251.5 1709.09 1251.5 1619.32 1251.73 1619.27" />
                            <path class="cls-1 k1b5path" d="M1256.33,1711.94l-241.7-47.8,5.16-.99v-91.66l241.67,47.8-5.13,.99v91.66Zm-226.9-48.69l217.25,42.96v-86.03l-217.24-42.97v86.04Z" />
                          </g>
                          <g className="k1b5base">
                            <polygon className={`k1b5 ${isHovered15 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered15(true)}
                              onMouseLeave={() => setIsHovered15(false)} points="1479.44 1574.27 1479.36 1574.26 1251.73 1619.27 1251.95 1619.32 1251.95 1709.17 1252.03 1709.19 1479.66 1664.16 1479.44 1664.12 1479.44 1574.27" />
                            <path class="cls-1 k1b5path" d="M1251.93,1711.11l-4.8-1.03v-89.8l-4.92-.95,7.1-1.5,229.98-45.48,4.88,.92,.09,.99v88.9s4.76,.96,4.76,.96l-6.96,1.46-230.13,45.52Zm4.84-90.93v86.16l217.83-43.09v-86.15s-217.83,43.08-217.83,43.08Z" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1251.95 1703.74 1251.5 1703.5 1251.5 1704.56 1251.73 1704.69 1251.95 1704.56 1251.95 1703.74" />
                            <polygon class="cls-1" points="1251.95 1710.06 1246.68 1707.44 1246.68 1695.25 1256.33 1700.68 1256.78 1706.63 1254.25 1708.81 1251.95 1710.06" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1251.73 1619.27 1251.5 1619.32 1251.5 1709.09 1251.95 1709.17 1251.95 1619.32 1251.73 1619.27" />
                            <polygon class="cls-1" points="1256.78 1711.99 1246.68 1710.05 1246.68 1618.35 1251.5 1617.42 1256.55 1618.31 1256.78 1619.32 1256.78 1711.99" />
                          </g>
                        </g>
                      </g>
                      <g>
                        <g>
                          <g>
                            <polygon class="cls-2" points="568.61 1298.35 795.73 1343.27 1023.36 1298.26 1023.44 1298.27 1023.43 1297.99 1023.66 1297.94 796.03 1252.93 568.38 1297.94 568.61 1297.99 568.61 1298.35" />
                            <path class="cls-1" d="M795.73,1345.18l-231.94-45.87v-.37s-4.79-.96-4.79-.96l6.98-1.48,230.06-45.49,237.35,46.94-5.11,.99v1.84l-4.61-.69-227.93,45.07Zm-218.64-47.05l218.64,43.24,218.93-43.3-218.63-43.24-218.94,43.29Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="795.73 1343.27 568.61 1298.35 568.61 1388.12 568.38 1388.17 795.5 1433.09 795.5 1343.32 795.73 1343.27" />
                            <path class="cls-1" d="M800.33,1435.94l-241.7-47.8,5.16-.99v-91.66l241.67,47.8-5.13,.99v91.66Zm-226.9-48.69l217.25,42.96v-86.03l-217.24-42.97v86.04Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="1023.44 1298.27 1023.36 1298.26 795.73 1343.27 795.95 1343.32 795.95 1433.17 796.03 1433.19 1023.66 1388.16 1023.44 1388.12 1023.44 1298.27" />
                            <path class="cls-1" d="M795.93,1435.11l-4.8-1.03v-89.8l-4.92-.95,7.1-1.5,229.98-45.48,4.88,.92,.09,.99v88.9s4.76,.96,4.76,.96l-6.96,1.46-230.13,45.52Zm4.84-90.93v86.16l217.83-43.09v-86.15s-217.83,43.08-217.83,43.08Z" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="795.95 1427.74 795.5 1427.5 795.5 1428.56 795.73 1428.69 795.95 1428.56 795.95 1427.74" />
                            <polygon class="cls-1" points="795.95 1434.06 790.68 1431.44 790.68 1419.25 800.33 1424.68 800.78 1430.63 798.25 1432.81 795.95 1434.06" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="795.73 1343.27 795.5 1343.32 795.5 1433.09 795.95 1433.17 795.95 1343.32 795.73 1343.27" />
                            <polygon class="cls-1" points="800.78 1435.99 790.68 1434.05 790.68 1342.35 795.5 1341.42 800.55 1342.31 800.78 1343.32 800.78 1435.99" />
                          </g>
                        </g>
                        <g>
                          <g>
                            <polygon class="cls-2" points="796.61 1343.35 1023.73 1388.27 1251.36 1343.26 1251.44 1343.27 1251.43 1342.99 1251.66 1342.94 1024.03 1297.93 796.38 1342.94 796.61 1342.99 796.61 1343.35" />
                            <path class="cls-1" d="M1023.73,1390.18l-231.94-45.87v-.37s-4.79-.96-4.79-.96l6.98-1.48,230.06-45.49,237.35,46.94-5.11,.99v1.84l-4.61-.69-227.93,45.07Zm-218.64-47.05l218.64,43.24,218.93-43.3-218.63-43.24-218.94,43.29Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="1023.73 1388.27 796.61 1343.35 796.61 1433.12 796.38 1433.17 1023.5 1478.09 1023.5 1388.32 1023.73 1388.27" />
                            <path class="cls-1" d="M1028.33,1480.94l-241.7-47.8,5.16-.99v-91.66l241.67,47.8-5.13,.99v91.66Zm-226.9-48.69l217.25,42.96v-86.03l-217.24-42.97v86.04Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="1251.44 1343.27 1251.36 1343.26 1023.73 1388.27 1023.95 1388.32 1023.95 1478.17 1024.03 1478.19 1251.66 1433.16 1251.44 1433.12 1251.44 1343.27" />
                            <path class="cls-1" d="M1023.93,1480.11l-4.8-1.03v-89.8l-4.92-.95,7.1-1.5,229.98-45.48,4.88,.92,.09,.99v88.9s4.76,.96,4.76,.96l-6.96,1.46-230.13,45.52Zm4.84-90.93v86.16l217.83-43.09v-86.15s-217.83,43.08-217.83,43.08Z" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1023.95 1472.74 1023.5 1472.5 1023.5 1473.56 1023.73 1473.69 1023.95 1473.56 1023.95 1472.74" />
                            <polygon class="cls-1" points="1023.95 1479.06 1018.68 1476.44 1018.68 1464.25 1028.33 1469.68 1028.78 1475.63 1026.25 1477.81 1023.95 1479.06" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1023.73 1388.27 1023.5 1388.32 1023.5 1478.09 1023.95 1478.17 1023.95 1388.32 1023.73 1388.27" />
                            <polygon class="cls-1" points="1028.78 1480.99 1018.68 1479.05 1018.68 1387.35 1023.5 1386.42 1028.55 1387.31 1028.78 1388.32 1028.78 1480.99" />
                          </g>
                        </g>
                        <g>
                          <g>
                            <polygon class="cls-2" points="1026.61 1389.35 1253.73 1434.27 1481.36 1389.26 1481.44 1389.27 1481.43 1388.99 1481.66 1388.94 1254.03 1343.93 1026.38 1388.94 1026.61 1388.99 1026.61 1389.35" />
                            <path class="cls-1" d="M1253.73,1436.18l-231.94-45.87v-.37s-4.79-.96-4.79-.96l6.98-1.48,230.06-45.49,237.35,46.94-5.11,.99v1.84l-4.61-.69-227.93,45.07Zm-218.64-47.05l218.64,43.24,218.93-43.3-218.63-43.24-218.94,43.29Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="1253.73 1434.27 1026.61 1389.35 1026.61 1479.12 1026.38 1479.17 1253.5 1524.09 1253.5 1434.32 1253.73 1434.27" />
                            <path class="cls-1" d="M1258.33,1526.94l-241.7-47.8,5.16-.99v-91.66l241.67,47.8-5.13,.99v91.66Zm-226.9-48.69l217.25,42.96v-86.03l-217.24-42.97v86.04Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="1481.44 1389.27 1481.36 1389.26 1253.73 1434.27 1253.95 1434.32 1253.95 1524.17 1254.03 1524.19 1481.66 1479.16 1481.44 1479.12 1481.44 1389.27" />
                            <path class="cls-1" d="M1253.93,1526.11l-4.8-1.03v-89.8l-4.92-.95,7.1-1.5,229.98-45.48,4.88,.92,.09,.99v88.9s4.76,.96,4.76,.96l-6.96,1.46-230.13,45.52Zm4.84-90.93v86.16l217.83-43.09v-86.15s-217.83,43.08-217.83,43.08Z" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1253.95 1518.74 1253.5 1518.5 1253.5 1519.56 1253.73 1519.69 1253.95 1519.56 1253.95 1518.74" />
                            <polygon class="cls-1" points="1253.95 1525.06 1248.68 1522.44 1248.68 1510.25 1258.33 1515.68 1258.78 1521.63 1256.25 1523.81 1253.95 1525.06" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1253.73 1434.27 1253.5 1434.32 1253.5 1524.09 1253.95 1524.17 1253.95 1434.32 1253.73 1434.27" />
                            <polygon class="cls-1" points="1258.78 1526.99 1248.68 1525.05 1248.68 1433.35 1253.5 1432.42 1258.55 1433.31 1258.78 1434.32 1258.78 1526.99" />
                          </g>
                        </g>
                        <g>
                          <g>
                            <polygon class="cls-2" points="1254.61 1434.35 1481.73 1479.27 1709.36 1434.26 1709.44 1434.27 1709.43 1433.99 1709.66 1433.94 1482.03 1388.93 1254.38 1433.94 1254.61 1433.99 1254.61 1434.35" />
                            <path class="cls-1" d="M1481.73,1481.18l-231.94-45.87v-.37s-4.79-.96-4.79-.96l6.98-1.48,230.06-45.49,237.35,46.94-5.11,.99v1.84l-4.61-.69-227.93,45.07Zm-218.64-47.05l218.64,43.24,218.93-43.3-218.63-43.24-218.94,43.29Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="1481.73 1479.27 1254.61 1434.35 1254.61 1524.12 1254.38 1524.17 1481.5 1569.09 1481.5 1479.32 1481.73 1479.27" />
                            <path class="cls-1" d="M1486.33,1571.94l-241.7-47.8,5.16-.99v-91.66l241.67,47.8-5.13,.99v91.66Zm-226.9-48.69l217.25,42.96v-86.03l-217.24-42.97v86.04Z" />
                          </g>
                          <g className="k2b6base">
                            <polygon className={`k2b6 ${isHovered26 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered26(true)}
                              onMouseLeave={() => setIsHovered26(false)} points="1709.44 1434.27 1709.36 1434.26 1481.73 1479.27 1481.95 1479.32 1481.95 1569.17 1482.03 1569.19 1709.66 1524.16 1709.44 1524.12 1709.44 1434.27" />
                            <path class="cls-1 k2b6path" d="M1481.93,1571.11l-4.8-1.03v-89.8l-4.92-.95,7.1-1.5,229.98-45.48,4.88,.92,.09,.99v88.9s4.76,.96,4.76,.96l-6.96,1.46-230.13,45.52Zm4.84-90.93v86.16l217.83-43.09v-86.15s-217.83,43.08-217.83,43.08Z" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1481.95 1563.74 1481.5 1563.5 1481.5 1564.56 1481.73 1564.69 1481.95 1564.56 1481.95 1563.74" />
                            <polygon class="cls-1" points="1481.95 1570.06 1476.68 1567.44 1476.68 1555.25 1486.33 1560.68 1486.78 1566.63 1484.25 1568.81 1481.95 1570.06" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1481.73 1479.27 1481.5 1479.32 1481.5 1569.09 1481.95 1569.17 1481.95 1479.32 1481.73 1479.27" />
                            <polygon class="cls-1" points="1486.78 1571.99 1476.68 1570.05 1476.68 1478.35 1481.5 1477.42 1486.55 1478.31 1486.78 1479.32 1486.78 1571.99" />
                          </g>
                        </g>
                        <g>
                          <g>
                            <polygon class="cls-2" points="338.61 1344.35 565.73 1389.27 793.36 1344.26 793.44 1344.27 793.43 1343.99 793.66 1343.94 566.03 1298.93 338.38 1343.94 338.61 1343.99 338.61 1344.35" />
                            <path class="cls-1" d="M565.73,1391.18l-231.94-45.87v-.37s-4.79-.96-4.79-.96l6.98-1.48,230.06-45.49,237.35,46.94-5.11,.99v1.84l-4.61-.69-227.93,45.07Zm-218.64-47.05l218.64,43.24,218.93-43.3-218.63-43.24-218.94,43.29Z" />
                          </g>
                          <g className="k2b2base">
                            <polygon className={`k2b2 ${isHovered22 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered22(true)}
                              onMouseLeave={() => setIsHovered22(false)} points="565.73 1389.27 338.61 1344.35 338.61 1434.12 338.38 1434.17 565.5 1479.09 565.5 1389.32 565.73 1389.27" />
                            <path class="cls-1 k2b2path" d="M570.33,1481.94l-241.7-47.8,5.16-.99v-91.66l241.67,47.8-5.13,.99v91.66Zm-226.9-48.69l217.25,42.96v-86.03l-217.24-42.97v86.04Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="793.44 1344.27 793.36 1344.26 565.73 1389.27 565.95 1389.32 565.95 1479.17 566.03 1479.19 793.66 1434.16 793.44 1434.12 793.44 1344.27" />
                            <path class="cls-1" d="M565.93,1481.11l-4.8-1.03v-89.8l-4.92-.95,7.1-1.5,229.98-45.48,4.88,.92,.09,.99v88.9s4.76,.96,4.76,.96l-6.96,1.46-230.13,45.52Zm4.84-90.93v86.16l217.83-43.09v-86.15s-217.83,43.08-217.83,43.08Z" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="565.95 1473.74 565.5 1473.5 565.5 1474.56 565.73 1474.69 565.95 1474.56 565.95 1473.74" />
                            <polygon class="cls-1" points="565.95 1480.06 560.68 1477.44 560.68 1465.25 570.33 1470.68 570.78 1476.63 568.25 1478.81 565.95 1480.06" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="565.73 1389.27 565.5 1389.32 565.5 1479.09 565.95 1479.17 565.95 1389.32 565.73 1389.27" />
                            <polygon class="cls-1" points="570.78 1481.99 560.68 1480.05 560.68 1388.35 565.5 1387.42 570.55 1388.31 570.78 1389.32 570.78 1481.99" />
                          </g>
                        </g>
                        <g>
                          <g>
                            <polygon class="cls-2" points="566.61 1389.35 793.73 1434.27 1021.36 1389.26 1021.44 1389.27 1021.43 1388.99 1021.66 1388.94 794.03 1343.93 566.38 1388.94 566.61 1388.99 566.61 1389.35" />
                            <path class="cls-1" d="M793.73,1436.18l-231.94-45.87v-.37s-4.79-.96-4.79-.96l6.98-1.48,230.06-45.49,237.35,46.94-5.11,.99v1.84l-4.61-.69-227.93,45.07Zm-218.64-47.05l218.64,43.24,218.93-43.3-218.63-43.24-218.94,43.29Z" />
                          </g>
                          <g className="k2b3base">
                            <polygon className={`k2b3 ${isHovered23 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered23(true)}
                              onMouseLeave={() => setIsHovered23(false)} points="793.73 1434.27 566.61 1389.35 566.61 1479.12 566.38 1479.17 793.5 1524.09 793.5 1434.32 793.73 1434.27" />
                            <path class="cls-1 k2b3path" d="M798.33,1526.94l-241.7-47.8,5.16-.99v-91.66l241.67,47.8-5.13,.99v91.66Zm-226.9-48.69l217.25,42.96v-86.03l-217.24-42.97v86.04Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="1021.44 1389.27 1021.36 1389.26 793.73 1434.27 793.95 1434.32 793.95 1524.17 794.03 1524.19 1021.66 1479.16 1021.44 1479.12 1021.44 1389.27" />
                            <path class="cls-1" d="M793.93,1526.11l-4.8-1.03v-89.8l-4.92-.95,7.1-1.5,229.98-45.48,4.88,.92,.09,.99v88.9s4.76,.96,4.76,.96l-6.96,1.46-230.13,45.52Zm4.84-90.93v86.16l217.83-43.09v-86.15s-217.83,43.08-217.83,43.08Z" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="793.95 1518.74 793.5 1518.5 793.5 1519.56 793.73 1519.69 793.95 1519.56 793.95 1518.74" />
                            <polygon class="cls-1" points="793.95 1525.06 788.68 1522.44 788.68 1510.25 798.33 1515.68 798.78 1521.63 796.25 1523.81 793.95 1525.06" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="793.73 1434.27 793.5 1434.32 793.5 1524.09 793.95 1524.17 793.95 1434.32 793.73 1434.27" />
                            <polygon class="cls-1" points="798.78 1526.99 788.68 1525.05 788.68 1433.35 793.5 1432.42 798.55 1433.31 798.78 1434.32 798.78 1526.99" />
                          </g>
                        </g>
                        <g>
                          <g>
                            <polygon class="cls-2" points="796.61 1435.35 1023.73 1480.27 1251.36 1435.26 1251.44 1435.27 1251.43 1434.99 1251.66 1434.94 1024.03 1389.93 796.38 1434.94 796.61 1434.99 796.61 1435.35" />
                            <path class="cls-1" d="M1023.73,1482.18l-231.94-45.87v-.37s-4.79-.96-4.79-.96l6.98-1.48,230.06-45.49,237.35,46.94-5.11,.99v1.84l-4.61-.69-227.93,45.07Zm-218.64-47.05l218.64,43.24,218.93-43.3-218.63-43.24-218.94,43.29Z" />
                          </g>
                          <g className="k2b4base">
                            <polygon className={`k2b4 ${isHovered24 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered24(true)}
                              onMouseLeave={() => setIsHovered24(false)} points="1023.73 1480.27 796.61 1435.35 796.61 1525.12 796.38 1525.17 1023.5 1570.09 1023.5 1480.32 1023.73 1480.27" />
                            <path class="cls-1 k2b4path" d="M1028.33,1572.94l-241.7-47.8,5.16-.99v-91.66l241.67,47.8-5.13,.99v91.66Zm-226.9-48.69l217.25,42.96v-86.03l-217.24-42.97v86.04Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="1251.44 1435.27 1251.36 1435.26 1023.73 1480.27 1023.95 1480.32 1023.95 1570.17 1024.03 1570.19 1251.66 1525.16 1251.44 1525.12 1251.44 1435.27" />
                            <path class="cls-1" d="M1023.93,1572.11l-4.8-1.03v-89.8l-4.92-.95,7.1-1.5,229.98-45.48,4.88,.92,.09,.99v88.9s4.76,.96,4.76,.96l-6.96,1.46-230.13,45.52Zm4.84-90.93v86.16l217.83-43.09v-86.15s-217.83,43.08-217.83,43.08Z" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1023.95 1564.74 1023.5 1564.5 1023.5 1565.56 1023.73 1565.69 1023.95 1565.56 1023.95 1564.74" />
                            <polygon class="cls-1" points="1023.95 1571.06 1018.68 1568.44 1018.68 1556.25 1028.33 1561.68 1028.78 1567.63 1026.25 1569.81 1023.95 1571.06" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1023.73 1480.27 1023.5 1480.32 1023.5 1570.09 1023.95 1570.17 1023.95 1480.32 1023.73 1480.27" />
                            <polygon class="cls-1" points="1028.78 1572.99 1018.68 1571.05 1018.68 1479.35 1023.5 1478.42 1028.55 1479.31 1028.78 1480.32 1028.78 1572.99" />
                          </g>
                        </g>
                        <g>
                          <g>
                            <polygon class="cls-2" points="1024.61 1480.35 1251.73 1525.27 1479.36 1480.26 1479.44 1480.27 1479.43 1479.99 1479.66 1479.94 1252.03 1434.93 1024.38 1479.94 1024.61 1479.99 1024.61 1480.35" />
                            <path class="cls-1" d="M1251.73,1527.18l-231.94-45.87v-.37s-4.79-.96-4.79-.96l6.98-1.48,230.06-45.49,237.35,46.94-5.11,.99v1.84l-4.61-.69-227.93,45.07Zm-218.64-47.05l218.64,43.24,218.93-43.3-218.63-43.24-218.94,43.29Z" />
                          </g>
                          <g className="k2b5base">
                            <polygon className={`k2b5 ${isHovered25 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered25(true)}
                              onMouseLeave={() => setIsHovered25(false)} points="1251.73 1525.27 1024.61 1480.35 1024.61 1570.12 1024.38 1570.17 1251.5 1615.09 1251.5 1525.32 1251.73 1525.27" />
                            <path class="cls-1 k2b5path" d="M1256.33,1617.94l-241.7-47.8,5.16-.99v-91.66l241.67,47.8-5.13,.99v91.66Zm-226.9-48.69l217.25,42.96v-86.03l-217.24-42.97v86.04Z" />
                          </g>
                          <g className="k2b5base">
                            <polygon className={`k2b5 ${isHovered25 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered25(true)}
                              onMouseLeave={() => setIsHovered25(false)} points="1479.44 1480.27 1479.36 1480.26 1251.73 1525.27 1251.95 1525.32 1251.95 1615.17 1252.03 1615.19 1479.66 1570.16 1479.44 1570.12 1479.44 1480.27" />
                            <path class="cls-1 k2b5path" d="M1251.93,1617.11l-4.8-1.03v-89.8l-4.92-.95,7.1-1.5,229.98-45.48,4.88,.92,.09,.99v88.9s4.76,.96,4.76,.96l-6.96,1.46-230.13,45.52Zm4.84-90.93v86.16l217.83-43.09v-86.15s-217.83,43.08-217.83,43.08Z" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1251.95 1609.74 1251.5 1609.5 1251.5 1610.56 1251.73 1610.69 1251.95 1610.56 1251.95 1609.74" />
                            <polygon class="cls-1" points="1251.95 1616.06 1246.68 1613.44 1246.68 1601.25 1256.33 1606.68 1256.78 1612.63 1254.25 1614.81 1251.95 1616.06" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1251.73 1525.27 1251.5 1525.32 1251.5 1615.09 1251.95 1615.17 1251.95 1525.32 1251.73 1525.27" />
                            <polygon class="cls-1" points="1256.78 1617.99 1246.68 1616.05 1246.68 1524.35 1251.5 1523.42 1256.55 1524.31 1256.78 1525.32 1256.78 1617.99" />
                          </g>
                        </g>
                      </g>
                      <g>
                        <g>
                          <g>
                            <polygon class="cls-2" points="568.61 1204.35 795.73 1249.27 1023.36 1204.26 1023.44 1204.27 1023.43 1203.99 1023.66 1203.94 796.03 1158.93 568.38 1203.94 568.61 1203.99 568.61 1204.35" />
                            <path class="cls-1" d="M795.73,1251.18l-231.94-45.87v-.37s-4.79-.96-4.79-.96l6.98-1.48,230.06-45.49,237.35,46.94-5.11,.99v1.84l-4.61-.69-227.93,45.07Zm-218.64-47.05l218.64,43.24,218.93-43.3-218.63-43.24-218.94,43.29Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="795.73 1249.27 568.61 1204.35 568.61 1294.12 568.38 1294.17 795.5 1339.09 795.5 1249.32 795.73 1249.27" />
                            <path class="cls-1" d="M800.33,1341.94l-241.7-47.8,5.16-.99v-91.66l241.67,47.8-5.13,.99v91.66Zm-226.9-48.69l217.25,42.96v-86.03l-217.24-42.97v86.04Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="1023.44 1204.27 1023.36 1204.26 795.73 1249.27 795.95 1249.32 795.95 1339.17 796.03 1339.19 1023.66 1294.16 1023.44 1294.12 1023.44 1204.27" />
                            <path class="cls-1" d="M795.93,1341.11l-4.8-1.03v-89.8l-4.92-.95,7.1-1.5,229.98-45.48,4.88,.92,.09,.99v88.9s4.76,.96,4.76,.96l-6.96,1.46-230.13,45.52Zm4.84-90.93v86.16l217.83-43.09v-86.15s-217.83,43.08-217.83,43.08Z" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="795.95 1333.74 795.5 1333.5 795.5 1334.56 795.73 1334.69 795.95 1334.56 795.95 1333.74" />
                            <polygon class="cls-1" points="795.95 1340.06 790.68 1337.44 790.68 1325.25 800.33 1330.68 800.78 1336.63 798.25 1338.81 795.95 1340.06" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="795.73 1249.27 795.5 1249.32 795.5 1339.09 795.95 1339.17 795.95 1249.32 795.73 1249.27" />
                            <polygon class="cls-1" points="800.78 1341.99 790.68 1340.05 790.68 1248.35 795.5 1247.42 800.55 1248.31 800.78 1249.32 800.78 1341.99" />
                          </g>
                        </g>
                        <g>
                          <g>
                            <polygon class="cls-2" points="796.61 1249.35 1023.73 1294.27 1251.36 1249.26 1251.44 1249.27 1251.43 1248.99 1251.66 1248.94 1024.03 1203.93 796.38 1248.94 796.61 1248.99 796.61 1249.35" />
                            <path class="cls-1" d="M1023.73,1296.18l-231.94-45.87v-.37s-4.79-.96-4.79-.96l6.98-1.48,230.06-45.49,237.35,46.94-5.11,.99v1.84l-4.61-.69-227.93,45.07Zm-218.64-47.05l218.64,43.24,218.93-43.3-218.63-43.24-218.94,43.29Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="1023.73 1294.27 796.61 1249.35 796.61 1339.12 796.38 1339.17 1023.5 1384.09 1023.5 1294.32 1023.73 1294.27" />
                            <path class="cls-1" d="M1028.33,1386.94l-241.7-47.8,5.16-.99v-91.66l241.67,47.8-5.13,.99v91.66Zm-226.9-48.69l217.25,42.96v-86.03l-217.24-42.97v86.04Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="1251.44 1249.27 1251.36 1249.26 1023.73 1294.27 1023.95 1294.32 1023.95 1384.17 1024.03 1384.19 1251.66 1339.16 1251.44 1339.12 1251.44 1249.27" />
                            <path class="cls-1" d="M1023.93,1386.11l-4.8-1.03v-89.8l-4.92-.95,7.1-1.5,229.98-45.48,4.88,.92,.09,.99v88.9s4.76,.96,4.76,.96l-6.96,1.46-230.13,45.52Zm4.84-90.93v86.16l217.83-43.09v-86.15s-217.83,43.08-217.83,43.08Z" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1023.95 1378.74 1023.5 1378.5 1023.5 1379.56 1023.73 1379.69 1023.95 1379.56 1023.95 1378.74" />
                            <polygon class="cls-1" points="1023.95 1385.06 1018.68 1382.44 1018.68 1370.25 1028.33 1375.68 1028.78 1381.63 1026.25 1383.81 1023.95 1385.06" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1023.73 1294.27 1023.5 1294.32 1023.5 1384.09 1023.95 1384.17 1023.95 1294.32 1023.73 1294.27" />
                            <polygon class="cls-1" points="1028.78 1386.99 1018.68 1385.05 1018.68 1293.35 1023.5 1292.42 1028.55 1293.31 1028.78 1294.32 1028.78 1386.99" />
                          </g>
                        </g>
                        <g>
                          <g>
                            <polygon class="cls-2" points="1026.61 1295.35 1253.73 1340.27 1481.36 1295.26 1481.44 1295.27 1481.43 1294.99 1481.66 1294.94 1254.03 1249.93 1026.38 1294.94 1026.61 1294.99 1026.61 1295.35" />
                            <path class="cls-1" d="M1253.73,1342.18l-231.94-45.87v-.37s-4.79-.96-4.79-.96l6.98-1.48,230.06-45.49,237.35,46.94-5.11,.99v1.84l-4.61-.69-227.93,45.07Zm-218.64-47.05l218.64,43.24,218.93-43.3-218.63-43.24-218.94,43.29Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="1253.73 1340.27 1026.61 1295.35 1026.61 1385.12 1026.38 1385.17 1253.5 1430.09 1253.5 1340.32 1253.73 1340.27" />
                            <path class="cls-1" d="M1258.33,1432.94l-241.7-47.8,5.16-.99v-91.66l241.67,47.8-5.13,.99v91.66Zm-226.9-48.69l217.25,42.96v-86.03l-217.24-42.97v86.04Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="1481.44 1295.27 1481.36 1295.26 1253.73 1340.27 1253.95 1340.32 1253.95 1430.17 1254.03 1430.19 1481.66 1385.16 1481.44 1385.12 1481.44 1295.27" />
                            <path class="cls-1" d="M1253.93,1432.11l-4.8-1.03v-89.8l-4.92-.95,7.1-1.5,229.98-45.48,4.88,.92,.09,.99v88.9s4.76,.96,4.76,.96l-6.96,1.46-230.13,45.52Zm4.84-90.93v86.16l217.83-43.09v-86.15s-217.83,43.08-217.83,43.08Z" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1253.95 1424.74 1253.5 1424.5 1253.5 1425.56 1253.73 1425.69 1253.95 1425.56 1253.95 1424.74" />
                            <polygon class="cls-1" points="1253.95 1431.06 1248.68 1428.44 1248.68 1416.25 1258.33 1421.68 1258.78 1427.63 1256.25 1429.81 1253.95 1431.06" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1253.73 1340.27 1253.5 1340.32 1253.5 1430.09 1253.95 1430.17 1253.95 1340.32 1253.73 1340.27" />
                            <polygon class="cls-1" points="1258.78 1432.99 1248.68 1431.05 1248.68 1339.35 1253.5 1338.42 1258.55 1339.31 1258.78 1340.32 1258.78 1432.99" />
                          </g>
                        </g>
                        <g>
                          <g>
                            <polygon class="cls-2" points="1254.61 1340.35 1481.73 1385.27 1709.36 1340.26 1709.44 1340.27 1709.43 1339.99 1709.66 1339.94 1482.03 1294.93 1254.38 1339.94 1254.61 1339.99 1254.61 1340.35" />
                            <path class="cls-1" d="M1481.73,1387.18l-231.94-45.87v-.37s-4.79-.96-4.79-.96l6.98-1.48,230.06-45.49,237.35,46.94-5.11,.99v1.84l-4.61-.69-227.93,45.07Zm-218.64-47.05l218.64,43.24,218.93-43.3-218.63-43.24-218.94,43.29Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="1481.73 1385.27 1254.61 1340.35 1254.61 1430.12 1254.38 1430.17 1481.5 1475.09 1481.5 1385.32 1481.73 1385.27" />
                            <path class="cls-1" d="M1486.33,1477.94l-241.7-47.8,5.16-.99v-91.66l241.67,47.8-5.13,.99v91.66Zm-226.9-48.69l217.25,42.96v-86.03l-217.24-42.97v86.04Z" />
                          </g>
                          <g className="k3b6base">
                            <polygon className={`k3b6 ${isHovered36 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered36(true)}
                              onMouseLeave={() => setIsHovered36(false)} points="1709.44 1340.27 1709.36 1340.26 1481.73 1385.27 1481.95 1385.32 1481.95 1475.17 1482.03 1475.19 1709.66 1430.16 1709.44 1430.12 1709.44 1340.27" />
                            <path class="cls-1 k3b6path" d="M1481.93,1477.11l-4.8-1.03v-89.8l-4.92-.95,7.1-1.5,229.98-45.48,4.88,.92,.09,.99v88.9s4.76,.96,4.76,.96l-6.96,1.46-230.13,45.52Zm4.84-90.93v86.16l217.83-43.09v-86.15s-217.83,43.08-217.83,43.08Z" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1481.95 1469.74 1481.5 1469.5 1481.5 1470.56 1481.73 1470.69 1481.95 1470.56 1481.95 1469.74" />
                            <polygon class="cls-1" points="1481.95 1476.06 1476.68 1473.44 1476.68 1461.25 1486.33 1466.68 1486.78 1472.63 1484.25 1474.81 1481.95 1476.06" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1481.73 1385.27 1481.5 1385.32 1481.5 1475.09 1481.95 1475.17 1481.95 1385.32 1481.73 1385.27" />
                            <polygon class="cls-1" points="1486.78 1477.99 1476.68 1476.05 1476.68 1384.35 1481.5 1383.42 1486.55 1384.31 1486.78 1385.32 1486.78 1477.99" />
                          </g>
                        </g>
                        <g>
                          <g>
                            <polygon class="cls-2" points="338.61 1250.35 565.73 1295.27 793.36 1250.26 793.44 1250.27 793.43 1249.99 793.66 1249.94 566.03 1204.93 338.38 1249.94 338.61 1249.99 338.61 1250.35" />
                            <path class="cls-1" d="M565.73,1297.18l-231.94-45.87v-.37s-4.79-.96-4.79-.96l6.98-1.48,230.06-45.49,237.35,46.94-5.11,.99v1.84l-4.61-.69-227.93,45.07Zm-218.64-47.05l218.64,43.24,218.93-43.3-218.63-43.24-218.94,43.29Z" />
                          </g>
                          <g className="k3b2base">
                            <polygon className={`k3b2 ${isHovered32 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered32(true)}
                              onMouseLeave={() => setIsHovered32(false)} points="565.73 1295.27 338.61 1250.35 338.61 1340.12 338.38 1340.17 565.5 1385.09 565.5 1295.32 565.73 1295.27" />
                            <path class="cls-1 k3b2path" d="M570.33,1387.94l-241.7-47.8,5.16-.99v-91.66l241.67,47.8-5.13,.99v91.66Zm-226.9-48.69l217.25,42.96v-86.03l-217.24-42.97v86.04Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="793.44 1250.27 793.36 1250.26 565.73 1295.27 565.95 1295.32 565.95 1385.17 566.03 1385.19 793.66 1340.16 793.44 1340.12 793.44 1250.27" />
                            <path class="cls-1" d="M565.93,1387.11l-4.8-1.03v-89.8l-4.92-.95,7.1-1.5,229.98-45.48,4.88,.92,.09,.99v88.9s4.76,.96,4.76,.96l-6.96,1.46-230.13,45.52Zm4.84-90.93v86.16l217.83-43.09v-86.15s-217.83,43.08-217.83,43.08Z" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="565.95 1379.74 565.5 1379.5 565.5 1380.56 565.73 1380.69 565.95 1380.56 565.95 1379.74" />
                            <polygon class="cls-1" points="565.95 1386.06 560.68 1383.44 560.68 1371.25 570.33 1376.68 570.78 1382.63 568.25 1384.81 565.95 1386.06" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="565.73 1295.27 565.5 1295.32 565.5 1385.09 565.95 1385.17 565.95 1295.32 565.73 1295.27" />
                            <polygon class="cls-1" points="570.78 1387.99 560.68 1386.05 560.68 1294.35 565.5 1293.42 570.55 1294.31 570.78 1295.32 570.78 1387.99" />
                          </g>
                        </g>
                        <g>
                          <g>
                            <polygon class="cls-2" points="566.61 1295.35 793.73 1340.27 1021.36 1295.26 1021.44 1295.27 1021.43 1294.99 1021.66 1294.94 794.03 1249.93 566.38 1294.94 566.61 1294.99 566.61 1295.35" />
                            <path class="cls-1" d="M793.73,1342.18l-231.94-45.87v-.37s-4.79-.96-4.79-.96l6.98-1.48,230.06-45.49,237.35,46.94-5.11,.99v1.84l-4.61-.69-227.93,45.07Zm-218.64-47.05l218.64,43.24,218.93-43.3-218.63-43.24-218.94,43.29Z" />
                          </g>
                          <g className="k3b3base">
                            <polygon className={`k3b3 ${isHovered33 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered33(true)}
                              onMouseLeave={() => setIsHovered33(false)} points="793.73 1340.27 566.61 1295.35 566.61 1385.12 566.38 1385.17 793.5 1430.09 793.5 1340.32 793.73 1340.27" />
                            <path class="cls-1 k3b3path" d="M798.33,1432.94l-241.7-47.8,5.16-.99v-91.66l241.67,47.8-5.13,.99v91.66Zm-226.9-48.69l217.25,42.96v-86.03l-217.24-42.97v86.04Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="1021.44 1295.27 1021.36 1295.26 793.73 1340.27 793.95 1340.32 793.95 1430.17 794.03 1430.19 1021.66 1385.16 1021.44 1385.12 1021.44 1295.27" />
                            <path class="cls-1" d="M793.93,1432.11l-4.8-1.03v-89.8l-4.92-.95,7.1-1.5,229.98-45.48,4.88,.92,.09,.99v88.9s4.76,.96,4.76,.96l-6.96,1.46-230.13,45.52Zm4.84-90.93v86.16l217.83-43.09v-86.15s-217.83,43.08-217.83,43.08Z" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="793.95 1424.74 793.5 1424.5 793.5 1425.56 793.73 1425.69 793.95 1425.56 793.95 1424.74" />
                            <polygon class="cls-1" points="793.95 1431.06 788.68 1428.44 788.68 1416.25 798.33 1421.68 798.78 1427.63 796.25 1429.81 793.95 1431.06" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="793.73 1340.27 793.5 1340.32 793.5 1430.09 793.95 1430.17 793.95 1340.32 793.73 1340.27" />
                            <polygon class="cls-1" points="798.78 1432.99 788.68 1431.05 788.68 1339.35 793.5 1338.42 798.55 1339.31 798.78 1340.32 798.78 1432.99" />
                          </g>
                        </g>
                        <g>
                          <g>
                            <polygon class="cls-2" points="796.61 1341.35 1023.73 1386.27 1251.36 1341.26 1251.44 1341.27 1251.43 1340.99 1251.66 1340.94 1024.03 1295.93 796.38 1340.94 796.61 1340.99 796.61 1341.35" />
                            <path class="cls-1" d="M1023.73,1388.18l-231.94-45.87v-.37s-4.79-.96-4.79-.96l6.98-1.48,230.06-45.49,237.35,46.94-5.11,.99v1.84l-4.61-.69-227.93,45.07Zm-218.64-47.05l218.64,43.24,218.93-43.3-218.63-43.24-218.94,43.29Z" />
                          </g>
                          <g className="k3b4base">
                            <polygon className={`k3b4 ${isHovered34 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered34(true)}
                              onMouseLeave={() => setIsHovered34(false)} points="1023.73 1386.27 796.61 1341.35 796.61 1431.12 796.38 1431.17 1023.5 1476.09 1023.5 1386.32 1023.73 1386.27" />
                            <path class="cls-1 k3b4path" d="M1028.33,1478.94l-241.7-47.8,5.16-.99v-91.66l241.67,47.8-5.13,.99v91.66Zm-226.9-48.69l217.25,42.96v-86.03l-217.24-42.97v86.04Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="1251.44 1341.27 1251.36 1341.26 1023.73 1386.27 1023.95 1386.32 1023.95 1476.17 1024.03 1476.19 1251.66 1431.16 1251.44 1431.12 1251.44 1341.27" />
                            <path class="cls-1" d="M1023.93,1478.11l-4.8-1.03v-89.8l-4.92-.95,7.1-1.5,229.98-45.48,4.88,.92,.09,.99v88.9s4.76,.96,4.76,.96l-6.96,1.46-230.13,45.52Zm4.84-90.93v86.16l217.83-43.09v-86.15s-217.83,43.08-217.83,43.08Z" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1023.95 1470.74 1023.5 1470.5 1023.5 1471.56 1023.73 1471.69 1023.95 1471.56 1023.95 1470.74" />
                            <polygon class="cls-1" points="1023.95 1477.06 1018.68 1474.44 1018.68 1462.25 1028.33 1467.68 1028.78 1473.63 1026.25 1475.81 1023.95 1477.06" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1023.73 1386.27 1023.5 1386.32 1023.5 1476.09 1023.95 1476.17 1023.95 1386.32 1023.73 1386.27" />
                            <polygon class="cls-1" points="1028.78 1478.99 1018.68 1477.05 1018.68 1385.35 1023.5 1384.42 1028.55 1385.31 1028.78 1386.32 1028.78 1478.99" />
                          </g>
                        </g>
                        <g>
                          <g>
                            <polygon class="cls-2" points="1024.61 1386.35 1251.73 1431.27 1479.36 1386.26 1479.44 1386.27 1479.43 1385.99 1479.66 1385.94 1252.03 1340.93 1024.38 1385.94 1024.61 1385.99 1024.61 1386.35" />
                            <path class="cls-1" d="M1251.73,1433.18l-231.94-45.87v-.37s-4.79-.96-4.79-.96l6.98-1.48,230.06-45.49,237.35,46.94-5.11,.99v1.84l-4.61-.69-227.93,45.07Zm-218.64-47.05l218.64,43.24,218.93-43.3-218.63-43.24-218.94,43.29Z" />
                          </g>
                          <g className="k3b5base">
                            <polygon className={`k3b5 ${isHovered35 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered35(true)}
                              onMouseLeave={() => setIsHovered35(false)} points="1251.73 1431.27 1024.61 1386.35 1024.61 1476.12 1024.38 1476.17 1251.5 1521.09 1251.5 1431.32 1251.73 1431.27" />
                            <path class="cls-1 k3b5path" d="M1256.33,1523.94l-241.7-47.8,5.16-.99v-91.66l241.67,47.8-5.13,.99v91.66Zm-226.9-48.69l217.25,42.96v-86.03l-217.24-42.97v86.04Z" />
                          </g>
                          <g className="k3b5base">
                            <polygon className={`k3b5 ${isHovered35 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered35(true)}
                              onMouseLeave={() => setIsHovered35(false)} points="1479.44 1386.27 1479.36 1386.26 1251.73 1431.27 1251.95 1431.32 1251.95 1521.17 1252.03 1521.19 1479.66 1476.16 1479.44 1476.12 1479.44 1386.27" />
                            <path class="cls-1 k3b5path" d="M1251.93,1523.11l-4.8-1.03v-89.8l-4.92-.95,7.1-1.5,229.98-45.48,4.88,.92,.09,.99v88.9s4.76,.96,4.76,.96l-6.96,1.46-230.13,45.52Zm4.84-90.93v86.16l217.83-43.09v-86.15s-217.83,43.08-217.83,43.08Z" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1251.95 1515.74 1251.5 1515.5 1251.5 1516.56 1251.73 1516.69 1251.95 1516.56 1251.95 1515.74" />
                            <polygon class="cls-1" points="1251.95 1522.06 1246.68 1519.44 1246.68 1507.25 1256.33 1512.68 1256.78 1518.63 1254.25 1520.81 1251.95 1522.06" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1251.73 1431.27 1251.5 1431.32 1251.5 1521.09 1251.95 1521.17 1251.95 1431.32 1251.73 1431.27" />
                            <polygon class="cls-1" points="1256.78 1523.99 1246.68 1522.05 1246.68 1430.35 1251.5 1429.42 1256.55 1430.31 1256.78 1431.32 1256.78 1523.99" />
                          </g>
                        </g>
                      </g>
                      <g>
                        <g>
                          <g>
                            <polygon class="cls-2" points="568.61 1110.35 795.73 1155.27 1023.36 1110.26 1023.44 1110.27 1023.43 1109.99 1023.66 1109.94 796.03 1064.93 568.38 1109.94 568.61 1109.99 568.61 1110.35" />
                            <path class="cls-1" d="M795.73,1157.18l-231.94-45.87v-.37s-4.79-.96-4.79-.96l6.98-1.48,230.06-45.49,237.35,46.94-5.11,.99v1.84l-4.61-.69-227.93,45.07Zm-218.64-47.05l218.64,43.24,218.93-43.3-218.63-43.24-218.94,43.29Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="795.73 1155.27 568.61 1110.35 568.61 1200.12 568.38 1200.17 795.5 1245.09 795.5 1155.32 795.73 1155.27" />
                            <path class="cls-1" d="M800.33,1247.94l-241.7-47.8,5.16-.99v-91.66l241.67,47.8-5.13,.99v91.66Zm-226.9-48.69l217.25,42.96v-86.03l-217.24-42.97v86.04Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="1023.44 1110.27 1023.36 1110.26 795.73 1155.27 795.95 1155.32 795.95 1245.17 796.03 1245.19 1023.66 1200.16 1023.44 1200.12 1023.44 1110.27" />
                            <path class="cls-1" d="M795.93,1247.11l-4.8-1.03v-89.8l-4.92-.95,7.1-1.5,229.98-45.48,4.88,.92,.09,.99v88.9s4.76,.96,4.76,.96l-6.96,1.46-230.13,45.52Zm4.84-90.93v86.16l217.83-43.09v-86.15s-217.83,43.08-217.83,43.08Z" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="795.95 1239.74 795.5 1239.5 795.5 1240.56 795.73 1240.69 795.95 1240.56 795.95 1239.74" />
                            <polygon class="cls-1" points="795.95 1246.06 790.68 1243.44 790.68 1231.25 800.33 1236.68 800.78 1242.63 798.25 1244.81 795.95 1246.06" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="795.73 1155.27 795.5 1155.32 795.5 1245.09 795.95 1245.17 795.95 1155.32 795.73 1155.27" />
                            <polygon class="cls-1" points="800.78 1247.99 790.68 1246.05 790.68 1154.35 795.5 1153.42 800.55 1154.31 800.78 1155.32 800.78 1247.99" />
                          </g>
                        </g>
                        <g>
                          <g>
                            <polygon class="cls-2" points="796.61 1155.35 1023.73 1200.27 1251.36 1155.26 1251.44 1155.27 1251.43 1154.99 1251.66 1154.94 1024.03 1109.93 796.38 1154.94 796.61 1154.99 796.61 1155.35" />
                            <path class="cls-1" d="M1023.73,1202.18l-231.94-45.87v-.37s-4.79-.96-4.79-.96l6.98-1.48,230.06-45.49,237.35,46.94-5.11,.99v1.84l-4.61-.69-227.93,45.07Zm-218.64-47.05l218.64,43.24,218.93-43.3-218.63-43.24-218.94,43.29Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="1023.73 1200.27 796.61 1155.35 796.61 1245.12 796.38 1245.17 1023.5 1290.09 1023.5 1200.32 1023.73 1200.27" />
                            <path class="cls-1" d="M1028.33,1292.94l-241.7-47.8,5.16-.99v-91.66l241.67,47.8-5.13,.99v91.66Zm-226.9-48.69l217.25,42.96v-86.03l-217.24-42.97v86.04Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="1251.44 1155.27 1251.36 1155.26 1023.73 1200.27 1023.95 1200.32 1023.95 1290.17 1024.03 1290.19 1251.66 1245.16 1251.44 1245.12 1251.44 1155.27" />
                            <path class="cls-1" d="M1023.93,1292.11l-4.8-1.03v-89.8l-4.92-.95,7.1-1.5,229.98-45.48,4.88,.92,.09,.99v88.9s4.76,.96,4.76,.96l-6.96,1.46-230.13,45.52Zm4.84-90.93v86.16l217.83-43.09v-86.15s-217.83,43.08-217.83,43.08Z" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1023.95 1284.74 1023.5 1284.5 1023.5 1285.56 1023.73 1285.69 1023.95 1285.56 1023.95 1284.74" />
                            <polygon class="cls-1" points="1023.95 1291.06 1018.68 1288.44 1018.68 1276.25 1028.33 1281.68 1028.78 1287.63 1026.25 1289.81 1023.95 1291.06" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1023.73 1200.27 1023.5 1200.32 1023.5 1290.09 1023.95 1290.17 1023.95 1200.32 1023.73 1200.27" />
                            <polygon class="cls-1" points="1028.78 1292.99 1018.68 1291.05 1018.68 1199.35 1023.5 1198.42 1028.55 1199.31 1028.78 1200.32 1028.78 1292.99" />
                          </g>
                        </g>
                        <g>
                          <g>
                            <polygon class="cls-2" points="1026.61 1201.35 1253.73 1246.27 1481.36 1201.26 1481.44 1201.27 1481.43 1200.99 1481.66 1200.94 1254.03 1155.93 1026.38 1200.94 1026.61 1200.99 1026.61 1201.35" />
                            <path class="cls-1" d="M1253.73,1248.18l-231.94-45.87v-.37s-4.79-.96-4.79-.96l6.98-1.48,230.06-45.49,237.35,46.94-5.11,.99v1.84l-4.61-.69-227.93,45.07Zm-218.64-47.05l218.64,43.24,218.93-43.3-218.63-43.24-218.94,43.29Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="1253.73 1246.27 1026.61 1201.35 1026.61 1291.12 1026.38 1291.17 1253.5 1336.09 1253.5 1246.32 1253.73 1246.27" />
                            <path class="cls-1" d="M1258.33,1338.94l-241.7-47.8,5.16-.99v-91.66l241.67,47.8-5.13,.99v91.66Zm-226.9-48.69l217.25,42.96v-86.03l-217.24-42.97v86.04Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="1481.44 1201.27 1481.36 1201.26 1253.73 1246.27 1253.95 1246.32 1253.95 1336.17 1254.03 1336.19 1481.66 1291.16 1481.44 1291.12 1481.44 1201.27" />
                            <path class="cls-1" d="M1253.93,1338.11l-4.8-1.03v-89.8l-4.92-.95,7.1-1.5,229.98-45.48,4.88,.92,.09,.99v88.9s4.76,.96,4.76,.96l-6.96,1.46-230.13,45.52Zm4.84-90.93v86.16l217.83-43.09v-86.15s-217.83,43.08-217.83,43.08Z" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1253.95 1330.74 1253.5 1330.5 1253.5 1331.56 1253.73 1331.69 1253.95 1331.56 1253.95 1330.74" />
                            <polygon class="cls-1" points="1253.95 1337.06 1248.68 1334.44 1248.68 1322.25 1258.33 1327.68 1258.78 1333.63 1256.25 1335.81 1253.95 1337.06" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1253.73 1246.27 1253.5 1246.32 1253.5 1336.09 1253.95 1336.17 1253.95 1246.32 1253.73 1246.27" />
                            <polygon class="cls-1" points="1258.78 1338.99 1248.68 1337.05 1248.68 1245.35 1253.5 1244.42 1258.55 1245.31 1258.78 1246.32 1258.78 1338.99" />
                          </g>
                        </g>
                        <g>
                          <g>
                            <polygon class="cls-2" points="1254.61 1246.35 1481.73 1291.27 1709.36 1246.26 1709.44 1246.27 1709.43 1245.99 1709.66 1245.94 1482.03 1200.93 1254.38 1245.94 1254.61 1245.99 1254.61 1246.35" />
                            <path class="cls-1" d="M1481.73,1293.18l-231.94-45.87v-.37s-4.79-.96-4.79-.96l6.98-1.48,230.06-45.49,237.35,46.94-5.11,.99v1.84l-4.61-.69-227.93,45.07Zm-218.64-47.05l218.64,43.24,218.93-43.3-218.63-43.24-218.94,43.29Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="1481.73 1291.27 1254.61 1246.35 1254.61 1336.12 1254.38 1336.17 1481.5 1381.09 1481.5 1291.32 1481.73 1291.27" />
                            <path class="cls-1" d="M1486.33,1383.94l-241.7-47.8,5.16-.99v-91.66l241.67,47.8-5.13,.99v91.66Zm-226.9-48.69l217.25,42.96v-86.03l-217.24-42.97v86.04Z" />
                          </g>
                          <g className="k4b6base">
                            <polygon className={`k4b6 ${isHovered46 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered46(true)}
                              onMouseLeave={() => setIsHovered46(false)} points="1709.44 1246.27 1709.36 1246.26 1481.73 1291.27 1481.95 1291.32 1481.95 1381.17 1482.03 1381.19 1709.66 1336.16 1709.44 1336.12 1709.44 1246.27" />
                            <path class="cls-1 k4b6path" d="M1481.93,1383.11l-4.8-1.03v-89.8l-4.92-.95,7.1-1.5,229.98-45.48,4.88,.92,.09,.99v88.9s4.76,.96,4.76,.96l-6.96,1.46-230.13,45.52Zm4.84-90.93v86.16l217.83-43.09v-86.15s-217.83,43.08-217.83,43.08Z" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1481.95 1375.74 1481.5 1375.5 1481.5 1376.56 1481.73 1376.69 1481.95 1376.56 1481.95 1375.74" />
                            <polygon class="cls-1" points="1481.95 1382.06 1476.68 1379.44 1476.68 1367.25 1486.33 1372.68 1486.78 1378.63 1484.25 1380.81 1481.95 1382.06" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1481.73 1291.27 1481.5 1291.32 1481.5 1381.09 1481.95 1381.17 1481.95 1291.32 1481.73 1291.27" />
                            <polygon class="cls-1" points="1486.78 1383.99 1476.68 1382.05 1476.68 1290.35 1481.5 1289.42 1486.55 1290.31 1486.78 1291.32 1486.78 1383.99" />
                          </g>
                        </g>
                        <g>
                          <g>
                            <polygon class="cls-2" points="338.61 1156.35 565.73 1201.27 793.36 1156.26 793.44 1156.27 793.43 1155.99 793.66 1155.94 566.03 1110.93 338.38 1155.94 338.61 1155.99 338.61 1156.35" />
                            <path class="cls-1" d="M565.73,1203.18l-231.94-45.87v-.37s-4.79-.96-4.79-.96l6.98-1.48,230.06-45.49,237.35,46.94-5.11,.99v1.84l-4.61-.69-227.93,45.07Zm-218.64-47.05l218.64,43.24,218.93-43.3-218.63-43.24-218.94,43.29Z" />
                          </g>
                          <g className="k4b2base">
                            <polygon className={`k4b2 ${isHovered42 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered42(true)}
                              onMouseLeave={() => setIsHovered42(false)} points="565.73 1201.27 338.61 1156.35 338.61 1246.12 338.38 1246.17 565.5 1291.09 565.5 1201.32 565.73 1201.27" />
                            <path class="cls-1 k4b2path" d="M570.33,1293.94l-241.7-47.8,5.16-.99v-91.66l241.67,47.8-5.13,.99v91.66Zm-226.9-48.69l217.25,42.96v-86.03l-217.24-42.97v86.04Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="793.44 1156.27 793.36 1156.26 565.73 1201.27 565.95 1201.32 565.95 1291.17 566.03 1291.19 793.66 1246.16 793.44 1246.12 793.44 1156.27" />
                            <path class="cls-1" d="M565.93,1293.11l-4.8-1.03v-89.8l-4.92-.95,7.1-1.5,229.98-45.48,4.88,.92,.09,.99v88.9s4.76,.96,4.76,.96l-6.96,1.46-230.13,45.52Zm4.84-90.93v86.16l217.83-43.09v-86.15s-217.83,43.08-217.83,43.08Z" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="565.95 1285.74 565.5 1285.5 565.5 1286.56 565.73 1286.69 565.95 1286.56 565.95 1285.74" />
                            <polygon class="cls-1" points="565.95 1292.06 560.68 1289.44 560.68 1277.25 570.33 1282.68 570.78 1288.63 568.25 1290.81 565.95 1292.06" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="565.73 1201.27 565.5 1201.32 565.5 1291.09 565.95 1291.17 565.95 1201.32 565.73 1201.27" />
                            <polygon class="cls-1" points="570.78 1293.99 560.68 1292.05 560.68 1200.35 565.5 1199.42 570.55 1200.31 570.78 1201.32 570.78 1293.99" />
                          </g>
                        </g>
                        <g>
                          <g>
                            <polygon class="cls-2" points="566.61 1201.35 793.73 1246.27 1021.36 1201.26 1021.44 1201.27 1021.43 1200.99 1021.66 1200.94 794.03 1155.93 566.38 1200.94 566.61 1200.99 566.61 1201.35" />
                            <path class="cls-1" d="M793.73,1248.18l-231.94-45.87v-.37s-4.79-.96-4.79-.96l6.98-1.48,230.06-45.49,237.35,46.94-5.11,.99v1.84l-4.61-.69-227.93,45.07Zm-218.64-47.05l218.64,43.24,218.93-43.3-218.63-43.24-218.94,43.29Z" />
                          </g>
                          <g className="k4b3base">
                            <polygon className={`k4b3 ${isHovered43 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered43(true)}
                              onMouseLeave={() => setIsHovered43(false)} points="793.73 1246.27 566.61 1201.35 566.61 1291.12 566.38 1291.17 793.5 1336.09 793.5 1246.32 793.73 1246.27" />
                            <path class="cls-1 k4b3path" d="M798.33,1338.94l-241.7-47.8,5.16-.99v-91.66l241.67,47.8-5.13,.99v91.66Zm-226.9-48.69l217.25,42.96v-86.03l-217.24-42.97v86.04Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="1021.44 1201.27 1021.36 1201.26 793.73 1246.27 793.95 1246.32 793.95 1336.17 794.03 1336.19 1021.66 1291.16 1021.44 1291.12 1021.44 1201.27" />
                            <path class="cls-1" d="M793.93,1338.11l-4.8-1.03v-89.8l-4.92-.95,7.1-1.5,229.98-45.48,4.88,.92,.09,.99v88.9s4.76,.96,4.76,.96l-6.96,1.46-230.13,45.52Zm4.84-90.93v86.16l217.83-43.09v-86.15s-217.83,43.08-217.83,43.08Z" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="793.95 1330.74 793.5 1330.5 793.5 1331.56 793.73 1331.69 793.95 1331.56 793.95 1330.74" />
                            <polygon class="cls-1" points="793.95 1337.06 788.68 1334.44 788.68 1322.25 798.33 1327.68 798.78 1333.63 796.25 1335.81 793.95 1337.06" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="793.73 1246.27 793.5 1246.32 793.5 1336.09 793.95 1336.17 793.95 1246.32 793.73 1246.27" />
                            <polygon class="cls-1" points="798.78 1338.99 788.68 1337.05 788.68 1245.35 793.5 1244.42 798.55 1245.31 798.78 1246.32 798.78 1338.99" />
                          </g>
                        </g>
                        <g>
                          <g>
                            <polygon class="cls-2" points="796.61 1247.35 1023.73 1292.27 1251.36 1247.26 1251.44 1247.27 1251.43 1246.99 1251.66 1246.94 1024.03 1201.93 796.38 1246.94 796.61 1246.99 796.61 1247.35" />
                            <path class="cls-1" d="M1023.73,1294.18l-231.94-45.87v-.37s-4.79-.96-4.79-.96l6.98-1.48,230.06-45.49,237.35,46.94-5.11,.99v1.84l-4.61-.69-227.93,45.07Zm-218.64-47.05l218.64,43.24,218.93-43.3-218.63-43.24-218.94,43.29Z" />
                          </g>
                          <g className="k4b4base">
                            <polygon className={`k4b4 ${isHovered44 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered44(true)}
                              onMouseLeave={() => setIsHovered44(false)} points="1023.73 1292.27 796.61 1247.35 796.61 1337.12 796.38 1337.17 1023.5 1382.09 1023.5 1292.32 1023.73 1292.27" />
                            <path class="cls-1 k4b4path" d="M1028.33,1384.94l-241.7-47.8,5.16-.99v-91.66l241.67,47.8-5.13,.99v91.66Zm-226.9-48.69l217.25,42.96v-86.03l-217.24-42.97v86.04Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="1251.44 1247.27 1251.36 1247.26 1023.73 1292.27 1023.95 1292.32 1023.95 1382.17 1024.03 1382.19 1251.66 1337.16 1251.44 1337.12 1251.44 1247.27" />
                            <path class="cls-1" d="M1023.93,1384.11l-4.8-1.03v-89.8l-4.92-.95,7.1-1.5,229.98-45.48,4.88,.92,.09,.99v88.9s4.76,.96,4.76,.96l-6.96,1.46-230.13,45.52Zm4.84-90.93v86.16l217.83-43.09v-86.15s-217.83,43.08-217.83,43.08Z" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1023.95 1376.74 1023.5 1376.5 1023.5 1377.56 1023.73 1377.69 1023.95 1377.56 1023.95 1376.74" />
                            <polygon class="cls-1" points="1023.95 1383.06 1018.68 1380.44 1018.68 1368.25 1028.33 1373.68 1028.78 1379.63 1026.25 1381.81 1023.95 1383.06" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1023.73 1292.27 1023.5 1292.32 1023.5 1382.09 1023.95 1382.17 1023.95 1292.32 1023.73 1292.27" />
                            <polygon class="cls-1" points="1028.78 1384.99 1018.68 1383.05 1018.68 1291.35 1023.5 1290.42 1028.55 1291.31 1028.78 1292.32 1028.78 1384.99" />
                          </g>
                        </g>
                        <g>
                          <g>
                            <polygon class="cls-2" points="1024.61 1292.35 1251.73 1337.27 1479.36 1292.26 1479.44 1292.27 1479.43 1291.99 1479.66 1291.94 1252.03 1246.93 1024.38 1291.94 1024.61 1291.99 1024.61 1292.35" />
                            <path class="cls-1" d="M1251.73,1339.18l-231.94-45.87v-.37s-4.79-.96-4.79-.96l6.98-1.48,230.06-45.49,237.35,46.94-5.11,.99v1.84l-4.61-.69-227.93,45.07Zm-218.64-47.05l218.64,43.24,218.93-43.3-218.63-43.24-218.94,43.29Z" />
                          </g>
                          <g className="k4b5base">
                            <polygon className={`k4b5 ${isHovered ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered(true)}
                              onMouseLeave={() => setIsHovered(false)} points="1251.73 1337.27 1024.61 1292.35 1024.61 1382.12 1024.38 1382.17 1251.5 1427.09 1251.5 1337.32 1251.73 1337.27" />
                            <path class="cls-1 k4b5path" d="M1256.33,1429.94l-241.7-47.8,5.16-.99v-91.66l241.67,47.8-5.13,.99v91.66Zm-226.9-48.69l217.25,42.96v-86.03l-217.24-42.97v86.04Z" />
                          </g>
                          <g className="k4b5base">
                            <polygon className={`k4b5 ${isHovered45 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered45(true)}
                              onMouseLeave={() => setIsHovered45(false)} points="1479.44 1292.27 1479.36 1292.26 1251.73 1337.27 1251.95 1337.32 1251.95 1427.17 1252.03 1427.19 1479.66 1382.16 1479.44 1382.12 1479.44 1292.27" />
                            <path class="cls-1 k4b5path" d="M1251.93,1429.11l-4.8-1.03v-89.8l-4.92-.95,7.1-1.5,229.98-45.48,4.88,.92,.09,.99v88.9s4.76,.96,4.76,.96l-6.96,1.46-230.13,45.52Zm4.84-90.93v86.16l217.83-43.09v-86.15s-217.83,43.08-217.83,43.08Z" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1251.95 1421.74 1251.5 1421.5 1251.5 1422.56 1251.73 1422.69 1251.95 1422.56 1251.95 1421.74" />
                            <polygon class="cls-1" points="1251.95 1428.06 1246.68 1425.44 1246.68 1413.25 1256.33 1418.68 1256.78 1424.63 1254.25 1426.81 1251.95 1428.06" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1251.73 1337.27 1251.5 1337.32 1251.5 1427.09 1251.95 1427.17 1251.95 1337.32 1251.73 1337.27" />
                            <polygon class="cls-1" points="1256.78 1429.99 1246.68 1428.05 1246.68 1336.35 1251.5 1335.42 1256.55 1336.31 1256.78 1337.32 1256.78 1429.99" />
                          </g>
                        </g>
                      </g>
                      <g>
                        <g>
                          <g>
                            <polygon class="cls-2" points="568.61 1015.35 795.73 1060.27 1023.36 1015.26 1023.44 1015.27 1023.43 1014.99 1023.66 1014.94 796.03 969.93 568.38 1014.94 568.61 1014.99 568.61 1015.35" />
                            <path class="cls-1" d="M795.73,1062.18l-231.94-45.87v-.37s-4.79-.96-4.79-.96l6.98-1.48,230.06-45.49,237.35,46.94-5.11,.99v1.84l-4.61-.69-227.93,45.07Zm-218.64-47.05l218.64,43.24,218.93-43.3-218.63-43.24-218.94,43.29Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="795.73 1060.27 568.61 1015.35 568.61 1105.12 568.38 1105.17 795.5 1150.09 795.5 1060.32 795.73 1060.27" />
                            <path class="cls-1" d="M800.33,1152.94l-241.7-47.8,5.16-.99v-91.66l241.67,47.8-5.13,.99v91.66Zm-226.9-48.69l217.25,42.96v-86.03l-217.24-42.97v86.04Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="1023.44 1015.27 1023.36 1015.26 795.73 1060.27 795.95 1060.32 795.95 1150.17 796.03 1150.19 1023.66 1105.16 1023.44 1105.12 1023.44 1015.27" />
                            <path class="cls-1" d="M795.93,1152.11l-4.8-1.03v-89.8l-4.92-.95,7.1-1.5,229.98-45.48,4.88,.92,.09,.99v88.9s4.76,.96,4.76,.96l-6.96,1.46-230.13,45.52Zm4.84-90.93v86.16l217.83-43.09v-86.15s-217.83,43.08-217.83,43.08Z" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="795.95 1144.74 795.5 1144.5 795.5 1145.56 795.73 1145.69 795.95 1145.56 795.95 1144.74" />
                            <polygon class="cls-1" points="795.95 1151.06 790.68 1148.44 790.68 1136.25 800.33 1141.68 800.78 1147.63 798.25 1149.81 795.95 1151.06" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="795.73 1060.27 795.5 1060.32 795.5 1150.09 795.95 1150.17 795.95 1060.32 795.73 1060.27" />
                            <polygon class="cls-1" points="800.78 1152.99 790.68 1151.05 790.68 1059.35 795.5 1058.42 800.55 1059.31 800.78 1060.32 800.78 1152.99" />
                          </g>
                        </g>
                        <g>
                          <g>
                            <polygon class="cls-2" points="796.61 1060.35 1023.73 1105.27 1251.36 1060.26 1251.44 1060.27 1251.43 1059.99 1251.66 1059.94 1024.03 1014.93 796.38 1059.94 796.61 1059.99 796.61 1060.35" />
                            <path class="cls-1" d="M1023.73,1107.18l-231.94-45.87v-.37s-4.79-.96-4.79-.96l6.98-1.48,230.06-45.49,237.35,46.94-5.11,.99v1.84l-4.61-.69-227.93,45.07Zm-218.64-47.05l218.64,43.24,218.93-43.3-218.63-43.24-218.94,43.29Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="1023.73 1105.27 796.61 1060.35 796.61 1150.12 796.38 1150.17 1023.5 1195.09 1023.5 1105.32 1023.73 1105.27" />
                            <path class="cls-1" d="M1028.33,1197.94l-241.7-47.8,5.16-.99v-91.66l241.67,47.8-5.13,.99v91.66Zm-226.9-48.69l217.25,42.96v-86.03l-217.24-42.97v86.04Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="1251.44 1060.27 1251.36 1060.26 1023.73 1105.27 1023.95 1105.32 1023.95 1195.17 1024.03 1195.19 1251.66 1150.16 1251.44 1150.12 1251.44 1060.27" />
                            <path class="cls-1" d="M1023.93,1197.11l-4.8-1.03v-89.8l-4.92-.95,7.1-1.5,229.98-45.48,4.88,.92,.09,.99v88.9s4.76,.96,4.76,.96l-6.96,1.46-230.13,45.52Zm4.84-90.93v86.16l217.83-43.09v-86.15s-217.83,43.08-217.83,43.08Z" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1023.95 1189.74 1023.5 1189.5 1023.5 1190.56 1023.73 1190.69 1023.95 1190.56 1023.95 1189.74" />
                            <polygon class="cls-1" points="1023.95 1196.06 1018.68 1193.44 1018.68 1181.25 1028.33 1186.68 1028.78 1192.63 1026.25 1194.81 1023.95 1196.06" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1023.73 1105.27 1023.5 1105.32 1023.5 1195.09 1023.95 1195.17 1023.95 1105.32 1023.73 1105.27" />
                            <polygon class="cls-1" points="1028.78 1197.99 1018.68 1196.05 1018.68 1104.35 1023.5 1103.42 1028.55 1104.31 1028.78 1105.32 1028.78 1197.99" />
                          </g>
                        </g>
                        <g>
                          <g>
                            <polygon class="cls-2" points="1026.61 1106.35 1253.73 1151.27 1481.36 1106.26 1481.44 1106.27 1481.43 1105.99 1481.66 1105.94 1254.03 1060.93 1026.38 1105.94 1026.61 1105.99 1026.61 1106.35" />
                            <path class="cls-1" d="M1253.73,1153.18l-231.94-45.87v-.37s-4.79-.96-4.79-.96l6.98-1.48,230.06-45.49,237.35,46.94-5.11,.99v1.84l-4.61-.69-227.93,45.07Zm-218.64-47.05l218.64,43.24,218.93-43.3-218.63-43.24-218.94,43.29Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="1253.73 1151.27 1026.61 1106.35 1026.61 1196.12 1026.38 1196.17 1253.5 1241.09 1253.5 1151.32 1253.73 1151.27" />
                            <path class="cls-1" d="M1258.33,1243.94l-241.7-47.8,5.16-.99v-91.66l241.67,47.8-5.13,.99v91.66Zm-226.9-48.69l217.25,42.96v-86.03l-217.24-42.97v86.04Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="1481.44 1106.27 1481.36 1106.26 1253.73 1151.27 1253.95 1151.32 1253.95 1241.17 1254.03 1241.19 1481.66 1196.16 1481.44 1196.12 1481.44 1106.27" />
                            <path class="cls-1" d="M1253.93,1243.11l-4.8-1.03v-89.8l-4.92-.95,7.1-1.5,229.98-45.48,4.88,.92,.09,.99v88.9s4.76,.96,4.76,.96l-6.96,1.46-230.13,45.52Zm4.84-90.93v86.16l217.83-43.09v-86.15s-217.83,43.08-217.83,43.08Z" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1253.95 1235.74 1253.5 1235.5 1253.5 1236.56 1253.73 1236.69 1253.95 1236.56 1253.95 1235.74" />
                            <polygon class="cls-1" points="1253.95 1242.06 1248.68 1239.44 1248.68 1227.25 1258.33 1232.68 1258.78 1238.63 1256.25 1240.81 1253.95 1242.06" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1253.73 1151.27 1253.5 1151.32 1253.5 1241.09 1253.95 1241.17 1253.95 1151.32 1253.73 1151.27" />
                            <polygon class="cls-1" points="1258.78 1243.99 1248.68 1242.05 1248.68 1150.35 1253.5 1149.42 1258.55 1150.31 1258.78 1151.32 1258.78 1243.99" />
                          </g>
                        </g>
                        <g>
                          <g>
                            <polygon class="cls-2" points="1254.61 1151.35 1481.73 1196.27 1709.36 1151.26 1709.44 1151.27 1709.43 1150.99 1709.66 1150.94 1482.03 1105.93 1254.38 1150.94 1254.61 1150.99 1254.61 1151.35" />
                            <path class="cls-1" d="M1481.73,1198.18l-231.94-45.87v-.37s-4.79-.96-4.79-.96l6.98-1.48,230.06-45.49,237.35,46.94-5.11,.99v1.84l-4.61-.69-227.93,45.07Zm-218.64-47.05l218.64,43.24,218.93-43.3-218.63-43.24-218.94,43.29Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="1481.73 1196.27 1254.61 1151.35 1254.61 1241.12 1254.38 1241.17 1481.5 1286.09 1481.5 1196.32 1481.73 1196.27" />
                            <path class="cls-1" d="M1486.33,1288.94l-241.7-47.8,5.16-.99v-91.66l241.67,47.8-5.13,.99v91.66Zm-226.9-48.69l217.25,42.96v-86.03l-217.24-42.97v86.04Z" />
                          </g>
                          <g className="k5b6base">
                            <polygon className={`k5b6 ${isHovered56 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered56(true)}
                              onMouseLeave={() => setIsHovered56(false)} points="1709.44 1151.27 1709.36 1151.26 1481.73 1196.27 1481.95 1196.32 1481.95 1286.17 1482.03 1286.19 1709.66 1241.16 1709.44 1241.12 1709.44 1151.27" />
                            <path class="cls-1 k5b6path" d="M1481.93,1288.11l-4.8-1.03v-89.8l-4.92-.95,7.1-1.5,229.98-45.48,4.88,.92,.09,.99v88.9s4.76,.96,4.76,.96l-6.96,1.46-230.13,45.52Zm4.84-90.93v86.16l217.83-43.09v-86.15s-217.83,43.08-217.83,43.08Z" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1481.95 1280.74 1481.5 1280.5 1481.5 1281.56 1481.73 1281.69 1481.95 1281.56 1481.95 1280.74" />
                            <polygon class="cls-1" points="1481.95 1287.06 1476.68 1284.44 1476.68 1272.25 1486.33 1277.68 1486.78 1283.63 1484.25 1285.81 1481.95 1287.06" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1481.73 1196.27 1481.5 1196.32 1481.5 1286.09 1481.95 1286.17 1481.95 1196.32 1481.73 1196.27" />
                            <polygon class="cls-1" points="1486.78 1288.99 1476.68 1287.05 1476.68 1195.35 1481.5 1194.42 1486.55 1195.31 1486.78 1196.32 1486.78 1288.99" />
                          </g>
                        </g>
                        <g>
                          <g>
                            <polygon class="cls-2" points="338.61 1061.35 565.73 1106.27 793.36 1061.26 793.44 1061.27 793.43 1060.99 793.66 1060.94 566.03 1015.93 338.38 1060.94 338.61 1060.99 338.61 1061.35" />
                            <path class="cls-1" d="M565.73,1108.18l-231.94-45.87v-.37s-4.79-.96-4.79-.96l6.98-1.48,230.06-45.49,237.35,46.94-5.11,.99v1.84l-4.61-.69-227.93,45.07Zm-218.64-47.05l218.64,43.24,218.93-43.3-218.63-43.24-218.94,43.29Z" />
                          </g>
                          <g className="k5b2base">
                            <polygon className={`k5b2 ${isHovered52 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered52(true)}
                              onMouseLeave={() => setIsHovered52(false)} points="565.73 1106.27 338.61 1061.35 338.61 1151.12 338.38 1151.17 565.5 1196.09 565.5 1106.32 565.73 1106.27" />
                            <path class="cls-1 k5b2path" d="M570.33,1198.94l-241.7-47.8,5.16-.99v-91.66l241.67,47.8-5.13,.99v91.66Zm-226.9-48.69l217.25,42.96v-86.03l-217.24-42.97v86.04Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="793.44 1061.27 793.36 1061.26 565.73 1106.27 565.95 1106.32 565.95 1196.17 566.03 1196.19 793.66 1151.16 793.44 1151.12 793.44 1061.27" />
                            <path class="cls-1" d="M565.93,1198.11l-4.8-1.03v-89.8l-4.92-.95,7.1-1.5,229.98-45.48,4.88,.92,.09,.99v88.9s4.76,.96,4.76,.96l-6.96,1.46-230.13,45.52Zm4.84-90.93v86.16l217.83-43.09v-86.15s-217.83,43.08-217.83,43.08Z" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="565.95 1190.74 565.5 1190.5 565.5 1191.56 565.73 1191.69 565.95 1191.56 565.95 1190.74" />
                            <polygon class="cls-1" points="565.95 1197.06 560.68 1194.44 560.68 1182.25 570.33 1187.68 570.78 1193.63 568.25 1195.81 565.95 1197.06" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="565.73 1106.27 565.5 1106.32 565.5 1196.09 565.95 1196.17 565.95 1106.32 565.73 1106.27" />
                            <polygon class="cls-1" points="570.78 1198.99 560.68 1197.05 560.68 1105.35 565.5 1104.42 570.55 1105.31 570.78 1106.32 570.78 1198.99" />
                          </g>
                        </g>
                        <g>
                          <g>
                            <polygon class="cls-2" points="566.61 1106.35 793.73 1151.27 1021.36 1106.26 1021.44 1106.27 1021.43 1105.99 1021.66 1105.94 794.03 1060.93 566.38 1105.94 566.61 1105.99 566.61 1106.35" />
                            <path class="cls-1" d="M793.73,1153.18l-231.94-45.87v-.37s-4.79-.96-4.79-.96l6.98-1.48,230.06-45.49,237.35,46.94-5.11,.99v1.84l-4.61-.69-227.93,45.07Zm-218.64-47.05l218.64,43.24,218.93-43.3-218.63-43.24-218.94,43.29Z" />
                          </g>
                          <g className="k5b3base"> 
                            <polygon className={`k5b3 ${isHovered53 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered53(true)}
                              onMouseLeave={() => setIsHovered53(false)} points="793.73 1151.27 566.61 1106.35 566.61 1196.12 566.38 1196.17 793.5 1241.09 793.5 1151.32 793.73 1151.27" />
                            <path class="cls-1 k5b3path" d="M798.33,1243.94l-241.7-47.8,5.16-.99v-91.66l241.67,47.8-5.13,.99v91.66Zm-226.9-48.69l217.25,42.96v-86.03l-217.24-42.97v86.04Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="1021.44 1106.27 1021.36 1106.26 793.73 1151.27 793.95 1151.32 793.95 1241.17 794.03 1241.19 1021.66 1196.16 1021.44 1196.12 1021.44 1106.27" />
                            <path class="cls-1" d="M793.93,1243.11l-4.8-1.03v-89.8l-4.92-.95,7.1-1.5,229.98-45.48,4.88,.92,.09,.99v88.9s4.76,.96,4.76,.96l-6.96,1.46-230.13,45.52Zm4.84-90.93v86.16l217.83-43.09v-86.15s-217.83,43.08-217.83,43.08Z" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="793.95 1235.74 793.5 1235.5 793.5 1236.56 793.73 1236.69 793.95 1236.56 793.95 1235.74" />
                            <polygon class="cls-1" points="793.95 1242.06 788.68 1239.44 788.68 1227.25 798.33 1232.68 798.78 1238.63 796.25 1240.81 793.95 1242.06" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="793.73 1151.27 793.5 1151.32 793.5 1241.09 793.95 1241.17 793.95 1151.32 793.73 1151.27" />
                            <polygon class="cls-1" points="798.78 1243.99 788.68 1242.05 788.68 1150.35 793.5 1149.42 798.55 1150.31 798.78 1151.32 798.78 1243.99" />
                          </g>
                        </g>
                        <g>
                          <g>
                            <polygon class="cls-2" points="796.61 1152.35 1023.73 1197.27 1251.36 1152.26 1251.44 1152.27 1251.43 1151.99 1251.66 1151.94 1024.03 1106.93 796.38 1151.94 796.61 1151.99 796.61 1152.35" />
                            <path class="cls-1" d="M1023.73,1199.18l-231.94-45.87v-.37s-4.79-.96-4.79-.96l6.98-1.48,230.06-45.49,237.35,46.94-5.11,.99v1.84l-4.61-.69-227.93,45.07Zm-218.64-47.05l218.64,43.24,218.93-43.3-218.63-43.24-218.94,43.29Z" />
                          </g>
                          <g className="k5b4base">
                            <polygon className={`k5b4 ${isHovered54 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered54(true)}
                              onMouseLeave={() => setIsHovered54(false)} points="1023.73 1197.27 796.61 1152.35 796.61 1242.12 796.38 1242.17 1023.5 1287.09 1023.5 1197.32 1023.73 1197.27" />
                            <path class="cls-1 k5b4path" d="M1028.33,1289.94l-241.7-47.8,5.16-.99v-91.66l241.67,47.8-5.13,.99v91.66Zm-226.9-48.69l217.25,42.96v-86.03l-217.24-42.97v86.04Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="1251.44 1152.27 1251.36 1152.26 1023.73 1197.27 1023.95 1197.32 1023.95 1287.17 1024.03 1287.19 1251.66 1242.16 1251.44 1242.12 1251.44 1152.27" />
                            <path class="cls-1" d="M1023.93,1289.11l-4.8-1.03v-89.8l-4.92-.95,7.1-1.5,229.98-45.48,4.88,.92,.09,.99v88.9s4.76,.96,4.76,.96l-6.96,1.46-230.13,45.52Zm4.84-90.93v86.16l217.83-43.09v-86.15s-217.83,43.08-217.83,43.08Z" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1023.95 1281.74 1023.5 1281.5 1023.5 1282.56 1023.73 1282.69 1023.95 1282.56 1023.95 1281.74" />
                            <polygon class="cls-1" points="1023.95 1288.06 1018.68 1285.44 1018.68 1273.25 1028.33 1278.68 1028.78 1284.63 1026.25 1286.81 1023.95 1288.06" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1023.73 1197.27 1023.5 1197.32 1023.5 1287.09 1023.95 1287.17 1023.95 1197.32 1023.73 1197.27" />
                            <polygon class="cls-1" points="1028.78 1289.99 1018.68 1288.05 1018.68 1196.35 1023.5 1195.42 1028.55 1196.31 1028.78 1197.32 1028.78 1289.99" />
                          </g>
                        </g>
                        <g>
                          <g>
                            <polygon class="cls-2" points="1024.61 1197.35 1251.73 1242.27 1479.36 1197.26 1479.44 1197.27 1479.43 1196.99 1479.66 1196.94 1252.03 1151.93 1024.38 1196.94 1024.61 1196.99 1024.61 1197.35" />
                            <path class="cls-1" d="M1251.73,1244.18l-231.94-45.87v-.37s-4.79-.96-4.79-.96l6.98-1.48,230.06-45.49,237.35,46.94-5.11,.99v1.84l-4.61-.69-227.93,45.07Zm-218.64-47.05l218.64,43.24,218.93-43.3-218.63-43.24-218.94,43.29Z" />
                          </g>
                          <g className="k5b5base">
                            <polygon className={`k5b5 ${isHovered55 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered55(true)}
                              onMouseLeave={() => setIsHovered55(false)} points="1251.73 1242.27 1024.61 1197.35 1024.61 1287.12 1024.38 1287.17 1251.5 1332.09 1251.5 1242.32 1251.73 1242.27" />
                            <path class="cls-1 k5b5path" d="M1256.33,1334.94l-241.7-47.8,5.16-.99v-91.66l241.67,47.8-5.13,.99v91.66Zm-226.9-48.69l217.25,42.96v-86.03l-217.24-42.97v86.04Z" />
                          </g>
                          <g className="k5b5base">
                            <polygon className={`k5b5 ${isHovered55 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered55(true)}
                              onMouseLeave={() => setIsHovered55(false)} points="1479.44 1197.27 1479.36 1197.26 1251.73 1242.27 1251.95 1242.32 1251.95 1332.17 1252.03 1332.19 1479.66 1287.16 1479.44 1287.12 1479.44 1197.27" />
                            <path class="cls-1 k5b5path" d="M1251.93,1334.11l-4.8-1.03v-89.8l-4.92-.95,7.1-1.5,229.98-45.48,4.88,.92,.09,.99v88.9s4.76,.96,4.76,.96l-6.96,1.46-230.13,45.52Zm4.84-90.93v86.16l217.83-43.09v-86.15s-217.83,43.08-217.83,43.08Z" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1251.95 1326.74 1251.5 1326.5 1251.5 1327.56 1251.73 1327.69 1251.95 1327.56 1251.95 1326.74" />
                            <polygon class="cls-1" points="1251.95 1333.06 1246.68 1330.44 1246.68 1318.25 1256.33 1323.68 1256.78 1329.63 1254.25 1331.81 1251.95 1333.06" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1251.73 1242.27 1251.5 1242.32 1251.5 1332.09 1251.95 1332.17 1251.95 1242.32 1251.73 1242.27" />
                            <polygon class="cls-1" points="1256.78 1334.99 1246.68 1333.05 1246.68 1241.35 1251.5 1240.42 1256.55 1241.31 1256.78 1242.32 1256.78 1334.99" />
                          </g>
                        </g>
                      </g>
                      <g>
                        <g>
                          <polygon class="cls-2" points="568.61 921.35 795.73 966.27 1023.36 921.26 1023.44 921.27 1023.43 920.99 1023.66 920.94 796.03 875.93 568.38 920.94 568.61 920.99 568.61 921.35" />
                          <path class="cls-1" d="M795.73,968.18l-231.94-45.87v-.37s-4.79-.96-4.79-.96l6.98-1.48,230.06-45.49,237.35,46.94-5.11,.99v1.84l-4.61-.69-227.93,45.07Zm-218.64-47.05l218.64,43.24,218.93-43.3-218.63-43.24-218.94,43.29Z" />
                        </g>
                        <g>
                          <polygon class="cls-2" points="795.73 966.27 568.61 921.35 568.61 1011.12 568.38 1011.17 795.5 1056.09 795.5 966.32 795.73 966.27" />
                          <path class="cls-1" d="M800.33,1058.94l-241.7-47.8,5.16-.99v-91.66l241.67,47.8-5.13,.99v91.66Zm-226.9-48.69l217.25,42.96v-86.03l-217.24-42.97v86.04Z" />
                        </g>
                        <g>
                          <polygon class="cls-2" points="1023.44 921.27 1023.36 921.26 795.73 966.27 795.95 966.32 795.95 1056.17 796.03 1056.19 1023.66 1011.16 1023.44 1011.12 1023.44 921.27" />
                          <path class="cls-1" d="M795.93,1058.11l-4.8-1.03v-89.8l-4.92-.95,7.1-1.5,229.98-45.48,4.88,.92,.09,.99v88.9s4.76,.96,4.76,.96l-6.96,1.46-230.13,45.52Zm4.84-90.93v86.16l217.83-43.09v-86.15s-217.83,43.08-217.83,43.08Z" />
                        </g>
                        <g>
                          <polygon class="cls-4" points="795.95 1050.74 795.5 1050.5 795.5 1051.56 795.73 1051.69 795.95 1051.56 795.95 1050.74" />
                          <polygon class="cls-1" points="795.95 1057.06 790.68 1054.44 790.68 1042.25 800.33 1047.68 800.78 1053.63 798.25 1055.81 795.95 1057.06" />
                        </g>
                        <g>
                          <polygon class="cls-4" points="795.73 966.27 795.5 966.32 795.5 1056.09 795.95 1056.17 795.95 966.32 795.73 966.27" />
                          <polygon class="cls-1" points="800.78 1058.99 790.68 1057.05 790.68 965.35 795.5 964.42 800.55 965.31 800.78 966.32 800.78 1058.99" />
                        </g>
                      </g>
                      <g>
                        <g>
                          <polygon class="cls-2" points="796.61 966.35 1023.73 1011.27 1251.36 966.26 1251.44 966.27 1251.43 965.99 1251.66 965.94 1024.03 920.93 796.38 965.94 796.61 965.99 796.61 966.35" />
                          <path class="cls-1" d="M1023.73,1013.18l-231.94-45.87v-.37s-4.79-.96-4.79-.96l6.98-1.48,230.06-45.49,237.35,46.94-5.11,.99v1.84l-4.61-.69-227.93,45.07Zm-218.64-47.05l218.64,43.24,218.93-43.3-218.63-43.24-218.94,43.29Z" />
                        </g>
                        <g>
                          <polygon class="cls-2" points="1023.73 1011.27 796.61 966.35 796.61 1056.12 796.38 1056.17 1023.5 1101.09 1023.5 1011.32 1023.73 1011.27" />
                          <path class="cls-1" d="M1028.33,1103.94l-241.7-47.8,5.16-.99v-91.66l241.67,47.8-5.13,.99v91.66Zm-226.9-48.69l217.25,42.96v-86.03l-217.24-42.97v86.04Z" />
                        </g>
                        <g>
                          <polygon class="cls-2" points="1251.44 966.27 1251.36 966.26 1023.73 1011.27 1023.95 1011.32 1023.95 1101.17 1024.03 1101.19 1251.66 1056.16 1251.44 1056.12 1251.44 966.27" />
                          <path class="cls-1" d="M1023.93,1103.11l-4.8-1.03v-89.8l-4.92-.95,7.1-1.5,229.98-45.48,4.88,.92,.09,.99v88.9s4.76,.96,4.76,.96l-6.96,1.46-230.13,45.52Zm4.84-90.93v86.16l217.83-43.09v-86.15s-217.83,43.08-217.83,43.08Z" />
                        </g>
                        <g>
                          <polygon class="cls-4" points="1023.95 1095.74 1023.5 1095.5 1023.5 1096.56 1023.73 1096.69 1023.95 1096.56 1023.95 1095.74" />
                          <polygon class="cls-1" points="1023.95 1102.06 1018.68 1099.44 1018.68 1087.25 1028.33 1092.68 1028.78 1098.63 1026.25 1100.81 1023.95 1102.06" />
                        </g>
                        <g>
                          <polygon class="cls-4" points="1023.73 1011.27 1023.5 1011.32 1023.5 1101.09 1023.95 1101.17 1023.95 1011.32 1023.73 1011.27" />
                          <polygon class="cls-1" points="1028.78 1103.99 1018.68 1102.05 1018.68 1010.35 1023.5 1009.42 1028.55 1010.31 1028.78 1011.32 1028.78 1103.99" />
                        </g>
                      </g>
                      <g>
                        <g>
                          <polygon class="cls-2" points="1026.61 1012.35 1253.73 1057.27 1481.36 1012.26 1481.44 1012.27 1481.43 1011.99 1481.66 1011.94 1254.03 966.93 1026.38 1011.94 1026.61 1011.99 1026.61 1012.35" />
                          <path class="cls-1" d="M1253.73,1059.18l-231.94-45.87v-.37s-4.79-.96-4.79-.96l6.98-1.48,230.06-45.49,237.35,46.94-5.11,.99v1.84l-4.61-.69-227.93,45.07Zm-218.64-47.05l218.64,43.24,218.93-43.3-218.63-43.24-218.94,43.29Z" />
                        </g>
                        <g>
                          <polygon class="cls-2" points="1253.73 1057.27 1026.61 1012.35 1026.61 1102.12 1026.38 1102.17 1253.5 1147.09 1253.5 1057.32 1253.73 1057.27" />
                          <path class="cls-1" d="M1258.33,1149.94l-241.7-47.8,5.16-.99v-91.66l241.67,47.8-5.13,.99v91.66Zm-226.9-48.69l217.25,42.96v-86.03l-217.24-42.97v86.04Z" />
                        </g>
                        <g>
                          <polygon class="cls-2" points="1481.44 1012.27 1481.36 1012.26 1253.73 1057.27 1253.95 1057.32 1253.95 1147.17 1254.03 1147.19 1481.66 1102.16 1481.44 1102.12 1481.44 1012.27" />
                          <path class="cls-1" d="M1253.93,1149.11l-4.8-1.03v-89.8l-4.92-.95,7.1-1.5,229.98-45.48,4.88,.92,.09,.99v88.9s4.76,.96,4.76,.96l-6.96,1.46-230.13,45.52Zm4.84-90.93v86.16l217.83-43.09v-86.15s-217.83,43.08-217.83,43.08Z" />
                        </g>
                        <g>
                          <polygon class="cls-4" points="1253.95 1141.74 1253.5 1141.5 1253.5 1142.56 1253.73 1142.69 1253.95 1142.56 1253.95 1141.74" />
                          <polygon class="cls-1" points="1253.95 1148.06 1248.68 1145.44 1248.68 1133.25 1258.33 1138.68 1258.78 1144.63 1256.25 1146.81 1253.95 1148.06" />
                        </g>
                        <g>
                          <polygon class="cls-4" points="1253.73 1057.27 1253.5 1057.32 1253.5 1147.09 1253.95 1147.17 1253.95 1057.32 1253.73 1057.27" />
                          <polygon class="cls-1" points="1258.78 1149.99 1248.68 1148.05 1248.68 1056.35 1253.5 1055.42 1258.55 1056.31 1258.78 1057.32 1258.78 1149.99" />
                        </g>
                      </g>
                      <g>
                        <g>
                          <polygon class="cls-2" points="1254.61 1057.35 1481.73 1102.27 1709.36 1057.26 1709.44 1057.27 1709.43 1056.99 1709.66 1056.94 1482.03 1011.93 1254.38 1056.94 1254.61 1056.99 1254.61 1057.35" />
                          <path class="cls-1" d="M1481.73,1104.18l-231.94-45.87v-.37s-4.79-.96-4.79-.96l6.98-1.48,230.06-45.49,237.35,46.94-5.11,.99v1.84l-4.61-.69-227.93,45.07Zm-218.64-47.05l218.64,43.24,218.93-43.3-218.63-43.24-218.94,43.29Z" />
                        </g>
                        <g>
                          <polygon class="cls-2" points="1481.73 1102.27 1254.61 1057.35 1254.61 1147.12 1254.38 1147.17 1481.5 1192.09 1481.5 1102.32 1481.73 1102.27" />
                          <path class="cls-1" d="M1486.33,1194.94l-241.7-47.8,5.16-.99v-91.66l241.67,47.8-5.13,.99v91.66Zm-226.9-48.69l217.25,42.96v-86.03l-217.24-42.97v86.04Z" />
                        </g>
                        <g className="k6b5base">
                          <polygon className={`k6b5 ${isHovered65 ? 'act' : ''}`}
                            onMouseEnter={() => setIsHovered65(true)}
                            onMouseLeave={() => setIsHovered65(false)} points="1709.44 1057.27 1709.36 1057.26 1481.73 1102.27 1481.95 1102.32 1481.95 1192.17 1482.03 1192.19 1709.66 1147.16 1709.44 1147.12 1709.44 1057.27" />
                          <path class="cls-1 k6b5path" d="M1481.93,1194.11l-4.8-1.03v-89.8l-4.92-.95,7.1-1.5,229.98-45.48,4.88,.92,.09,.99v88.9s4.76,.96,4.76,.96l-6.96,1.46-230.13,45.52Zm4.84-90.93v86.16l217.83-43.09v-86.15s-217.83,43.08-217.83,43.08Z" />
                        </g>
                        <g>
                          <polygon class="cls-4" points="1481.95 1186.74 1481.5 1186.5 1481.5 1187.56 1481.73 1187.69 1481.95 1187.56 1481.95 1186.74" />
                          <polygon class="cls-1" points="1481.95 1193.06 1476.68 1190.44 1476.68 1178.25 1486.33 1183.68 1486.78 1189.63 1484.25 1191.81 1481.95 1193.06" />
                        </g>
                        <g>
                          <polygon class="cls-4" points="1481.73 1102.27 1481.5 1102.32 1481.5 1192.09 1481.95 1192.17 1481.95 1102.32 1481.73 1102.27" />
                          <polygon class="cls-1" points="1486.78 1194.99 1476.68 1193.05 1476.68 1101.35 1481.5 1100.42 1486.55 1101.31 1486.78 1102.32 1486.78 1194.99" />
                        </g>
                      </g>
                      <g>
                        <g>
                          <polygon class="cls-2" points="338.61 967.35 565.73 1012.27 793.36 967.26 793.44 967.27 793.43 966.99 793.66 966.94 566.03 921.93 338.38 966.94 338.61 966.99 338.61 967.35" />
                          <path class="cls-1" d="M565.73,1014.18l-231.94-45.87v-.37s-4.79-.96-4.79-.96l6.98-1.48,230.06-45.49,237.35,46.94-5.11,.99v1.84l-4.61-.69-227.93,45.07Zm-218.64-47.05l218.64,43.24,218.93-43.3-218.63-43.24-218.94,43.29Z" />
                        </g>
                        <g className="k6b2base">
                          <polygon className={`k6b2 ${isHovered62 ? 'act' : ''}`}
                            onMouseEnter={() => setIsHovered62(true)}
                            onMouseLeave={() => setIsHovered62(false)} points="565.73 1012.27 338.61 967.35 338.61 1057.12 338.38 1057.17 565.5 1102.09 565.5 1012.32 565.73 1012.27" />
                          <path class="cls-1 k6b2path" d="M570.33,1104.94l-241.7-47.8,5.16-.99v-91.66l241.67,47.8-5.13,.99v91.66Zm-226.9-48.69l217.25,42.96v-86.03l-217.24-42.97v86.04Z" />
                        </g>
                        <g>
                          <polygon class="cls-2" points="793.44 967.27 793.36 967.26 565.73 1012.27 565.95 1012.32 565.95 1102.17 566.03 1102.19 793.66 1057.16 793.44 1057.12 793.44 967.27" />
                          <path class="cls-1" d="M565.93,1104.11l-4.8-1.03v-89.8l-4.92-.95,7.1-1.5,229.98-45.48,4.88,.92,.09,.99v88.9s4.76,.96,4.76,.96l-6.96,1.46-230.13,45.52Zm4.84-90.93v86.16l217.83-43.09v-86.15s-217.83,43.08-217.83,43.08Z" />
                        </g>
                        <g>
                          <polygon class="cls-4" points="565.95 1096.74 565.5 1096.5 565.5 1097.56 565.73 1097.69 565.95 1097.56 565.95 1096.74" />
                          <polygon class="cls-1" points="565.95 1103.06 560.68 1100.44 560.68 1088.25 570.33 1093.68 570.78 1099.63 568.25 1101.81 565.95 1103.06" />
                        </g>
                        <g>
                          <polygon class="cls-4" points="565.73 1012.27 565.5 1012.32 565.5 1102.09 565.95 1102.17 565.95 1012.32 565.73 1012.27" />
                          <polygon class="cls-1" points="570.78 1104.99 560.68 1103.05 560.68 1011.35 565.5 1010.42 570.55 1011.31 570.78 1012.32 570.78 1104.99" />
                        </g>
                      </g>
                      <g>
                        <g>
                          <polygon class="cls-2" points="566.61 1012.35 793.73 1057.27 1021.36 1012.26 1021.44 1012.27 1021.43 1011.99 1021.66 1011.94 794.03 966.93 566.38 1011.94 566.61 1011.99 566.61 1012.35" />
                          <path class="cls-1" d="M793.73,1059.18l-231.94-45.87v-.37s-4.79-.96-4.79-.96l6.98-1.48,230.06-45.49,237.35,46.94-5.11,.99v1.84l-4.61-.69-227.93,45.07Zm-218.64-47.05l218.64,43.24,218.93-43.3-218.63-43.24-218.94,43.29Z" />
                        </g>
                        <g className="k6b3base">
                          <polygon className={`k6b3 ${isHovered63 ? 'act' : ''}`}
                            onMouseEnter={() => setIsHovered63(true)}
                            onMouseLeave={() => setIsHovered63(false)} points="793.73 1057.27 566.61 1012.35 566.61 1102.12 566.38 1102.17 793.5 1147.09 793.5 1057.32 793.73 1057.27" />
                          <path class="cls-1 k6b3path" d="M798.33,1149.94l-241.7-47.8,5.16-.99v-91.66l241.67,47.8-5.13,.99v91.66Zm-226.9-48.69l217.25,42.96v-86.03l-217.24-42.97v86.04Z" />
                        </g>
                        <g>
                          <polygon class="cls-2" points="1021.44 1012.27 1021.36 1012.26 793.73 1057.27 793.95 1057.32 793.95 1147.17 794.03 1147.19 1021.66 1102.16 1021.44 1102.12 1021.44 1012.27" />
                          <path class="cls-1" d="M793.93,1149.11l-4.8-1.03v-89.8l-4.92-.95,7.1-1.5,229.98-45.48,4.88,.92,.09,.99v88.9s4.76,.96,4.76,.96l-6.96,1.46-230.13,45.52Zm4.84-90.93v86.16l217.83-43.09v-86.15s-217.83,43.08-217.83,43.08Z" />
                        </g>
                        <g>
                          <polygon class="cls-4" points="793.95 1141.74 793.5 1141.5 793.5 1142.56 793.73 1142.69 793.95 1142.56 793.95 1141.74" />
                          <polygon class="cls-1" points="793.95 1148.06 788.68 1145.44 788.68 1133.25 798.33 1138.68 798.78 1144.63 796.25 1146.81 793.95 1148.06" />
                        </g>
                        <g>
                          <polygon class="cls-4" points="793.73 1057.27 793.5 1057.32 793.5 1147.09 793.95 1147.17 793.95 1057.32 793.73 1057.27" />
                          <polygon class="cls-1" points="798.78 1149.99 788.68 1148.05 788.68 1056.35 793.5 1055.42 798.55 1056.31 798.78 1057.32 798.78 1149.99" />
                        </g>
                      </g>
                      <g>
                        <g>
                          <polygon class="cls-2" points="796.61 1058.35 1023.73 1103.27 1251.36 1058.26 1251.44 1058.27 1251.43 1057.99 1251.66 1057.94 1024.03 1012.93 796.38 1057.94 796.61 1057.99 796.61 1058.35" />
                          <path class="cls-1" d="M1023.73,1105.18l-231.94-45.87v-.37s-4.79-.96-4.79-.96l6.98-1.48,230.06-45.49,237.35,46.94-5.11,.99v1.84l-4.61-.69-227.93,45.07Zm-218.64-47.05l218.64,43.24,218.93-43.3-218.63-43.24-218.94,43.29Z" />
                        </g>
                        <g className="k6b4base">
                          <polygon className={`k6b4 ${isHovered64 ? 'act' : ''}`}
                            onMouseEnter={() => setIsHovered64(true)}
                            onMouseLeave={() => setIsHovered64(false)} points="1023.73 1103.27 796.61 1058.35 796.61 1148.12 796.38 1148.17 1023.5 1193.09 1023.5 1103.32 1023.73 1103.27" />
                          <path class="cls-1 k6b4path" d="M1028.33,1195.94l-241.7-47.8,5.16-.99v-91.66l241.67,47.8-5.13,.99v91.66Zm-226.9-48.69l217.25,42.96v-86.03l-217.24-42.97v86.04Z" />
                        </g>
                        <g>
                          <polygon class="cls-2" points="1251.44 1058.27 1251.36 1058.26 1023.73 1103.27 1023.95 1103.32 1023.95 1193.17 1024.03 1193.19 1251.66 1148.16 1251.44 1148.12 1251.44 1058.27" />
                          <path class="cls-1" d="M1023.93,1195.11l-4.8-1.03v-89.8l-4.92-.95,7.1-1.5,229.98-45.48,4.88,.92,.09,.99v88.9s4.76,.96,4.76,.96l-6.96,1.46-230.13,45.52Zm4.84-90.93v86.16l217.83-43.09v-86.15s-217.83,43.08-217.83,43.08Z" />
                        </g>
                        <g>
                          <polygon class="cls-4" points="1023.95 1187.74 1023.5 1187.5 1023.5 1188.56 1023.73 1188.69 1023.95 1188.56 1023.95 1187.74" />
                          <polygon class="cls-1" points="1023.95 1194.06 1018.68 1191.44 1018.68 1179.25 1028.33 1184.68 1028.78 1190.63 1026.25 1192.81 1023.95 1194.06" />
                        </g>
                        <g>
                          <polygon class="cls-4" points="1023.73 1103.27 1023.5 1103.32 1023.5 1193.09 1023.95 1193.17 1023.95 1103.32 1023.73 1103.27" />
                          <polygon class="cls-1" points="1028.78 1195.99 1018.68 1194.05 1018.68 1102.35 1023.5 1101.42 1028.55 1102.31 1028.78 1103.32 1028.78 1195.99" />
                        </g>
                      </g>
                      <g>
                        <g>
                          <polygon class="cls-1" points="1024.61 1103.35 1251.73 1148.27 1479.36 1103.26 1479.44 1103.27 1479.43 1102.99 1479.66 1102.94 1252.03 1057.93 1024.38 1102.94 1024.61 1102.99 1024.61 1103.35" />
                          <path class="cls-1" d="M1251.73,1150.18l-231.94-45.87v-.37s-4.79-.96-4.79-.96l6.98-1.48,230.06-45.49,237.35,46.94-5.11,.99v1.84l-4.61-.69-227.93,45.07Zm-218.64-47.05l218.64,43.24,218.93-43.3-218.63-43.24-218.94,43.29Z" />
                        </g>
                        <g className="k6b5base">
                          <polygon className={`k6b5 ${isHovered65 ? 'act' : ''}`}
                            onMouseEnter={() => setIsHovered65(true)}
                            onMouseLeave={() => setIsHovered65(false)} points="1251.73 1148.27 1024.61 1103.35 1024.61 1193.12 1024.38 1193.17 1251.5 1238.09 1251.5 1148.32 1251.73 1148.27" />
                          <path class="cls-1 k6b5path" d="M1256.33,1240.94l-241.7-47.8,5.16-.99v-91.66l241.67,47.8-5.13,.99v91.66Zm-226.9-48.69l217.25,42.96v-86.03l-217.24-42.97v86.04Z" />
                        </g>
                        <g className="k6b5base">
                          <polygon className={`k6b5 ${isHovered65 ? 'act' : ''}`}
                            onMouseEnter={() => setIsHovered65(true)}
                            onMouseLeave={() => setIsHovered65(false)} points="1479.44 1103.27 1479.36 1103.26 1251.73 1148.27 1251.95 1148.32 1251.95 1238.17 1252.03 1238.19 1479.66 1193.16 1479.44 1193.12 1479.44 1103.27" />
                          <path class="cls-1 k6b5path" d="M1251.93,1240.11l-4.8-1.03v-89.8l-4.92-.95,7.1-1.5,229.98-45.48,4.88,.92,.09,.99v88.9s4.76,.96,4.76,.96l-6.96,1.46-230.13,45.52Zm4.84-90.93v86.16l217.83-43.09v-86.15s-217.83,43.08-217.83,43.08Z" />
                        </g>
                        <g>
                          <polygon class="cls-1" points="1251.95 1232.74 1251.5 1232.5 1251.5 1233.56 1251.73 1233.69 1251.95 1233.56 1251.95 1232.74" />
                          <polygon class="cls-1" points="1251.95 1239.06 1246.68 1236.44 1246.68 1224.25 1256.33 1229.68 1256.78 1235.63 1254.25 1237.81 1251.95 1239.06" />
                        </g>
                        <g>
                          <polygon class="cls-1" points="1251.73 1148.27 1251.5 1148.32 1251.5 1238.09 1251.95 1238.17 1251.95 1148.32 1251.73 1148.27" />
                          <polygon class="cls-1" points="1256.78 1240.99 1246.68 1239.05 1246.68 1147.35 1251.5 1146.42 1256.55 1147.31 1256.78 1148.32 1256.78 1240.99" />
                        </g>
                      </g>
                      <g>
                        <g>
                          <g>
                            <polygon class="cls-2" points="568.61 827.35 795.73 872.27 1023.36 827.26 1023.44 827.27 1023.43 826.99 1023.66 826.94 796.03 781.93 568.38 826.94 568.61 826.99 568.61 827.35" />
                            <path class="cls-1" d="M795.73,874.18l-231.94-45.87v-.37s-4.79-.96-4.79-.96l6.98-1.48,230.06-45.49,237.35,46.94-5.11,.99v1.84l-4.61-.69-227.93,45.07Zm-218.64-47.05l218.64,43.24,218.93-43.3-218.63-43.24-218.94,43.29Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="795.73 872.27 568.61 827.35 568.61 917.12 568.38 917.17 795.5 962.09 795.5 872.32 795.73 872.27" />
                            <path class="cls-1" d="M800.33,964.94l-241.7-47.8,5.16-.99v-91.66l241.67,47.8-5.13,.99v91.66Zm-226.9-48.69l217.25,42.96v-86.03l-217.24-42.97v86.04Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="1023.44 827.27 1023.36 827.26 795.73 872.27 795.95 872.32 795.95 962.17 796.03 962.19 1023.66 917.16 1023.44 917.12 1023.44 827.27" />
                            <path class="cls-1" d="M795.93,964.11l-4.8-1.03v-89.8l-4.92-.95,7.1-1.5,229.98-45.48,4.88,.92,.09,.99v88.9s4.76,.96,4.76,.96l-6.96,1.46-230.13,45.52Zm4.84-90.93v86.16l217.83-43.09v-86.15s-217.83,43.08-217.83,43.08Z" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="795.95 956.74 795.5 956.5 795.5 957.56 795.73 957.69 795.95 957.56 795.95 956.74" />
                            <polygon class="cls-1" points="795.95 963.06 790.68 960.44 790.68 948.25 800.33 953.68 800.78 959.63 798.25 961.81 795.95 963.06" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="795.73 872.27 795.5 872.32 795.5 962.09 795.95 962.17 795.95 872.32 795.73 872.27" />
                            <polygon class="cls-1" points="800.78 964.99 790.68 963.05 790.68 871.35 795.5 870.42 800.55 871.31 800.78 872.32 800.78 964.99" />
                          </g>
                        </g>
                        <g>
                          <g>
                            <polygon class="cls-2" points="796.61 872.35 1023.73 917.27 1251.36 872.26 1251.44 872.27 1251.43 871.99 1251.66 871.94 1024.03 826.93 796.38 871.94 796.61 871.99 796.61 872.35" />
                            <path class="cls-1" d="M1023.73,919.18l-231.94-45.87v-.37s-4.79-.96-4.79-.96l6.98-1.48,230.06-45.49,237.35,46.94-5.11,.99v1.84l-4.61-.69-227.93,45.07Zm-218.64-47.05l218.64,43.24,218.93-43.3-218.63-43.24-218.94,43.29Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="1023.73 917.27 796.61 872.35 796.61 962.12 796.38 962.17 1023.5 1007.09 1023.5 917.32 1023.73 917.27" />
                            <path class="cls-1" d="M1028.33,1009.94l-241.7-47.8,5.16-.99v-91.66l241.67,47.8-5.13,.99v91.66Zm-226.9-48.69l217.25,42.96v-86.03l-217.24-42.97v86.04Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="1251.44 872.27 1251.36 872.26 1023.73 917.27 1023.95 917.32 1023.95 1007.17 1024.03 1007.19 1251.66 962.16 1251.44 962.12 1251.44 872.27" />
                            <path class="cls-1" d="M1023.93,1009.11l-4.8-1.03v-89.8l-4.92-.95,7.1-1.5,229.98-45.48,4.88,.92,.09,.99v88.9s4.76,.96,4.76,.96l-6.96,1.46-230.13,45.52Zm4.84-90.93v86.16l217.83-43.09v-86.15s-217.83,43.08-217.83,43.08Z" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1023.95 1001.74 1023.5 1001.5 1023.5 1002.56 1023.73 1002.69 1023.95 1002.56 1023.95 1001.74" />
                            <polygon class="cls-1" points="1023.95 1008.06 1018.68 1005.44 1018.68 993.25 1028.33 998.68 1028.78 1004.63 1026.25 1006.81 1023.95 1008.06" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1023.73 917.27 1023.5 917.32 1023.5 1007.09 1023.95 1007.17 1023.95 917.32 1023.73 917.27" />
                            <polygon class="cls-1" points="1028.78 1009.99 1018.68 1008.05 1018.68 916.35 1023.5 915.42 1028.55 916.31 1028.78 917.32 1028.78 1009.99" />
                          </g>
                        </g>
                        <g>
                          <g>
                            <polygon class="cls-2" points="1026.61 918.35 1253.73 963.27 1481.36 918.26 1481.44 918.27 1481.43 917.99 1481.66 917.94 1254.03 872.93 1026.38 917.94 1026.61 917.99 1026.61 918.35" />
                            <path class="cls-1" d="M1253.73,965.18l-231.94-45.87v-.37s-4.79-.96-4.79-.96l6.98-1.48,230.06-45.49,237.35,46.94-5.11,.99v1.84l-4.61-.69-227.93,45.07Zm-218.64-47.05l218.64,43.24,218.93-43.3-218.63-43.24-218.94,43.29Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="1253.73 963.27 1026.61 918.35 1026.61 1008.12 1026.38 1008.17 1253.5 1053.09 1253.5 963.32 1253.73 963.27" />
                            <path class="cls-1" d="M1258.33,1055.94l-241.7-47.8,5.16-.99v-91.66l241.67,47.8-5.13,.99v91.66Zm-226.9-48.69l217.25,42.96v-86.03l-217.24-42.97v86.04Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="1481.44 918.27 1481.36 918.26 1253.73 963.27 1253.95 963.32 1253.95 1053.17 1254.03 1053.19 1481.66 1008.16 1481.44 1008.12 1481.44 918.27" />
                            <path class="cls-1" d="M1253.93,1055.11l-4.8-1.03v-89.8l-4.92-.95,7.1-1.5,229.98-45.48,4.88,.92,.09,.99v88.9s4.76,.96,4.76,.96l-6.96,1.46-230.13,45.52Zm4.84-90.93v86.16l217.83-43.09v-86.15s-217.83,43.08-217.83,43.08Z" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1253.95 1047.74 1253.5 1047.5 1253.5 1048.56 1253.73 1048.69 1253.95 1048.56 1253.95 1047.74" />
                            <polygon class="cls-1" points="1253.95 1054.06 1248.68 1051.44 1248.68 1039.25 1258.33 1044.68 1258.78 1050.63 1256.25 1052.81 1253.95 1054.06" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1253.73 963.27 1253.5 963.32 1253.5 1053.09 1253.95 1053.17 1253.95 963.32 1253.73 963.27" />
                            <polygon class="cls-1" points="1258.78 1055.99 1248.68 1054.05 1248.68 962.35 1253.5 961.42 1258.55 962.31 1258.78 963.32 1258.78 1055.99" />
                          </g>
                        </g>
                        <g>
                          <g>
                            <polygon class="cls-2" points="1254.61 963.35 1481.73 1008.27 1709.36 963.26 1709.44 963.27 1709.43 962.99 1709.66 962.94 1482.03 917.93 1254.38 962.94 1254.61 962.99 1254.61 963.35" />
                            <path class="cls-1" d="M1481.73,1010.18l-231.94-45.87v-.37s-4.79-.96-4.79-.96l6.98-1.48,230.06-45.49,237.35,46.94-5.11,.99v1.84l-4.61-.69-227.93,45.07Zm-218.64-47.05l218.64,43.24,218.93-43.3-218.63-43.24-218.94,43.29Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="1481.73 1008.27 1254.61 963.35 1254.61 1053.12 1254.38 1053.17 1481.5 1098.09 1481.5 1008.32 1481.73 1008.27" />
                            <path class="cls-1" d="M1486.33,1100.94l-241.7-47.8,5.16-.99v-91.66l241.67,47.8-5.13,.99v91.66Zm-226.9-48.69l217.25,42.96v-86.03l-217.24-42.97v86.04Z" />
                          </g>
                          <g className="k7b6base">
                            <polygon className={`k7b6 ${isHovered76 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered76(true)}
                              onMouseLeave={() => setIsHovered76(false)} points="1709.44 963.27 1709.36 963.26 1481.73 1008.27 1481.95 1008.32 1481.95 1098.17 1482.03 1098.19 1709.66 1053.16 1709.44 1053.12 1709.44 963.27" />
                            <path class="cls-1 k7b6path" d="M1481.93,1100.11l-4.8-1.03v-89.8l-4.92-.95,7.1-1.5,229.98-45.48,4.88,.92,.09,.99v88.9s4.76,.96,4.76,.96l-6.96,1.46-230.13,45.52Zm4.84-90.93v86.16l217.83-43.09v-86.15s-217.83,43.08-217.83,43.08Z" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1481.95 1092.74 1481.5 1092.5 1481.5 1093.56 1481.73 1093.69 1481.95 1093.56 1481.95 1092.74" />
                            <polygon class="cls-1" points="1481.95 1099.06 1476.68 1096.44 1476.68 1084.25 1486.33 1089.68 1486.78 1095.63 1484.25 1097.81 1481.95 1099.06" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1481.73 1008.27 1481.5 1008.32 1481.5 1098.09 1481.95 1098.17 1481.95 1008.32 1481.73 1008.27" />
                            <polygon class="cls-1" points="1486.78 1100.99 1476.68 1099.05 1476.68 1007.35 1481.5 1006.42 1486.55 1007.31 1486.78 1008.32 1486.78 1100.99" />
                          </g>
                        </g>
                        <g>
                          <g>
                            <polygon class="cls-2" points="338.61 873.35 565.73 918.27 793.36 873.26 793.44 873.27 793.43 872.99 793.66 872.94 566.03 827.93 338.38 872.94 338.61 872.99 338.61 873.35" />
                            <path class="cls-1" d="M565.73,920.18l-231.94-45.87v-.37s-4.79-.96-4.79-.96l6.98-1.48,230.06-45.49,237.35,46.94-5.11,.99v1.84l-4.61-.69-227.93,45.07Zm-218.64-47.05l218.64,43.24,218.93-43.3-218.63-43.24-218.94,43.29Z" />
                          </g>
                          <g className="k7b2base">
                            <polygon className={`k7b2 ${isHovered72 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered72(true)}
                              onMouseLeave={() => setIsHovered72(false)} points="565.73 918.27 338.61 873.35 338.61 963.12 338.38 963.17 565.5 1008.09 565.5 918.32 565.73 918.27" />
                            <path class="cls-1 k7b2path" d="M570.33,1010.94l-241.7-47.8,5.16-.99v-91.66l241.67,47.8-5.13,.99v91.66Zm-226.9-48.69l217.25,42.96v-86.03l-217.24-42.97v86.04Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="793.44 873.27 793.36 873.26 565.73 918.27 565.95 918.32 565.95 1008.17 566.03 1008.19 793.66 963.16 793.44 963.12 793.44 873.27" />
                            <path class="cls-1" d="M565.93,1010.11l-4.8-1.03v-89.8l-4.92-.95,7.1-1.5,229.98-45.48,4.88,.92,.09,.99v88.9s4.76,.96,4.76,.96l-6.96,1.46-230.13,45.52Zm4.84-90.93v86.16l217.83-43.09v-86.15s-217.83,43.08-217.83,43.08Z" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="565.95 1002.74 565.5 1002.5 565.5 1003.56 565.73 1003.69 565.95 1003.56 565.95 1002.74" />
                            <polygon class="cls-1" points="565.95 1009.06 560.68 1006.44 560.68 994.25 570.33 999.68 570.78 1005.63 568.25 1007.81 565.95 1009.06" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="565.73 918.27 565.5 918.32 565.5 1008.09 565.95 1008.17 565.95 918.32 565.73 918.27" />
                            <polygon class="cls-1" points="570.78 1010.99 560.68 1009.05 560.68 917.35 565.5 916.42 570.55 917.31 570.78 918.32 570.78 1010.99" />
                          </g>
                        </g>
                        <g>
                          <g>
                            <polygon class="cls-2" points="566.61 918.35 793.73 963.27 1021.36 918.26 1021.44 918.27 1021.43 917.99 1021.66 917.94 794.03 872.93 566.38 917.94 566.61 917.99 566.61 918.35" />
                            <path class="cls-1" d="M793.73,965.18l-231.94-45.87v-.37s-4.79-.96-4.79-.96l6.98-1.48,230.06-45.49,237.35,46.94-5.11,.99v1.84l-4.61-.69-227.93,45.07Zm-218.64-47.05l218.64,43.24,218.93-43.3-218.63-43.24-218.94,43.29Z" />
                          </g>
                          <g className="k7b3base">
                            <polygon className={`k7b3 ${isHovered73 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered73(true)}
                              onMouseLeave={() => setIsHovered73(false)} points="793.73 963.27 566.61 918.35 566.61 1008.12 566.38 1008.17 793.5 1053.09 793.5 963.32 793.73 963.27" />
                            <path class="cls-1 k7b3path" d="M798.33,1055.94l-241.7-47.8,5.16-.99v-91.66l241.67,47.8-5.13,.99v91.66Zm-226.9-48.69l217.25,42.96v-86.03l-217.24-42.97v86.04Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="1021.44 918.27 1021.36 918.26 793.73 963.27 793.95 963.32 793.95 1053.17 794.03 1053.19 1021.66 1008.16 1021.44 1008.12 1021.44 918.27" />
                            <path class="cls-1" d="M793.93,1055.11l-4.8-1.03v-89.8l-4.92-.95,7.1-1.5,229.98-45.48,4.88,.92,.09,.99v88.9s4.76,.96,4.76,.96l-6.96,1.46-230.13,45.52Zm4.84-90.93v86.16l217.83-43.09v-86.15s-217.83,43.08-217.83,43.08Z" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="793.95 1047.74 793.5 1047.5 793.5 1048.56 793.73 1048.69 793.95 1048.56 793.95 1047.74" />
                            <polygon class="cls-1" points="793.95 1054.06 788.68 1051.44 788.68 1039.25 798.33 1044.68 798.78 1050.63 796.25 1052.81 793.95 1054.06" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="793.73 963.27 793.5 963.32 793.5 1053.09 793.95 1053.17 793.95 963.32 793.73 963.27" />
                            <polygon class="cls-1" points="798.78 1055.99 788.68 1054.05 788.68 962.35 793.5 961.42 798.55 962.31 798.78 963.32 798.78 1055.99" />
                          </g>
                        </g>
                        <g>
                          <g>
                            <polygon class="cls-2" points="796.61 964.35 1023.73 1009.27 1251.36 964.26 1251.44 964.27 1251.43 963.99 1251.66 963.94 1024.03 918.93 796.38 963.94 796.61 963.99 796.61 964.35" />
                            <path class="cls-1" d="M1023.73,1011.18l-231.94-45.87v-.37s-4.79-.96-4.79-.96l6.98-1.48,230.06-45.49,237.35,46.94-5.11,.99v1.84l-4.61-.69-227.93,45.07Zm-218.64-47.05l218.64,43.24,218.93-43.3-218.63-43.24-218.94,43.29Z" />
                          </g>
                          <g className="k7b4base">
                            <polygon className={`k7b4 ${isHovered74 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered74(true)}
                              onMouseLeave={() => setIsHovered74(false)} points="1023.73 1009.27 796.61 964.35 796.61 1054.12 796.38 1054.17 1023.5 1099.09 1023.5 1009.32 1023.73 1009.27" />
                            <path class="cls-1 k7b4path" d="M1028.33,1101.94l-241.7-47.8,5.16-.99v-91.66l241.67,47.8-5.13,.99v91.66Zm-226.9-48.69l217.25,42.96v-86.03l-217.24-42.97v86.04Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="1251.44 964.27 1251.36 964.26 1023.73 1009.27 1023.95 1009.32 1023.95 1099.17 1024.03 1099.19 1251.66 1054.16 1251.44 1054.12 1251.44 964.27" />
                            <path class="cls-1" d="M1023.93,1101.11l-4.8-1.03v-89.8l-4.92-.95,7.1-1.5,229.98-45.48,4.88,.92,.09,.99v88.9s4.76,.96,4.76,.96l-6.96,1.46-230.13,45.52Zm4.84-90.93v86.16l217.83-43.09v-86.15s-217.83,43.08-217.83,43.08Z" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1023.95 1093.74 1023.5 1093.5 1023.5 1094.56 1023.73 1094.69 1023.95 1094.56 1023.95 1093.74" />
                            <polygon class="cls-1" points="1023.95 1100.06 1018.68 1097.44 1018.68 1085.25 1028.33 1090.68 1028.78 1096.63 1026.25 1098.81 1023.95 1100.06" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1023.73 1009.27 1023.5 1009.32 1023.5 1099.09 1023.95 1099.17 1023.95 1009.32 1023.73 1009.27" />
                            <polygon class="cls-1" points="1028.78 1101.99 1018.68 1100.05 1018.68 1008.35 1023.5 1007.42 1028.55 1008.31 1028.78 1009.32 1028.78 1101.99" />
                          </g>
                        </g>
                        <g>
                          <g>
                            <polygon class="cls-2" points="1024.61 1009.35 1251.73 1054.27 1479.36 1009.26 1479.44 1009.27 1479.43 1008.99 1479.66 1008.94 1252.03 963.93 1024.38 1008.94 1024.61 1008.99 1024.61 1009.35" />
                            <path class="cls-1" d="M1251.73,1056.18l-231.94-45.87v-.37s-4.79-.96-4.79-.96l6.98-1.48,230.06-45.49,237.35,46.94-5.11,.99v1.84l-4.61-.69-227.93,45.07Zm-218.64-47.05l218.64,43.24,218.93-43.3-218.63-43.24-218.94,43.29Z" />
                          </g>
                          <g className="k7b5base">
                            <polygon className={`k7b5 ${isHovered75 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered75(true)}
                              onMouseLeave={() => setIsHovered75(false)} points="1251.73 1054.27 1024.61 1009.35 1024.61 1099.12 1024.38 1099.17 1251.5 1144.09 1251.5 1054.32 1251.73 1054.27" />
                            <path class="cls-1 k7b5path" d="M1256.33,1146.94l-241.7-47.8,5.16-.99v-91.66l241.67,47.8-5.13,.99v91.66Zm-226.9-48.69l217.25,42.96v-86.03l-217.24-42.97v86.04Z" />
                          </g>
                          <g className="k7b5base">
                            <polygon className={`k7b5 ${isHovered75 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered75(true)}
                              onMouseLeave={() => setIsHovered75(false)} points="1479.44 1009.27 1479.36 1009.26 1251.73 1054.27 1251.95 1054.32 1251.95 1144.17 1252.03 1144.19 1479.66 1099.16 1479.44 1099.12 1479.44 1009.27" />
                            <path class="cls-1 k7b5path" d="M1251.93,1146.11l-4.8-1.03v-89.8l-4.92-.95,7.1-1.5,229.98-45.48,4.88,.92,.09,.99v88.9s4.76,.96,4.76,.96l-6.96,1.46-230.13,45.52Zm4.84-90.93v86.16l217.83-43.09v-86.15s-217.83,43.08-217.83,43.08Z" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1251.95 1138.74 1251.5 1138.5 1251.5 1139.56 1251.73 1139.69 1251.95 1139.56 1251.95 1138.74" />
                            <polygon class="cls-1" points="1251.95 1145.06 1246.68 1142.44 1246.68 1130.25 1256.33 1135.68 1256.78 1141.63 1254.25 1143.81 1251.95 1145.06" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1251.73 1054.27 1251.5 1054.32 1251.5 1144.09 1251.95 1144.17 1251.95 1054.32 1251.73 1054.27" />
                            <polygon class="cls-1" points="1256.78 1146.99 1246.68 1145.05 1246.68 1053.35 1251.5 1052.42 1256.55 1053.31 1256.78 1054.32 1256.78 1146.99" />
                          </g>
                        </g>
                      </g>
                      <g>
                        <g>
                          <g>
                            <polygon class="cls-3" points="568.61 733.35 795.73 778.27 1023.36 733.26 1023.44 733.27 1023.43 732.99 1023.66 732.94 796.03 687.93 568.38 732.94 568.61 732.99 568.61 733.35" />
                            <path class="cls-1" d="M795.73,780.18l-231.94-45.87v-.37s-4.79-.96-4.79-.96l6.98-1.48,230.06-45.49,237.35,46.94-5.11,.99v1.84l-4.61-.69-227.93,45.07Zm-218.64-47.05l218.64,43.24,218.93-43.3-218.63-43.24-218.94,43.29Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="795.73 778.27 568.61 733.35 568.61 823.12 568.38 823.17 795.5 868.09 795.5 778.32 795.73 778.27" />
                            <path class="cls-1" d="M800.33,870.94l-241.7-47.8,5.16-.99v-91.66l241.67,47.8-5.13,.99v91.66Zm-226.9-48.69l217.25,42.96v-86.03l-217.24-42.97v86.04Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="1023.44 733.27 1023.36 733.26 795.73 778.27 795.95 778.32 795.95 868.17 796.03 868.19 1023.66 823.16 1023.44 823.12 1023.44 733.27" />
                            <path class="cls-1" d="M795.93,870.11l-4.8-1.03v-89.8l-4.92-.95,7.1-1.5,229.98-45.48,4.88,.92,.09,.99v88.9s4.76,.96,4.76,.96l-6.96,1.46-230.13,45.52Zm4.84-90.93v86.16l217.83-43.09v-86.15s-217.83,43.08-217.83,43.08Z" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="795.95 862.74 795.5 862.5 795.5 863.56 795.73 863.69 795.95 863.56 795.95 862.74" />
                            <polygon class="cls-1" points="795.95 869.06 790.68 866.44 790.68 854.25 800.33 859.68 800.78 865.63 798.25 867.81 795.95 869.06" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="795.73 778.27 795.5 778.32 795.5 868.09 795.95 868.17 795.95 778.32 795.73 778.27" />
                            <polygon class="cls-1" points="800.78 870.99 790.68 869.05 790.68 777.35 795.5 776.42 800.55 777.31 800.78 778.32 800.78 870.99" />
                          </g>
                        </g>
                        <g>
                          <g>
                            <polygon class="cls-3" points="796.61 778.35 1023.73 823.27 1251.36 778.26 1251.44 778.27 1251.43 777.99 1251.66 777.94 1024.03 732.93 796.38 777.94 796.61 777.99 796.61 778.35" />
                            <path class="cls-1" d="M1023.73,825.18l-231.94-45.87v-.37s-4.79-.96-4.79-.96l6.98-1.48,230.06-45.49,237.35,46.94-5.11,.99v1.84l-4.61-.69-227.93,45.07Zm-218.64-47.05l218.64,43.24,218.93-43.3-218.63-43.24-218.94,43.29Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="1023.73 823.27 796.61 778.35 796.61 868.12 796.38 868.17 1023.5 913.09 1023.5 823.32 1023.73 823.27" />
                            <path class="cls-1" d="M1028.33,915.94l-241.7-47.8,5.16-.99v-91.66l241.67,47.8-5.13,.99v91.66Zm-226.9-48.69l217.25,42.96v-86.03l-217.24-42.97v86.04Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="1251.44 778.27 1251.36 778.26 1023.73 823.27 1023.95 823.32 1023.95 913.17 1024.03 913.19 1251.66 868.16 1251.44 868.12 1251.44 778.27" />
                            <path class="cls-1" d="M1023.93,915.11l-4.8-1.03v-89.8l-4.92-.95,7.1-1.5,229.98-45.48,4.88,.92,.09,.99v88.9s4.76,.96,4.76,.96l-6.96,1.46-230.13,45.52Zm4.84-90.93v86.16l217.83-43.09v-86.15s-217.83,43.08-217.83,43.08Z" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1023.95 907.74 1023.5 907.5 1023.5 908.56 1023.73 908.69 1023.95 908.56 1023.95 907.74" />
                            <polygon class="cls-1" points="1023.95 914.06 1018.68 911.44 1018.68 899.25 1028.33 904.68 1028.78 910.63 1026.25 912.81 1023.95 914.06" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1023.73 823.27 1023.5 823.32 1023.5 913.09 1023.95 913.17 1023.95 823.32 1023.73 823.27" />
                            <polygon class="cls-1" points="1028.78 915.99 1018.68 914.05 1018.68 822.35 1023.5 821.42 1028.55 822.31 1028.78 823.32 1028.78 915.99" />
                          </g>
                        </g>
                        <g>
                          <g>
                            <polygon class="cls-3" points="1026.61 824.35 1253.73 869.27 1481.36 824.26 1481.44 824.27 1481.43 823.99 1481.66 823.94 1254.03 778.93 1026.38 823.94 1026.61 823.99 1026.61 824.35" />
                            <path class="cls-1" d="M1253.73,871.18l-231.94-45.87v-.37s-4.79-.96-4.79-.96l6.98-1.48,230.06-45.49,237.35,46.94-5.11,.99v1.84l-4.61-.69-227.93,45.07Zm-218.64-47.05l218.64,43.24,218.93-43.3-218.63-43.24-218.94,43.29Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="1253.73 869.27 1026.61 824.35 1026.61 914.12 1026.38 914.17 1253.5 959.09 1253.5 869.32 1253.73 869.27" />
                            <path class="cls-1" d="M1258.33,961.94l-241.7-47.8,5.16-.99v-91.66l241.67,47.8-5.13,.99v91.66Zm-226.9-48.69l217.25,42.96v-86.03l-217.24-42.97v86.04Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="1481.44 824.27 1481.36 824.26 1253.73 869.27 1253.95 869.32 1253.95 959.17 1254.03 959.19 1481.66 914.16 1481.44 914.12 1481.44 824.27" />
                            <path class="cls-1" d="M1253.93,961.11l-4.8-1.03v-89.8l-4.92-.95,7.1-1.5,229.98-45.48,4.88,.92,.09,.99v88.9s4.76,.96,4.76,.96l-6.96,1.46-230.13,45.52Zm4.84-90.93v86.16l217.83-43.09v-86.15s-217.83,43.08-217.83,43.08Z" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1253.95 953.74 1253.5 953.5 1253.5 954.56 1253.73 954.69 1253.95 954.56 1253.95 953.74" />
                            <polygon class="cls-1" points="1253.95 960.06 1248.68 957.44 1248.68 945.25 1258.33 950.68 1258.78 956.63 1256.25 958.81 1253.95 960.06" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1253.73 869.27 1253.5 869.32 1253.5 959.09 1253.95 959.17 1253.95 869.32 1253.73 869.27" />
                            <polygon class="cls-1" points="1258.78 961.99 1248.68 960.05 1248.68 868.35 1253.5 867.42 1258.55 868.31 1258.78 869.32 1258.78 961.99" />
                          </g>
                        </g>
                        <g>
                          <g>
                            <polygon class="cls-3" points="1254.61 869.35 1481.73 914.27 1709.36 869.26 1709.44 869.27 1709.43 868.99 1709.66 868.94 1482.03 823.93 1254.38 868.94 1254.61 868.99 1254.61 869.35" />
                            <path class="cls-1" d="M1481.73,916.18l-231.94-45.87v-.37s-4.79-.96-4.79-.96l6.98-1.48,230.06-45.49,237.35,46.94-5.11,.99v1.84l-4.61-.69-227.93,45.07Zm-218.64-47.05l218.64,43.24,218.93-43.3-218.63-43.24-218.94,43.29Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="1481.73 914.27 1254.61 869.35 1254.61 959.12 1254.38 959.17 1481.5 1004.09 1481.5 914.32 1481.73 914.27" />
                            <path class="cls-1" d="M1486.33,1006.94l-241.7-47.8,5.16-.99v-91.66l241.67,47.8-5.13,.99v91.66Zm-226.9-48.69l217.25,42.96v-86.03l-217.24-42.97v86.04Z" />
                          </g>
                          <g className="k8b6base">
                            <polygon className={`k8b6 ${isHovered86 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered86(true)}
                              onMouseLeave={() => setIsHovered86(false)} points="1709.44 869.27 1709.36 869.26 1481.73 914.27 1481.95 914.32 1481.95 1004.17 1482.03 1004.19 1709.66 959.16 1709.44 959.12 1709.44 869.27" />
                            <path class="cls-1 k8b6path" d="M1481.93,1006.11l-4.8-1.03v-89.8l-4.92-.95,7.1-1.5,229.98-45.48,4.88,.92,.09,.99v88.9s4.76,.96,4.76,.96l-6.96,1.46-230.13,45.52Zm4.84-90.93v86.16l217.83-43.09v-86.15s-217.83,43.08-217.83,43.08Z" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1481.95 998.74 1481.5 998.5 1481.5 999.56 1481.73 999.69 1481.95 999.56 1481.95 998.74" />
                            <polygon class="cls-1" points="1481.95 1005.06 1476.68 1002.44 1476.68 990.25 1486.33 995.68 1486.78 1001.63 1484.25 1003.81 1481.95 1005.06" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1481.73 914.27 1481.5 914.32 1481.5 1004.09 1481.95 1004.17 1481.95 914.32 1481.73 914.27" />
                            <polygon class="cls-1" points="1486.78 1006.99 1476.68 1005.05 1476.68 913.35 1481.5 912.42 1486.55 913.31 1486.78 914.32 1486.78 1006.99" />
                          </g>
                        </g>
                        <g>
                          <g>
                            <polygon class="cls-3" points="338.61 779.35 565.73 824.27 793.36 779.26 793.44 779.27 793.43 778.99 793.66 778.94 566.03 733.93 338.38 778.94 338.61 778.99 338.61 779.35" />
                            <path class="cls-1" d="M565.73,826.18l-231.94-45.87v-.37s-4.79-.96-4.79-.96l6.98-1.48,230.06-45.49,237.35,46.94-5.11,.99v1.84l-4.61-.69-227.93,45.07Zm-218.64-47.05l218.64,43.24,218.93-43.3-218.63-43.24-218.94,43.29Z" />
                          </g>
                          <g className="k8b2base">
                            <polygon className={`k8b2 ${isHovered82 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered82(true)}
                              onMouseLeave={() => setIsHovered82(false)} points="565.73 824.27 338.61 779.35 338.61 869.12 338.38 869.17 565.5 914.09 565.5 824.32 565.73 824.27" />
                            <path class="cls-1 k8b2path" d="M570.33,916.94l-241.7-47.8,5.16-.99v-91.66l241.67,47.8-5.13,.99v91.66Zm-226.9-48.69l217.25,42.96v-86.03l-217.24-42.97v86.04Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="793.44 779.27 793.36 779.26 565.73 824.27 565.95 824.32 565.95 914.17 566.03 914.19 793.66 869.16 793.44 869.12 793.44 779.27" />
                            <path class="cls-1" d="M565.93,916.11l-4.8-1.03v-89.8l-4.92-.95,7.1-1.5,229.98-45.48,4.88,.92,.09,.99v88.9s4.76,.96,4.76,.96l-6.96,1.46-230.13,45.52Zm4.84-90.93v86.16l217.83-43.09v-86.15s-217.83,43.08-217.83,43.08Z" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="565.95 908.74 565.5 908.5 565.5 909.56 565.73 909.69 565.95 909.56 565.95 908.74" />
                            <polygon class="cls-1" points="565.95 915.06 560.68 912.44 560.68 900.25 570.33 905.68 570.78 911.63 568.25 913.81 565.95 915.06" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="565.73 824.27 565.5 824.32 565.5 914.09 565.95 914.17 565.95 824.32 565.73 824.27" />
                            <polygon class="cls-1" points="570.78 916.99 560.68 915.05 560.68 823.35 565.5 822.42 570.55 823.31 570.78 824.32 570.78 916.99" />
                          </g>
                        </g>
                        <g>
                          <g>
                            <polygon class="cls-3" points="566.61 824.35 793.73 869.27 1021.36 824.26 1021.44 824.27 1021.43 823.99 1021.66 823.94 794.03 778.93 566.38 823.94 566.61 823.99 566.61 824.35" />
                            <path class="cls-1" d="M793.73,871.18l-231.94-45.87v-.37s-4.79-.96-4.79-.96l6.98-1.48,230.06-45.49,237.35,46.94-5.11,.99v1.84l-4.61-.69-227.93,45.07Zm-218.64-47.05l218.64,43.24,218.93-43.3-218.63-43.24-218.94,43.29Z" />
                          </g>
                          <g className="k8b3base">
                            <polygon className={`k8b3 ${isHovered83 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered83(true)}
                              onMouseLeave={() => setIsHovered83(false)} points="793.73 869.27 566.61 824.35 566.61 914.12 566.38 914.17 793.5 959.09 793.5 869.32 793.73 869.27" />
                            <path class="cls-1 k8b3path" d="M798.33,961.94l-241.7-47.8,5.16-.99v-91.66l241.67,47.8-5.13,.99v91.66Zm-226.9-48.69l217.25,42.96v-86.03l-217.24-42.97v86.04Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="1021.44 824.27 1021.36 824.26 793.73 869.27 793.95 869.32 793.95 959.17 794.03 959.19 1021.66 914.16 1021.44 914.12 1021.44 824.27" />
                            <path class="cls-1" d="M793.93,961.11l-4.8-1.03v-89.8l-4.92-.95,7.1-1.5,229.98-45.48,4.88,.92,.09,.99v88.9s4.76,.96,4.76,.96l-6.96,1.46-230.13,45.52Zm4.84-90.93v86.16l217.83-43.09v-86.15s-217.83,43.08-217.83,43.08Z" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="793.95 953.74 793.5 953.5 793.5 954.56 793.73 954.69 793.95 954.56 793.95 953.74" />
                            <polygon class="cls-1" points="793.95 960.06 788.68 957.44 788.68 945.25 798.33 950.68 798.78 956.63 796.25 958.81 793.95 960.06" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="793.73 869.27 793.5 869.32 793.5 959.09 793.95 959.17 793.95 869.32 793.73 869.27" />
                            <polygon class="cls-1" points="798.78 961.99 788.68 960.05 788.68 868.35 793.5 867.42 798.55 868.31 798.78 869.32 798.78 961.99" />
                          </g>
                        </g>
                        <g>
                          <g>
                            <polygon class="cls-3" points="796.61 870.35 1023.73 915.27 1251.36 870.26 1251.44 870.27 1251.43 869.99 1251.66 869.94 1024.03 824.93 796.38 869.94 796.61 869.99 796.61 870.35" />
                            <path class="cls-1" d="M1023.73,917.18l-231.94-45.87v-.37s-4.79-.96-4.79-.96l6.98-1.48,230.06-45.49,237.35,46.94-5.11,.99v1.84l-4.61-.69-227.93,45.07Zm-218.64-47.05l218.64,43.24,218.93-43.3-218.63-43.24-218.94,43.29Z" />
                          </g>
                          <g className="k8b4base">
                            <polygon className={`k8b4 ${isHovered84 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered84(true)}
                              onMouseLeave={() => setIsHovered84(false)} points="1023.73 915.27 796.61 870.35 796.61 960.12 796.38 960.17 1023.5 1005.09 1023.5 915.32 1023.73 915.27" />
                            <path class="cls-1 k8b4path" d="M1028.33,1007.94l-241.7-47.8,5.16-.99v-91.66l241.67,47.8-5.13,.99v91.66Zm-226.9-48.69l217.25,42.96v-86.03l-217.24-42.97v86.04Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="1251.44 870.27 1251.36 870.26 1023.73 915.27 1023.95 915.32 1023.95 1005.17 1024.03 1005.19 1251.66 960.16 1251.44 960.12 1251.44 870.27" />
                            <path class="cls-1" d="M1023.93,1007.11l-4.8-1.03v-89.8l-4.92-.95,7.1-1.5,229.98-45.48,4.88,.92,.09,.99v88.9s4.76,.96,4.76,.96l-6.96,1.46-230.13,45.52Zm4.84-90.93v86.16l217.83-43.09v-86.15s-217.83,43.08-217.83,43.08Z" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1023.95 999.74 1023.5 999.5 1023.5 1000.56 1023.73 1000.69 1023.95 1000.56 1023.95 999.74" />
                            <polygon class="cls-1" points="1023.95 1006.06 1018.68 1003.44 1018.68 991.25 1028.33 996.68 1028.78 1002.63 1026.25 1004.81 1023.95 1006.06" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1023.73 915.27 1023.5 915.32 1023.5 1005.09 1023.95 1005.17 1023.95 915.32 1023.73 915.27" />
                            <polygon class="cls-1" points="1028.78 1007.99 1018.68 1006.05 1018.68 914.35 1023.5 913.42 1028.55 914.31 1028.78 915.32 1028.78 1007.99" />
                          </g>
                        </g>
                        <g>
                          <g>
                            <polygon class="cls-3" points="1024.61 915.35 1251.73 960.27 1479.36 915.26 1479.44 915.27 1479.43 914.99 1479.66 914.94 1252.03 869.93 1024.38 914.94 1024.61 914.99 1024.61 915.35" />
                            <path class="cls-1" d="M1251.73,962.18l-231.94-45.87v-.37s-4.79-.96-4.79-.96l6.98-1.48,230.06-45.49,237.35,46.94-5.11,.99v1.84l-4.61-.69-227.93,45.07Zm-218.64-47.05l218.64,43.24,218.93-43.3-218.63-43.24-218.94,43.29Z" />
                          </g>
                          <g className="k8b5base">
                            <polygon className={`k8b5 ${isHovered85 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered85(true)}
                              onMouseLeave={() => setIsHovered85(false)} points="1251.73 960.27 1024.61 915.35 1024.61 1005.12 1024.38 1005.17 1251.5 1050.09 1251.5 960.32 1251.73 960.27" />
                            <path class="cls-1 k8b5path" d="M1256.33,1052.94l-241.7-47.8,5.16-.99v-91.66l241.67,47.8-5.13,.99v91.66Zm-226.9-48.69l217.25,42.96v-86.03l-217.24-42.97v86.04Z" />
                          </g>
                          <g className="k8b5base">
                            <polygon className={`k8b5 ${isHovered85 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered85(true)}
                              onMouseLeave={() => setIsHovered85(false)} points="1479.44 915.27 1479.36 915.26 1251.73 960.27 1251.95 960.32 1251.95 1050.17 1252.03 1050.19 1479.66 1005.16 1479.44 1005.12 1479.44 915.27" />
                            <path class="cls-1 k8b5path" d="M1251.93,1052.11l-4.8-1.03v-89.8l-4.92-.95,7.1-1.5,229.98-45.48,4.88,.92,.09,.99v88.9s4.76,.96,4.76,.96l-6.96,1.46-230.13,45.52Zm4.84-90.93v86.16l217.83-43.09v-86.15s-217.83,43.08-217.83,43.08Z" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1251.95 1044.74 1251.5 1044.5 1251.5 1045.56 1251.73 1045.69 1251.95 1045.56 1251.95 1044.74" />
                            <polygon class="cls-1" points="1251.95 1051.06 1246.68 1048.44 1246.68 1036.25 1256.33 1041.68 1256.78 1047.63 1254.25 1049.81 1251.95 1051.06" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1251.73 960.27 1251.5 960.32 1251.5 1050.09 1251.95 1050.17 1251.95 960.32 1251.73 960.27" />
                            <polygon class="cls-1" points="1256.78 1052.99 1246.68 1051.05 1246.68 959.35 1251.5 958.42 1256.55 959.31 1256.78 960.32 1256.78 1052.99" />
                          </g>
                        </g>
                      </g>
                      <g>
                        <g>
                          <g>
                            <polygon class="cls-3" points="568.61 638.35 795.73 683.27 1023.36 638.26 1023.44 638.27 1023.43 637.99 1023.66 637.94 796.03 592.93 568.38 637.94 568.61 637.99 568.61 638.35" />
                            <path class="cls-1" d="M795.73,685.18l-231.94-45.87v-.37s-4.79-.96-4.79-.96l6.98-1.48,230.06-45.49,237.35,46.94-5.11,.99v1.84l-4.61-.69-227.93,45.07Zm-218.64-47.05l218.64,43.24,218.93-43.3-218.63-43.24-218.94,43.29Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="795.73 683.27 568.61 638.35 568.61 728.12 568.38 728.17 795.5 773.09 795.5 683.32 795.73 683.27" />
                            <path class="cls-1" d="M800.33,775.94l-241.7-47.8,5.16-.99v-91.66l241.67,47.8-5.13,.99v91.66Zm-226.9-48.69l217.25,42.96v-86.03l-217.24-42.97v86.04Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="1023.44 638.27 1023.36 638.26 795.73 683.27 795.95 683.32 795.95 773.17 796.03 773.19 1023.66 728.16 1023.44 728.12 1023.44 638.27" />
                            <path class="cls-1" d="M795.93,775.11l-4.8-1.03v-89.8l-4.92-.95,7.1-1.5,229.98-45.48,4.88,.92,.09,.99v88.9s4.76,.96,4.76,.96l-6.96,1.46-230.13,45.52Zm4.84-90.93v86.16l217.83-43.09v-86.15s-217.83,43.08-217.83,43.08Z" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="795.95 767.74 795.5 767.5 795.5 768.56 795.73 768.69 795.95 768.56 795.95 767.74" />
                            <polygon class="cls-1" points="795.95 774.06 790.68 771.44 790.68 759.25 800.33 764.68 800.78 770.63 798.25 772.81 795.95 774.06" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="795.73 683.27 795.5 683.32 795.5 773.09 795.95 773.17 795.95 683.32 795.73 683.27" />
                            <polygon class="cls-1" points="800.78 775.99 790.68 774.05 790.68 682.35 795.5 681.42 800.55 682.31 800.78 683.32 800.78 775.99" />
                          </g>
                        </g>
                        <g>
                          <g>
                            <polygon class="cls-3" points="796.61 683.35 1023.73 728.27 1251.36 683.26 1251.44 683.27 1251.43 682.99 1251.66 682.94 1024.03 637.93 796.38 682.94 796.61 682.99 796.61 683.35" />
                            <path class="cls-1" d="M1023.73,730.18l-231.94-45.87v-.37s-4.79-.96-4.79-.96l6.98-1.48,230.06-45.49,237.35,46.94-5.11,.99v1.84l-4.61-.69-227.93,45.07Zm-218.64-47.05l218.64,43.24,218.93-43.3-218.63-43.24-218.94,43.29Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="1023.73 728.27 796.61 683.35 796.61 773.12 796.38 773.17 1023.5 818.09 1023.5 728.32 1023.73 728.27" />
                            <path class="cls-1" d="M1028.33,820.94l-241.7-47.8,5.16-.99v-91.66l241.67,47.8-5.13,.99v91.66Zm-226.9-48.69l217.25,42.96v-86.03l-217.24-42.97v86.04Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="1251.44 683.27 1251.36 683.26 1023.73 728.27 1023.95 728.32 1023.95 818.17 1024.03 818.19 1251.66 773.16 1251.44 773.12 1251.44 683.27" />
                            <path class="cls-1" d="M1023.93,820.11l-4.8-1.03v-89.8l-4.92-.95,7.1-1.5,229.98-45.48,4.88,.92,.09,.99v88.9s4.76,.96,4.76,.96l-6.96,1.46-230.13,45.52Zm4.84-90.93v86.16l217.83-43.09v-86.15s-217.83,43.08-217.83,43.08Z" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1023.95 812.74 1023.5 812.5 1023.5 813.56 1023.73 813.69 1023.95 813.56 1023.95 812.74" />
                            <polygon class="cls-1" points="1023.95 819.06 1018.68 816.44 1018.68 804.25 1028.33 809.68 1028.78 815.63 1026.25 817.81 1023.95 819.06" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1023.73 728.27 1023.5 728.32 1023.5 818.09 1023.95 818.17 1023.95 728.32 1023.73 728.27" />
                            <polygon class="cls-1" points="1028.78 820.99 1018.68 819.05 1018.68 727.35 1023.5 726.42 1028.55 727.31 1028.78 728.32 1028.78 820.99" />
                          </g>
                        </g>
                        <g>
                          <g>
                            <polygon class="cls-3" points="1026.61 729.35 1253.73 774.27 1481.36 729.26 1481.44 729.27 1481.43 728.99 1481.66 728.94 1254.03 683.93 1026.38 728.94 1026.61 728.99 1026.61 729.35" />
                            <path class="cls-1" d="M1253.73,776.18l-231.94-45.87v-.37s-4.79-.96-4.79-.96l6.98-1.48,230.06-45.49,237.35,46.94-5.11,.99v1.84l-4.61-.69-227.93,45.07Zm-218.64-47.05l218.64,43.24,218.93-43.3-218.63-43.24-218.94,43.29Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="1253.73 774.27 1026.61 729.35 1026.61 819.12 1026.38 819.17 1253.5 864.09 1253.5 774.32 1253.73 774.27" />
                            <path class="cls-1" d="M1258.33,866.94l-241.7-47.8,5.16-.99v-91.66l241.67,47.8-5.13,.99v91.66Zm-226.9-48.69l217.25,42.96v-86.03l-217.24-42.97v86.04Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="1481.44 729.27 1481.36 729.26 1253.73 774.27 1253.95 774.32 1253.95 864.17 1254.03 864.19 1481.66 819.16 1481.44 819.12 1481.44 729.27" />
                            <path class="cls-1" d="M1253.93,866.11l-4.8-1.03v-89.8l-4.92-.95,7.1-1.5,229.98-45.48,4.88,.92,.09,.99v88.9s4.76,.96,4.76,.96l-6.96,1.46-230.13,45.52Zm4.84-90.93v86.16l217.83-43.09v-86.15s-217.83,43.08-217.83,43.08Z" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1253.95 858.74 1253.5 858.5 1253.5 859.56 1253.73 859.69 1253.95 859.56 1253.95 858.74" />
                            <polygon class="cls-1" points="1253.95 865.06 1248.68 862.44 1248.68 850.25 1258.33 855.68 1258.78 861.63 1256.25 863.81 1253.95 865.06" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1253.73 774.27 1253.5 774.32 1253.5 864.09 1253.95 864.17 1253.95 774.32 1253.73 774.27" />
                            <polygon class="cls-1" points="1258.78 866.99 1248.68 865.05 1248.68 773.35 1253.5 772.42 1258.55 773.31 1258.78 774.32 1258.78 866.99" />
                          </g>
                        </g>
                        <g>
                          <g>
                            <polygon class="cls-3" points="1254.61 774.35 1481.73 819.27 1709.36 774.26 1709.44 774.27 1709.43 773.99 1709.66 773.94 1482.03 728.93 1254.38 773.94 1254.61 773.99 1254.61 774.35" />
                            <path class="cls-1" d="M1481.73,821.18l-231.94-45.87v-.37s-4.79-.96-4.79-.96l6.98-1.48,230.06-45.49,237.35,46.94-5.11,.99v1.84l-4.61-.69-227.93,45.07Zm-218.64-47.05l218.64,43.24,218.93-43.3-218.63-43.24-218.94,43.29Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="1481.73 819.27 1254.61 774.35 1254.61 864.12 1254.38 864.17 1481.5 909.09 1481.5 819.32 1481.73 819.27" />
                            <path class="cls-1" d="M1486.33,911.94l-241.7-47.8,5.16-.99v-91.66l241.67,47.8-5.13,.99v91.66Zm-226.9-48.69l217.25,42.96v-86.03l-217.24-42.97v86.04Z" />
                          </g>
                          <g className="k9b6base">
                            <polygon className={`k9b6 ${isHovered96 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered96(true)}
                              onMouseLeave={() => setIsHovered96(false)} points="1709.44 774.27 1709.36 774.26 1481.73 819.27 1481.95 819.32 1481.95 909.17 1482.03 909.19 1709.66 864.16 1709.44 864.12 1709.44 774.27" />
                            <path class="cls-1 k9b6path" d="M1481.93,911.11l-4.8-1.03v-89.8l-4.92-.95,7.1-1.5,229.98-45.48,4.88,.92,.09,.99v88.9s4.76,.96,4.76,.96l-6.96,1.46-230.13,45.52Zm4.84-90.93v86.16l217.83-43.09v-86.15s-217.83,43.08-217.83,43.08Z" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1481.95 903.74 1481.5 903.5 1481.5 904.56 1481.73 904.69 1481.95 904.56 1481.95 903.74" />
                            <polygon class="cls-1" points="1481.95 910.06 1476.68 907.44 1476.68 895.25 1486.33 900.68 1486.78 906.63 1484.25 908.81 1481.95 910.06" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1481.73 819.27 1481.5 819.32 1481.5 909.09 1481.95 909.17 1481.95 819.32 1481.73 819.27" />
                            <polygon class="cls-1" points="1486.78 911.99 1476.68 910.05 1476.68 818.35 1481.5 817.42 1486.55 818.31 1486.78 819.32 1486.78 911.99" />
                          </g>
                        </g>
                        <g>
                          <g>
                            <polygon class="cls-3" points="338.61 684.35 565.73 729.27 793.36 684.26 793.44 684.27 793.43 683.99 793.66 683.94 566.03 638.93 338.38 683.94 338.61 683.99 338.61 684.35" />
                            <path class="cls-1" d="M565.73,731.18l-231.94-45.87v-.37s-4.79-.96-4.79-.96l6.98-1.48,230.06-45.49,237.35,46.94-5.11,.99v1.84l-4.61-.69-227.93,45.07Zm-218.64-47.05l218.64,43.24,218.93-43.3-218.63-43.24-218.94,43.29Z" />
                          </g>
                          <g className="k9b2base">
                            <polygon className={`k9b2 ${isHovered92 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered92(true)}
                              onMouseLeave={() => setIsHovered92(false)} points="565.73 729.27 338.61 684.35 338.61 774.12 338.38 774.17 565.5 819.09 565.5 729.32 565.73 729.27" />
                            <path class="cls-1 k9b2path" d="M570.33,821.94l-241.7-47.8,5.16-.99v-91.66l241.67,47.8-5.13,.99v91.66Zm-226.9-48.69l217.25,42.96v-86.03l-217.24-42.97v86.04Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="793.44 684.27 793.36 684.26 565.73 729.27 565.95 729.32 565.95 819.17 566.03 819.19 793.66 774.16 793.44 774.12 793.44 684.27" />
                            <path class="cls-1" d="M565.93,821.11l-4.8-1.03v-89.8l-4.92-.95,7.1-1.5,229.98-45.48,4.88,.92,.09,.99v88.9s4.76,.96,4.76,.96l-6.96,1.46-230.13,45.52Zm4.84-90.93v86.16l217.83-43.09v-86.15s-217.83,43.08-217.83,43.08Z" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="565.95 813.74 565.5 813.5 565.5 814.56 565.73 814.69 565.95 814.56 565.95 813.74" />
                            <polygon class="cls-1" points="565.95 820.06 560.68 817.44 560.68 805.25 570.33 810.68 570.78 816.63 568.25 818.81 565.95 820.06" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="565.73 729.27 565.5 729.32 565.5 819.09 565.95 819.17 565.95 729.32 565.73 729.27" />
                            <polygon class="cls-1" points="570.78 821.99 560.68 820.05 560.68 728.35 565.5 727.42 570.55 728.31 570.78 729.32 570.78 821.99" />
                          </g>
                        </g>
                        <g>
                          <g>
                            <polygon class="cls-3" points="566.61 729.35 793.73 774.27 1021.36 729.26 1021.44 729.27 1021.43 728.99 1021.66 728.94 794.03 683.93 566.38 728.94 566.61 728.99 566.61 729.35" />
                            <path class="cls-1" d="M793.73,776.18l-231.94-45.87v-.37s-4.79-.96-4.79-.96l6.98-1.48,230.06-45.49,237.35,46.94-5.11,.99v1.84l-4.61-.69-227.93,45.07Zm-218.64-47.05l218.64,43.24,218.93-43.3-218.63-43.24-218.94,43.29Z" />
                          </g>
                          <g className="k9b3base">
                            <polygon className={`k9b3 ${isHovered93 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered93(true)}
                              onMouseLeave={() => setIsHovered93(false)} points="793.73 774.27 566.61 729.35 566.61 819.12 566.38 819.17 793.5 864.09 793.5 774.32 793.73 774.27" />
                            <path class="cls-1 k9b3path" d="M798.33,866.94l-241.7-47.8,5.16-.99v-91.66l241.67,47.8-5.13,.99v91.66Zm-226.9-48.69l217.25,42.96v-86.03l-217.24-42.97v86.04Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="1021.44 729.27 1021.36 729.26 793.73 774.27 793.95 774.32 793.95 864.17 794.03 864.19 1021.66 819.16 1021.44 819.12 1021.44 729.27" />
                            <path class="cls-1" d="M793.93,866.11l-4.8-1.03v-89.8l-4.92-.95,7.1-1.5,229.98-45.48,4.88,.92,.09,.99v88.9s4.76,.96,4.76,.96l-6.96,1.46-230.13,45.52Zm4.84-90.93v86.16l217.83-43.09v-86.15s-217.83,43.08-217.83,43.08Z" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="793.95 858.74 793.5 858.5 793.5 859.56 793.73 859.69 793.95 859.56 793.95 858.74" />
                            <polygon class="cls-1" points="793.95 865.06 788.68 862.44 788.68 850.25 798.33 855.68 798.78 861.63 796.25 863.81 793.95 865.06" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="793.73 774.27 793.5 774.32 793.5 864.09 793.95 864.17 793.95 774.32 793.73 774.27" />
                            <polygon class="cls-1" points="798.78 866.99 788.68 865.05 788.68 773.35 793.5 772.42 798.55 773.31 798.78 774.32 798.78 866.99" />
                          </g>
                        </g>
                        <g>
                          <g>
                            <polygon class="cls-3" points="796.61 775.35 1023.73 820.27 1251.36 775.26 1251.44 775.27 1251.43 774.99 1251.66 774.94 1024.03 729.93 796.38 774.94 796.61 774.99 796.61 775.35" />
                            <path class="cls-1" d="M1023.73,822.18l-231.94-45.87v-.37s-4.79-.96-4.79-.96l6.98-1.48,230.06-45.49,237.35,46.94-5.11,.99v1.84l-4.61-.69-227.93,45.07Zm-218.64-47.05l218.64,43.24,218.93-43.3-218.63-43.24-218.94,43.29Z" />
                          </g>
                          <g className="k9b4base">
                            <polygon className={`k9b4 ${isHovered94 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered94(true)}
                              onMouseLeave={() => setIsHovered94(false)} points="1023.73 820.27 796.61 775.35 796.61 865.12 796.38 865.17 1023.5 910.09 1023.5 820.32 1023.73 820.27" />
                            <path class="cls-1 k9b4path" d="M1028.33,912.94l-241.7-47.8,5.16-.99v-91.66l241.67,47.8-5.13,.99v91.66Zm-226.9-48.69l217.25,42.96v-86.03l-217.24-42.97v86.04Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="1251.44 775.27 1251.36 775.26 1023.73 820.27 1023.95 820.32 1023.95 910.17 1024.03 910.19 1251.66 865.16 1251.44 865.12 1251.44 775.27" />
                            <path class="cls-1" d="M1023.93,912.11l-4.8-1.03v-89.8l-4.92-.95,7.1-1.5,229.98-45.48,4.88,.92,.09,.99v88.9s4.76,.96,4.76,.96l-6.96,1.46-230.13,45.52Zm4.84-90.93v86.16l217.83-43.09v-86.15s-217.83,43.08-217.83,43.08Z" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1023.95 904.74 1023.5 904.5 1023.5 905.56 1023.73 905.69 1023.95 905.56 1023.95 904.74" />
                            <polygon class="cls-1" points="1023.95 911.06 1018.68 908.44 1018.68 896.25 1028.33 901.68 1028.78 907.63 1026.25 909.81 1023.95 911.06" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1023.73 820.27 1023.5 820.32 1023.5 910.09 1023.95 910.17 1023.95 820.32 1023.73 820.27" />
                            <polygon class="cls-1" points="1028.78 912.99 1018.68 911.05 1018.68 819.35 1023.5 818.42 1028.55 819.31 1028.78 820.32 1028.78 912.99" />
                          </g>
                        </g>
                        <g>
                          <g>
                            <polygon class="cls-3" points="1024.61 820.35 1251.73 865.27 1479.36 820.26 1479.44 820.27 1479.43 819.99 1479.66 819.94 1252.03 774.93 1024.38 819.94 1024.61 819.99 1024.61 820.35" />
                            <path class="cls-1" d="M1251.73,867.18l-231.94-45.87v-.37s-4.79-.96-4.79-.96l6.98-1.48,230.06-45.49,237.35,46.94-5.11,.99v1.84l-4.61-.69-227.93,45.07Zm-218.64-47.05l218.64,43.24,218.93-43.3-218.63-43.24-218.94,43.29Z" />
                          </g>
                          <g className="k9b5base">
                            <polygon className={`k9b5 ${isHovered95 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered95(true)}
                              onMouseLeave={() => setIsHovered95(false)} points="1251.73 865.27 1024.61 820.35 1024.61 910.12 1024.38 910.17 1251.5 955.09 1251.5 865.32 1251.73 865.27" />
                            <path class="cls-1 k9b5path" d="M1256.33,957.94l-241.7-47.8,5.16-.99v-91.66l241.67,47.8-5.13,.99v91.66Zm-226.9-48.69l217.25,42.96v-86.03l-217.24-42.97v86.04Z" />
                          </g>
                          <g className="k9b5base">
                            <polygon className={`k9b5 ${isHovered95 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered95(true)}
                              onMouseLeave={() => setIsHovered95(false)} points="1479.44 820.27 1479.36 820.26 1251.73 865.27 1251.95 865.32 1251.95 955.17 1252.03 955.19 1479.66 910.16 1479.44 910.12 1479.44 820.27" />
                            <path class="cls-1 k9b5path" d="M1251.93,957.11l-4.8-1.03v-89.8l-4.92-.95,7.1-1.5,229.98-45.48,4.88,.92,.09,.99v88.9s4.76,.96,4.76,.96l-6.96,1.46-230.13,45.52Zm4.84-90.93v86.16l217.83-43.09v-86.15s-217.83,43.08-217.83,43.08Z" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1251.95 949.74 1251.5 949.5 1251.5 950.56 1251.73 950.69 1251.95 950.56 1251.95 949.74" />
                            <polygon class="cls-1" points="1251.95 956.06 1246.68 953.44 1246.68 941.25 1256.33 946.68 1256.78 952.63 1254.25 954.81 1251.95 956.06" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1251.73 865.27 1251.5 865.32 1251.5 955.09 1251.95 955.17 1251.95 865.32 1251.73 865.27" />
                            <polygon class="cls-1" points="1256.78 957.99 1246.68 956.05 1246.68 864.35 1251.5 863.42 1256.55 864.31 1256.78 865.32 1256.78 957.99" />
                          </g>
                        </g>
                      </g>
                      <g>
                        <g>
                          <g>
                            <polygon class="cls-3" points="568.61 544.35 795.73 589.27 1023.36 544.26 1023.44 544.27 1023.43 543.99 1023.66 543.94 796.03 498.93 568.38 543.94 568.61 543.99 568.61 544.35" />
                            <path class="cls-1" d="M795.73,591.18l-231.94-45.87v-.37s-4.79-.96-4.79-.96l6.98-1.48,230.06-45.49,237.35,46.94-5.11,.99v1.84l-4.61-.69-227.93,45.07Zm-218.64-47.05l218.64,43.24,218.93-43.3-218.63-43.24-218.94,43.29Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="795.73 589.27 568.61 544.35 568.61 634.12 568.38 634.17 795.5 679.09 795.5 589.32 795.73 589.27" />
                            <path class="cls-1" d="M800.33,681.94l-241.7-47.8,5.16-.99v-91.66l241.67,47.8-5.13,.99v91.66Zm-226.9-48.69l217.25,42.96v-86.03l-217.24-42.97v86.04Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="1023.44 544.27 1023.36 544.26 795.73 589.27 795.95 589.32 795.95 679.17 796.03 679.19 1023.66 634.16 1023.44 634.12 1023.44 544.27" />
                            <path class="cls-1" d="M795.93,681.11l-4.8-1.03v-89.8l-4.92-.95,7.1-1.5,229.98-45.48,4.88,.92,.09,.99v88.9s4.76,.96,4.76,.96l-6.96,1.46-230.13,45.52Zm4.84-90.93v86.16l217.83-43.09v-86.15s-217.83,43.08-217.83,43.08Z" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="795.95 673.74 795.5 673.5 795.5 674.56 795.73 674.69 795.95 674.56 795.95 673.74" />
                            <polygon class="cls-1" points="795.95 680.06 790.68 677.44 790.68 665.25 800.33 670.68 800.78 676.63 798.25 678.81 795.95 680.06" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="795.73 589.27 795.5 589.32 795.5 679.09 795.95 679.17 795.95 589.32 795.73 589.27" />
                            <polygon class="cls-1" points="800.78 681.99 790.68 680.05 790.68 588.35 795.5 587.42 800.55 588.31 800.78 589.32 800.78 681.99" />
                          </g>
                        </g>
                        <g>
                          <g>
                            <polygon class="cls-3" points="796.61 589.35 1023.73 634.27 1251.36 589.26 1251.44 589.27 1251.43 588.99 1251.66 588.94 1024.03 543.93 796.38 588.94 796.61 588.99 796.61 589.35" />
                            <path class="cls-1" d="M1023.73,636.18l-231.94-45.87v-.37s-4.79-.96-4.79-.96l6.98-1.48,230.06-45.49,237.35,46.94-5.11,.99v1.84l-4.61-.69-227.93,45.07Zm-218.64-47.05l218.64,43.24,218.93-43.3-218.63-43.24-218.94,43.29Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="1023.73 634.27 796.61 589.35 796.61 679.12 796.38 679.17 1023.5 724.09 1023.5 634.32 1023.73 634.27" />
                            <path class="cls-1" d="M1028.33,726.94l-241.7-47.8,5.16-.99v-91.66l241.67,47.8-5.13,.99v91.66Zm-226.9-48.69l217.25,42.96v-86.03l-217.24-42.97v86.04Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="1251.44 589.27 1251.36 589.26 1023.73 634.27 1023.95 634.32 1023.95 724.17 1024.03 724.19 1251.66 679.16 1251.44 679.12 1251.44 589.27" />
                            <path class="cls-1" d="M1023.93,726.11l-4.8-1.03v-89.8l-4.92-.95,7.1-1.5,229.98-45.48,4.88,.92,.09,.99v88.9s4.76,.96,4.76,.96l-6.96,1.46-230.13,45.52Zm4.84-90.93v86.16l217.83-43.09v-86.15s-217.83,43.08-217.83,43.08Z" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1023.95 718.74 1023.5 718.5 1023.5 719.56 1023.73 719.69 1023.95 719.56 1023.95 718.74" />
                            <polygon class="cls-1" points="1023.95 725.06 1018.68 722.44 1018.68 710.25 1028.33 715.68 1028.78 721.63 1026.25 723.81 1023.95 725.06" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1023.73 634.27 1023.5 634.32 1023.5 724.09 1023.95 724.17 1023.95 634.32 1023.73 634.27" />
                            <polygon class="cls-1" points="1028.78 726.99 1018.68 725.05 1018.68 633.35 1023.5 632.42 1028.55 633.31 1028.78 634.32 1028.78 726.99" />
                          </g>
                        </g>
                        <g>
                          <g>
                            <polygon class="cls-3" points="1026.61 635.35 1253.73 680.27 1481.36 635.26 1481.44 635.27 1481.43 634.99 1481.66 634.94 1254.03 589.93 1026.38 634.94 1026.61 634.99 1026.61 635.35" />
                            <path class="cls-1" d="M1253.73,682.18l-231.94-45.87v-.37s-4.79-.96-4.79-.96l6.98-1.48,230.06-45.49,237.35,46.94-5.11,.99v1.84l-4.61-.69-227.93,45.07Zm-218.64-47.05l218.64,43.24,218.93-43.3-218.63-43.24-218.94,43.29Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="1253.73 680.27 1026.61 635.35 1026.61 725.12 1026.38 725.17 1253.5 770.09 1253.5 680.32 1253.73 680.27" />
                            <path class="cls-1" d="M1258.33,772.94l-241.7-47.8,5.16-.99v-91.66l241.67,47.8-5.13,.99v91.66Zm-226.9-48.69l217.25,42.96v-86.03l-217.24-42.97v86.04Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="1481.44 635.27 1481.36 635.26 1253.73 680.27 1253.95 680.32 1253.95 770.17 1254.03 770.19 1481.66 725.16 1481.44 725.12 1481.44 635.27" />
                            <path class="cls-1" d="M1253.93,772.11l-4.8-1.03v-89.8l-4.92-.95,7.1-1.5,229.98-45.48,4.88,.92,.09,.99v88.9s4.76,.96,4.76,.96l-6.96,1.46-230.13,45.52Zm4.84-90.93v86.16l217.83-43.09v-86.15s-217.83,43.08-217.83,43.08Z" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1253.95 764.74 1253.5 764.5 1253.5 765.56 1253.73 765.69 1253.95 765.56 1253.95 764.74" />
                            <polygon class="cls-1" points="1253.95 771.06 1248.68 768.44 1248.68 756.25 1258.33 761.68 1258.78 767.63 1256.25 769.81 1253.95 771.06" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1253.73 680.27 1253.5 680.32 1253.5 770.09 1253.95 770.17 1253.95 680.32 1253.73 680.27" />
                            <polygon class="cls-1" points="1258.78 772.99 1248.68 771.05 1248.68 679.35 1253.5 678.42 1258.55 679.31 1258.78 680.32 1258.78 772.99" />
                          </g>
                        </g>
                        <g>
                          <g>
                            <polygon class="cls-3" points="1254.61 680.35 1481.73 725.27 1709.36 680.26 1709.44 680.27 1709.43 679.99 1709.66 679.94 1482.03 634.93 1254.38 679.94 1254.61 679.99 1254.61 680.35" />
                            <path class="cls-1" d="M1481.73,727.18l-231.94-45.87v-.37s-4.79-.96-4.79-.96l6.98-1.48,230.06-45.49,237.35,46.94-5.11,.99v1.84l-4.61-.69-227.93,45.07Zm-218.64-47.05l218.64,43.24,218.93-43.3-218.63-43.24-218.94,43.29Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="1481.73 725.27 1254.61 680.35 1254.61 770.12 1254.38 770.17 1481.5 815.09 1481.5 725.32 1481.73 725.27" />
                            <path class="cls-1" d="M1486.33,817.94l-241.7-47.8,5.16-.99v-91.66l241.67,47.8-5.13,.99v91.66Zm-226.9-48.69l217.25,42.96v-86.03l-217.24-42.97v86.04Z" />
                          </g>
                          <g className="k10b6base">
                            <polygon className={`k10b6 ${isHovered106 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered106(true)}
                              onMouseLeave={() => setIsHovered106(false)} points="1709.44 680.27 1709.36 680.26 1481.73 725.27 1481.95 725.32 1481.95 815.17 1482.03 815.19 1709.66 770.16 1709.44 770.12 1709.44 680.27" />
                            <path class="cls-1 k10b6path" d="M1481.93,817.11l-4.8-1.03v-89.8l-4.92-.95,7.1-1.5,229.98-45.48,4.88,.92,.09,.99v88.9s4.76,.96,4.76,.96l-6.96,1.46-230.13,45.52Zm4.84-90.93v86.16l217.83-43.09v-86.15s-217.83,43.08-217.83,43.08Z" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1481.95 809.74 1481.5 809.5 1481.5 810.56 1481.73 810.69 1481.95 810.56 1481.95 809.74" />
                            <polygon class="cls-1" points="1481.95 816.06 1476.68 813.44 1476.68 801.25 1486.33 806.68 1486.78 812.63 1484.25 814.81 1481.95 816.06" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1481.73 725.27 1481.5 725.32 1481.5 815.09 1481.95 815.17 1481.95 725.32 1481.73 725.27" />
                            <polygon class="cls-1" points="1486.78 817.99 1476.68 816.05 1476.68 724.35 1481.5 723.42 1486.55 724.31 1486.78 725.32 1486.78 817.99" />
                          </g>
                        </g>
                        <g>
                          <g>
                            <polygon class="cls-3" points="338.61 590.35 565.73 635.27 793.36 590.26 793.44 590.27 793.43 589.99 793.66 589.94 566.03 544.93 338.38 589.94 338.61 589.99 338.61 590.35" />
                            <path class="cls-1" d="M565.73,637.18l-231.94-45.87v-.37s-4.79-.96-4.79-.96l6.98-1.48,230.06-45.49,237.35,46.94-5.11,.99v1.84l-4.61-.69-227.93,45.07Zm-218.64-47.05l218.64,43.24,218.93-43.3-218.63-43.24-218.94,43.29Z" />
                          </g>
                          <g className="k10b2base">
                            <polygon className={`k10b2 ${isHovered102 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered102(true)}
                              onMouseLeave={() => setIsHovered102(false)} points="565.73 635.27 338.61 590.35 338.61 680.12 338.38 680.17 565.5 725.09 565.5 635.32 565.73 635.27" />
                            <path class="cls-1 k10b2path" d="M570.33,727.94l-241.7-47.8,5.16-.99v-91.66l241.67,47.8-5.13,.99v91.66Zm-226.9-48.69l217.25,42.96v-86.03l-217.24-42.97v86.04Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="793.44 590.27 793.36 590.26 565.73 635.27 565.95 635.32 565.95 725.17 566.03 725.19 793.66 680.16 793.44 680.12 793.44 590.27" />
                            <path class="cls-1" d="M565.93,727.11l-4.8-1.03v-89.8l-4.92-.95,7.1-1.5,229.98-45.48,4.88,.92,.09,.99v88.9s4.76,.96,4.76,.96l-6.96,1.46-230.13,45.52Zm4.84-90.93v86.16l217.83-43.09v-86.15s-217.83,43.08-217.83,43.08Z" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="565.95 719.74 565.5 719.5 565.5 720.56 565.73 720.69 565.95 720.56 565.95 719.74" />
                            <polygon class="cls-1" points="565.95 726.06 560.68 723.44 560.68 711.25 570.33 716.68 570.78 722.63 568.25 724.81 565.95 726.06" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="565.73 635.27 565.5 635.32 565.5 725.09 565.95 725.17 565.95 635.32 565.73 635.27" />
                            <polygon class="cls-1" points="570.78 727.99 560.68 726.05 560.68 634.35 565.5 633.42 570.55 634.31 570.78 635.32 570.78 727.99" />
                          </g>
                        </g>
                        <g>
                          <g>
                            <polygon class="cls-3" points="566.61 635.35 793.73 680.27 1021.36 635.26 1021.44 635.27 1021.43 634.99 1021.66 634.94 794.03 589.93 566.38 634.94 566.61 634.99 566.61 635.35" />
                            <path class="cls-1" d="M793.73,682.18l-231.94-45.87v-.37s-4.79-.96-4.79-.96l6.98-1.48,230.06-45.49,237.35,46.94-5.11,.99v1.84l-4.61-.69-227.93,45.07Zm-218.64-47.05l218.64,43.24,218.93-43.3-218.63-43.24-218.94,43.29Z" />
                          </g>
                          <g className="k10b3base">
                            <polygon className={`k10b3 ${isHovered103 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered103(true)}
                              onMouseLeave={() => setIsHovered103(false)} points="793.73 680.27 566.61 635.35 566.61 725.12 566.38 725.17 793.5 770.09 793.5 680.32 793.73 680.27" />
                            <path class="cls-1 k10b3path" d="M798.33,772.94l-241.7-47.8,5.16-.99v-91.66l241.67,47.8-5.13,.99v91.66Zm-226.9-48.69l217.25,42.96v-86.03l-217.24-42.97v86.04Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="1021.44 635.27 1021.36 635.26 793.73 680.27 793.95 680.32 793.95 770.17 794.03 770.19 1021.66 725.16 1021.44 725.12 1021.44 635.27" />
                            <path class="cls-1" d="M793.93,772.11l-4.8-1.03v-89.8l-4.92-.95,7.1-1.5,229.98-45.48,4.88,.92,.09,.99v88.9s4.76,.96,4.76,.96l-6.96,1.46-230.13,45.52Zm4.84-90.93v86.16l217.83-43.09v-86.15s-217.83,43.08-217.83,43.08Z" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="793.95 764.74 793.5 764.5 793.5 765.56 793.73 765.69 793.95 765.56 793.95 764.74" />
                            <polygon class="cls-1" points="793.95 771.06 788.68 768.44 788.68 756.25 798.33 761.68 798.78 767.63 796.25 769.81 793.95 771.06" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="793.73 680.27 793.5 680.32 793.5 770.09 793.95 770.17 793.95 680.32 793.73 680.27" />
                            <polygon class="cls-1" points="798.78 772.99 788.68 771.05 788.68 679.35 793.5 678.42 798.55 679.31 798.78 680.32 798.78 772.99" />
                          </g>
                        </g>
                        <g>
                          <g>
                            <polygon class="cls-3" points="796.61 681.35 1023.73 726.27 1251.36 681.26 1251.44 681.27 1251.43 680.99 1251.66 680.94 1024.03 635.93 796.38 680.94 796.61 680.99 796.61 681.35" />
                            <path class="cls-1" d="M1023.73,728.18l-231.94-45.87v-.37s-4.79-.96-4.79-.96l6.98-1.48,230.06-45.49,237.35,46.94-5.11,.99v1.84l-4.61-.69-227.93,45.07Zm-218.64-47.05l218.64,43.24,218.93-43.3-218.63-43.24-218.94,43.29Z" />
                          </g>
                          <g className="k10b4base">
                            <polygon className={`k10b4 ${isHovered104 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered104(true)}
                              onMouseLeave={() => setIsHovered104(false)} points="1023.73 726.27 796.61 681.35 796.61 771.12 796.38 771.17 1023.5 816.09 1023.5 726.32 1023.73 726.27" />
                            <path class="cls-1 k10b4path" d="M1028.33,818.94l-241.7-47.8,5.16-.99v-91.66l241.67,47.8-5.13,.99v91.66Zm-226.9-48.69l217.25,42.96v-86.03l-217.24-42.97v86.04Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="1251.44 681.27 1251.36 681.26 1023.73 726.27 1023.95 726.32 1023.95 816.17 1024.03 816.19 1251.66 771.16 1251.44 771.12 1251.44 681.27" />
                            <path class="cls-1" d="M1023.93,818.11l-4.8-1.03v-89.8l-4.92-.95,7.1-1.5,229.98-45.48,4.88,.92,.09,.99v88.9s4.76,.96,4.76,.96l-6.96,1.46-230.13,45.52Zm4.84-90.93v86.16l217.83-43.09v-86.15s-217.83,43.08-217.83,43.08Z" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1023.95 810.74 1023.5 810.5 1023.5 811.56 1023.73 811.69 1023.95 811.56 1023.95 810.74" />
                            <polygon class="cls-1" points="1023.95 817.06 1018.68 814.44 1018.68 802.25 1028.33 807.68 1028.78 813.63 1026.25 815.81 1023.95 817.06" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1023.73 726.27 1023.5 726.32 1023.5 816.09 1023.95 816.17 1023.95 726.32 1023.73 726.27" />
                            <polygon class="cls-1" points="1028.78 818.99 1018.68 817.05 1018.68 725.35 1023.5 724.42 1028.55 725.31 1028.78 726.32 1028.78 818.99" />
                          </g>
                        </g>
                        <g>
                          <g>
                            <polygon class="cls-3" points="1024.61 726.35 1251.73 771.27 1479.36 726.26 1479.44 726.27 1479.43 725.99 1479.66 725.94 1252.03 680.93 1024.38 725.94 1024.61 725.99 1024.61 726.35" />
                            <path class="cls-1" d="M1251.73,773.18l-231.94-45.87v-.37s-4.79-.96-4.79-.96l6.98-1.48,230.06-45.49,237.35,46.94-5.11,.99v1.84l-4.61-.69-227.93,45.07Zm-218.64-47.05l218.64,43.24,218.93-43.3-218.63-43.24-218.94,43.29Z" />
                          </g>
                          <g className="k10b5base">
                            <polygon className={`k10b5 ${isHovered105 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered105(true)}
                              onMouseLeave={() => setIsHovered105(false)} points="1251.73 771.27 1024.61 726.35 1024.61 816.12 1024.38 816.17 1251.5 861.09 1251.5 771.32 1251.73 771.27" />
                            <path class="cls-1 k10b5path" d="M1256.33,863.94l-241.7-47.8,5.16-.99v-91.66l241.67,47.8-5.13,.99v91.66Zm-226.9-48.69l217.25,42.96v-86.03l-217.24-42.97v86.04Z" />
                          </g>
                          <g className="k10b5base">
                            <polygon className={`k10b5 ${isHovered105 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered105(true)}
                              onMouseLeave={() => setIsHovered105(false)} points="1479.44 726.27 1479.36 726.26 1251.73 771.27 1251.95 771.32 1251.95 861.17 1252.03 861.19 1479.66 816.16 1479.44 816.12 1479.44 726.27" />
                            <path class="cls-1 k10b5path" d="M1251.93,863.11l-4.8-1.03v-89.8l-4.92-.95,7.1-1.5,229.98-45.48,4.88,.92,.09,.99v88.9s4.76,.96,4.76,.96l-6.96,1.46-230.13,45.52Zm4.84-90.93v86.16l217.83-43.09v-86.15s-217.83,43.08-217.83,43.08Z" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1251.95 855.74 1251.5 855.5 1251.5 856.56 1251.73 856.69 1251.95 856.56 1251.95 855.74" />
                            <polygon class="cls-1" points="1251.95 862.06 1246.68 859.44 1246.68 847.25 1256.33 852.68 1256.78 858.63 1254.25 860.81 1251.95 862.06" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1251.73 771.27 1251.5 771.32 1251.5 861.09 1251.95 861.17 1251.95 771.32 1251.73 771.27" />
                            <polygon class="cls-1" points="1256.78 863.99 1246.68 862.05 1246.68 770.35 1251.5 769.42 1256.55 770.31 1256.78 771.32 1256.78 863.99" />
                          </g>
                        </g>
                      </g>
                      <g>
                        <g>
                          <g>
                            <polygon class="cls-3" points="568.61 450.35 795.73 495.27 1023.36 450.26 1023.44 450.27 1023.43 449.99 1023.66 449.94 796.03 404.93 568.38 449.94 568.61 449.99 568.61 450.35" />
                            <path class="cls-1" d="M795.73,497.18l-231.94-45.87v-.37s-4.79-.96-4.79-.96l6.98-1.48,230.06-45.49,237.35,46.94-5.11,.99v1.84l-4.61-.69-227.93,45.07Zm-218.64-47.05l218.64,43.24,218.93-43.3-218.63-43.24-218.94,43.29Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="795.73 495.27 568.61 450.35 568.61 540.12 568.38 540.17 795.5 585.09 795.5 495.32 795.73 495.27" />
                            <path class="cls-1" d="M800.33,587.94l-241.7-47.8,5.16-.99v-91.66l241.67,47.8-5.13,.99v91.66Zm-226.9-48.69l217.25,42.96v-86.03l-217.24-42.97v86.04Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="1023.44 450.27 1023.36 450.26 795.73 495.27 795.95 495.32 795.95 585.17 796.03 585.19 1023.66 540.16 1023.44 540.12 1023.44 450.27" />
                            <path class="cls-1" d="M795.93,587.11l-4.8-1.03v-89.8l-4.92-.95,7.1-1.5,229.98-45.48,4.88,.92,.09,.99v88.9s4.76,.96,4.76,.96l-6.96,1.46-230.13,45.52Zm4.84-90.93v86.16l217.83-43.09v-86.15s-217.83,43.08-217.83,43.08Z" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="795.95 579.74 795.5 579.5 795.5 580.56 795.73 580.69 795.95 580.56 795.95 579.74" />
                            <polygon class="cls-1" points="795.95 586.06 790.68 583.44 790.68 571.25 800.33 576.68 800.78 582.63 798.25 584.81 795.95 586.06" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="795.73 495.27 795.5 495.32 795.5 585.09 795.95 585.17 795.95 495.32 795.73 495.27" />
                            <polygon class="cls-1" points="800.78 587.99 790.68 586.05 790.68 494.35 795.5 493.42 800.55 494.31 800.78 495.32 800.78 587.99" />
                          </g>
                        </g>
                        <g>
                          <g>
                            <polygon class="cls-3" points="796.61 495.35 1023.73 540.27 1251.36 495.26 1251.44 495.27 1251.43 494.99 1251.66 494.94 1024.03 449.93 796.38 494.94 796.61 494.99 796.61 495.35" />
                            <path class="cls-1" d="M1023.73,542.18l-231.94-45.87v-.37s-4.79-.96-4.79-.96l6.98-1.48,230.06-45.49,237.35,46.94-5.11,.99v1.84l-4.61-.69-227.93,45.07Zm-218.64-47.05l218.64,43.24,218.93-43.3-218.63-43.24-218.94,43.29Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="1023.73 540.27 796.61 495.35 796.61 585.12 796.38 585.17 1023.5 630.09 1023.5 540.32 1023.73 540.27" />
                            <path class="cls-1" d="M1028.33,632.94l-241.7-47.8,5.16-.99v-91.66l241.67,47.8-5.13,.99v91.66Zm-226.9-48.69l217.25,42.96v-86.03l-217.24-42.97v86.04Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="1251.44 495.27 1251.36 495.26 1023.73 540.27 1023.95 540.32 1023.95 630.17 1024.03 630.19 1251.66 585.16 1251.44 585.12 1251.44 495.27" />
                            <path class="cls-1" d="M1023.93,632.11l-4.8-1.03v-89.8l-4.92-.95,7.1-1.5,229.98-45.48,4.88,.92,.09,.99v88.9s4.76,.96,4.76,.96l-6.96,1.46-230.13,45.52Zm4.84-90.93v86.16l217.83-43.09v-86.15s-217.83,43.08-217.83,43.08Z" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1023.95 624.74 1023.5 624.5 1023.5 625.56 1023.73 625.69 1023.95 625.56 1023.95 624.74" />
                            <polygon class="cls-1" points="1023.95 631.06 1018.68 628.44 1018.68 616.25 1028.33 621.68 1028.78 627.63 1026.25 629.81 1023.95 631.06" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1023.73 540.27 1023.5 540.32 1023.5 630.09 1023.95 630.17 1023.95 540.32 1023.73 540.27" />
                            <polygon class="cls-1" points="1028.78 632.99 1018.68 631.05 1018.68 539.35 1023.5 538.42 1028.55 539.31 1028.78 540.32 1028.78 632.99" />
                          </g>
                        </g>
                        <g>
                          <g>
                            <polygon class="cls-3" points="1026.61 541.35 1253.73 586.27 1481.36 541.26 1481.44 541.27 1481.43 540.99 1481.66 540.94 1254.03 495.93 1026.38 540.94 1026.61 540.99 1026.61 541.35" />
                            <path class="cls-1" d="M1253.73,588.18l-231.94-45.87v-.37s-4.79-.96-4.79-.96l6.98-1.48,230.06-45.49,237.35,46.94-5.11,.99v1.84l-4.61-.69-227.93,45.07Zm-218.64-47.05l218.64,43.24,218.93-43.3-218.63-43.24-218.94,43.29Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="1253.73 586.27 1026.61 541.35 1026.61 631.12 1026.38 631.17 1253.5 676.09 1253.5 586.32 1253.73 586.27" />
                            <path class="cls-1" d="M1258.33,678.94l-241.7-47.8,5.16-.99v-91.66l241.67,47.8-5.13,.99v91.66Zm-226.9-48.69l217.25,42.96v-86.03l-217.24-42.97v86.04Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="1481.44 541.27 1481.36 541.26 1253.73 586.27 1253.95 586.32 1253.95 676.17 1254.03 676.19 1481.66 631.16 1481.44 631.12 1481.44 541.27" />
                            <path class="cls-1" d="M1253.93,678.11l-4.8-1.03v-89.8l-4.92-.95,7.1-1.5,229.98-45.48,4.88,.92,.09,.99v88.9s4.76,.96,4.76,.96l-6.96,1.46-230.13,45.52Zm4.84-90.93v86.16l217.83-43.09v-86.15s-217.83,43.08-217.83,43.08Z" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1253.95 670.74 1253.5 670.5 1253.5 671.56 1253.73 671.69 1253.95 671.56 1253.95 670.74" />
                            <polygon class="cls-1" points="1253.95 677.06 1248.68 674.44 1248.68 662.25 1258.33 667.68 1258.78 673.63 1256.25 675.81 1253.95 677.06" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1253.73 586.27 1253.5 586.32 1253.5 676.09 1253.95 676.17 1253.95 586.32 1253.73 586.27" />
                            <polygon class="cls-1" points="1258.78 678.99 1248.68 677.05 1248.68 585.35 1253.5 584.42 1258.55 585.31 1258.78 586.32 1258.78 678.99" />
                          </g>
                        </g>
                        <g>
                          <g className="k11b6base">
                            <polygon className={`k11b6 ${isHovered116 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered116(true)}
                              onMouseLeave={() => setIsHovered116(false)} points="1254.61 586.35 1481.73 631.27 1709.36 586.26 1709.44 586.27 1709.43 585.99 1709.66 585.94 1482.03 540.93 1254.38 585.94 1254.61 585.99 1254.61 586.35" />
                            <path class="cls-1 k11b6path" d="M1481.73,633.18l-231.94-45.87v-.37s-4.79-.96-4.79-.96l6.98-1.48,230.06-45.49,237.35,46.94-5.11,.99v1.84l-4.61-.69-227.93,45.07Zm-218.64-47.05l218.64,43.24,218.93-43.3-218.63-43.24-218.94,43.29Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="1481.73 631.27 1254.61 586.35 1254.61 676.12 1254.38 676.17 1481.5 721.09 1481.5 631.32 1481.73 631.27" />
                            <path class="cls-1" d="M1486.33,723.94l-241.7-47.8,5.16-.99v-91.66l241.67,47.8-5.13,.99v91.66Zm-226.9-48.69l217.25,42.96v-86.03l-217.24-42.97v86.04Z" />
                          </g>
                          <g className="k11b6base">
                            <polygon className={`k11b6 ${isHovered116 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered116(true)}
                              onMouseLeave={() => setIsHovered116(false)} points="1709.44 586.27 1709.36 586.26 1481.73 631.27 1481.95 631.32 1481.95 721.17 1482.03 721.19 1709.66 676.16 1709.44 676.12 1709.44 586.27" />
                            <path class="cls-1 k11b6path" d="M1481.93,723.11l-4.8-1.03v-89.8l-4.92-.95,7.1-1.5,229.98-45.48,4.88,.92,.09,.99v88.9s4.76,.96,4.76,.96l-6.96,1.46-230.13,45.52Zm4.84-90.93v86.16l217.83-43.09v-86.15s-217.83,43.08-217.83,43.08Z" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1481.95 715.74 1481.5 715.5 1481.5 716.56 1481.73 716.69 1481.95 716.56 1481.95 715.74" />
                            <polygon class="cls-1" points="1481.95 722.06 1476.68 719.44 1476.68 707.25 1486.33 712.68 1486.78 718.63 1484.25 720.81 1481.95 722.06" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1481.73 631.27 1481.5 631.32 1481.5 721.09 1481.95 721.17 1481.95 631.32 1481.73 631.27" />
                            <polygon class="cls-1" points="1486.78 723.99 1476.68 722.05 1476.68 630.35 1481.5 629.42 1486.55 630.31 1486.78 631.32 1486.78 723.99" />
                          </g>
                        </g>
                        <g>
                          <g className="k11b2base">
                            <polygon className={`k11b2 ${isHovered112 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered112(true)}
                              onMouseLeave={() => setIsHovered112(false)} points="338.61 496.35 565.73 541.27 793.36 496.26 793.44 496.27 793.43 495.99 793.66 495.94 566.03 450.93 338.38 495.94 338.61 495.99 338.61 496.35" />
                            <path class="cls-1 k11b2path" d="M565.73,543.18l-231.94-45.87v-.37s-4.79-.96-4.79-.96l6.98-1.48,230.06-45.49,237.35,46.94-5.11,.99v1.84l-4.61-.69-227.93,45.07Zm-218.64-47.05l218.64,43.24,218.93-43.3-218.63-43.24-218.94,43.29Z" />
                          </g>
                          <g className="k11b2base">
                            <polygon className={`k11b2 ${isHovered112 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered112(true)}
                              onMouseLeave={() => setIsHovered112(false)} points="565.73 541.27 338.61 496.35 338.61 586.12 338.38 586.17 565.5 631.09 565.5 541.32 565.73 541.27" />
                            <path class="cls-1 k11b2path" d="M570.33,633.94l-241.7-47.8,5.16-.99v-91.66l241.67,47.8-5.13,.99v91.66Zm-226.9-48.69l217.25,42.96v-86.03l-217.24-42.97v86.04Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="793.44 496.27 793.36 496.26 565.73 541.27 565.95 541.32 565.95 631.17 566.03 631.19 793.66 586.16 793.44 586.12 793.44 496.27" />
                            <path class="cls-1" d="M565.93,633.11l-4.8-1.03v-89.8l-4.92-.95,7.1-1.5,229.98-45.48,4.88,.92,.09,.99v88.9s4.76,.96,4.76,.96l-6.96,1.46-230.13,45.52Zm4.84-90.93v86.16l217.83-43.09v-86.15s-217.83,43.08-217.83,43.08Z" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="565.95 625.74 565.5 625.5 565.5 626.56 565.73 626.69 565.95 626.56 565.95 625.74" />
                            <polygon class="cls-1" points="565.95 632.06 560.68 629.44 560.68 617.25 570.33 622.68 570.78 628.63 568.25 630.81 565.95 632.06" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="565.73 541.27 565.5 541.32 565.5 631.09 565.95 631.17 565.95 541.32 565.73 541.27" />
                            <polygon class="cls-1" points="570.78 633.99 560.68 632.05 560.68 540.35 565.5 539.42 570.55 540.31 570.78 541.32 570.78 633.99" />
                          </g>
                        </g>
                        <g>
                          <g className="k11b3base">
                            <polygon className={`k11b3 ${isHovered113 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered113(true)}
                              onMouseLeave={() => setIsHovered113(false)} points="566.61 541.35 793.73 586.27 1021.36 541.26 1021.44 541.27 1021.43 540.99 1021.66 540.94 794.03 495.93 566.38 540.94 566.61 540.99 566.61 541.35" />
                            <path class="cls-1 k11b3path" d="M793.73,588.18l-231.94-45.87v-.37s-4.79-.96-4.79-.96l6.98-1.48,230.06-45.49,237.35,46.94-5.11,.99v1.84l-4.61-.69-227.93,45.07Zm-218.64-47.05l218.64,43.24,218.93-43.3-218.63-43.24-218.94,43.29Z" />
                          </g>
                          <g className="k11b3base">
                            <polygon className={`k11b3 ${isHovered113 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered113(true)}
                              onMouseLeave={() => setIsHovered113(false)} points="793.73 586.27 566.61 541.35 566.61 631.12 566.38 631.17 793.5 676.09 793.5 586.32 793.73 586.27" />
                            <path class="cls-1 k11b3path" d="M798.33,678.94l-241.7-47.8,5.16-.99v-91.66l241.67,47.8-5.13,.99v91.66Zm-226.9-48.69l217.25,42.96v-86.03l-217.24-42.97v86.04Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="1021.44 541.27 1021.36 541.26 793.73 586.27 793.95 586.32 793.95 676.17 794.03 676.19 1021.66 631.16 1021.44 631.12 1021.44 541.27" />
                            <path class="cls-1" d="M793.93,678.11l-4.8-1.03v-89.8l-4.92-.95,7.1-1.5,229.98-45.48,4.88,.92,.09,.99v88.9s4.76,.96,4.76,.96l-6.96,1.46-230.13,45.52Zm4.84-90.93v86.16l217.83-43.09v-86.15s-217.83,43.08-217.83,43.08Z" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="793.95 670.74 793.5 670.5 793.5 671.56 793.73 671.69 793.95 671.56 793.95 670.74" />
                            <polygon class="cls-1" points="793.95 677.06 788.68 674.44 788.68 662.25 798.33 667.68 798.78 673.63 796.25 675.81 793.95 677.06" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="793.73 586.27 793.5 586.32 793.5 676.09 793.95 676.17 793.95 586.32 793.73 586.27" />
                            <polygon class="cls-1" points="798.78 678.99 788.68 677.05 788.68 585.35 793.5 584.42 798.55 585.31 798.78 586.32 798.78 678.99" />
                          </g>
                        </g>
                        <g>
                          <g className="k11b4base">
                            <polygon className={`k11b4 ${isHovered114 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered114(true)}
                              onMouseLeave={() => setIsHovered114(false)} points="796.61 587.35 1023.73 632.27 1251.36 587.26 1251.44 587.27 1251.43 586.99 1251.66 586.94 1024.03 541.93 796.38 586.94 796.61 586.99 796.61 587.35" />
                            <path class="cls-1 k11b4path" d="M1023.73,634.18l-231.94-45.87v-.37s-4.79-.96-4.79-.96l6.98-1.48,230.06-45.49,237.35,46.94-5.11,.99v1.84l-4.61-.69-227.93,45.07Zm-218.64-47.05l218.64,43.24,218.93-43.3-218.63-43.24-218.94,43.29Z" />
                          </g>
                          <g className="k11b4base">
                            <polygon className={`k11b4 ${isHovered114 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered114(true)}
                              onMouseLeave={() => setIsHovered114(false)} points="1023.73 632.27 796.61 587.35 796.61 677.12 796.38 677.17 1023.5 722.09 1023.5 632.32 1023.73 632.27" />
                            <path class="cls-1 k11b4path" d="M1028.33,724.94l-241.7-47.8,5.16-.99v-91.66l241.67,47.8-5.13,.99v91.66Zm-226.9-48.69l217.25,42.96v-86.03l-217.24-42.97v86.04Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="1251.44 587.27 1251.36 587.26 1023.73 632.27 1023.95 632.32 1023.95 722.17 1024.03 722.19 1251.66 677.16 1251.44 677.12 1251.44 587.27" />
                            <path class="cls-1" d="M1023.93,724.11l-4.8-1.03v-89.8l-4.92-.95,7.1-1.5,229.98-45.48,4.88,.92,.09,.99v88.9s4.76,.96,4.76,.96l-6.96,1.46-230.13,45.52Zm4.84-90.93v86.16l217.83-43.09v-86.15s-217.83,43.08-217.83,43.08Z" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1023.95 716.74 1023.5 716.5 1023.5 717.56 1023.73 717.69 1023.95 717.56 1023.95 716.74" />
                            <polygon class="cls-1" points="1023.95 723.06 1018.68 720.44 1018.68 708.25 1028.33 713.68 1028.78 719.63 1026.25 721.81 1023.95 723.06" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1023.73 632.27 1023.5 632.32 1023.5 722.09 1023.95 722.17 1023.95 632.32 1023.73 632.27" />
                            <polygon class="cls-1" points="1028.78 724.99 1018.68 723.05 1018.68 631.35 1023.5 630.42 1028.55 631.31 1028.78 632.32 1028.78 724.99" />
                          </g>
                        </g>
                        <g>
                          <g className="k11b5base">
                            <polygon className={`k11b5 ${isHovered115 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered115(true)}
                              onMouseLeave={() => setIsHovered115(false)} points="1024.61 632.35 1251.73 677.27 1479.36 632.26 1479.44 632.27 1479.43 631.99 1479.66 631.94 1252.03 586.93 1024.38 631.94 1024.61 631.99 1024.61 632.35" />
                            <path class="cls-1 k11b5path" d="M1251.73,679.18l-231.94-45.87v-.37s-4.79-.96-4.79-.96l6.98-1.48,230.06-45.49,237.35,46.94-5.11,.99v1.84l-4.61-.69-227.93,45.07Zm-218.64-47.05l218.64,43.24,218.93-43.3-218.63-43.24-218.94,43.29Z" />
                          </g>
                          <g className="k11b5base">
                            <polygon className={`k11b5 ${isHovered115 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered115(true)}
                              onMouseLeave={() => setIsHovered115(false)} points="1251.73 677.27 1024.61 632.35 1024.61 722.12 1024.38 722.17 1251.5 767.09 1251.5 677.32 1251.73 677.27" />
                            <path class="cls-1 k11b5path" d="M1256.33,769.94l-241.7-47.8,5.16-.99v-91.66l241.67,47.8-5.13,.99v91.66Zm-226.9-48.69l217.25,42.96v-86.03l-217.24-42.97v86.04Z" />
                          </g>
                          <g className="k11b5base">
                            <polygon className={`k11b5 ${isHovered115 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered115(true)}
                              onMouseLeave={() => setIsHovered115(false)} points="1479.44 632.27 1479.36 632.26 1251.73 677.27 1251.95 677.32 1251.95 767.17 1252.03 767.19 1479.66 722.16 1479.44 722.12 1479.44 632.27" />
                            <path class="cls-1 k11b5path" d="M1251.93,769.11l-4.8-1.03v-89.8l-4.92-.95,7.1-1.5,229.98-45.48,4.88,.92,.09,.99v88.9s4.76,.96,4.76,.96l-6.96,1.46-230.13,45.52Zm4.84-90.93v86.16l217.83-43.09v-86.15s-217.83,43.08-217.83,43.08Z" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1251.95 761.74 1251.5 761.5 1251.5 762.56 1251.73 762.69 1251.95 762.56 1251.95 761.74" />
                            <polygon class="cls-1" points="1251.95 768.06 1246.68 765.44 1246.68 753.25 1256.33 758.68 1256.78 764.63 1254.25 766.81 1251.95 768.06" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1251.73 677.27 1251.5 677.32 1251.5 767.09 1251.95 767.17 1251.95 677.32 1251.73 677.27" />
                            <polygon class="cls-1" points="1256.78 769.99 1246.68 768.05 1246.68 676.35 1251.5 675.42 1256.55 676.31 1256.78 677.32 1256.78 769.99" />
                          </g>
                        </g>
                      </g>
                      <g>
                        <g>
                          <g className="k12b1base">
                            <polygon className={`k12b1 ${isHovered121 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered121(true)}
                              onMouseLeave={() => setIsHovered121(false)} points="645.14 378.05 846.89 417.96 1049.1 377.97 1049.17 377.98 1049.16 377.73 1049.36 377.69 847.16 337.7 644.94 377.69 645.14 377.73 645.14 378.05" />
                            <path class="cls-1 k12b1path" d="M846.89,419.65l-206.04-40.74v-.33s-4.26-.85-4.26-.85l6.2-1.31,204.37-40.41,210.84,41.7-4.54,.88v1.64l-4.09-.61-202.47,40.04Zm-194.22-41.79l194.22,38.41,194.48-38.46-194.21-38.41-194.49,38.46Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="846.89 417.96 645.14 378.05 645.14 457.79 644.94 457.84 846.69 497.74 846.69 418 846.89 417.96" />
                            <path class="cls-1" d="M850.98,500.28l-214.71-42.47,4.59-.88v-81.42l214.68,42.46-4.56,.88v81.42Zm-201.55-43.25l192.99,38.16v-76.43l-192.98-38.17v76.43Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="1049.17 377.98 1049.1 377.97 846.89 417.96 847.09 418 847.09 497.81 847.16 497.83 1049.36 457.83 1049.17 457.79 1049.17 377.98" />
                            <path class="cls-1" d="M847.07,499.54l-4.27-.91v-79.77l-4.37-.84,6.31-1.33,204.3-40.4,4.33,.82,.08,.88v78.97s4.23,.85,4.23,.85l-6.18,1.29-204.43,40.44Zm4.3-80.77v76.53l193.5-38.28v-76.52s-193.5,38.27-193.5,38.27Z" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="847.09 492.99 846.69 492.78 846.69 493.72 846.89 493.83 847.09 493.72 847.09 492.99" />
                            <polygon class="cls-1" points="847.09 498.6 842.41 496.28 842.41 485.45 850.98 490.27 851.38 495.55 849.13 497.5 847.09 498.6" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="846.89 417.96 846.69 418 846.69 497.74 847.09 497.81 847.09 418 846.89 417.96" />
                            <polygon class="cls-1" points="851.38 500.32 842.41 498.6 842.41 417.14 846.69 416.31 851.18 417.1 851.38 418 851.38 500.32" />
                          </g>
                        </g>
                        <g>
                          <g className="k12b8base">
                            <polygon className={`k12b8 ${isHovered128 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered128(true)}
                              onMouseLeave={() => setIsHovered128(false)} points="847.67 418.03 1049.43 457.93 1251.63 417.94 1251.7 417.95 1251.69 417.7 1251.9 417.66 1049.69 377.67 847.47 417.66 847.67 417.7 847.67 418.03" />
                            <path class="cls-1 k12b8path" d="M1049.43,459.62l-206.04-40.74v-.33s-4.26-.85-4.26-.85l6.2-1.31,204.37-40.41,210.84,41.7-4.54,.88v1.64l-4.09-.61-202.47,40.04Zm-194.22-41.79l194.22,38.41,194.48-38.46-194.21-38.41-194.49,38.46Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="1049.43 457.93 847.67 418.03 847.67 497.77 847.47 497.81 1049.23 537.71 1049.23 457.97 1049.43 457.93" />
                            <path class="cls-1" d="M1053.51,540.25l-214.71-42.47,4.59-.88v-81.42l214.68,42.46-4.56,.88v81.42Zm-201.55-43.25l192.99,38.16v-76.43l-192.98-38.17v76.43Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="1251.7 417.95 1251.63 417.94 1049.43 457.93 1049.63 457.97 1049.63 537.78 1049.69 537.8 1251.9 497.8 1251.7 497.77 1251.7 417.95" />
                            <path class="cls-1" d="M1049.61,539.51l-4.27-.91v-79.77l-4.37-.84,6.31-1.33,204.3-40.4,4.33,.82,.08,.88v78.97s4.23,.85,4.23,.85l-6.18,1.29-204.43,40.44Zm4.3-80.77v76.53l193.5-38.28v-76.52s-193.5,38.27-193.5,38.27Z" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1049.63 532.97 1049.23 532.75 1049.23 533.69 1049.43 533.81 1049.63 533.69 1049.63 532.97" />
                            <polygon class="cls-1" points="1049.63 538.57 1044.94 536.25 1044.94 525.43 1053.51 530.24 1053.91 535.53 1051.67 537.47 1049.63 538.57" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1049.43 457.93 1049.23 457.97 1049.23 537.71 1049.63 537.78 1049.63 457.97 1049.43 457.93" />
                            <polygon class="cls-1" points="1053.91 540.3 1044.94 538.57 1044.94 457.11 1049.23 456.29 1053.71 457.07 1053.91 457.97 1053.91 540.3" />
                          </g>
                        </g>
                        <g>
                          <g className="k12b7base">
                            <polygon className={`k12b7 ${isHovered127 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered127(true)}
                              onMouseLeave={() => setIsHovered127(false)} points="1051.98 458.89 1253.74 498.79 1455.94 458.81 1456.01 458.82 1456 458.56 1456.21 458.52 1254 418.54 1051.78 458.52 1051.98 458.56 1051.98 458.89" />
                            <path class="cls-1 k12b7path" d="M1253.74,500.48l-206.04-40.74v-.33s-4.26-.85-4.26-.85l6.2-1.31,204.37-40.41,210.84,41.7-4.54,.88v1.64l-4.09-.61-202.47,40.04Zm-194.22-41.79l194.22,38.41,194.48-38.46-194.21-38.41-194.49,38.46Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="1253.74 498.79 1051.98 458.89 1051.98 538.63 1051.78 538.67 1253.54 578.57 1253.54 498.83 1253.74 498.79" />
                            <path class="cls-1" d="M1257.82,581.11l-214.71-42.47,4.59-.88v-81.42l214.68,42.46-4.56,.88v81.42Zm-201.55-43.25l192.99,38.16v-76.43l-192.98-38.17v76.43Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="1456.01 458.82 1455.94 458.81 1253.74 498.79 1253.94 498.83 1253.94 578.65 1254 578.67 1456.21 538.67 1456.01 538.63 1456.01 458.82" />
                            <path class="cls-1" d="M1253.92,580.37l-4.27-.91v-79.77l-4.37-.84,6.31-1.33,204.3-40.4,4.33,.82,.08,.88v78.97s4.23,.85,4.23,.85l-6.18,1.29-204.43,40.44Zm4.3-80.77v76.53l193.5-38.28v-76.52s-193.5,38.27-193.5,38.27Z" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1253.94 573.83 1253.54 573.62 1253.54 574.55 1253.74 574.67 1253.94 574.55 1253.94 573.83" />
                            <polygon class="cls-1" points="1253.94 579.44 1249.25 577.11 1249.25 566.29 1257.82 571.1 1258.22 576.39 1255.98 578.33 1253.94 579.44" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1253.74 498.79 1253.54 498.83 1253.54 578.57 1253.94 578.65 1253.94 498.83 1253.74 498.79" />
                            <polygon class="cls-1" points="1258.22 581.16 1249.25 579.43 1249.25 497.97 1253.54 497.15 1258.02 497.93 1258.22 498.83 1258.22 581.16" />
                          </g>
                        </g>
                        <g>
                          <g className="k12b6base">
                            <polygon className={`k12b6 ${isHovered126 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered126(true)}
                              onMouseLeave={() => setIsHovered126(false)} points="1254.52 498.86 1456.27 538.77 1658.48 498.78 1658.55 498.79 1658.54 498.54 1658.74 498.5 1456.54 458.51 1254.32 498.5 1254.52 498.54 1254.52 498.86" />
                            <path class="cls-1 k12b6path" d="M1456.27,540.46l-206.04-40.74v-.33s-4.26-.85-4.26-.85l6.2-1.31,204.37-40.41,210.84,41.7-4.54,.88v1.64l-4.09-.61-202.47,40.04Zm-194.22-41.79l194.22,38.41,194.48-38.46-194.21-38.41-194.49,38.46Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="1456.27 538.77 1254.52 498.86 1254.52 578.6 1254.32 578.65 1456.07 618.55 1456.07 538.81 1456.27 538.77" />
                            <path class="cls-1" d="M1460.36,621.09l-214.71-42.47,4.59-.88v-81.42l214.68,42.46-4.56,.88v81.42Zm-201.55-43.25l192.99,38.16v-76.43l-192.98-38.17v76.43Z" />
                          </g>
                          <g className="k12b6base">
                            <polygon className={`k12b6 ${isHovered126 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered126(true)}
                              onMouseLeave={() => setIsHovered126(false)} points="1658.55 498.79 1658.48 498.78 1456.27 538.77 1456.47 538.81 1456.47 618.62 1456.54 618.64 1658.74 578.64 1658.55 578.6 1658.55 498.79" />
                            <path class="cls-1 k12b6path" d="M1456.45,620.35l-4.27-.91v-79.77l-4.37-.84,6.31-1.33,204.3-40.4,4.33,.82,.08,.88v78.97s4.23,.85,4.23,.85l-6.18,1.29-204.43,40.44Zm4.3-80.77v76.53l193.5-38.28v-76.52s-193.5,38.27-193.5,38.27Z" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1456.47 613.8 1456.07 613.59 1456.07 614.53 1456.27 614.64 1456.47 614.53 1456.47 613.8" />
                            <polygon class="cls-1" points="1456.47 619.41 1451.79 617.09 1451.79 606.26 1460.36 611.08 1460.76 616.36 1458.51 618.31 1456.47 619.41" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1456.27 538.77 1456.07 538.81 1456.07 618.55 1456.47 618.62 1456.47 538.81 1456.27 538.77" />
                            <polygon class="cls-1" points="1460.76 621.13 1451.79 619.41 1451.79 537.95 1456.07 537.12 1460.56 537.91 1460.76 538.81 1460.76 621.13" />
                          </g>
                        </g>
                        <g>
                          <g className="k12b2base">
                            <polygon className={`k12b2 ${isHovered122 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered122(true)}
                              onMouseLeave={() => setIsHovered122(false)} points="440.83 418.92 642.58 458.82 844.79 418.83 844.86 418.84 844.85 418.59 845.05 418.55 642.85 378.56 440.63 418.55 440.83 418.59 440.83 418.92" />
                            <path class="cls-1 k12b2path" d="M642.58,460.51l-206.04-40.74v-.33s-4.26-.85-4.26-.85l6.2-1.31,204.37-40.41,210.84,41.7-4.54,.88v1.64l-4.09-.61-202.47,40.04Zm-194.22-41.79l194.22,38.41,194.48-38.46-194.21-38.41-194.49,38.46Z" />
                          </g>
                          <g className="k12b2base">
                            <polygon className={`k12b2 ${isHovered122 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered122(true)}
                              onMouseLeave={() => setIsHovered122(false)} points="642.58 458.82 440.83 418.92 440.83 498.66 440.63 498.7 642.38 538.6 642.38 458.86 642.58 458.82" />
                            <path class="cls-1 k12b2path" d="M646.66,541.14l-214.71-42.47,4.59-.88v-81.42l214.68,42.46-4.56,.88v81.42Zm-201.55-43.25l192.99,38.16v-76.43l-192.98-38.17v76.43Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="844.86 418.84 844.79 418.83 642.58 458.82 642.78 458.86 642.78 538.67 642.85 538.69 845.05 498.69 844.86 498.66 844.86 418.84" />
                            <path class="cls-1" d="M642.76,540.4l-4.27-.91v-79.77l-4.37-.84,6.31-1.33,204.3-40.4,4.33,.82,.08,.88v78.97s4.23,.85,4.23,.85l-6.18,1.29-204.43,40.44Zm4.3-80.77v76.53l193.5-38.28v-76.52s-193.5,38.27-193.5,38.27Z" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="642.78 533.85 642.38 533.64 642.38 534.58 642.58 534.69 642.78 534.58 642.78 533.85" />
                            <polygon class="cls-1" points="642.78 539.46 638.1 537.14 638.1 526.31 646.66 531.13 647.06 536.42 644.82 538.36 642.78 539.46" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="642.58 458.82 642.38 458.86 642.38 538.6 642.78 538.67 642.78 458.86 642.58 458.82" />
                            <polygon class="cls-1" points="647.06 541.18 638.1 539.46 638.1 458 642.38 457.17 646.86 457.96 647.06 458.86 647.06 541.18" />
                          </g>
                        </g>
                        <g>
                          <g className="k12b3base">
                            <polygon className={`k12b3 ${isHovered123 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered123(true)}
                              onMouseLeave={() => setIsHovered123(false)} points="643.36 458.89 845.12 498.79 1047.32 458.81 1047.39 458.82 1047.38 458.56 1047.59 458.52 845.38 418.54 643.16 458.52 643.36 458.56 643.36 458.89" />
                            <path class="cls-1 k12b3path" d="M845.12,500.48l-206.04-40.74v-.33s-4.26-.85-4.26-.85l6.2-1.31,204.37-40.41,210.84,41.7-4.54,.88v1.64l-4.09-.61-202.47,40.04Zm-194.22-41.79l194.22,38.41,194.48-38.46-194.21-38.41-194.49,38.46Z" />
                          </g>
                          <g className="k12b3base">
                            <polygon className={`k12b3 ${isHovered123 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered123(true)}
                              onMouseLeave={() => setIsHovered123(false)} points="845.12 498.79 643.36 458.89 643.36 538.63 643.16 538.67 844.92 578.57 844.92 498.83 845.12 498.79" />
                            <path class="cls-1 k12b3path" d="M849.2,581.11l-214.71-42.47,4.59-.88v-81.42l214.68,42.46-4.56,.88v81.42Zm-201.55-43.25l192.99,38.16v-76.43l-192.98-38.17v76.43Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="1047.39 458.82 1047.32 458.81 845.12 498.79 845.32 498.83 845.32 578.65 845.38 578.67 1047.59 538.67 1047.39 538.63 1047.39 458.82" />
                            <path class="cls-1" d="M845.3,580.37l-4.27-.91v-79.77l-4.37-.84,6.31-1.33,204.3-40.4,4.33,.82,.08,.88v78.97s4.23,.85,4.23,.85l-6.18,1.29-204.43,40.44Zm4.3-80.77v76.53l193.5-38.28v-76.52s-193.5,38.27-193.5,38.27Z" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="845.32 573.83 844.92 573.62 844.92 574.55 845.12 574.67 845.32 574.55 845.32 573.83" />
                            <polygon class="cls-1" points="845.32 579.44 840.63 577.11 840.63 566.29 849.2 571.1 849.6 576.39 847.36 578.33 845.32 579.44" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="845.12 498.79 844.92 498.83 844.92 578.57 845.32 578.65 845.32 498.83 845.12 498.79" />
                            <polygon class="cls-1" points="849.6 581.16 840.63 579.43 840.63 497.97 844.92 497.15 849.4 497.93 849.6 498.83 849.6 581.16" />
                          </g>
                        </g>
                        <g>
                          <g className="k12b4base">
                            <polygon className={`k12b4 ${isHovered124 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered124(true)}
                              onMouseLeave={() => setIsHovered124(false)} points="847.67 499.75 1049.43 539.65 1251.63 499.67 1251.7 499.68 1251.69 499.43 1251.9 499.39 1049.69 459.4 847.47 499.39 847.67 499.43 847.67 499.75" />
                            <path class="cls-1 k12b4path" d="M1049.43,541.35l-206.04-40.74v-.33s-4.26-.85-4.26-.85l6.2-1.31,204.37-40.41,210.84,41.7-4.54,.88v1.64l-4.09-.61-202.47,40.04Zm-194.22-41.79l194.22,38.41,194.48-38.46-194.21-38.41-194.49,38.46Z" />
                          </g>
                          <g className="k12b4base">
                            <polygon className={`k12b4 ${isHovered124 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered124(true)}
                              onMouseLeave={() => setIsHovered124(false)} points="1049.43 539.65 847.67 499.75 847.67 579.49 847.47 579.54 1049.23 619.44 1049.23 539.69 1049.43 539.65" />
                            <path class="cls-1 k12b4path" d="M1053.51,621.98l-214.71-42.47,4.59-.88v-81.42l214.68,42.46-4.56,.88v81.42Zm-201.55-43.25l192.99,38.16v-76.43l-192.98-38.17v76.43Z" />
                          </g>
                          <g>
                            <polygon class="cls-2" points="1251.7 499.68 1251.63 499.67 1049.43 539.65 1049.63 539.69 1049.63 619.51 1049.69 619.53 1251.9 579.53 1251.7 579.49 1251.7 499.68" />
                            <path class="cls-1" d="M1049.61,621.24l-4.27-.91v-79.77l-4.37-.84,6.31-1.33,204.3-40.4,4.33,.82,.08,.88v78.97s4.23,.85,4.23,.85l-6.18,1.29-204.43,40.44Zm4.3-80.77v76.53l193.5-38.28v-76.52s-193.5,38.27-193.5,38.27Z" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1049.63 614.69 1049.23 614.48 1049.23 615.41 1049.43 615.53 1049.63 615.41 1049.63 614.69" />
                            <polygon class="cls-1" points="1049.63 620.3 1044.94 617.97 1044.94 607.15 1053.51 611.97 1053.91 617.25 1051.67 619.19 1049.63 620.3" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1049.43 539.65 1049.23 539.69 1049.23 619.44 1049.63 619.51 1049.63 539.69 1049.43 539.65" />
                            <polygon class="cls-1" points="1053.91 622.02 1044.94 620.3 1044.94 538.84 1049.23 538.01 1053.71 538.8 1053.91 539.69 1053.91 622.02" />
                          </g>
                        </g>
                        <g>
                          <g className="k12b5base">
                            <polygon className={`k12b5 ${isHovered125 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered125(true)}
                              onMouseLeave={() => setIsHovered125(false)} points="1050.21 539.73 1251.96 579.63 1454.17 539.64 1454.24 539.65 1454.23 539.4 1454.43 539.36 1252.23 499.37 1050.01 539.36 1050.21 539.4 1050.21 539.73" />
                            <path class="cls-1 k12b5path" d="M1251.96,581.32l-206.04-40.74v-.33s-4.26-.85-4.26-.85l6.2-1.31,204.37-40.41,210.84,41.7-4.54,.88v1.64l-4.09-.61-202.47,40.04Zm-194.22-41.79l194.22,38.41,194.48-38.46-194.21-38.41-194.49,38.46Z" />
                          </g>
                          <g className="k12b5base">
                            <polygon className={`k12b5 ${isHovered125 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered125(true)}
                              onMouseLeave={() => setIsHovered125(false)} points="1251.96 579.63 1050.21 539.73 1050.21 619.47 1050.01 619.51 1251.76 659.41 1251.76 579.67 1251.96 579.63" />
                            <path class="cls-1 k12b5path" d="M1256.04,661.95l-214.71-42.47,4.59-.88v-81.42l214.68,42.46-4.56,.88v81.42Zm-201.55-43.25l192.99,38.16v-76.43l-192.98-38.17v76.43Z" />
                          </g>
                          <g className="k12b5base">
                            <polygon className={`k12b5 ${isHovered125 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered125(true)}
                              onMouseLeave={() => setIsHovered125(false)} points="1454.24 539.65 1454.17 539.64 1251.96 579.63 1252.16 579.67 1252.16 659.48 1252.23 659.5 1454.43 619.5 1454.24 619.47 1454.24 539.65" />
                            <path class="cls-1 k12b5path" d="M1252.14,661.21l-4.27-.91v-79.77l-4.37-.84,6.31-1.33,204.3-40.4,4.33,.82,.08,.88v78.97s4.23,.85,4.23,.85l-6.18,1.29-204.43,40.44Zm4.3-80.77v76.53l193.5-38.28v-76.52s-193.5,38.27-193.5,38.27Z" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1252.16 654.67 1251.76 654.45 1251.76 655.39 1251.96 655.5 1252.16 655.39 1252.16 654.67" />
                            <polygon class="cls-1" points="1252.16 660.27 1247.48 657.95 1247.48 647.12 1256.04 651.94 1256.44 657.23 1254.2 659.17 1252.16 660.27" />
                          </g>
                          <g>
                            <polygon class="cls-4" points="1251.96 579.63 1251.76 579.67 1251.76 659.41 1252.16 659.48 1252.16 579.67 1251.96 579.63" />
                            <polygon class="cls-1" points="1256.44 661.99 1247.48 660.27 1247.48 578.81 1251.76 577.98 1256.24 578.77 1256.44 579.67 1256.44 661.99" />
                          </g>
                        </g>
                      </g>
                    </svg></SwiperSlide>
                  </Swiper>
                </div>

              )
              }
              <div className='offset-md-1 col-md-5'>
                <div>
                  <Accordion TransitionProps={{ unmountOnExit: true }} expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography>Kati 1</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <table class="table">
                          <thead>
                            <tr>
                              <th scope="col">Dhoma</th>
                              <th scope="col">Hapesira</th>
                              <th scope="col">Detajet</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr className={`k1b1 ${isHovered11 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered11(true)}
                              onMouseLeave={() => setIsHovered11(false)}>
                              <td>3+1 dhomëshe</td>
                              <td>130.64 ㎡</td>
                                                            <td><button className='detajetbtn' onClick={() => setShowModalfk134b1(true)}>→</button></td>

                            </tr>
                            <tr className={`k1b2 ${isHovered12 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered12(true)}
                              onMouseLeave={() => setIsHovered12(false)}>
                              <td>2+1 dhomëshe</td>
                              <td>110.52 ㎡</td>
                                                            <td><button className='detajetbtn' onClick={() => setShowModalfk12b8(true)}>→</button></td>

                            </tr>
                            <tr className={`k1b3 ${isHovered13 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered13(true)}
                              onMouseLeave={() => setIsHovered13(false)}>
                              <td>1+1 dhomëshe</td>
                              <td>68.41 ㎡</td>
                                                            <td><button className='detajetbtn' onClick={() => setShowModalfk12345678b3(true)}>→</button></td>

                            </tr>
                            <tr className={`k1b4 ${isHovered14 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered14(true)}
                              onMouseLeave={() => setIsHovered14(false)}>
                              <td>2+1 dhomëshe</td>
                              <td>110.17 ㎡</td>
                                                            <td><button className='detajetbtn' onClick={() => setShowModalfk12345678910b4(true)}>→</button></td>

                            </tr>
                            <tr className={`k1b5 ${isHovered15 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered15(true)}
                              onMouseLeave={() => setIsHovered15(false)}>
                              <td>2+1 dhomëshe</td>
                              <td>113.87 ㎡</td>
                                                            <td><button className='detajetbtn' onClick={() => setShowModalfk1234578910b5(true)}>→</button></td>

                            </tr>
                            <tr className={`k1b6 ${isHovered16 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered16(true)}
                              onMouseLeave={() => setIsHovered16(false)}>
                              <td>2+1 dhomëshe</td>
                              <td>111.51 ㎡</td>
                                                            <td><button className='detajetbtn' onClick={() => setShowModalfk123456789b6(true)}>→</button></td>

                            </tr>
                          </tbody>
                        </table>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion TransitionProps={{ unmountOnExit: true }} expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <Typography>Kati 2</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <table class="table">
                          <thead>
                            <tr>
                              <th scope="col">Dhoma</th>
                              <th scope="col">Hapesira</th>
                              <th scope="col">Detajet</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr className={`k2b1 ${isHovered21 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered21(true)}
                              onMouseLeave={() => setIsHovered21(false)}>
                              <td>3+1 dhomëshe</td>
                              <td>130.64 ㎡</td>
                                                            <td><button className='detajetbtn' onClick={() => setShowModalfk25b1(true)}>→</button></td>

                            </tr>
                            <tr className={`k2b2 ${isHovered22 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered22(true)}
                              onMouseLeave={() => setIsHovered22(false)}>
                              <td>2+1 dhomëshe</td>
                              <td>110.52 ㎡</td>
                                                            <td><button className='detajetbtn' onClick={() => setShowModalfk12b8(true)}>→</button></td>

                            </tr>
                            <tr className={`k2b3 ${isHovered23 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered23(true)}
                              onMouseLeave={() => setIsHovered23(false)}>
                              <td>1+1 dhomëshe</td>
                              <td>68.41 ㎡</td>
                                                            <td><button className='detajetbtn' onClick={() => setShowModalfk12345678b3(true)}>→</button></td>

                            </tr>
                            <tr className={`k2b4 ${isHovered24 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered24(true)}
                              onMouseLeave={() => setIsHovered24(false)}>
                              <td>2+1 dhomëshe</td>
                              <td>110.17 ㎡</td>
                                                            <td><button className='detajetbtn' onClick={() => setShowModalfk12345678910b4(true)}>→</button></td>

                            </tr>
                            <tr className={`k2b5 ${isHovered25 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered25(true)}
                              onMouseLeave={() => setIsHovered25(false)}>
                              <td>2+1 dhomëshe</td>
                              <td>113.87 ㎡</td>
                                                            <td><button className='detajetbtn' onClick={() => setShowModalfk1234578910b5(true)}>→</button></td>

                            </tr>
                            <tr className={`k2b6 ${isHovered26 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered26(true)}
                              onMouseLeave={() => setIsHovered26(false)}>
                              <td>2+1 dhomëshe</td>
                              <td>111.51 ㎡</td>
                                                            <td><button className='detajetbtn' onClick={() => setShowModalfk123456789b6(true)}>→</button></td>

                            </tr>
                          </tbody>
                        </table>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion TransitionProps={{ unmountOnExit: true }} expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel3a-content"
                      id="panel3a-header"
                    >
                      <Typography>Kati 3</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <table class="table">
                          <thead>
                            <tr>
                              <th scope="col">Dhoma</th>
                              <th scope="col">Hapesira</th>
                              <th scope="col">Detajet</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr className={`k3b1 ${isHovered31 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered31(true)}
                              onMouseLeave={() => setIsHovered31(false)}>
                              <td>3+1 dhomëshe</td>
                              <td>130.64 ㎡</td>
                                                            <td><button className='detajetbtn' onClick={() => setShowModalfk134b1(true)}>→</button></td>

                            </tr>
                            <tr className={`k3b2 ${isHovered32 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered32(true)}
                              onMouseLeave={() => setIsHovered32(false)}>
                              <td>2+1 dhomëshe</td>
                              <td>110.52 ㎡</td>
                                                            <td><button className='detajetbtn' onClick={() => setShowModalfk12b8(true)}>→</button></td>

                            </tr>
                            <tr className={`k3b3 ${isHovered33 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered33(true)}
                              onMouseLeave={() => setIsHovered33(false)}>
                              <td>1+1 dhomëshe</td>
                              <td>68.41 ㎡</td>
                                                            <td><button className='detajetbtn' onClick={() => setShowModalfk12345678b3(true)}>→</button></td>

                            </tr>
                            <tr className={`k3b4 ${isHovered34 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered34(true)}
                              onMouseLeave={() => setIsHovered34(false)}>
                              <td>2+1 dhomëshe</td>
                              <td>110.17 ㎡</td>
                                                            <td><button className='detajetbtn' onClick={() => setShowModalfk12345678910b4(true)}>→</button></td>

                            </tr>
                            <tr className={`k3b5 ${isHovered35 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered35(true)}
                              onMouseLeave={() => setIsHovered35(false)}>
                              <td>2+1 dhomëshe</td>
                              <td>113.87 ㎡</td>
                                                            <td><button className='detajetbtn' onClick={() => setShowModalfk1234578910b5(true)}>→</button></td>

                            </tr>
                            <tr className={`k3b6 ${isHovered36 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered36(true)}
                              onMouseLeave={() => setIsHovered36(false)}>
                              <td>2+1 dhomëshe</td>
                              <td>111.51 ㎡</td>
                                                            <td><button className='detajetbtn' onClick={() => setShowModalfk123456789b6(true)}>→</button></td>

                            </tr>
                          </tbody>
                        </table>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion TransitionProps={{ unmountOnExit: true }} expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel4a-content"
                      id="panel4a-header"
                    >
                      <Typography>Kati 4</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <table class="table">
                          <thead>
                            <tr>
                              <th scope="col">Dhoma</th>
                              <th scope="col">Hapesira</th>
                              <th scope="col">Detajet</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr className={`k4b1 ${isHovered41 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered41(true)}
                              onMouseLeave={() => setIsHovered41(false)}>
                              <td>3+1 dhomëshe</td>
                              <td>130.64 ㎡</td>
                                                            <td><button className='detajetbtn' onClick={() => setShowModalfk134b1(true)}>→</button></td>

                            </tr>
                            <tr className={`k4b2 ${isHovered42 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered42(true)}
                              onMouseLeave={() => setIsHovered42(false)}>
                              <td>2+1 dhomëshe</td>
                              <td>110.52 ㎡</td>
                                                            <td><button className='detajetbtn' onClick={() => setShowModalfk12b8(true)}>→</button></td>

                            </tr>
                            <tr className={`k4b3 ${isHovered43 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered43(true)}
                              onMouseLeave={() => setIsHovered43(false)}>
                              <td>1+1 dhomëshe</td>
                              <td>68.41 ㎡</td>
                                                            <td><button className='detajetbtn' onClick={() => setShowModalfk12345678b3(true)}>→</button></td>

                            </tr>
                            <tr className={`k4b4 ${isHovered44 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered44(true)}
                              onMouseLeave={() => setIsHovered44(false)}>
                              <td>2+1 dhomëshe</td>
                              <td>110.17 ㎡</td>
                                                            <td><button className='detajetbtn' onClick={() => setShowModalfk12345678910b4(true)}>→</button></td>

                            </tr>
                            <tr className={`k4b5 ${isHovered45 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered45(true)}
                              onMouseLeave={() => setIsHovered45(false)}>
                              <td>2+1 dhomëshe</td>
                              <td>113.87 ㎡</td>
                                                            <td><button className='detajetbtn' onClick={() => setShowModalfk1234578910b5(true)}>→</button></td>

                            </tr>
                            <tr className={`k4b6 ${isHovered46 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered46(true)}
                              onMouseLeave={() => setIsHovered46(false)}>
                              <td>2+1 dhomëshe</td>
                              <td>111.51 ㎡</td>
                                                            <td><button className='detajetbtn' onClick={() => setShowModalfk123456789b6(true)}>→</button></td>

                            </tr>
                          </tbody>
                        </table>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion TransitionProps={{ unmountOnExit: true }} expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel5a-content"
                      id="panel5a-header"
                    >
                      <Typography>Kati 5</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <table class="table">
                          <thead>
                            <tr>
                              <th scope="col">Dhoma</th>
                              <th scope="col">Hapesira</th>
                              <th scope="col">Detajet</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr className={`k5b1 ${isHovered51 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered51(true)}
                              onMouseLeave={() => setIsHovered51(false)}>
                              <td>3+1 dhomëshe</td>
                              <td>130.64 ㎡</td>
                                                            <td><button className='detajetbtn' onClick={() => setShowModalfk25b1(true)}>→</button></td>

                            </tr>
                            <tr className={`k5b2 ${isHovered52 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered52(true)}
                              onMouseLeave={() => setIsHovered52(false)}>
                              <td>2+1 dhomëshe</td>
                              <td>110.52 ㎡</td>
                                                            <td><button className='detajetbtn' onClick={() => setShowModalfk12b8(true)}>→</button></td>

                            </tr>
                            <tr className={`k5b3 ${isHovered53 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered53(true)}
                              onMouseLeave={() => setIsHovered53(false)}>
                              <td>1+1 dhomëshe</td>
                              <td>68.41 ㎡</td>
                                                            <td><button className='detajetbtn' onClick={() => setShowModalfk12345678b3(true)}>→</button></td>

                            </tr>
                            <tr className={`k5b4 ${isHovered54 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered54(true)}
                              onMouseLeave={() => setIsHovered54(false)}>
                              <td>2+1 dhomëshe</td>
                              <td>110.17 ㎡</td>
                                                            <td><button className='detajetbtn' onClick={() => setShowModalfk12345678910b4(true)}>→</button></td>

                            </tr>
                            <tr className={`k5b5 ${isHovered55 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered55(true)}
                              onMouseLeave={() => setIsHovered55(false)}>
                              <td>2+1 dhomëshe</td>
                              <td>113.87 ㎡</td>
                                                            <td><button className='detajetbtn' onClick={() => setShowModalfk1234578910b5(true)}>→</button></td>

                            </tr>
                            <tr className={`k5b6 ${isHovered56 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered56(true)}
                              onMouseLeave={() => setIsHovered56(false)}>
                              <td>2+1 dhomëshe</td>
                              <td>111.51 ㎡</td>
                                                            <td><button className='detajetbtn' onClick={() => setShowModalfk123456789b6(true)}>→</button></td>

                            </tr>
                          </tbody>
                        </table>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion TransitionProps={{ unmountOnExit: true }} expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel6a-content"
                      id="panel6a-header"
                    >
                      <Typography>Kati 6</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <table class="table">
                          <thead>
                            <tr>
                              <th scope="col">Dhoma</th>
                              <th scope="col">Hapesira</th>
                              <th scope="col">Detajet</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr className={`k6b1 ${isHovered61 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered61(true)}
                              onMouseLeave={() => setIsHovered61(false)}>
                              <td>3+1 dhomëshe</td>
                              <td>130.64 ㎡</td>
                                                            <td><button className='detajetbtn' onClick={() => setShowModalfk6b1(true)}>→</button></td>

                            </tr>
                            <tr className={`k6b2 ${isHovered62 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered62(true)}
                              onMouseLeave={() => setIsHovered62(false)}>
                              <td>2+1 dhomëshe</td>
                              <td>110.52 ㎡</td>
                                                            <td><button className='detajetbtn' onClick={() => setShowModalfk6b2(true)}>→</button></td>

                            </tr>
                            <tr className={`k6b3 ${isHovered63 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered63(true)}
                              onMouseLeave={() => setIsHovered63(false)}>
                              <td>1+1 dhomëshe</td>
                              <td>68.41 ㎡</td>
                                                            <td><button className='detajetbtn' onClick={() => setShowModalfk12345678b3(true)}>→</button></td>

                            </tr>
                            <tr className={`k6b4 ${isHovered64 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered64(true)}
                              onMouseLeave={() => setIsHovered64(false)}>
                              <td>2+1 dhomëshe</td>
                              <td>110.17 ㎡</td>
                                                            <td><button className='detajetbtn' onClick={() => setShowModalfk12345678910b4(true)}>→</button></td>

                            </tr>
                            <tr className={`k6b5 ${isHovered65 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered65(true)}
                              onMouseLeave={() => setIsHovered65(false)}>
                              <td>2+1 dhomëshe</td>
                              <td>113.87 ㎡</td>
                                                            <td><button className='detajetbtn' onClick={() => setShowModalfk1234578910b5(true)}>→</button></td>

                            </tr>
                            <tr className={`k6b6 ${isHovered66 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered66(true)}
                              onMouseLeave={() => setIsHovered66(false)}>
                              <td>2+1 dhomëshe</td>
                              <td>111.51 ㎡</td>
                                                            <td><button className='detajetbtn' onClick={() => setShowModalfk123456789b6(true)}>→</button></td>

                            </tr>
                          </tbody>
                        </table>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion TransitionProps={{ unmountOnExit: true }} expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel7a-content"
                      id="panel7a-header"
                    >
                      <Typography>Kati 7</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <table class="table">
                          <thead>
                            <tr>
                              <th scope="col">Dhoma</th>
                              <th scope="col">Hapesira</th>
                              <th scope="col">Detajet</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr className={`k7b1 ${isHovered71 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered71(true)}
                              onMouseLeave={() => setIsHovered71(false)}>
                              <td>3+1 dhomëshe</td>
                              <td>130.64 ㎡</td>
                                                            <td><button className='detajetbtn' onClick={() => setShowModalfk7891011b1(true)}>→</button></td>

                            </tr>
                            <tr className={`k7b2 ${isHovered72 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered72(true)}
                              onMouseLeave={() => setIsHovered72(false)}>
                              <td>2+1 dhomëshe</td>
                              <td>110.52 ㎡</td>
                                                            <td><button className='detajetbtn' onClick={() => setShowModalfk12b8(true)}>→</button></td>

                            </tr>
                            <tr className={`k7b3 ${isHovered73 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered73(true)}
                              onMouseLeave={() => setIsHovered73(false)}>
                              <td>1+1 dhomëshe</td>
                              <td>68.41 ㎡</td>
                                                            <td><button className='detajetbtn' onClick={() => setShowModalfk12345678b3(true)}>→</button></td>

                            </tr>
                            <tr className={`k7b4 ${isHovered74 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered74(true)}
                              onMouseLeave={() => setIsHovered74(false)}>
                              <td>2+1 dhomëshe</td>
                              <td>110.17 ㎡</td>
                                                            <td><button className='detajetbtn' onClick={() => setShowModalfk12345678910b4(true)}>→</button></td>

                            </tr>
                            <tr className={`k7b5 ${isHovered75 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered75(true)}
                              onMouseLeave={() => setIsHovered75(false)}>
                              <td>2+1 dhomëshe</td>
                              <td>113.87 ㎡</td>
                                                            <td><button className='detajetbtn' onClick={() => setShowModalfk1234578910b5(true)}>→</button></td>

                            </tr>
                            <tr className={`k7b6 ${isHovered76 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered76(true)}
                              onMouseLeave={() => setIsHovered76(false)}>
                              <td>2+1 dhomëshe</td>
                              <td>111.51 ㎡</td>
                                                            <td><button className='detajetbtn' onClick={() => setShowModalfk123456789b6(true)}>→</button></td>

                            </tr>
                          </tbody>
                        </table>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion TransitionProps={{ unmountOnExit: true }} expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel8a-content"
                      id="panel8a-header"
                    >
                      <Typography>Kati 8</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <table class="table">
                          <thead>
                            <tr>
                              <th scope="col">Dhoma</th>
                              <th scope="col">Hapesira</th>
                              <th scope="col">Detajet</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr className={`k8b1 ${isHovered81 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered81(true)}
                              onMouseLeave={() => setIsHovered81(false)}>
                              <td>3+1 dhomëshe</td>
                              <td>130.64 ㎡</td>
                                                            <td><button className='detajetbtn' onClick={() => setShowModalfk7891011b1(true)}>→</button></td>

                            </tr>
                            <tr className={`k8b2 ${isHovered82 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered82(true)}
                              onMouseLeave={() => setIsHovered82(false)}>
                              <td>2+1 dhomëshe</td>
                              <td>110.52 ㎡</td>
                                                            <td><button className='detajetbtn' onClick={() => setShowModalfk12b8(true)}>→</button></td>

                            </tr>
                            <tr className={`k8b3 ${isHovered83 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered83(true)}
                              onMouseLeave={() => setIsHovered83(false)}>
                              <td>1+1 dhomëshe</td>
                              <td>68.41 ㎡</td>
                                                            <td><button className='detajetbtn' onClick={() => setShowModalfk12345678b3(true)}>→</button></td>

                            </tr>
                            <tr className={`k8b4 ${isHovered84 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered84(true)}
                              onMouseLeave={() => setIsHovered84(false)}>
                              <td>2+1 dhomëshe</td>
                              <td>110.17 ㎡</td>
                                                            <td><button className='detajetbtn' onClick={() => setShowModalfk12345678910b4(true)}>→</button></td>

                            </tr>
                            <tr className={`k8b5 ${isHovered85 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered85(true)}
                              onMouseLeave={() => setIsHovered85(false)}>
                              <td>2+1 dhomëshe</td>
                              <td>113.87 ㎡</td>
                                                            <td><button className='detajetbtn' onClick={() => setShowModalfk1234578910b5(true)}>→</button></td>

                            </tr>
                            <tr className={`k8b6 ${isHovered86 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered86(true)}
                              onMouseLeave={() => setIsHovered86(false)}>
                              <td>2+1 dhomëshe</td>
                              <td>111.51 ㎡</td>
                                                            <td><button className='detajetbtn' onClick={() => setShowModalfk123456789b6(true)}>→</button></td>

                            </tr>
                          </tbody>
                        </table>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion TransitionProps={{ unmountOnExit: true }} expanded={expanded === 'panel9'} onChange={handleChange('panel9')}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel9a-content"
                      id="panel9a-header"
                    >


                      <Typography>Kati 9</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <table class="table">
                          <thead>
                            <tr>
                              <th scope="col">Dhoma</th>
                              <th scope="col">Hapesira</th>
                              <th scope="col">Detajet</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr className={`k9b1 ${isHovered91 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered91(true)}
                              onMouseLeave={() => setIsHovered91(false)}>
                              <td>3+1 dhomëshe</td>
                              <td>130.64 ㎡</td>
                                                            <td><button className='detajetbtn' onClick={() => setShowModalfk7891011b1(true)}>→</button></td>

                            </tr>
                            <tr className={`k9b2 ${isHovered92 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered92(true)}
                              onMouseLeave={() => setIsHovered92(false)}>
                              <td>2+1 dhomëshe</td>
                              <td>110.52 ㎡</td>
                                                            <td><button className='detajetbtn' onClick={() => setShowModalfk12b8(true)}>→</button></td>

                            </tr>
                            <tr className={`k9b3 ${isHovered93 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered93(true)}
                              onMouseLeave={() => setIsHovered93(false)}>
                              <td>1+1 dhomëshe</td>
                              <td>68.41 ㎡</td>
                                                            <td><button className='detajetbtn' onClick={() => setShowModalfk910b3(true)}>→</button></td>

                            </tr>
                            <tr className={`k9b4 ${isHovered94 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered94(true)}
                              onMouseLeave={() => setIsHovered94(false)}>
                              <td>2+1 dhomëshe</td>
                              <td>110.17 ㎡</td>
                                                            <td><button className='detajetbtn' onClick={() => setShowModalfk12345678910b4(true)}>→</button></td>

                            </tr>
                            <tr className={`k9b5 ${isHovered95 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered95(true)}
                              onMouseLeave={() => setIsHovered95(false)}>
                              <td>2+1 dhomëshe</td>
                              <td>113.87 ㎡</td>
                                                            <td><button className='detajetbtn' onClick={() => setShowModalfk1234578910b5(true)}>→</button></td>

                            </tr>
                            <tr className={`k9b6 ${isHovered96 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered96(true)}
                              onMouseLeave={() => setIsHovered96(false)}>
                              <td>2+1 dhomëshe</td>
                              <td>111.51 ㎡</td>
                                                            <td><button className='detajetbtn' onClick={() => setShowModalfk123456789b6(true)}>→</button></td>

                            </tr>
                          </tbody>
                        </table>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion TransitionProps={{ unmountOnExit: true }} expanded={expanded === 'panel10'} onChange={handleChange('panel10')}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel10a-content"
                      id="panel10a-header"
                    >
                      <Typography>Kati 10</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <table class="table">
                          <thead>
                            <tr>
                              <th scope="col">Dhoma</th>
                              <th scope="col">Hapesira</th>
                              <th scope="col">Detajet</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr className={`k10b1 ${isHovered101 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered101(true)}
                              onMouseLeave={() => setIsHovered101(false)}>
                              <td>3+1 dhomëshe</td>
                              <td>130.64 ㎡</td>
                                                            <td><button className='detajetbtn' onClick={() => setShowModalfk7891011b1(true)}>→</button></td>

                            </tr>
                            <tr className={`k10b2 ${isHovered102 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered102(true)}
                              onMouseLeave={() => setIsHovered102(false)}>
                              <td>2+1 dhomëshe</td>
                              <td>110.52 ㎡</td>
                                                            <td><button className='detajetbtn' onClick={() => setShowModalfk12b8(true)}>→</button></td>

                            </tr>
                            <tr className={`k10b3 ${isHovered103 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered103(true)}
                              onMouseLeave={() => setIsHovered103(false)}>
                              <td>1+1 dhomëshe</td>
                              <td>68.41 ㎡</td>
                                                            <td><button className='detajetbtn' onClick={() => setShowModalfk910b3(true)}>→</button></td>

                            </tr>
                            <tr className={`k10b4 ${isHovered104 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered104(true)}
                              onMouseLeave={() => setIsHovered104(false)}>
                              <td>2+1 dhomëshe</td>
                              <td>110.17 ㎡</td>
                                                            <td><button className='detajetbtn' onClick={() => setShowModalfk12345678910b4(true)}>→</button></td>

                            </tr>
                            <tr className={`k10b5 ${isHovered105 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered105(true)}
                              onMouseLeave={() => setIsHovered105(false)}>
                              <td>2+1 dhomëshe</td>
                              <td>113.87  ㎡</td>
                                                            <td><button className='detajetbtn' onClick={() => setShowModalfk1234578910b5(true)}>→</button></td>

                            </tr>
                            <tr className={`k10b6 ${isHovered106 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered106(true)}
                              onMouseLeave={() => setIsHovered106(false)}>
                              <td>2+1 dhomëshe</td>
                              <td>95.54 ㎡</td>
                                                            <td><button className='detajetbtn' onClick={() => setShowModalfk10b6(true)}>→</button></td>

                            </tr>
                          </tbody>
                        </table>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion TransitionProps={{ unmountOnExit: true }} expanded={expanded === 'panel11'} onChange={handleChange('panel11')}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel11a-content"
                      id="panel11a-header"
                    >
                      <Typography>Kati 11</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <table class="table">
                          <thead>
                            <tr>
                              <th scope="col">Dhoma</th>
                              <th scope="col">Hapesira</th>
                              <th scope="col">Detajet</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr className={`k11b1 ${isHovered111 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered111(true)}
                              onMouseLeave={() => setIsHovered111(false)}>
                              <td>3+1 dhomëshe</td>
                              <td>130.64 ㎡</td>
                                                            <td><button className='detajetbtn' onClick={() => setShowModalfk7891011b1(true)}>→</button></td>

                            </tr>
                            <tr className={`k11b2 ${isHovered112 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered112(true)}
                              onMouseLeave={() => setIsHovered112(false)}>
                              <td>2+1 dhomëshe</td>
                              <td>110.52 ㎡</td>
                                                            <td><button className='detajetbtn' onClick={() => setShowModalfk12b8(true)}>→</button></td>

                            </tr>
                            <tr className={`k11b3 ${isHovered113 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered113(true)}
                              onMouseLeave={() => setIsHovered113(false)}>
                              <td>1+1 dhomëshe</td>
                              <td>68.41 ㎡</td>
                                                            <td><button className='detajetbtn' onClick={() => setShowModalfk910b3(true)}>→</button></td>

                            </tr>
                            <tr className={`k11b4 ${isHovered114 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered114(true)}
                              onMouseLeave={() => setIsHovered114(false)}>
                              <td>2+1 dhomëshe</td>
                              <td>115.75 ㎡</td>
                                                            <td><button className='detajetbtn' onClick={() => setShowModalfk11b4(true)}>→</button></td>

                            </tr>
                            <tr className={`k11b5 ${isHovered115 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered115(true)}
                              onMouseLeave={() => setIsHovered115(false)}>
                              <td>2+1 dhomëshe</td>
                              <td>137.85 ㎡</td>
                                                            <td><button className='detajetbtn' onClick={() => setShowModalfk11b5(true)}>→</button></td>

                            </tr>
                            <tr className={`k11b6 ${isHovered116 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered116(true)}
                              onMouseLeave={() => setIsHovered116(false)}>
                              <td>2+1 dhomëshe</td>
                              <td>102.84 ㎡</td>
                                                            <td><button className='detajetbtn' onClick={() => setShowModalfk11b6(true)}>→</button></td>

                                                          </tr>
                          </tbody>
                        </table>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion TransitionProps={{ unmountOnExit: true }} expanded={expanded === 'panel12'} onChange={handleChange('panel12')}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel12a-content"
                      id="panel12a-header"
                    >
                      <Typography>Kati 12 Penthouse</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <table class="table">
                          <thead>
                            <tr>
                              <th scope="col">Dhoma</th>
                              <th scope="col">Hapesira</th>
                              <th scope="col">Detajet</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr className={`k12b1 ${isHovered121 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered121(true)}
                              onMouseLeave={() => setIsHovered121(false)}>
                              <td>3+1 dhomëshe</td>
                              <td>125.28 ㎡</td>
                              <td><button onClick={() => setShowModalfk12b1(true)}>→</button></td>
                            </tr>
                            <tr className={`k12b2 ${isHovered122 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered122(true)}
                              onMouseLeave={() => setIsHovered122(false)}>
                              <td>2+1 dhomëshe</td>
                              <td>104.09 ㎡ </td>
                              <td><button onClick={() => setShowModalfk12b2(true)}>→</button></td>
                            </tr>
                            <tr className={`k12b3 ${isHovered123 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered123(true)}
                              onMouseLeave={() => setIsHovered123(false)}>
                              <td>1+1 dhomëshe</td>
                              <td>68.41 ㎡</td>
                              <td><button onClick={() => setShowModalfk12b3(true)}>→</button></td>
                            </tr>
                            <tr className={`k12b4 ${isHovered124 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered124(true)}
                              onMouseLeave={() => setIsHovered124(false)}>
                              <td>4+1 dhomëshe</td>
                              <td>190.50 ㎡</td>
                              <td><button onClick={() => setShowModalfk12b4(true)}>→</button></td>
                            </tr>
                            <tr className={`k12b5 ${isHovered125 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered125(true)}
                              onMouseLeave={() => setIsHovered125(false)}>
                              <td>4+1 dhomëshe</td>
                              <td>190.50 ㎡</td>
                              <td><button onClick={() => setShowModalfk12b5(true)}>→</button></td>
                            </tr>
                            <tr className={`k12b6 ${isHovered126 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered126(true)}
                              onMouseLeave={() => setIsHovered126(false)}>
                              <td>2+1 dhomëshe</td>
                              <td>174.33 ㎡</td>
                              <td><button onClick={() => setShowModalfk12b6(true)}>→</button></td>
                            </tr>
                            <tr className={`k12b7 ${isHovered127 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered127(true)}
                              onMouseLeave={() => setIsHovered127(false)}>
                              <td>1+1 dhomëshe</td>
                              <td>68.41 ㎡</td>
                              <td><button onClick={() => setShowModalfk12b7(true)}>→</button></td>
                            </tr>
                            <tr className={`k12b8 ${isHovered128 ? 'act' : ''}`}
                              onMouseEnter={() => setIsHovered128(true)}
                              onMouseLeave={() => setIsHovered128(false)}>
                              <td>4+1 dhomëshe</td>
                              <td>190.50 ㎡</td>
                              <td><button className='detajetbtn' onClick={() => setShowModalfk12b8(true)}>→</button></td>
                            </tr>
                          </tbody>
                        </table>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </div>
              </div>
            </div>

          </div>
        </div>



      </div>

      <div>
        {/* scripts pent*/}

   
        {modalComponents.map(({ showModal, image, setShowModal }) => (
          showModal && (
            <div style={{ position: "fixed", top: "0", bottom: "0", left: "0", right: "0", background: "rgba(0, 0, 0, 0.8)" }}>
              <img src={image} alt="Your Image" style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
              <button onClick={() => setShowModal(false)} style={{ position: "absolute", top: "20px", right: "20px", border:"none",padding:"5px 10px",fontSize:"23px", borderRadius:"8px" }}>
                Close
              </button>
            </div>
          )
        ))}




      </div></>
  )


}

export default Projectpage


// const modalStates = [
//   ['fk12b1', false],
//   ['fk12b2', false],
//   ['fk12b3', false],
//   ['fk12b4', false],
//   ['fk12b5', false],
//   ['fk12b6', false],
//   ['fk12b7', false],
//   ['fk12b8', false]
// ];

// const [modalData, setModalData] = useState({});

// modalStates.forEach(([modalKey, initialValue]) => {
//   const [showModal, setShowModal] = useState(initialValue);
//   setModalData(prevData => ({...prevData, [modalKey]: {showModal, setShowModal}}));
// });

// const modalComponents = [
//   { showModal: modalData.fk12b1.showModal, image: require('../../images/ven/k12b1.jpg'), setShowModal: modalData.fk12b1.setShowModal },
//   { showModal: modalData.fk12b2.showModal, image: require('../../images/ven/k12b2.jpg'), setShowModal: modalData.fk12b2.setShowModal },
//   { showModal: modalData.fk12b3.showModal, image: require('../../images/ven/k12b45.jpg'), setShowModal: modalData.fk12b3.setShowModal },
//   { showModal: modalData.fk12b4.showModal, image: require('../../images/ven/k12b45.jpg'), setShowModal: modalData.fk12b4.setShowModal },
//   { showModal: modalData.fk12b5.showModal, image: require('../../images/ven/k12b45.jpg'), setShowModal: modalData.fk12b5.setShowModal },
//   { showModal: modalData.fk12b6.showModal, image: require('../../images/ven/k12b67.jpg'), setShowModal: modalData.fk12b6.setShowModal },
//   { showModal: modalData.fk12b7.showModal, image: require('../../images/ven/k12b67.jpg'), setShowModal: modalData.fk12b7.setShowModal },
// ];