
import './App.css';
import Navbar from './components/Navbar/Navbar';
import Section1 from './components/Section1/Section1';
import Section2 from './components/Section2/Section2';
import Section3 from './components/Section3/Section3';
import Section4 from './components/Section4/Section4';
import Footer from './components/Footer/Footer';
import * as Icon from 'react-bootstrap-icons';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Contact from './pages/Contact';
import Project from './pages/Project';
import View360 from './pages/View360';
import { dictionary } from './dictionary';


function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home/>}>
        </Route>
        <Route path='/contact' element={<Contact />}></Route>
        <Route path='/360' element={<View360 />}></Route>
        <Route path='/project' element={<Project />}></Route>
      </Routes>
    </BrowserRouter>

    </div>
  );
}

export default App;
