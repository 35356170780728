import React from 'react'
import Footer from '../components/Footer/Footer'
import Navbar from '../components/Navbar/Navbar'
import Section1 from '../components/Section1/Section1'
import Section2 from '../components/Section2/Section2'
import Section3 from '../components/Section3/Section3'
import Section4 from '../components/Section4/Section4'

function Home() {
  return (
    <div>
       <div className='navi px-md-5'>
      <Navbar />
      </div>
      <div>
        <Section1 />
      </div>
      <div>
        <Section2 />
      </div>
      <div>
        <Section3 />
      </div>
      <div>
        <Section4 />
      </div>
      <Footer />
    </div>
  )
}

export default Home
