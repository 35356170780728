import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './navbar.css';

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  const handleNavbarToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="px-md-5">
      <nav className="navbar navbar-expand-lg px-md-5 mx-md-5 mx-3">
        <div className="container px-md-5">
          <Link className="navbar-brand d-none d-md-block" to="/">
            <img src={require('../../images/Xharra Towers - Logo-07.png')} width={80} alt="Logo" />
          </Link>
          <Link className="navbar-brand d-md-none py-4" to="/">
            <img src={require('../../images/Xharra Towers - Logo-04.png')} width={150} alt="Logo" />
          </Link>
          <button
            className={`navbar-toggler${isOpen ? ' active' : ''}`}
            type="button"
            onClick={handleNavbarToggle}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className={`collapse navbar-collapse${isOpen ? ' show' : ''}`}>
            <ul className="navbar-nav me-auto mb-2 mb-lg-0 ms-md-5 ms-0">
              <li className="nav-item">
                <Link className="nav-link me-md-4" to="/" style={{ color: 'white' }}>Home</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link me-md-4" to="/project" style={{ color: 'white' }}>Project</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link me-md-4" to="/360" style={{ color: 'white' }}>360° Tour</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link me-md-4" to="/contact" style={{ color: 'white' }}>Contact</Link>
              </li>
            </ul>
            <span className="navbar-text pe-md-5">
              <ul className='navbar-nav d-flex '>
                <li className='nav-item bre '>
                  <div className=' px-4' style={{ color: 'white' }}>AL</div>
                </li>
                <li className='nav-item'>
                  <div className=' px-4' style={{ color: 'white' }}>EN</div>
                </li>
              </ul>
            </span>
          </div>
        </div>
      </nav>
      {isOpen && (
        <div className="sidebar">
          <button className="sidebar-close" onClick={handleNavbarToggle}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="currentColor"
              className="bi bi-x"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M9.646 8l4.853-4.854a.5.5 0 1 0-.708-.708L8.939 7.293l-4.854-4.853a.5.5 0 0 0-.708.708L8.293 8l-4.853 4.854a.5.5 0 1 0 .708.708L8 8.707l4.854 4.853a.5.5 0 0 0 .708-.708L8.707 8z"
              />
            </svg>
          </button>
          <ul className="sidebar-menu">
            <li className="nav-item">
              <Link className="nav-link" to="/" onClick={handleNavbarToggle}>Home</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/project" onClick={handleNavbarToggle}>Project</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/360" onClick={handleNavbarToggle}>360° Tour</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/contact" onClick={handleNavbarToggle}>Contact</Link>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
}

export default Navbar;
