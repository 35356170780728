import React from 'react'
import './footer.css'
import location from '../../images/location.svg'
import tel from '../../images/tel.svg'
import mail from '../../images/mail.svg'

function Footer() {
  return (
    <div className='footerbg'>
        <div className='container'>
            <div className='row g-0 py-5'>
                    <div className='col-md-4 py-md-0 pt-5 pt-md-0'>
                         <div className='mb-md-3'>
                          <img src={location} />
                         </div>
                         <div>
                         <p>Rr. Tirana p.n, Prizren 20000</p>
                         </div>
                    </div>

                    <div className='col-md-4 py-md-0 pt-5 pt-md-0'>
                         <div className='mb-md-3'>
                          <img src={tel} />
                         </div>
                         <div>
                         <p>+383 43 733 000</p>
                         </div>
                    </div>

                    <div className='col-md-4 py-md-0 pt-5 pt-md-0'>
                         <div className='mb-md-3'>
                          <img src={mail} />
                         </div>
                         <div>
                         <p>info@xharratowers.com</p>
                         </div>
                    </div>
                          
            </div>
            <div className='pb-4'>
                            <p id='pjaperzero'>Copyright © 2023 XHARRA TOWERS. All rights reserved.</p>
                          </div>
        </div>
      
    </div>
  )
}

export default Footer
