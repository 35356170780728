import React from 'react'
import Footer from '../components/Footer/Footer'
import Navbar from '../components/Navbar/Navbar'
import View360fullpage from '../components/View360full/View360fullpage'

function View360() {
    return (
        <div>
            <div className='navi px-md-5'>
                <Navbar />
            </div>

            <View360fullpage />

            <div>
                <Footer />
            </div>

        </div>
    )
}

export default View360
