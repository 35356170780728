import React from 'react'
import './section3.css'

function Section3() {
  return (
    <div className='bgopa'>
      <div className='container'>
               <div className='px-md-5 py-5'>
                      <div className='row g-0 py-5'>
                            <div className='col-md-4'>
                                <div>
                                    <img src={require('../../images/Ikonat-06.png')} width={150} />
                                </div>
                                <div> 
                                    <h3>Maintenance</h3>

                                </div>
                                <div>
                                    <p className='w-50 mx-auto'>The indoor and outdoor environment is under constant care.</p>
                                </div>

                            </div>
                            <div className='col-md-4'>
                                <div>
                                    <img src={require('../../images/Ikonat-05.png')} width={150} />
                                </div>
                                <div> 
                                    <h3>Security
</h3>

                                </div>
                                <div>
                                    <p className='w-50 mx-auto'>The facilities are built with first-hand construction materials and have superior security.</p>
                                </div>

                            </div>
                            <div className='col-md-4'>
                                <div>
                                    <img src={require('../../images/Ikonat-04.png')} width={150} />
                                </div>
                                <div> 
                                    <h3>Innovation</h3>

                                </div>
                                <div>
                                    <p className='w-50 mx-auto'>From the planning to the detailed realization of each element. Innovation at every step of the way.</p>
                                </div>

                            </div>
                      </div>
               </div>
      </div>
    </div>
  )
}

export default Section3
