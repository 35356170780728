import React from 'react'

// import './section1.css'

function Section1() {
  return (
    <div className='sec1bg'>
        <div>
        <img src={require('../../images/5ph.jpg')} style={{width:'100%'}}/>
        </div>
      
    </div>
  )
}
export default Section1
