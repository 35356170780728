import React from 'react'
import './section2.css'

function Section2() {
    return (
        <div className='bgsec2'>
            <div className='container'>
                <div className='py-5'>
                    <div className='py-4 px-md-5'>
                        <h1 className='text-start px-5' style={{color:'white'}}>Location in Kosovo.<br />
                            </h1>
                    </div>
                    <div className='px-5 pb-4 text-start'>
                        <p className='px-md-5 mb-4' style={{color:'white'}}>Xharra Towers offers residential complexes which are compositions of Swiss elements ranging from quality, safety, design, spatial organization to building materials. Working with expert, well-informed and visionary teams will result in magnificent settlements that improve the quality of life.</p>
                        <p className='px-md-5' style={{color:'white'}}>The quality of our work and customer service is uncompromising. With mastery in construction, extremely good use of space and green areas that give life to the environment, our complexes are elite. A combination of creativity and efficiency, our project surpasses even your biggest dreams!</p>
                    
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Section2
