import React from 'react'
import Contactfull from '../components/Contactpagefull/Contactfull'
import Navbar from '../components/Navbar/Navbar'

function Contact() {
  return (
    <div>
        <div className='navi px-md-5'>
          <Navbar />
          </div>
       <div className='bgwhitecontact'>
        <Contactfull />
        </div>
      
    </div>
  )
}

export default Contact
