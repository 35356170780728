import React from 'react';

export const TranslationContext = React.createContext({
  language: 'EN',
  dictionary: {},
});

export const TranslationProvider = ({ children, dictionary }) => {
  const [language, setLanguage] = React.useState('EN');

  const value = React.useMemo(() => {
    return {
      language,
      setLanguage,
      dictionary,
    };
  }, [language, dictionary]);

  return (
    <TranslationContext.Provider value={value}>
      {children}
    </TranslationContext.Provider>
  );
};