export const dictionary = {
    'Home': {
      AL: 'Ballina',
      EN: 'Home'
    },
    'Project': {
      AL: 'Projekti',
      EN: 'Project'
    },
    '360° Tour': {
      AL: '360° Shkall',
      EN: '360° Tour'
    },
    'Contact': {
      AL: 'Kontakti',
      EN: 'Contact'
    },
    'Location in Kosovo. Swiss Concept.': {
      AL: 'Vendndodhja në Kosovë. Koncepti zviceran.',
      EN: 'Location in Kosovo. Swiss Concept.'
    },
    'Xharra Towers offers residential complexes which are compositions of Swiss elements ranging from quality, safety, design, spatial organization to building materials. Working with expert, well-informed and visionary teams will result in magnificent settlements that improve the quality of life.': {
      AL: 'Xharra Towers ofron komplekse banimi të cilat janë kompozime të elementeve zvicerane duke filluar nga cilësia, siguria, dizajni, organizimi hapësinor e deri te materialet e ndërtimit. Puna me ekipe ekspertësh, të mirëinformuar dhe vizionare do të rezultojë në vendbanime të mrekullueshme që përmirësojnë cilësinë e jetës.',
      EN: 'Xharra Towers offers residential complexes which are compositions of Swiss elements ranging from quality, safety, design, spatial organization to building materials. Working with expert, well-informed and visionary teams will result in magnificent settlements that improve the quality of life.'
    },
    'The quality of our work and customer service is uncompromising. With mastery in construction, extremely good use of space and green areas that give life to the environment, our complexes are elite. A combination of creativity and efficiency, our project surpasses even your biggest dreams!': {
      AL: 'Cilësia e punës sonë dhe shërbimi ndaj klientit është i pakompromis. Me mjeshtëri në ndërtim, shfrytëzim jashtëzakonisht të mirë të hapësirës dhe sipërfaqeve të gjelbra që i japin jetë ambientit, komplekset tona janë elitare. Një kombinim i kreativitetit dhe efikasitetit, projekti ynë tejkalon edhe ëndrrat tuaja më të mëdha!',
      EN: 'The quality of our work and customer service is uncompromising. With mastery in construction, extremely good use of space and green areas that give life to the environment, our complexes are elite. A combination of creativity and efficiency, our project surpasses even your biggest dreams!'
    },
    'Maintenance': {
      AL: 'Mirëmbajtje',
      EN: 'Maintenance'
    },
    'The indoor and outdoor environment is under constant care.': {
      AL: 'Ambienti i brendshëm dhe i jashtëm është nën kujdes të vazhdueshëm.',
      EN: 'The indoor and outdoor environment is under constant care.'
    },
    'Security': {
      AL: 'Siguria',
      EN: 'Security'
    },
    'The facilities are built with first-hand construction materials and have superior security': {
      AL: 'Objektet janë të ndërtuara me materiale ndërtimi të dorës së parë dhe kanë siguri superiore.',
      EN: 'The facilities are built with first-hand construction materials and have superior security'
    },
    'Innovation': {
      AL: 'Inovacioni',
      EN: 'Innovation'
    },
    'From the planning to the detailed realization of each element. Innovation at every step of the way.': {
      AL: 'Nga planifikimi deri te realizimi i detajuar i çdo elementi. Inovacion në çdo hap të rrugës.',
      EN: 'From the planning to the detailed realization of each element. Innovation at every step of the way.'
    },
    'CLICK TO VIEW THE PROJECT': {
      AL: 'KLIKONI QË TË SHIKONI PROJEKTIN',
      EN: 'CLICK TO VIEW THE PROJECT'
    },
    'Click To View Apartment in 360': {
      AL: 'Klikoni për të parë apartamentin në 360',
      EN: 'Click To View Apartment in 360'
    },
    'All rights reserved.': {
      AL: 'Të gjitha të drejtat e rezervuara.',
      EN: 'All rights reserved.'
    },
    'View All Projects': {
      AL: 'Shiko të gjitha projektet',
      EN: 'View All Projects'
    },
    'Surface': {
      AL: 'Sipërfaqe',
      EN: 'Surface'
    },
    'Room': {
      AL: 'Dhoma',
      EN: 'Room'
    },
    'For any additional information do not hesitate to Contact Us': {
      AL: 'Për çdo informacion shtesë mos hezitoni të na kontaktoni',
      EN: 'For any additional information do not hesitate to Contact Us'
    },
    
  };