import React from 'react'
import location2 from '../../images/location2.svg'
import tel2 from '../../images/tel2.svg'
import mail2 from '../../images/mail2.svg'


function Contactfull() {
  return (
    <div className='container'>
        <div>
            <div>
                <img src={require('../../images/Contact us.png')} id='contactimg' width={500}/>
            </div>
            <div className=''>
                  <h5>For any additional information do not hesitate to</h5>
            </div>
            <div>
                <h1>Contact Us</h1>
            </div>

            <div className='row g-0 py-5 mt-4'>
            <div className='col-md-4 mb-5 mb-md-0'>
                         <div className='mb-3'>
                          <img src={location2} />
                         </div>
                         <div>
                         <p>Rr. Tirana p.n, Prizren 20000</p>
                         </div>
                    </div>

                    <div className='col-md-4 mb-5 mb-md-0'>
                         <div className='mb-3'>
                          <img src={tel2} />
                         </div>
                         <div>
                         <p>+383 43 733 000</p>
                         </div>
                    </div>

                    <div className='col-md-4'>
                         <div className='mb-3'>
                          <img src={mail2} />
                         </div>
                         <div>
                         <p>info@xharratowers.com</p>
                         </div>
                    </div>

            </div>
        </div>
      
    </div>
  )
}

export default Contactfull
